import { useMemo } from 'react'
import type { ResponseModel, BusinessMode, CurvePoint, ProfitAnalysis } from '@/types/metrics'
import { generateCurvePoints, calculateProfit, findOptimalZone } from '@/lib/profit-calculations'

interface Campaign {
  campaign: string
  cost: number
  conv: number
  value: number
  imprShare?: number
}

interface ProfitInputs {
  businessMode: BusinessMode
  cogsAmount?: number
  breakEvenCpa?: number
}

export function useProfit(
  campaign: Campaign | null, 
  inputs: ProfitInputs
): ProfitAnalysis {
  const campaignName = campaign?.campaign ?? ''
  const cost = campaign?.cost ?? 0
  const conv = campaign?.conv ?? 0
  const value = campaign?.value ?? 0
  const imprShare = campaign?.imprShare ?? 1
  const { businessMode, cogsAmount = 0, breakEvenCpa = 0 } = inputs

  return useMemo(() => {
    // Always return valid data structure, even if empty
    if (!campaign) {
      return {
        curvePoints: [],
        optimalZone: {
          start: 0,
          end: 0,
          maxProfit: 0,
          current: 0,
          targetSpend: 0,
          profitAtTarget: 0
        },
        currentMetrics: {
          cost: 0,
          conv: 0,
          value: 0,
          profit: 0
        }
      }
    }

    // Generate curve points
    const points = generateCurvePoints({
      currentCost: cost,
      currentConv: conv,
      currentValue: value,
      responseModel: 'diminishing',
      businessMode,
      cogsAmount,
      breakEvenCpa,
      impressionShare: imprShare
    })

    // Calculate current profit
    const currentProfit = calculateProfit(
      { cost, conv, value, profit: 0 },
      { businessMode, cogsAmount, breakEvenCpa }
    )

    // Find optimal zone
    const optimalZone = findOptimalZone(points, cost)
    
    return {
      curvePoints: points,
      optimalZone,
      currentMetrics: {
        cost,
        conv,
        value,
        profit: currentProfit
      }
    }
  }, [
    campaignName,
    cost, 
    conv, 
    value, 
    imprShare,
    businessMode, 
    cogsAmount, 
    breakEvenCpa
  ])
}
 