// src/pages/4Cs.tsx
import { useRef, useEffect, useState } from 'react'
import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { Alert, AlertDescription } from "@/components/ui/alert"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { Stage, stages } from '@/lib/4cs-content'
import { SCRIPT_EXAMPLES, scriptInfo } from '@/lib/4cs-scripts'
import { ArrowRightIcon, CopyIcon, AlertTriangle, ChevronRight } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Link } from 'react-router-dom'
import { useToast } from "@/components/ui/use-toast"

const StageVisual = ({ stage, isActive }: {
  stage: (typeof stages)[number],
  isActive: boolean
}) => (
  <div className={cn(
    "w-full transition-all duration-500",
    "flex flex-col items-center justify-center bg-background/50 rounded-lg p-4",
    isActive ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
  )}>
    <div className="mb-4">
      <stage.icon className={cn("w-16 h-16", stage.color)} />
    </div>
    <h3 className="text-2xl font-bold mb-2">{stage.title}</h3>
    <p className="text-sm text-muted-foreground text-center">
      {stage.description}
    </p>
  </div>
)

const ScriptExample = ({
  code,
  info,
  showChallengeLink = false
}: {
  code: string,
  info: (typeof scriptInfo)[keyof typeof scriptInfo],
  showChallengeLink?: boolean
}) => {
  const { toast } = useToast()

  const copyCode = async () => {
    await navigator.clipboard.writeText(code)
    toast({ title: "Copied to clipboard" })
  }

  return (
    <Card className="mb-12">
      <div className="p-6 border-b">
        <div className="flex justify-between items-start mb-4">
          <div className="flex-1 mr-8">
            <h4 className="text-2xl font-semibold mb-3">{info.title}</h4>
            <p className="text-lg text-muted-foreground">{info.description}</p>
          </div>
          <Button onClick={copyCode} variant="default" size="lg">
            <CopyIcon className="w-5 h-5 mr-2" />
            Copy Script
          </Button>
        </div>
        {info.safety.includes('⚠️') && (
          <Alert className="mt-4">
            <AlertTriangle className="h-5 w-5" />
            <AlertDescription className="text-base">{info.safety}</AlertDescription>
          </Alert>
        )}
      </div>

      <Accordion type="single" collapsible>
        <AccordionItem value="code">
          <AccordionTrigger className="px-6">View Code</AccordionTrigger>
          <AccordionContent>
            <div className="p-6 bg-muted/50">
              <pre className="text-sm overflow-x-auto">
                <code>{code}</code>
              </pre>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <div className="p-6 border-t">
        <h5 className="text-lg font-semibold mb-3">Setup Steps:</h5>
        {showChallengeLink && (
          <div className="mb-4 p-4 bg-muted rounded-lg">
            <p className="text-sm mb-2">Never installed a script before?</p>
            <Link
              to="/scary"
              className="text-primary hover:text-primary/80 font-medium inline-flex items-center"
            >
              Take our Script Challenge to learn the basics
              <ChevronRight className="ml-2 h-4 w-4" />
            </Link>
          </div>
        )}
        <ul className="list-disc list-inside text-base text-muted-foreground space-y-2">
          {info.setupSteps.map((step, i) => (
            <li key={i}>{step}</li>
          ))}
        </ul>
      </div>
    </Card>
  )
}

const ChartGuide = () => (
  <div className="my-8">
    <img
      src="/images/which-chart.webp"
      alt="Chart Selection Guide"
      className="w-full rounded-lg shadow-lg"
    />
    <p className="text-sm text-muted-foreground mt-2 text-center">
      Guide to selecting the right chart type for your data
    </p>
  </div>
)

const FrameworkIntro = () => (
  <div className="py-12 space-y-16">
    <div className="text-center mb-12">
      <h1 className="text-5xl font-bold mb-6">The 4Cs Framework</h1>
      <p className="text-2xl text-muted-foreground max-w-3xl mx-auto">
        A proven progression from basic checks to AI-powered optimization for Google Ads Scripts
      </p>
    </div>

    <div className="grid grid-cols-2 gap-12">
      <div className="prose prose-lg dark:prose-invert">
        <h2 className="text-3xl font-semibold mb-4">Automate Your Way to Success</h2>
        <p className="text-xl mb-6">
          Our proven framework takes you from simple checks to AI-powered automation,
          building your confidence and skills at each stage.
        </p>
        <ul className="space-y-4 text-xl">
          {stages
            .filter(stage => stage.id !== 'build')
            .map(stage => (
              <li key={stage.id}>
                <strong className={stage.color}>{stage.id.toUpperCase()}:</strong> {stage.description}
              </li>
            ))}
        </ul>
      </div>

      <div>
        <img
          src="/images/4cs-framework.png"
          alt="4Cs Framework Grid"
          className="w-full max-w-xl rounded-lg shadow-lg mx-auto"
        />
      </div>
    </div>
  </div>
)

export default function FourCsPage() {
  const [activeStageId, setActiveStageId] = useState<string>('check')
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

  useEffect(() => {
    const observers: IntersectionObserver[] = []

    stages.forEach(stage => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setActiveStageId(stage.id)
            }
          })
        },
        {
          threshold: 0.3,
          rootMargin: '-20% 0px -20% 0px'
        }
      )

      if (sectionRefs.current[stage.id]) {
        observer.observe(sectionRefs.current[stage.id]!)
      }
      observers.push(observer)
    })

    return () => observers.forEach(observer => observer.disconnect())
  }, [])

  const renderSectionContent = (section: { content?: string, bullets?: string[] }) => {
    return (
      <div className="prose prose-lg dark:prose-invert">
        {section.content && (
          <p className="mb-4">{section.content}</p>
        )}
        {section.bullets && section.bullets.length > 0 && (
          <ul className="mt-4 space-y-2 list-disc pl-6">
            {section.bullets.map((bullet, i) => (
              <li key={i}>{bullet}</li>
            ))}
          </ul>
        )}
      </div>
    )
  }

  const stageSpecificContent: Record<Stage['id'], React.ReactNode> = {
    check: (
      <ScriptExample
        code={SCRIPT_EXAMPLES.CHECK_ZERO_IMPRESSIONS}
        info={scriptInfo.CHECK_ZERO_IMPRESSIONS}
        showChallengeLink={true}
      />
    ),
    chart: (
      <>
        <div className="prose prose-lg dark:prose-invert mb-8">
          <ScriptExample
            code={SCRIPT_EXAMPLES.EXPORT_TO_SHEETS}
            info={scriptInfo.EXPORT_TO_SHEETS}
          />
        </div>
      </>
    ),
    change: (
      <ScriptExample
        code={SCRIPT_EXAMPLES.PAUSE_OVERSPEND}
        info={scriptInfo.PAUSE_OVERSPEND}
      />
    ),
    cognition: (
      <ScriptExample
        code={SCRIPT_EXAMPLES.AI_INSIGHTS}
        info={scriptInfo.AI_INSIGHTS}
      />
    ),
    build: (
      <div className="bg-primary/5 rounded-lg p-12 mt-8">
        <div className="max-w-3xl mx-auto text-center">
          <Button asChild size="lg">
            <Link to="/build">
              Join Build the Agent
              <ArrowRightIcon className="ml-2 h-4 w-4" />
            </Link>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-background">
      <div className="max-w-7xl mx-auto px-4">
        <FrameworkIntro />
      </div>

      <div className="flex max-w-7xl mx-auto">
        <div className="w-[25%]">
          <div className="sticky top-24">
            {stages.map(stage => (
              <StageVisual
                key={stage.id}
                stage={stage}
                isActive={activeStageId === stage.id}
              />
            ))}
          </div>
        </div>

        <div className="w-[75%]">
          <div className="p-8 max-w-4xl">
            {stages.map(stage => (
              <div
                key={stage.id}
                ref={el => sectionRefs.current[stage.id] = el}
                className="min-h-screen py-12"
                id={stage.id}
              >
                <h2 className="text-4xl font-bold mb-12">{stage.title}</h2>
                <div className="mb-16">
                  {stage.sections.map(section => (
                    <div key={section.id} className="mb-16">
                      <h3 className="text-3xl font-semibold mb-6">{section.title}</h3>
                      {renderSectionContent(section)}
                      {section.id === 'chart-types' && <ChartGuide />}
                    </div>
                  ))}
                  {stageSpecificContent[stage.id]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}