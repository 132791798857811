// src/pages/hourly.tsx
'use client'

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useCampaignData } from "@/contexts/campaign-data";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Line, LineChart, Legend } from 'recharts';
import { useMetricFormatter, MetricKey, METRIC_CONFIGS, getMetricColor } from '@/lib/metrics';
import { useAccounts } from '@/contexts/account-context';
import { DEBUG } from '@/lib/constants';
import { LineChart as LineChartIcon, BarChart as BarChartIcon, AlertCircle } from 'lucide-react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { TablePagination } from "@/components/ui/table-pagination";
import { trackEvent, ANALYTICS_EVENTS } from '@/lib/analytics'
import { Fredo } from '@/components/fredo-chat';

const SAMPLE_COLORS = {
  today: 'hsl(var(--muted))',
  yesterday: 'hsl(var(--muted-foreground))'
} as const

const CHART_COLORS = {
  primary: 'hsl(220 65% 45%)', // Warm dark blue  
  secondary: 'hsl(20 90% 60%)' // Bright warm orange
} as const;

// Transform metrics consistently for both sample and real data
const transformMetrics = (row: any) => {

  const transformed = {
    hour: Number(row.hour || 0),
    campaign: String(row.campaign || ''),
    impr: Number(row.impr || 0),
    clicks: Number(row.clicks || 0),
    cost: Number(row.cost || 0),
    conv: Number(row.conv || 0),
    value: Number(row.value || 0)
  };

  return transformed;
};

// Add helper for cumulative metrics
const CUMULATIVE_METRICS = ['Impressions', 'Clicks', 'Cost', 'Conversions', 'ConvValue'];

type HourlyDOWData = {
  [key: string]: {
    [hour: number]: {
      impr: number;
      clicks: number;
      cost: number;
      conv: number;
      value: number;
      days: number;
    }
  }
};

const transformDOWData = (data: any[]): HourlyDOWData => {
  const result: HourlyDOWData = {};

  // Initialize structure for all days
  for (let i = 0; i < 7; i++) {
    result[i] = {};
    for (let h = 0; h < 24; h++) {
      result[i][h] = {
        impr: 0,
        clicks: 0,
        cost: 0,
        conv: 0,
        value: 0,
        days: 0
      };
    }
  }

  // Fill in actual data
  data?.forEach(row => {
    const dowNum = typeof row.dow === 'string'
      ? getDayNumber(row.dow)
      : Number(row.dow || 0);

    const hour = Number(row.hour || 0);

    if (result[dowNum] && result[dowNum][hour]) {
      result[dowNum][hour] = {
        impr: Number(row.impr || 0),
        clicks: Number(row.clicks || 0),
        cost: Number(row.cost || 0),
        conv: Number(row.conv || 0),
        value: Number(row.value || 0),
        days: Number(row.days || 0)
      };
    }
  });

  return result;
};

const DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] as const;
type DayName = (typeof DAYS)[number];

const getDayName = (dow: number): string => {
  return DAYS[dow] || '';
};

const getDayNumber = (dayName: string): number => {
  const upperDay = dayName.toUpperCase();
  // Check if it's a valid day name
  if (DAYS.includes(upperDay as DayName)) {
    return DAYS.indexOf(upperDay as DayName);
  }
  return 0; // Default to Sunday if invalid
};

// First, add the HEATMAP_THEMES constant at the top level
const HEATMAP_THEMES = {
  purple: {
    name: 'Purple',
    light: ['rgb(233, 213, 255)', 'rgb(147, 51, 234)'],
    dark: ['rgb(233, 213, 255)', 'rgb(88, 28, 135)']
  },
  blue: {
    name: 'Blue',
    light: ['rgb(219, 234, 254)', 'rgb(29, 78, 216)'],
    dark: ['rgb(219, 234, 254)', 'rgb(30, 58, 138)']
  },
  green: {
    name: 'Green',
    light: ['rgb(220, 252, 231)', 'rgb(22, 163, 74)'],
    dark: ['rgb(220, 252, 231)', 'rgb(21, 128, 61)']
  },
  orange: {
    name: 'Orange',
    light: ['rgb(254, 235, 200)', 'rgb(234, 88, 12)'],
    dark: ['rgb(254, 235, 200)', 'rgb(194, 65, 12)']
  },
  red: {
    name: 'Red',
    light: ['rgb(254, 226, 226)', 'rgb(220, 38, 38)'],
    dark: ['rgb(254, 226, 226)', 'rgb(185, 28, 28)']
  }
} as const;

type HeatmapTheme = keyof typeof HEATMAP_THEMES;

interface CellData {
  impr: number;
  clicks: number;
  cost: number;
  conv: number;
  value: number;
  days?: number;
}

const getMetricValue = (metric: MetricKey, data: CellData): number => {
  switch (metric) {
    case 'Impressions': return data.impr;
    case 'Clicks': return data.clicks;
    case 'Cost': return data.cost;
    case 'Conversions': return data.conv;
    case 'ConvValue': return data.value;
    case 'CTR': return data.impr ? (data.clicks / data.impr) * 100 : 0;
    case 'CvR': return data.clicks ? (data.conv / data.clicks) * 100 : 0;
    case 'ROAS': return data.cost ? data.value / data.cost : 0;
    case 'CPA': return data.conv ? data.cost / data.conv : 0;
    case 'AOV': return data.conv ? data.value / data.conv : 0;
    default: return 0;
  }
};

const getAllMetricValues = (data: any[], selectedMetric: MetricKey, view: 'dow' | 'campaign'): number[] => {
  const values: number[] = [];

  if (view === 'dow') {
    Object.values(data).forEach(day => {
      Object.values(day as { [key: number]: CellData }).forEach(cell => {
        const value = getMetricValue(selectedMetric, cell);
        if (value > 0) values.push(value);
      });
    });
  } else {
    data.forEach(row => {
      (row.hourlyData as CellData[]).forEach(cell => {
        const value = getMetricValue(selectedMetric, cell);
        if (value > 0) values.push(value);
      });
    });
  }

  return values;
};

// Then update the component
export default function Hourly() {
  console.log('=== HOURLY PAGE RENDER ===');

  const { data, loadingStatus, error } = useCampaignData();
  const { activeAccount } = useAccounts();

  console.log('HOURLY PAGE - INITIAL STATE:', {
    hasData: !!data,
    loadingStatus,
    isSampleData: !activeAccount
  });

  const [timeRange, setTimeRange] = useState<'today' | 'yesterday'>('today');
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const [selectedMetrics, setSelectedMetrics] = useState<MetricKey[]>(['Cost', 'ConvValue']);
  const [chartType, setChartType] = useState<'line' | 'bar'>('bar');
  const [viewType, setViewType] = useState<'hourly' | 'cumulative'>('hourly');
  const [selectedDOWMetric, setSelectedDOWMetric] = useState<MetricKey>('Cost');
  const [dowTimeRange, setDowTimeRange] = useState<'7' | '30'>('30');
  const [analysisView, setAnalysisView] = useState<'dow' | 'campaign'>('dow');
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [campaignFilter, setCampaignFilter] = useState('');
  const [selectedHeatmapTheme, setSelectedHeatmapTheme] = useState<HeatmapTheme>('blue');

  const accountCurrency = activeAccount?.currency || '$';
  const formatMetric = useMetricFormatter(accountCurrency);

  // Get raw data for the selected time range
  const currentData = useMemo(() => {
    const rawData = timeRange === 'today' ? data?.hourly : data?.hourly_yest;

    // Always log this data, not behind DEBUG flag
    console.log('=== HOURLY DATA ===', {
      timeRange,
      isSampleData: !activeAccount,
      rawDataLength: rawData?.length,
      firstTwoRows: rawData?.slice(0, 2)
    });

    if (!rawData?.length) return [];

    const transformed = rawData.map(transformMetrics);

    return transformed;
  }, [timeRange, data?.hourly, data?.hourly_yest, activeAccount, data]);

  // Get unique campaigns and their total cost
  const campaigns = useMemo(() => {
    if (!currentData?.length) {
      if (DEBUG) console.log('Campaigns: No current data available');
      return [];
    }

    const campaignMap = new Map();
    currentData.forEach(row => {
      const campaignName = String(row.campaign || '').trim();
      if (!campaignName) {
        if (DEBUG) console.log('Skipping row with empty campaign name:', row);
        return;
      }

      if (!campaignMap.has(campaignName)) {
        campaignMap.set(campaignName, {
          campaign: campaignName,
          cost: 0,
          isSample: !activeAccount
        });
      }
      const newCost = Number(row.cost || 0);
      campaignMap.get(campaignName).cost += newCost;

    });

    const result = Array.from(campaignMap.values())
      .filter(c => c.campaign && c.cost > 0);

    if (DEBUG) {
      console.log('Campaigns processed:', {
        totalCampaigns: campaignMap.size,
        validCampaigns: result.length,
        campaigns: result.slice(0, 3)
      });
    }

    return result.sort((a, b) => b.cost - a.cost);
  }, [currentData, activeAccount]);

  // Auto-select highest cost campaign when campaigns change
  useEffect(() => {
    // Clear selected campaign when no campaigns are available
    if (!campaigns.length) {
      setSelectedCampaign('');
      return;
    }

    if (DEBUG) {
      console.log('Campaign selection:', {
        availableCampaigns: campaigns.length,
        currentSelection: selectedCampaign,
        willUpdate: campaigns.length > 0 && (!selectedCampaign || !campaigns.find(c => c.campaign === selectedCampaign))
      });
    }

    if (!selectedCampaign || !campaigns.find(c => c.campaign === selectedCampaign)) {
      setSelectedCampaign(campaigns[0].campaign);
    }
  }, [campaigns, selectedCampaign]);

  // Get hourly data for selected campaign
  const hourlyData = useMemo(() => {
    if (!selectedCampaign || !currentData?.length) return [];

    // Get all rows for selected campaign
    const campaignRows = currentData.filter(row => row.campaign === selectedCampaign);

    if (!campaignRows.length) return Array(24).fill({
      hour: 0,
      Cost: 0,
      Clicks: 0,
      Impressions: 0,
      Conversions: 0,
      ConvValue: 0,
      CTR: 0,
      CvR: 0,
      ROAS: 0,
      CPA: 0,
      AOV: 0
    });

    // Create array of 24 hours with actual data or zeros
    return Array.from({ length: 24 }, (_, hour) => {
      const row = campaignRows.find(r => r.hour === hour) || {
        cost: 0, clicks: 0, impr: 0, conv: 0, value: 0
      };

      // Map the lowercase metrics to the expected capitalized names
      return {
        hour,
        Cost: row.cost,
        Clicks: row.clicks,
        Impressions: row.impr,
        Conversions: row.conv,
        ConvValue: row.value,
        CTR: row.impr ? (row.clicks / row.impr) * 100 : 0,
        CvR: row.clicks ? (row.conv / row.clicks) * 100 : 0,
        ROAS: row.cost ? row.value / row.cost : 0,
        CPA: row.conv ? row.cost / row.conv : 0,
        AOV: row.conv ? row.value / row.conv : 0
      };
    });
  }, [currentData, selectedCampaign]);

  // Calculate cumulative data
  const cumulativeData = useMemo(() => {
    if (!hourlyData?.length) return [];

    let cumulatives: { [key: string]: number } = {};
    return hourlyData.map(row => {
      const newRow = { ...row };
      // Handle all selected metrics, not just base metrics
      selectedMetrics.forEach(metric => {
        cumulatives[metric] = (cumulatives[metric] || 0) + (row[metric] || 0);
        newRow[metric] = cumulatives[metric];
      });
      return newRow;
    });
  }, [hourlyData, selectedMetrics]);

  // Calculate totals for metrics
  const totals = useMemo(() => {
    if (!hourlyData?.length) return {
      Impressions: 0,
      Clicks: 0,
      Cost: 0,
      Conversions: 0,
      ConvValue: 0,
      CTR: 0,
      CvR: 0,
      ROAS: 0,
      CPA: 0,
      AOV: 0,
    };

    const sums = hourlyData.reduce((acc, row) => ({
      Impressions: acc.Impressions + row.Impressions,
      Clicks: acc.Clicks + row.Clicks,
      Cost: acc.Cost + row.Cost,
      Conversions: acc.Conversions + row.Conversions,
      ConvValue: acc.ConvValue + row.ConvValue,
    }), {
      Impressions: 0,
      Clicks: 0,
      Cost: 0,
      Conversions: 0,
      ConvValue: 0,
    });

    return {
      ...sums,
      CTR: sums.Impressions ? (sums.Clicks / sums.Impressions) * 100 : 0,
      CvR: sums.Clicks ? (sums.Conversions / sums.Clicks) * 100 : 0,
      ROAS: sums.Cost ? sums.ConvValue / sums.Cost : 0,
      CPA: sums.Conversions ? sums.Cost / sums.Conversions : 0,
      AOV: sums.Conversions ? sums.ConvValue / sums.Conversions : 0,
    };
  }, [hourlyData]);

  // Define available metrics
  const availableMetrics = useMemo(() =>
    Object.entries(METRIC_CONFIGS)
      .map(([key, config]) => ({
        key: key as keyof typeof METRIC_CONFIGS,
        ...config,
      }))
      .filter(metric => !['ImprShare', 'LostToBudget', 'LostToRank', 'CPC'].includes(metric.key as string)),
    []
  );

  const toggleMetric = (metricKey: MetricKey) => {
    trackEvent(ANALYTICS_EVENTS.HOURLY.METRIC_TOGGLE(metricKey));
    setSelectedMetrics(current => {
      if (current.includes(metricKey)) {
        return current.filter(m => m !== metricKey);
      }
      if (current.length < 2) {
        return [...current, metricKey];
      }
      return [current[1], metricKey];
    });
  };

  const dowData = useMemo(() => {
    if (dowTimeRange === '7' && data?.h_dow_7d?.length) {
      return transformDOWData(data.h_dow_7d);
    }
    if (dowTimeRange === '30' && data?.h_dow_30d?.length) {
      return transformDOWData(data.h_dow_30d);
    }
    return null;
  }, [data?.h_dow_7d, data?.h_dow_30d, dowTimeRange]);

  useEffect(() => {
    if (DEBUG) {
      console.log('Hourly component data:', {
        h_dow_30d: data?.h_dow_30d?.length,
        h_dow_7d: data?.h_dow_7d?.length,
        dowData,
        dowTimeRange
      });
    }
  }, [data, dowData, dowTimeRange]);

  useEffect(() => {
    if (DEBUG) {
      console.log('Raw data in Hourly:', {
        allTabs: Object.keys(data || {}),
        h_dow_30d: {
          length: data?.h_dow_30d?.length,
          sample: data?.h_dow_30d?.slice(0, 2)
        },
        h_dow_7d: {
          length: data?.h_dow_7d?.length,
          sample: data?.h_dow_7d?.slice(0, 2)
        }
      });
    }
  }, [data]);

  const campaignData = useMemo(() => {
    if (analysisView !== 'campaign') return null;

    const rawData = dowTimeRange === '7' ? data?.h_camp_7d : data?.h_camp_30d;
    if (!rawData?.length) return null;

    // Group by campaign and hour
    const campaignMap = new Map();

    rawData.forEach(row => {
      const campaign = row.campaign || '';
      // Apply filter
      if (campaignFilter && !campaign.toLowerCase().includes(campaignFilter.toLowerCase())) {
        return;
      }
      if (!campaignMap.has(campaign)) {
        campaignMap.set(campaign, Array(24).fill(null).map(() => ({
          impr: 0,
          clicks: 0,
          cost: 0,
          conv: 0,
          value: 0,
          days: 0
        })));
      }

      const hour = Number(row.hour || 0);
      const hourData = campaignMap.get(campaign)[hour];

      hourData.impr += Number(row.impr || 0);
      hourData.clicks += Number(row.clicks || 0);
      hourData.cost += Number(row.cost || 0);
      hourData.conv += Number(row.conv || 0);
      hourData.value += Number(row.value || 0);
      hourData.days += Number(row.days || 0);
    });

    // Convert to array and sort by total cost
    const allCampaigns = Array.from(campaignMap.entries())
      .map(([campaign, hourlyData]) => ({
        campaign,
        hourlyData,
        totalCost: hourlyData.reduce((sum, hour) => sum + hour.cost, 0)
      }))
      .sort((a, b) => b.totalCost - a.totalCost);

    // Calculate pagination
    const startIndex = (currentPage - 1) * rowsPerPage;
    return allCampaigns.slice(startIndex, startIndex + rowsPerPage);
  }, [data?.h_camp_7d, data?.h_camp_30d, dowTimeRange, analysisView, rowsPerPage, currentPage, campaignFilter]);

  // Update totalCampaigns to consider filter
  const totalCampaigns = useMemo(() => {
    if (analysisView !== 'campaign') return 0;
    const rawData = dowTimeRange === '7' ? data?.h_camp_7d : data?.h_camp_30d;
    if (!rawData?.length) return 0;

    // Count unique campaigns after filtering
    return new Set(
      rawData
        .map(row => row.campaign)
        .filter(campaign => !campaignFilter ||
          campaign.toLowerCase().includes(campaignFilter.toLowerCase()))
    ).size;
  }, [data?.h_camp_7d, data?.h_camp_30d, dowTimeRange, analysisView, campaignFilter]);

  // Reset page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage, dowTimeRange]);

  // Then update the getHeatmapColor function
  const getHeatmapColor = useCallback((value: number, allValues: number[], selectedMetric: MetricKey): string => {
    if (allValues.length === 0) return 'transparent';

    const max = Math.max(...allValues);
    const min = Math.min(...allValues);

    if (max === min) return HEATMAP_THEMES[selectedHeatmapTheme].light[0];

    const intensity = (value - min) / (max - min);
    const colors = HEATMAP_THEMES[selectedHeatmapTheme][
      document.documentElement.classList.contains('dark') ? 'dark' : 'light'
    ];

    const startRGB = colors[0].match(/\d+/g)!.map(Number);
    const endRGB = colors[1].match(/\d+/g)!.map(Number);

    const r = Math.round(startRGB[0] + (endRGB[0] - startRGB[0]) * intensity);
    const g = Math.round(startRGB[1] + (endRGB[1] - startRGB[1]) * intensity);
    const b = Math.round(startRGB[2] + (endRGB[2] - startRGB[2]) * intensity);

    return `rgb(${r}, ${g}, ${b})`;
  }, [selectedHeatmapTheme]);

  // Add logging in useEffect
  useEffect(() => {
    console.log('HOURLY PAGE - RAW DATA:', {
      timeRange,
      isSampleData: !activeAccount,
      hourlyData: data?.hourly?.slice(0, 2),
      hourlyYestData: data?.hourly_yest?.slice(0, 2)
    });
  }, [data, timeRange, activeAccount]);

  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return <div className="flex items-center justify-center h-96">Loading...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-96 text-red-500">Error: {error}</div>;
  }

  if (!currentData?.length) {
    return <div className="flex items-center justify-center h-96">No data available</div>;
  }

  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <Select value={selectedCampaign} onValueChange={setSelectedCampaign}>
          <SelectTrigger className="w-[300px] md:w-[400px] lg:w-[500px]">
            <SelectValue>
              {selectedCampaign ? (
                <div className="flex items-center justify-between w-full">
                  <span className="truncate max-w-[200px]">
                    {!activeAccount && '(Sample) '}{selectedCampaign}
                  </span>
                  <span className="text-sm font-medium text-muted-foreground ml-2">
                    {formatMetric('Cost', campaigns.find(c => c.campaign === selectedCampaign)?.cost || 0)}
                  </span>
                </div>
              ) : (
                "Select a campaign"
              )}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {campaigns.map(({ campaign, cost, isSample }) => (
              <SelectItem
                key={campaign}
                value={campaign}
                className="flex items-center justify-between py-2 pr-2"
              >
                <span className="truncate mr-4">
                  {isSample && '(Sample) '}{campaign}
                </span>
                <span className="text-sm font-medium text-muted-foreground">
                  {formatMetric('Cost', cost)}
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Tabs value={timeRange} onValueChange={(value) => {
          trackEvent(ANALYTICS_EVENTS.HOURLY.TIME_RANGE(value));
          setTimeRange(value as 'today' | 'yesterday');
        }}>
          <TabsList className="bg-transparent gap-2">
            <TabsTrigger value="today" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
              Today
            </TabsTrigger>
            <TabsTrigger value="yesterday" className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                       text-muted-foreground hover:bg-primary/10 transition-colors">
              Yesterday
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {/* Metric Cards */}
      <div className="grid grid-cols-10 gap-2">
        {availableMetrics.map(metric => (
          <Card
            key={metric.key}
            onClick={() => toggleMetric(metric.key)}
            className={`cursor-pointer transition-colors hover:bg-accent ${selectedMetrics.includes(metric.key) ? 'ring-2 ring-primary' : ''
              }`}
          >
            <CardHeader className="pb-1 pt-2 px-3 space-y-0">
              <CardTitle className="text-xs font-medium">
                {!activeAccount && '(Sample) '}{metric.label}
              </CardTitle>
            </CardHeader>
            <CardContent className="pb-2 px-3">
              <div className="text-lg font-bold">
                {formatMetric(metric.key, totals[metric.key] || 0)}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Main Chart Card */}
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <Tabs
              value={viewType}
              onValueChange={(value) => {
                trackEvent(ANALYTICS_EVENTS.HOURLY.VIEW_TYPE(value));
                setViewType(value as 'hourly' | 'cumulative');
              }}
              className="w-[400px]"
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="hourly">Hourly Performance</TabsTrigger>
                <TabsTrigger value="cumulative">Cumulative Analysis</TabsTrigger>
              </TabsList>
            </Tabs>
            <Tabs value={chartType} onValueChange={(value) => {
              trackEvent(ANALYTICS_EVENTS.HOURLY.CHART_TYPE(value));
              setChartType(value as 'line' | 'bar');
            }}>
              <TabsList className="grid w-fit grid-cols-2">
                <TabsTrigger value="line" className="px-4">
                  <LineChartIcon className="h-4 w-4 mr-2" />
                  Line
                </TabsTrigger>
                <TabsTrigger value="bar" className="px-4">
                  <BarChartIcon className="h-4 w-4 mr-2" />
                  Bar
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </CardHeader>
        <CardContent>
          <div className="h-[400px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              {chartType === 'line' ? (
                <LineChart data={viewType === 'hourly' ? hourlyData : cumulativeData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="hour"
                    tickFormatter={(hour) => hour.toString().padStart(2, '0')}
                  />
                  <YAxis yAxisId="left" orientation="left" />
                  {selectedMetrics.length > 1 && (
                    <YAxis yAxisId="right" orientation="right" />
                  )}
                  <Tooltip
                    formatter={(value: any, name: string) => {
                      const numValue = typeof value === 'string' ? parseFloat(value) : value;
                      return [formatMetric(name as MetricKey, numValue), name];
                    }}
                    labelFormatter={(hour) => `Hour ${hour.toString().padStart(2, '0')}`}
                  />
                  <Legend />
                  {selectedMetrics.map((metric, index) => (
                    <Line
                      key={metric}
                      type="monotone"
                      dataKey={metric}
                      stroke={index === 0 ? CHART_COLORS.primary : CHART_COLORS.secondary}
                      yAxisId={index === 0 ? "left" : "right"}
                      dot={false}
                      animationDuration={1}
                    />
                  ))}
                </LineChart>
              ) : (
                <BarChart data={viewType === 'hourly' ? hourlyData : cumulativeData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="hour"
                    tickFormatter={(hour) => hour.toString().padStart(2, '0')}
                  />
                  <YAxis yAxisId="left" orientation="left" />
                  {selectedMetrics.length > 1 && (
                    <YAxis yAxisId="right" orientation="right" />
                  )}
                  <Tooltip
                    formatter={(value: any, name: string) => {
                      const numValue = typeof value === 'string' ? parseFloat(value) : value;
                      return [formatMetric(name as MetricKey, numValue), name];
                    }}
                    labelFormatter={(hour) => `Hour ${hour.toString().padStart(2, '0')}`}
                  />
                  <Legend />
                  {selectedMetrics.map((metric, index) => (
                    <Bar
                      key={metric}
                      dataKey={metric}
                      fill={index === 0 ? CHART_COLORS.primary : CHART_COLORS.secondary}
                      yAxisId={index === 0 ? "left" : "right"}
                      animationDuration={1}
                    />
                  ))}
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
      {/* Performance Patterns Card */}
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <CardTitle>Performance Patterns by Day & Hour</CardTitle>
              <Tabs value={analysisView} onValueChange={(value) => {
                trackEvent(ANALYTICS_EVENTS.HOURLY.ANALYSIS_VIEW(value));
                setAnalysisView(value as 'dow' | 'campaign');
              }}>
                <TabsList className="bg-transparent gap-2">
                  <TabsTrigger
                    value="dow"
                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                               text-muted-foreground hover:bg-primary/10 transition-colors"
                  >
                    Day of Week
                  </TabsTrigger>
                  {/* Temporarily hidden campaign tab
                    <TabsTrigger
                      value="campaign"
                      className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                               text-muted-foreground hover:bg-primary/10 transition-colors"
                    >
                      Campaign
                    </TabsTrigger>
                    */}
                </TabsList>
              </Tabs>
              {analysisView === 'campaign' && (
                <div className="flex items-center gap-4">
                  <input
                    type="text"
                    value={campaignFilter}
                    onChange={(e) => setCampaignFilter(e.target.value)}
                    placeholder="Filter campaigns..."
                    className="h-9 px-3 rounded-md border border-input bg-background text-sm"
                  />
                  <Select value={rowsPerPage.toString()} onValueChange={(value) => {
                    trackEvent(ANALYTICS_EVENTS.HOURLY.ROWS_PER_PAGE(Number(value)));
                    setRowsPerPage(Number(value));
                  }}>
                    <SelectTrigger className="w-[100px]">
                      <SelectValue placeholder="Rows" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="5">5 rows</SelectItem>
                      <SelectItem value="10">10 rows</SelectItem>
                      <SelectItem value="20">20 rows</SelectItem>
                      <SelectItem value="50">50 rows</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
            <div className="flex items-center gap-4">
              <Select
                value={selectedHeatmapTheme}
                onValueChange={(value) => {
                  trackEvent(ANALYTICS_EVENTS.HOURLY.HEATMAP_THEME(value));
                  setSelectedHeatmapTheme(value as HeatmapTheme);
                }}
              >
                <SelectTrigger className="w-[100px]">
                  <SelectValue placeholder="Theme" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(HEATMAP_THEMES).map(([key, theme]) => (
                    <SelectItem key={key} value={key}>
                      {theme.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Tabs value={dowTimeRange} onValueChange={(value) => {
                trackEvent(ANALYTICS_EVENTS.HOURLY.DOW_TIMERANGE(value));
                setDowTimeRange(value as '7' | '30');
              }}>
                <TabsList className="bg-transparent gap-2">
                  <TabsTrigger
                    value="7"
                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                               text-muted-foreground hover:bg-primary/10 transition-colors"
                  >
                    7 Days
                  </TabsTrigger>
                  <TabsTrigger
                    value="30"
                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                               text-muted-foreground hover:bg-primary/10 transition-colors"
                  >
                    28 Days
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          {/* Metric Cards */}
          <div className="grid grid-cols-10 gap-2">
            {availableMetrics.map(metric => (
              <Card
                key={metric.key}
                onClick={() => {
                  trackEvent(ANALYTICS_EVENTS.HOURLY.METRIC_TOGGLE(metric.key));
                  setSelectedDOWMetric(metric.key);
                }}
                className={`cursor-pointer transition-colors hover:bg-accent ${selectedDOWMetric === metric.key ? 'ring-2 ring-primary' : ''
                  }`}
              >
                <CardHeader className="pb-1 pt-2 px-3 space-y-0">
                  <CardTitle className="text-xs font-medium">
                    {!activeAccount && '(Sample) '}{metric.label}
                  </CardTitle>
                </CardHeader>
              </Card>
            ))}
          </div>

          {/* Updated Table */}
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="min-w-[15%] max-w-[15%]">
                    {analysisView === 'dow' ? 'Day' : 'Campaign'}
                  </TableHead>
                  {Array.from({ length: 24 }, (_, i) => (
                    <TableHead key={i} className="text-center w-[3.5%]">
                      {i.toString().padStart(2, '0')}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {analysisView === 'dow' ? (
                  // Existing DOW rows
                  Array.from({ length: 7 }, (_, dow) => (
                    <TableRow key={dow}>
                      <TableCell className="font-medium">{getDayName(dow)}</TableCell>
                      {Array.from({ length: 24 }, (_, hour) => (
                        <TableCell
                          key={hour}
                          className="text-center relative"
                          style={{
                            backgroundColor: getHeatmapColor(
                              getMetricValue(selectedDOWMetric, dowData[dow][hour]),
                              getAllMetricValues(Object.values(dowData), selectedDOWMetric, 'dow'),
                              selectedDOWMetric
                            ),
                            color: 'inherit',
                          }}
                        >
                          {selectedDOWMetric === 'Conversions'
                            ? Number(getMetricValue(selectedDOWMetric, dowData[dow][hour])).toFixed(1)
                            : formatMetric(selectedDOWMetric, getMetricValue(selectedDOWMetric, dowData[dow][hour]))
                          }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  // Campaign rows
                  campaignData?.map(({ campaign, hourlyData }) => (
                    <TableRow key={campaign}>
                      <TableCell
                        className="font-medium whitespace-normal line-clamp-2 min-w-[300px] w-[300px]"
                        title={campaign}
                      >
                        {campaign}
                      </TableCell>
                      {hourlyData.map((hour, index) => (
                        <TableCell
                          key={index}
                          className="text-center relative"
                          style={{
                            backgroundColor: getHeatmapColor(
                              getMetricValue(selectedDOWMetric, hour),
                              getAllMetricValues(campaignData, selectedDOWMetric, 'campaign'),
                              selectedDOWMetric
                            ),
                            color: 'inherit',
                          }}
                        >
                          {selectedDOWMetric === 'Conversions'
                            ? Number(getMetricValue(selectedDOWMetric, hour)).toFixed(1)
                            : formatMetric(selectedDOWMetric, getMetricValue(selectedDOWMetric, hour))
                          }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            {analysisView === 'campaign' && (
              <TablePagination
                totalItems={totalCampaigns}
                pageSize={rowsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            )}
          </div>
        </CardContent>
      </Card>

      {/* Add Fredo */}
      <Fredo
        pageData={{
          timeRange,
          selectedCampaign,
          selectedMetrics,
          chartType,
          viewType,
          selectedDOWMetric,
          dowTimeRange,
          analysisView,
          totals
        }}
      />

    </div>
  );
}