import { ProcessedKeywordData } from "@/pages/keywords"
import { SearchTermData } from "@/types/metrics"

interface NgramMetrics {
    impr: number
    clicks: number
    cost: number
    conv: number
    value: number
    CTR: number
    CPC: number
    CvR: number
    ROAS: number
    CPA: number
    count: number
}

export interface NgramData extends NgramMetrics {
    ngram: string
    wordCount: number
}

interface NgramMap {
    [key: string]: NgramMetrics
}

function initializeMetrics(): NgramMetrics {
    return {
        impr: 0,
        clicks: 0,
        cost: 0,
        conv: 0,
        value: 0,
        CTR: 0,
        CPC: 0,
        CvR: 0,
        ROAS: 0,
        CPA: 0,
        count: 0
    }
}

function updateMetrics(metrics: NgramMetrics, row: ProcessedKeywordData | SearchTermData): void {
    metrics.impr += row.impr
    metrics.clicks += row.clicks
    metrics.cost += row.cost
    metrics.conv += row.conv
    metrics.value += row.value
    metrics.count += 1
}

function calculateDerivedMetrics(metrics: NgramMetrics): void {
    metrics.CTR = metrics.impr ? (metrics.clicks / metrics.impr) * 100 : 0
    metrics.CPC = metrics.clicks ? metrics.cost / metrics.clicks : 0
    metrics.CvR = metrics.clicks ? (metrics.conv / metrics.clicks) * 100 : 0
    metrics.ROAS = metrics.cost ? metrics.value / metrics.cost : 0
    metrics.CPA = metrics.conv ? metrics.cost / metrics.conv : 0
}

function generateNgrams(text: string, n: number): string[] {
    const words = text.toLowerCase().split(/\s+/)
    if (n > words.length) return []

    const ngrams: string[] = []
    for (let i = 0; i <= words.length - n; i++) {
        ngrams.push(words.slice(i, i + n).join(' '))
    }
    return ngrams
}

function processNgramsGeneric<T extends ProcessedKeywordData | SearchTermData>(
    data: T[],
    textField: keyof T,
    maxN: number = 3,
    wordCount?: number
): NgramData[] {
    const ngramMap: { [key: string]: NgramMetrics & { sourceData: T[] } } = {}

    // Process each item
    for (const item of data) {
        // Skip items with no impressions
        if (!item.impr) continue

        // Generate ngrams of different lengths
        for (let n = 1; n <= maxN; n++) {
            // Skip if we're filtering by word count and this isn't the count we want
            if (wordCount !== undefined && n !== wordCount) continue

            const ngrams = generateNgrams(String(item[textField]), n)

            // Update metrics for each ngram
            for (const ngram of ngrams) {
                if (!ngramMap[ngram]) {
                    ngramMap[ngram] = {
                        ...initializeMetrics(),
                        sourceData: []
                    }
                }
                updateMetrics(ngramMap[ngram], item)
                ngramMap[ngram].sourceData.push(item)
            }
        }
    }

    // Calculate derived metrics and convert to array
    return Object.entries(ngramMap).map(([ngram, metrics]) => {
        const { sourceData, ...baseMetrics } = metrics
        calculateDerivedMetrics(baseMetrics)
        return {
            ngram,
            wordCount: ngram.split(/\s+/).length,
            ...baseMetrics,
            sourceData
        }
    })
}

export function processNgrams(
    keywords: ProcessedKeywordData[],
    maxN: number = 3,
    wordCount?: number
): NgramData[] {
    return processNgramsGeneric(keywords, 'keywordText', maxN, wordCount)
}

export function processSearchTermNgrams(
    searchTerms: SearchTermData[],
    maxN: number = 3,
    wordCount?: number
): NgramData[] {
    return processNgramsGeneric(searchTerms, 'searchTerm', maxN, wordCount)
} 