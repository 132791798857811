import React from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { ChevronRight } from 'lucide-react';

const LINKEDIN_POSTS = [
  {
    title: "Two types of business in 2025",
    url: "https://www.linkedin.com/posts/mikerhodesideas_two-types-of-agencies-will-exist-in-2025-activity-7274690134489346048-Z3GV",
    description: "The future divides into two paths: those using AI like it's 2022, and those achieving what looks like magic."
  },
  {
    title: "There is no Manual",
    url: "https://www.linkedin.com/posts/mikerhodesideas_%F0%9D%97%A7%F0%9D%97%B5%F0%9D%97%B2%F0%9D%97%BF%F0%9D%97%B2-%F0%9D%97%9C%F0%9D%98%80-%F0%9D%97%A1%F0%9D%97%BC-%F0%9D%97%A0%F0%9D%97%AE%F0%9D%97%BB%F0%9D%98%82%F0%9D%97%AE%F0%9D%97%B9-most-businesses-activity-7275055207389011968-gzlH",
    description: "Why traditional approaches to business operations are becoming obsolete."
  },
  {
    title: "Should you spend less on ads",
    url: "https://www.linkedin.com/posts/mikerhodesideas_%F0%9D%97%A6%F0%9D%97%B5%F0%9D%97%BC%F0%9D%98%82%F0%9D%97%B9%F0%9D%97%B1-%F0%9D%98%86%F0%9D%97%BC%F0%9D%98%82-%F0%9D%98%80%F0%9D%97%BD%F0%9D%97%B2%F0%9D%97%BB%F0%9D%97%B1-%F0%9D%97%B9%F0%9D%97%B2%F0%9D%98%80%F0%9D%98%80-%F0%9D%97%BC%F0%9D%97%BB-activity-7275250653080309760-W_l3",
    description: "A deep dive into ad spend optimization in the age of AI."
  },
  {
    title: "Let's go down the Agent rabbit hole",
    url: "https://www.linkedin.com/posts/mikerhodesideas_%F0%9D%97%9F%F0%9D%97%B2%F0%9D%98%81%F0%9D%98%80-%F0%9D%97%B4%F0%9D%97%BC-%F0%9D%97%B1%F0%9D%97%BC%F0%9D%98%84%F0%9D%97%BB-%F0%9D%98%81%F0%9D%97%B5%F0%9D%97%B2-%F0%9D%97%94%F0%9D%97%B4%F0%9D%97%B2%F0%9D%97%BB%F0%9D%98%81-activity-7275810877897879552-Fy7B",
    description: "Exploring the transformative potential of AI agents."
  },
  {
    title: "Scaling expertise is hard",
    url: "https://www.linkedin.com/posts/mikerhodesideas_the-hardest-part-of-running-a-marketing-agency-activity-7275983082820681728-WVDc",
    description: "The challenges and solutions in scaling business expertise."
  },
  {
    title: "The evolution from Ads to AI",
    url: "https://www.linkedin.com/posts/mikerhodesideas_%F0%9D%97%A7%F0%9D%97%B5%F0%9D%97%B2-%F0%9D%97%98%F0%9D%98%83%F0%9D%97%BC%F0%9D%97%B9%F0%9D%98%82%F0%9D%98%81%F0%9D%97%B6%F0%9D%97%BC%F0%9D%97%BB-%F0%9D%97%B3%F0%9D%97%BF%F0%9D%97%BC%F0%9D%97%BA-%F0%9D%97%94%F0%9D%97%B1%F0%9D%98%80-activity-7276114173795737600-CYer",
    description: "How advertising is transforming through AI integration."
  },
  {
    title: "The new Management challenge - can you conduct?",
    url: "https://www.linkedin.com/posts/mikerhodesideas_%F0%9D%97%A7%F0%9D%97%B5%F0%9D%97%B2-%F0%9D%97%A1%F0%9D%97%B2%F0%9D%98%84-%F0%9D%97%A0%F0%9D%97%AE%F0%9D%97%BB%F0%9D%97%AE%F0%9D%97%B4%F0%9D%97%B2%F0%9D%97%BA%F0%9D%97%B2%F0%9D%97%BB%F0%9D%98%81-%F0%9D%97%96%F0%9D%97%B5%F0%9D%97%AE-activity-7276700239019851776-4l1b",
    description: "Leadership in the age of AI orchestration."
  },
  {
    title: "Three steps to using Agents in 2025",
    url: "https://www.linkedin.com/posts/mikerhodesideas_today-lets-wander-into-speculation-imagine-activity-7276863826971578369-n1RT",
    description: "A practical guide to implementing AI agents in your business."
  },
  {
    title: "A new form of Deep Work",
    url: "https://www.linkedin.com/posts/mikerhodesideas_%F0%9D%97%99%F0%9D%97%BF%F0%9D%97%BC%F0%9D%97%BA-%F0%9D%97%97%F0%9D%97%B2%F0%9D%97%B2%F0%9D%97%BD-%F0%9D%97%99%F0%9D%97%BC%F0%9D%97%B0%F0%9D%98%82%F0%9D%98%80-%F0%9D%98%81%F0%9D%97%BC-%F0%9D%97%99%F0%9D%97%B9%F0%9D%98%82%F0%9D%97%B6%F0%9D%97%B1-activity-7277130436042993664-jWf9",
    description: "How AI is redefining productivity and focus."
  },
  {
    title: "Wrap up",
    url: "https://www.linkedin.com/posts/mikerhodesideas_ive-added-all-the-links-in-one-place-at-activity-7277223993646952449-68q5",
    description: "Bringing it all together - the future of business with AI."
  }
];

export default function WhyPage() {
  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Why I Created 8020agent</h1>
        <p className="text-xl text-muted-foreground">
          A story about the future of business automation
        </p>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        {/* Main Story - 2/3 width */}
        <div className="lg:w-2/3">
          <Card>
            <CardContent className="pt-6 space-y-6">
              <div className="space-y-4">
                <p className="text-lg">
                  In 2025, there will be two types of businesses: those still using AI like it's 2022,
                  and those running what looks like magic to everyone else.
                </p>
                <p className="text-lg">
                  I've spent the last decade helping businesses optimize their advertising,
                  and I've noticed a pattern: the most successful businesses aren't just using better tools,
                  they're thinking about automation in a fundamentally different way.
                </p>
                <p className="text-lg">
                  8020agent is built on this insight. It's not just about optimizing ads -
                  it's about rethinking how we approach business automation in an AI-first world.
                </p>
              </div>

              <div className="space-y-4 pt-4 border-t">
                <p className="text-lg">
                  What fascinates me most about this divide isn't the technology – it's the mindset. Every agency owner I talk to believes they're ahead of the curve. "Of course we use AI," they tell me. "We've got ChatGPT, we're using Google's automated bidding, we're doing great!"
                </p>

                <p className="text-lg">
                  But there's a reality check coming.
                </p>

                <p className="text-lg">
                  While everyone's focused on prompts and basic automation, something far more powerful is being built in the background. The real revolution isn't in chat interfaces or automated bidding – it's in AI that thinks like a marketing specialist. AI that understands context, learns from patterns across accounts, and makes intelligent decisions. AI that works alongside your team like a tireless, brilliant colleague.
                </p>

                <p className="text-lg">
                  I've spent the last decade helping businesses optimize their advertising, and I've noticed a pattern: the most successful businesses aren't just using better tools – they're thinking about automation in a fundamentally different way. They don't see AI as a fancy autocomplete or a way to generate quick ad copy. They see it as a co-pilot, a thinking partner that helps them work differently, not just faster.
                </p>

                <p className="text-lg">
                  That's why I built 8020agent. At first, I just wanted to help businesses visualize their profit curves – to find that sweet spot where spending more actually hurts your bottom line. But as I watched agencies struggle with the transition to AI, I realized we needed more than just another optimization tool.
                </p>

                <p className="text-lg">
                  We needed a bridge to the future.
                </p>

                <p className="text-lg">
                  Think about it: Right now, most AI use in marketing is pretty basic – write a prompt, get an answer, move on. But what if your AI could review its own work, identifying issues and making improvements? What if it could break down complex tasks into steps, just like your best team members do? What if different AI specialists could work together, each focusing on what they do best?
                </p>

                <p className="text-lg">
                  This isn't science fiction. It's happening now, and it's going to transform our industry.
                </p>

                <p className="text-lg">
                  The gap between these two types of businesses isn't about budget, size, or technical expertise. It's about mindset and action. The businesses that will thrive aren't necessarily the ones with the most resources – they're the ones making decisive moves today while others wait for perfect clarity.
                </p>

                <p className="text-lg">
                  The choices you make in the next few months will echo through the next decade of your business's story. The tools are available. The knowledge is accessible. The only question that matters is: What will you build with them?
                </p>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* LinkedIn Posts - 1/3 width */}
        <div className="lg:w-1/3">
          <h2 className="text-2xl font-semibold mb-6">The Complete Story</h2>
          <div className="space-y-6">
            {LINKEDIN_POSTS.map((post, index) => (
              <a 
                key={index}
                href={post.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block group"
              >
                <div className="flex items-start justify-between p-4 rounded-lg hover:bg-accent transition-colors">
                  <div className="space-y-1 flex-1">
                    <h3 className="text-lg font-semibold group-hover:text-primary transition-colors">
                      {index + 1}. {post.title}
                    </h3>
                    <p className="text-sm text-muted-foreground">
                      {post.description}
                    </p>
                  </div>
                  <ChevronRight className="h-5 w-5 text-muted-foreground group-hover:text-primary transition-colors mt-1" />
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
} 
