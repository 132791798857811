// components/logo.tsx
import { appConfig } from "@/config/app";

export function Logo() {
    return (
        <>
            <img 
                src="/logo-light.png" 
                alt={`${appConfig.name} logo`}
                className="h-6 w-6 dark:hidden" 
            />
            <img 
                src="/logo-dark.png" 
                alt={`${appConfig.name} logo`}
                className="hidden h-6 w-6 dark:block" 
            />
        </>
    )
}