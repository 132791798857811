// src/pages/analysis.tsx
"use client"

import { useState, useMemo, useEffect } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useCampaignData } from "@/contexts/campaign-data"
import { useAccounts } from '@/contexts/account-context'
import { CampaignWithShare, BaseMetrics, OptimizableCampaign } from '@/types/metrics'
import { calculateTotals } from '@/lib/metrics'
import { useMetricFormatter } from '@/lib/metrics'
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs"
import { BudgetOptimization } from "@/components/budget-optimization/budget-optimization"
import type { OptimizationMode, CampaignProjection } from '@/components/budget-optimization/types'
import { HelpCircle } from "lucide-react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { calculateProfit } from '@/lib/profit-calculations';
import { TablePagination } from "@/components/ui/table-pagination"
import { trackEvent, ANALYTICS_EVENTS } from '@/lib/analytics'
import { Fredo } from "@/components/fredo-chat"

// Define interfaces with all required properties
interface CampaignWithProfit extends CampaignWithShare {
  profit: number;
}

interface TotalsWithProfit extends BaseMetrics {
  profit: number;
}

// Update the totals calculation
const calculateTotalsWithProfit = (campaigns: CampaignWithProfit[]): TotalsWithProfit => {
  // Transform CampaignWithProfit to BaseMetrics format first
  const baseMetricsData: BaseMetrics[] = campaigns.map(campaign => ({
    cost: Number(campaign.Cost || campaign.cost || 0),
    conversions: Number(campaign.Conversions || campaign.conv || 0),
    convValue: Number(campaign.ConvValue || campaign.value || 0),
    clicks: Number(campaign.Clicks || campaign.clicks || 0),
    impressions: Number(campaign.Impressions || campaign.impr || 0),
    impr: Number(campaign.Impressions || campaign.impr || 0)
  }));

  const baseTotals = calculateTotals(baseMetricsData);

  return {
    ...baseTotals,
    profit: campaigns.reduce((sum, campaign) => sum + campaign.profit, 0)
  };
};

// Add this debug helper at the top of the component
const debugValue = (value: any, label: string) => {
  console.log(`Debug ${label}:`, value);
  return value;
};

export default function Analysis() {
  const { data, loadingStatus, error } = useCampaignData()
  const { activeAccount } = useAccounts()
  const [includeFilter, setIncludeFilter] = useState('')
  const [excludeFilter, setExcludeFilter] = useState('')
  const [rowLimit, setRowLimit] = useState(10)
  const [optimizationMode, setOptimizationMode] = useState<OptimizationMode>('none')
  const [selectedTab, setSelectedTab] = useState('projections')
  const [selectedPeriod, setSelectedPeriod] = useState<'30d' | '7d'>('30d')
  const [campaignProjections, setCampaignProjections] = useState<CampaignProjection[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  // Get currency from active account
  const accountCurrency = activeAccount?.currency || '$'
  const formatMetric = useMetricFormatter(accountCurrency)

  // Format currency for campaign list
  const formatCurrency = (value: number) =>
    `${accountCurrency}${Math.round(value).toLocaleString()}`

  // Calculate campaigns with proper typing
  const campaigns = useMemo(() => {
    if (!data?.thirty_days) {
      return [];
    }

    return data.thirty_days.map(campaign => {
      if (!campaign) return null;

      // Transform to base metrics format with correct property name 'impr'
      const baseMetrics = {
        cost: Number(campaign.Cost || campaign.cost || 0),
        conv: Number(campaign.Conversions || campaign.conv || 0),
        value: Number(campaign.ConvValue || campaign.value || 0),
        clicks: Number(campaign.Clicks || campaign.clicks || 0),
        impr: Number(campaign.Impressions || campaign.impr || 0)
      };

      const campaignName = campaign.Campaign || campaign.campaign || '';
      const campaignId = campaign.CampaignId || campaign.campaignId || campaignName;

      // Calculate profit using base metrics
      const profit = calculateProfit(baseMetrics, {
        businessMode: activeAccount?.businessMode || 'ecomm',
        cogsAmount: activeAccount?.cogsAmount || 0,
        breakEvenCpa: activeAccount?.breakEvenCpa || 0
      });

      return {
        Campaign: campaignName,
        CampaignId: campaignId,
        Cost: baseMetrics.cost,
        Conversions: baseMetrics.conv,
        ConvValue: baseMetrics.value,
        Clicks: baseMetrics.clicks,
        Impressions: baseMetrics.impr,
        ImprShare: Number(campaign.ImprShare || campaign.imprShare || 0),
        LostToBudget: Number(campaign.LostToBudget || campaign.lostBudget || 0),
        LostToRank: Number(campaign.LostToRank || campaign.lostRank || 0),
        profit
      };
    }).filter(Boolean) as CampaignWithProfit[];
  }, [data?.thirty_days, activeAccount]);

  // Add a separate filteredCampaigns calculation
  const filteredCampaigns = useMemo(() => {
    if (!campaigns) return [];

    const filtered = campaigns.filter(campaign => {
      const name = campaign.Campaign?.toLowerCase() || '';
      const include = includeFilter?.toLowerCase() || '';
      const exclude = excludeFilter?.toLowerCase() || '';

      if (include && !name.includes(include)) return false;
      if (exclude && name.includes(exclude)) return false;
      return true;
    });

    // Calculate pagination
    const startIndex = (currentPage - 1) * rowLimit;
    return filtered.slice(startIndex, startIndex + rowLimit);
  }, [campaigns, includeFilter, excludeFilter, rowLimit, currentPage]);

  // Add total count for pagination
  const totalFilteredCampaigns = useMemo(() => {
    if (!campaigns) return 0;
    return campaigns.filter(campaign => {
      const name = campaign.Campaign?.toLowerCase() || '';
      const include = includeFilter?.toLowerCase() || '';
      const exclude = excludeFilter?.toLowerCase() || '';

      if (include && !name.includes(include)) return false;
      if (exclude && name.includes(exclude)) return false;
      return true;
    }).length;
  }, [campaigns, includeFilter, excludeFilter]);

  // Reset page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [rowLimit, includeFilter, excludeFilter]);

  // Calculate totals with profit
  const totals = useMemo(() => {
    if (!campaigns?.length) return null;

    return calculateTotalsWithProfit(campaigns);
  }, [campaigns]);

  // Add performance metrics calculation
  const performanceMetrics = useMemo(() => {
    if (!data?.thirty_days?.length) {
      console.log('No data available for metrics calculation');
      return [];
    }

    const currentData = selectedPeriod === '30d' ? data?.thirty_days : data?.seven_days;
    const previousData = selectedPeriod === '30d' ? data?.p_thirty_days : data?.p_seven_days;

    if (!Array.isArray(currentData) || !Array.isArray(previousData)) {
      console.log('Data is not in expected array format');
      return [];
    }

    const calculate = (data: any[]) => {
      const totals = data.reduce((acc, row) => ({
        cost: acc.cost + Number(row?.Cost || row?.cost || 0),
        value: acc.value + Number(row?.ConvValue || row?.value || 0),
        conv: acc.conv + Number(row?.Conversions || row?.conv || 0),
        clicks: acc.clicks + Number(row?.Clicks || row?.clicks || 0),
        impr: acc.impr + Number(row?.Impressions || row?.impr || 0)
      }), { cost: 0, value: 0, conv: 0, clicks: 0, impr: 0 });

      return {
        ...totals,
        ctr: totals.impr ? (totals.clicks / totals.impr) * 100 : 0,
        cvr: totals.clicks ? (totals.conv / totals.clicks) * 100 : 0,
        cpc: totals.clicks ? totals.cost / totals.clicks : 0,
        cpa: totals.conv ? totals.cost / totals.conv : 0,
        roas: totals.cost ? totals.value / totals.cost : 0,
        aov: totals.conv ? totals.value / totals.conv : 0
      };
    };

    const current = calculate(currentData);
    const previous = calculate(previousData);

    const getChange = (curr: number = 0, prev: number = 0) => {
      if (!prev) return 0;
      return ((curr - prev) / prev * 100);
    };

    return [
      {
        label: "Impr",
        value: Math.round(current.impr).toLocaleString(),
        change: getChange(current.impr, previous.impr)
      },
      {
        label: "Clicks",
        value: Math.round(current.clicks).toLocaleString(),
        change: getChange(current.clicks, previous.clicks)
      },
      {
        label: "Cost",
        value: `${accountCurrency}${Math.round(current.cost).toLocaleString()}`,
        change: getChange(current.cost, previous.cost),
        inverse: true
      },
      {
        label: "Conv",
        value: Math.round(current.conv).toLocaleString(),
        change: getChange(current.conv, previous.conv)
      },
      {
        label: "Value",
        value: `${accountCurrency}${Math.round(current.value).toLocaleString()}`,
        change: getChange(current.value, previous.value)
      },
      {
        label: "CTR",
        value: `${current.ctr.toFixed(1)}%`,
        change: getChange(current.ctr, previous.ctr)
      },
      {
        label: "CvR",
        value: `${current.cvr.toFixed(1)}%`,
        change: getChange(current.cvr, previous.cvr)
      },
      {
        label: "CPC",
        value: `${accountCurrency}${current.cpc.toFixed(2)}`,
        change: getChange(current.cpc, previous.cpc),
        inverse: true
      },
      {
        label: "CPA",
        value: `${accountCurrency}${current.cpa.toFixed(2)}`,
        change: getChange(current.cpa, previous.cpa),
        inverse: true
      },
      {
        label: "ROAS",
        value: current.roas.toFixed(2),
        change: getChange(current.roas, previous.roas)
      }
    ];
  }, [selectedPeriod, data, accountCurrency]);

  // Add useEffect to watch for account changes
  useEffect(() => {
    // Reset optimization mode when account changes
    setOptimizationMode('none')
  }, [activeAccount?.id]) // Use id instead of dexieId

  // Add getFilteredCampaigns inside component to access filters
  const getFilteredCampaigns = (campaigns: CampaignWithProfit[]) => {
    return campaigns.filter(campaign => {
      const name = campaign.Campaign?.toLowerCase() || '';  // PascalCase
      const include = includeFilter?.toLowerCase() || '';
      const exclude = excludeFilter?.toLowerCase() || '';

      if (include && !name.includes(include)) return false;
      if (exclude && name.includes(exclude)) return false;
      return true;
    });
  };

  // Add null check for BudgetOptimization campaigns
  const optimizationCampaigns = useMemo(() => {
    if (!campaigns?.length) return [];

    return campaigns.map(campaign => ({
      campaign: campaign.Campaign,
      campaignId: campaign.CampaignId,
      impr: campaign.Impressions,
      clicks: campaign.Clicks,
      cost: campaign.Cost,
      conv: campaign.Conversions,
      value: campaign.ConvValue,
      imprShare: campaign.ImprShare,
      lostBudget: campaign.LostToBudget,
      lostRank: campaign.LostToRank
    }));
  }, [campaigns]);

  // Early returns for loading/error/no data states
  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">
          {loadingStatus === 'initial' ? "Loading campaign data..." : "Loading..."}
        </div>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">
          {error || "No data available"}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      {/* Performance Overview Card */}
      <Card className="w-full max-w-[1800px] mx-auto">
        <CardHeader>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <CardTitle>Overall Performance (for all campaigns)</CardTitle>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <HelpCircle className="h-4 w-4 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Metrics compared to previous {selectedPeriod === '30d' ? '30' : '7'} day period</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex flex-col items-end gap-1">
              <Tabs
                value={selectedPeriod}
                onValueChange={(value) => {
                  trackEvent(ANALYTICS_EVENTS.ANALYSIS.TIME_RANGE(value));
                  setSelectedPeriod(value as '30d' | '7d');
                }}
                className="w-fit"
              >
                <TabsList className="bg-transparent gap-2">
                  <TabsTrigger
                    value="7d"
                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                     text-muted-foreground hover:bg-primary/10 transition-colors"
                  >
                    7 Days
                  </TabsTrigger>
                  <TabsTrigger
                    value="30d"
                    className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                     text-muted-foreground hover:bg-primary/10 transition-colors"
                  >
                    30 Days
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-10 gap-4">
            {performanceMetrics.map(({ label, value, change, inverse }) => (
              <div key={label} className="flex flex-col items-center text-center">
                <p className="text-sm font-medium text-muted-foreground">{label}</p>
                <p className="text-2xl font-bold">{value}</p>
                {change !== 0 && !isNaN(change) && (
                  <span className={change > 0 === !inverse ? 'text-green-500' : 'text-red-500'}>
                    {change > 0 ? '↑' : '↓'} {Math.abs(Math.round(change))}%
                  </span>
                )}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Filter Controls */}
      <div className="my-6">
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-3 flex-1">
            <span className="text-sm font-medium whitespace-nowrap">Include:</span>
            <input
              type="text"
              value={includeFilter}
              onChange={(e) => {
                trackEvent(ANALYTICS_EVENTS.ANALYSIS.CAMPAIGN_FILTER('include'));
                setIncludeFilter(e.target.value);
              }}
              placeholder="Filter campaigns..."
              className="flex-1 h-9 px-3 rounded-md border border-input bg-background text-sm"
            />
          </div>
          <div className="flex items-center gap-3 flex-1">
            <span className="text-sm font-medium whitespace-nowrap">Exclude:</span>
            <input
              type="text"
              value={excludeFilter}
              onChange={(e) => {
                trackEvent(ANALYTICS_EVENTS.ANALYSIS.CAMPAIGN_FILTER('exclude'));
                setExcludeFilter(e.target.value);
              }}
              placeholder="Exclude campaigns..."
              className="flex-1 h-9 px-3 rounded-md border border-input bg-background text-sm"
            />
          </div>
          <Select value={rowLimit.toString()} onValueChange={(v) => {
            trackEvent(ANALYTICS_EVENTS.ANALYSIS.ROWS_PER_PAGE(Number(v)));
            setRowLimit(Number(v));
          }}>
            <SelectTrigger className="w-[130px]">
              <SelectValue placeholder="Show rows" />
            </SelectTrigger>
            <SelectContent>
              {[5, 10, 25, 50, 100].map(value => (
                <SelectItem key={`limit-${value}`} value={value.toString()}>{value} rows</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* Tabs and Budget Optimization */}
      <Tabs defaultValue="projections" onValueChange={(value) => {
        trackEvent(ANALYTICS_EVENTS.ANALYSIS.TAB_CHANGE(value));
        setSelectedTab(value);
      }}>
        <div className="flex justify-between items-center">
          <TabsList className="h-12">
            <TabsTrigger value="projections">Budget Optimization</TabsTrigger>
            <TabsTrigger value="summary">Campaign Summary</TabsTrigger>
          </TabsList>

          {/* Optimization mode tabs */}
          {selectedTab === "projections" && (
            <Tabs
              value={optimizationMode}
              onValueChange={(value) => {
                if (['none', 'conservative', 'balanced', 'aggressive'].includes(value)) {
                  trackEvent(ANALYTICS_EVENTS.ANALYSIS.OPTIMIZATION_MODE(value));
                  setOptimizationMode(value as OptimizationMode);
                }
              }}
              className="w-fit"
            >
              <TabsList className="bg-transparent gap-2">
                <TabsTrigger
                  value="none"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                >
                  None
                </TabsTrigger>
                <TabsTrigger
                  value="conservative"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                >
                  Conservative
                </TabsTrigger>
                <TabsTrigger
                  value="balanced"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                >
                  Balanced
                </TabsTrigger>
                <TabsTrigger
                  value="aggressive"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground 
                   text-muted-foreground hover:bg-primary/10 transition-colors"
                >
                  Aggressive
                </TabsTrigger>
              </TabsList>
            </Tabs>
          )}
        </div>

        <TabsContent value="projections">
          <BudgetOptimization
            campaigns={campaigns}
            cogsPercentage={activeAccount?.cogsAmount || 0}
            breakEvenCpa={activeAccount?.breakEvenCpa || 0}
            businessMode={activeAccount?.businessMode || 'ecomm'}
            includeFilter={includeFilter}
            excludeFilter={excludeFilter}
            rowLimit={rowLimit}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            optimizationMode={optimizationMode}
            onProjectionsChange={(projections) => setCampaignProjections(projections)}
            accountCurrency={accountCurrency}
            onFilterChange={({ includeFilter: newInclude, excludeFilter: newExclude }) => {
              setIncludeFilter(newInclude);
              setExcludeFilter(newExclude);
            }}
          />
        </TabsContent>

        <TabsContent value="summary">
          <Card>
            <CardHeader>
              <CardTitle>Campaign Performance Summary using 30 day data</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="relative overflow-x-auto">
                {campaigns.length === 0 ? (
                  <div className="flex items-center justify-center py-8 text-muted-foreground">
                    No campaign data available
                  </div>
                ) : filteredCampaigns.length === 0 ? (
                  <div className="flex items-center justify-center py-8 text-muted-foreground">
                    No campaigns match your filter criteria
                  </div>
                ) : (
                  <>
                    <table className="w-full text-sm text-foreground">
                      <thead>
                        <tr className="border-b border-border">
                          <th className="px-4 py-2 text-left min-w-[300px] w-[300px]">Campaign</th>
                          <th className="px-4 py-2 text-right w-[120px]">Cost</th>
                          {activeAccount?.businessMode === 'ecomm' ? (
                            <>
                              <th className="px-4 py-2 text-right w-[120px]">Revenue</th>
                              <th className="px-4 py-2 text-right w-[120px]">Profit</th>
                              <th className="px-4 py-2 text-right w-[80px]">ROAS</th>
                            </>
                          ) : (
                            <>
                              <th className="px-4 py-2 text-right w-[120px]">Profit</th>
                              <th className="px-4 py-2 text-right w-[80px]">CPA</th>
                              <th className="px-4 py-2 text-right w-[80px]">Conv Rate</th>
                            </>
                          )}
                          <th className="px-4 py-2 text-right w-[80px]">Conversions</th>
                          <th className="px-4 py-2 text-right w-[80px]">Impr. Share</th>
                          <th className="px-4 py-2 text-right w-[80px]">Lost IS (Budget)</th>
                          <th className="px-4 py-2 text-right w-[80px]">Lost IS (Rank)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCampaigns.map((campaign, index) => {
                          const revenue = campaign.ConvValue || 0;
                          const cost = campaign.Cost || 0;
                          const grossRevenue = revenue * (1 - (activeAccount?.cogsAmount || 0) / 100);
                          const profit = grossRevenue - cost;
                          const roas = cost > 0 ? revenue / cost : 0;
                          const conversions = typeof campaign.Conversions === 'number' ? campaign.Conversions : 0;
                          const clicks = typeof campaign.Clicks === 'number' ? campaign.Clicks : 0;
                          const impressionShare = campaign.ImprShare * 100;
                          const lostToBudget = campaign.LostToBudget * 100;
                          const lostToRank = campaign.LostToRank * 100;
                          const hasImpressionShareMetrics =
                            typeof campaign.ImprShare === 'number' &&
                            typeof campaign.LostToBudget === 'number' &&
                            typeof campaign.LostToRank === 'number' &&
                            (campaign.ImprShare !== 0 || campaign.LostToBudget !== 0 || campaign.LostToRank !== 0);

                          // Safe number formatting helper
                          const safeFixed = (value: any, decimals: number = 1) => {
                            const num = Number(value);
                            return !isNaN(num) ? num.toFixed(decimals) : '0';
                          };

                          return (
                            <tr key={`${campaign.Campaign}-${index}`} className="border-b border-border hover:bg-muted/50">
                              <td
                                className="px-4 py-2 whitespace-normal line-clamp-2 min-w-[300px] w-[300px]"
                                title={campaign.Campaign}
                              >
                                {campaign.Campaign}
                              </td>
                              <td className="px-4 py-2 text-right">{accountCurrency}{Math.round(cost).toLocaleString()}</td>
                              {activeAccount?.businessMode === 'ecomm' ? (
                                <>
                                  <td className="px-4 py-2 text-right">{accountCurrency}{Math.round(revenue).toLocaleString()}</td>
                                  <td className="px-4 py-2 text-right">
                                    <span className={profit < 0 ? 'text-red-500' : 'text-green-500'}>
                                      {accountCurrency}{Math.round(profit).toLocaleString()}
                                    </span>
                                  </td>
                                  <td className="px-4 py-2 text-right">{safeFixed(roas, 1)}x</td>
                                </>
                              ) : (
                                <>
                                  <td className="px-4 py-2 text-right">
                                    <span className={
                                      (conversions * (activeAccount?.breakEvenCpa || 0) - cost) < 0
                                        ? 'text-red-500'
                                        : 'text-green-500'
                                    }>
                                      {accountCurrency}{Math.round(conversions * (activeAccount?.breakEvenCpa || 0) - cost).toLocaleString()}
                                    </span>
                                  </td>
                                  <td className="px-4 py-2 text-right">
                                    {accountCurrency}{safeFixed(conversions > 0 ? cost / conversions : 0, 2)}
                                  </td>
                                  <td className="px-4 py-2 text-right">
                                    {safeFixed(clicks > 0 ? (conversions / clicks * 100) : 0, 1)}%
                                  </td>
                                </>
                              )}
                              <td className="px-4 py-2 text-right">{safeFixed(conversions)}</td>
                              <td className="px-4 py-2 text-right">
                                {hasImpressionShareMetrics && typeof impressionShare === 'number'
                                  ? `${safeFixed(impressionShare)}%`
                                  : '-'}
                              </td>
                              <td className="px-4 py-2 text-right">
                                {hasImpressionShareMetrics && typeof lostToBudget === 'number'
                                  ? `${safeFixed(lostToBudget)}%`
                                  : '-'}
                              </td>
                              <td className="px-4 py-2 text-right">
                                {hasImpressionShareMetrics && typeof lostToRank === 'number'
                                  ? `${safeFixed(lostToRank)}%`
                                  : '-'}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <TablePagination
                      totalItems={totalFilteredCampaigns}
                      pageSize={rowLimit}
                      currentPage={currentPage}
                      onPageChange={setCurrentPage}
                    />
                  </>
                )}
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      {/* Add Fredo */}
      <Fredo
        pageData={{
          selectedTab,
          optimizationMode,
          performanceMetrics,
          campaigns: filteredCampaigns,
          totals,
          includeFilter,
          excludeFilter,
          rowLimit,
          campaignProjections,
          selectedPeriod
        }}
      />
    </div>
  )
}