'use client'

import React, { useState, useMemo, useEffect } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { useCampaignData } from "@/contexts/campaign-data"
import { useAccounts } from '@/contexts/account-context'
import { useUser } from '@clerk/clerk-react'
import { DEBUG, APP_CATEGORIES } from '@/lib/constants'
import { debounce } from 'lodash'
import { DataTable } from "@/components/placements/data-table"
import { Switch } from "@/components/ui/switch"
import { toast } from "@/components/ui/use-toast"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { InfoIcon, Check } from 'lucide-react'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { useNavigate } from 'react-router-dom'
import { hasVotedOnFeature } from '@/lib/user-votes'
import { trackEvent, ANALYTICS_EVENTS } from '@/lib/analytics'
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogCancel } from "@/components/ui/alert-dialog"
import { Fredo } from "@/components/fredo-chat"

// Raw data format from CSV
interface RawPlacementData {
  'Display Name': string;
  'Placement': string;
  'Type': string;
  'Target URL': string;
  'Campaign': string;
  'Impressions': string | number;
}

// Normalized format for internal use
interface PlacementData {
  displayName: string;
  placement: string;
  type: string;
  targetUrl: string;
  campaign: string;
  impressions: number;
  exclude?: boolean;
  canExclude: boolean;
}

// Type guard function
function isRawPlacementData(row: any): row is RawPlacementData {
  return (
    'Display Name' in row &&
    'Placement' in row &&
    'Type' in row &&
    'Target URL' in row &&
    'Campaign' in row &&
    'Impressions' in row
  )
}

export default function Placements() {
  const { data, loadingStatus, error, useSampleData } = useCampaignData()
  const { activeAccount } = useAccounts()
  const { isSignedIn, user } = useUser()
  const navigate = useNavigate()
  const hasVotedUserLists = hasVotedOnFeature(user, 'user-lists')

  const [selectedCampaign, setSelectedCampaign] = useState<string>('')
  const [selectedType, setSelectedType] = useState<string>('All Types')
  const [placementFilter, setPlacementFilter] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState('100')
  const [excludedPlacements, setExcludedPlacements] = useState<string[]>([])
  const [showExcludeDialog, setShowExcludeDialog] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyAppSuccess, setCopyAppSuccess] = useState(false);
  const [showAppInstructions, setShowAppInstructions] = useState(false);

  // Reset page when filters change
  useEffect(() => {
    setCurrentPage(1)
  }, [selectedCampaign, selectedType, placementFilter])

  // Reset exclusions when account changes
  useEffect(() => {
    if (activeAccount?.dexieId) {
      setExcludedPlacements([])
    }
  }, [activeAccount?.dexieId])

  // Handle exclusion toggle - Remove campaign dependency
  const handleExclusionToggle = (targetUrl: string, checked: boolean) => {
    // Find the placement in filtered data to check if it can be excluded
    const placementData = filteredPlacements.find(p => p.targetUrl === targetUrl)
    if (!placementData?.canExclude) return

    trackEvent(ANALYTICS_EVENTS.PLACEMENTS.EXCLUDE_TOGGLE());
    setExcludedPlacements(prev => {
      if (checked) {
        return [...prev, targetUrl]
      } else {
        return prev.filter(url => url !== targetUrl)
      }
    })
  }

  // Handle copy to clipboard with visual feedback
  const handleCopyExclusions = async () => {
    try {
      await navigator.clipboard.writeText(excludedPlacements.join(','))
      setCopySuccess(true);
      trackEvent(ANALYTICS_EVENTS.PLACEMENTS.COPY_EXCLUSIONS());
      toast({
        title: "Copied to clipboard",
        description: `${excludedPlacements.length} placement(s) copied`
      })
      // Reset success state after 2 seconds
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err)
      toast({
        variant: "destructive",
        title: "Failed to copy",
        description: "Please try again"
      })
    }
  }

  // Add handler for copying app categories
  const handleCopyAppCategories = async () => {
    try {
      await navigator.clipboard.writeText(APP_CATEGORIES.join('\n'));
      setCopyAppSuccess(true);
      trackEvent(ANALYTICS_EVENTS.PLACEMENTS.COPY_APP_CATEGORIES());
      toast({
        title: "Copied app categories",
        description: `${APP_CATEGORIES.length} app categories copied`
      });
      // Show instructions
      setShowAppInstructions(true);
      // Reset success state after 2 seconds
      setTimeout(() => setCopyAppSuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy app categories:', err);
      toast({
        variant: "destructive",
        title: "Failed to copy",
        description: "Please try again"
      });
    }
  };

  // Add the instructions dialog
  const AppInstructionsDialog = () => (
    <AlertDialog open={showAppInstructions} onOpenChange={setShowAppInstructions}>
      <AlertDialogContent className="max-w-3xl">
        <AlertDialogHeader>
          <AlertDialogTitle>How to Use App Category Exclusions</AlertDialogTitle>
          <AlertDialogDescription className="space-y-6">
            <div className="bg-muted/50 p-4 rounded-lg space-y-2">
              <p className="font-medium text-foreground">Why exclude mobile apps?</p>
              <p>Mobile app placements often have lower engagement rates and can consume significant budget without delivering optimal results. Excluding them can help focus your budget on more effective placements.</p>
            </div>

            <div>
              <p className="font-medium text-foreground mb-2">
                The app categories have been copied to your clipboard. Follow these steps to exclude them in Google Ads:
              </p>

              <div className="space-y-2 bg-background p-4 rounded-lg border">
                <ol className="list-decimal list-inside space-y-3">
                  <li>Go to Google Ads and select <span className="font-medium">Campaigns</span></li>
                  <li>In the left menu, click <span className="font-medium">Audiences, keywords and content</span></li>
                  <li>Click <span className="font-medium">Content</span></li>
                  <li>Scroll down to Exclusions & Click <span className="font-medium">Edit exclusions</span></li>
                  <li>Select <span className="font-medium">Account</span> level
                    <div className="text-sm text-muted-foreground ml-6 mt-1">
                      Note: PMax placement exclusions must be added at the account level
                    </div>
                  </li>
                  <li>Click <span className="font-medium">Enter</span></li>
                  <li>Paste the copied categories under <span className="font-medium">'Enter multiple placements'</span></li>
                  <li>Click <span className="font-medium">Add Placements (you must 'add' them before saving)</span></li>
                  <li>Click <span className="font-medium">Save</span></li>
                </ol>
              </div>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="gap-2">
          <AlertDialogCancel>Close</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  // Memoize type buttons to prevent unnecessary re-renders
  const typeButtons = useMemo(() => (
    <div className="flex items-center gap-4">
      <div className="flex gap-2">
        {['All Types', 'Website', 'Google Products', 'YouTube Video', 'Mobile App'].map(type => (
          <Button
            key={type}
            variant={selectedType === type ? "default" : "outline"}
            onClick={() => {
              trackEvent(ANALYTICS_EVENTS.PLACEMENTS.TYPE_FILTER(type.toLowerCase()));
              setSelectedType(type);
            }}
            className={`px-4 ${selectedType === type ? 'bg-primary text-primary-foreground hover:bg-primary/90' : ''}`}
          >
            {type}
          </Button>
        ))}
      </div>
      <div className="flex gap-2 ml-auto">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="default"
                onClick={handleCopyAppCategories}
                className="bg-purple-600 hover:bg-purple-700 text-white"
              >
                {copyAppSuccess ? (
                  <>
                    Copied! <Check className="w-4 h-4 ml-2" />
                  </>
                ) : (
                  <>
                    Copy All Apps ({APP_CATEGORIES.length})
                    <InfoIcon className="w-4 h-4 ml-2" />
                  </>
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Copies all mobile app category URLs for bulk exclusion in Google Ads.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="default"
                onClick={handleCopyExclusions}
                disabled={excludedPlacements.length === 0}
              >
                {copySuccess ? (
                  <>
                    Copied! <Check className="w-4 h-4 ml-2" />
                  </>
                ) : (
                  <>
                    Copy Exclusions ({excludedPlacements.length})
                    <InfoIcon className="w-4 h-4 ml-2" />
                  </>
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Copies selected placement URLs as a comma-separated list, ready to paste into Google Ads exclusions.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  ), [selectedType, excludedPlacements.length, copySuccess, copyAppSuccess, handleCopyExclusions, handleCopyAppCategories]);

  // Normalize placements data to handle both sample and user data formats
  const placementsData = useMemo(() => {
    const rawData = data?.placements || []


    const normalized = rawData.map(row => {
      if (isRawPlacementData(row)) {
        // Check if placement is excludable
        const isGoogleOwned = row['Type'] === 'GOOGLE_PRODUCTS'
        const isUnavailableVideo = row['Display Name'] === 'Video no longer available'
        const canExclude = !isGoogleOwned && !isUnavailableVideo
        const targetUrl = String(row['Target URL'])

        return {
          displayName: String(row['Display Name']),
          placement: String(row['Placement']),
          type: String(row['Type']),
          targetUrl,
          campaign: String(row['Campaign']),
          impressions: Number(String(row['Impressions'] || '0').replace(/,/g, '')),
          exclude: excludedPlacements.includes(targetUrl),
          canExclude
        }
      }
      // Already in normalized format
      const isGoogleOwned = row.type === 'GOOGLE_PRODUCTS'
      const isUnavailableVideo = row.displayName === 'Video no longer available'
      const canExclude = !isGoogleOwned && !isUnavailableVideo

      return {
        ...row,
        exclude: excludedPlacements.includes(row.targetUrl),
        canExclude
      } as PlacementData
    })

    return normalized
  }, [data?.placements, useSampleData, excludedPlacements])

  // Process campaigns with raw data properties
  const campaigns = useMemo(() => {
    if (!placementsData?.length) return []

    const campaignMap = new Map<string, { Campaign: string, Impressions: number }>()

    // First add "All Campaigns" if it exists
    const allCampaignsData = placementsData.filter(row =>
      row.campaign === 'All Campaigns'
    )

    if (allCampaignsData.length > 0) {
      campaignMap.set('All Campaigns', {
        Campaign: 'All Campaigns',
        Impressions: allCampaignsData.reduce((sum, row) =>
          sum + row.impressions, 0
        )
      })
    }

    // Then add individual campaigns
    placementsData
      .filter(row => row.campaign !== 'All Campaigns')
      .forEach(row => {
        const campaignName = row.campaign
        if (!campaignName) return

        if (!campaignMap.has(campaignName)) {
          campaignMap.set(campaignName, {
            Campaign: campaignName,
            Impressions: 0
          })
        }
        campaignMap.get(campaignName)!.Impressions += row.impressions
      })

    const sortedCampaigns = Array.from(campaignMap.values())
      .filter(campaign => campaign.Impressions > 0)
      .sort((a, b) => b.Impressions - a.Impressions)

    if (DEBUG) {
      console.log('Placements: Campaigns processed:', {
        total: sortedCampaigns.length,
        first: sortedCampaigns[0],
        useSampleData,
        placementsData: placementsData.slice(0, 2)
      })
    }

    return sortedCampaigns
  }, [placementsData, useSampleData])

  // Auto-select highest impression campaign - only on mount and account change
  useEffect(() => {
    if (campaigns.length > 0 && !selectedCampaign) {
      const highestImprCampaign = campaigns[0]
      if (DEBUG) {
        console.log('Auto-selecting highest impression campaign:', {
          campaign: highestImprCampaign.Campaign,
          impressions: highestImprCampaign.Impressions,
          totalCampaigns: campaigns.length,
          allCampaigns: campaigns,
          activeAccountId: activeAccount?.dexieId
        })
      }
      setSelectedCampaign(highestImprCampaign.Campaign)
    }
  }, [campaigns, activeAccount?.dexieId])

  // Debug logging for campaign selection changes
  useEffect(() => {
    if (DEBUG) {
      console.log('Placements campaign selection changed:', {
        selectedCampaign,
        campaignsAvailable: campaigns.length,
        activeAccountId: activeAccount?.dexieId,
        hasPlacementsData: placementsData.length > 0
      })
    }
  }, [selectedCampaign, campaigns.length, placementsData.length, activeAccount?.dexieId])

  // Filter placements based on all criteria
  const filteredPlacements = useMemo(() => {
    if (!placementsData?.length) return [];

    // Log first few rows to debug type values
    if (DEBUG && selectedType !== 'All Types') {
      console.log('Type filtering debug:', {
        selectedType,
        sampleTypes: placementsData.slice(0, 5).map(p => p.type),
        matchCount: placementsData.filter(p => p.type === selectedType).length
      });
    }

    return placementsData.map(placement => ({
      ...placement,
      exclude: excludedPlacements.includes(placement.targetUrl)
    })).filter(placement => {
      // Campaign filter
      if (selectedCampaign && placement.campaign !== selectedCampaign) {
        return false;
      }
      // Type filter - direct match with the button text
      if (selectedType !== 'All Types') {
        const normalizedType = placement.type.replace('_', ' ').toLowerCase();
        const searchType = selectedType.toLowerCase();
        if (!normalizedType.includes(searchType)) {
          return false;
        }
      }
      // Placement name filter - only apply if filter has 2+ characters
      if (placementFilter.length > 1 && !placement.displayName.toLowerCase().includes(placementFilter.toLowerCase())) {
        return false;
      }
      return true;
    });
  }, [
    placementsData,
    selectedCampaign,
    selectedType,
    excludedPlacements,
    // Only include placementFilter in dependencies if it has 2+ characters
    placementFilter.length > 1 ? placementFilter : ''
  ]);

  // Calculate visible vs total filtered placements
  const visibleCount = Math.min(Number(rowsPerPage), filteredPlacements.length)
  const totalFilteredCount = filteredPlacements.length

  // Handle bulk exclusion
  const handleBulkExclude = (excludeAll: boolean) => {
    const placementsToExclude = excludeAll
      ? filteredPlacements
      : filteredPlacements.slice(0, Number(rowsPerPage))

    trackEvent(ANALYTICS_EVENTS.PLACEMENTS.BULK_EXCLUDE(excludeAll ? 'all' : 'visible'));

    const newExclusions = placementsToExclude
      .filter(p => p.canExclude && !excludedPlacements.includes(p.targetUrl))
      .map(p => p.targetUrl)

    setExcludedPlacements(prev => [...prev, ...newExclusions])
    setSelectAllChecked(true)
    setShowExcludeDialog(false)
  }

  // Handle select all toggle
  const handleSelectAllToggle = (checked: boolean) => {
    if (checked && filteredPlacements.length > Number(rowsPerPage)) {
      setShowExcludeDialog(true)
    } else {
      handleBulkExclude(false) // Just exclude visible rows
    }
    setSelectAllChecked(checked)
  }

  // Reset select all when filters change
  useEffect(() => {
    setSelectAllChecked(false)
  }, [selectedCampaign, placementFilter, selectedType])

  // Early returns for loading/error/no data states
  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    if (DEBUG) {
      console.log('Loading state:', { loadingStatus })
      console.groupEnd()
    }
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">
          {loadingStatus === 'initial'
            ? "Loading your campaign data (this may take a minute or two)..."
            : "Loading..."}
        </div>
      </div>
    )
  }

  if (!data?.placements?.length) {
    if (DEBUG) {
      console.log('No data state:', {
        hasData: !!data,
        hasPlacementsData: !!data?.placements,
        placementsCount: data?.placements?.length
      })
      console.groupEnd()
    }
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-muted-foreground">
          No placement data available. {useSampleData ?
            'Sample data may be incomplete.' :
            'Please check your data source in Settings.'}
        </div>
      </div>
    )
  }

  if (error) {
    if (DEBUG) {
      console.log('Error state:', { error })
      console.groupEnd()
    }
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">Error loading data: {error}</div>
      </div>
    )
  }

  if (DEBUG) {
    console.groupEnd()
  }

  return (
    <div className="container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-6 space-y-6">
      <div>
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-semibold">Performance Max Placements</h1>
            <p className="text-sm text-muted-foreground mt-1">
              View and manage your PMax placement data across different campaigns.
              <span className="ml-1 text-purple-500 font-medium">
                (Demand Gen placement data coming in 2025)
              </span>
            </p>
          </div>
          <Button
            variant="outline"
            onClick={() => !hasVotedUserLists && navigate('/feedback?feature=user-lists&returnTo=/placements')}
            className={`${hasVotedUserLists ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={hasVotedUserLists}
          >
            Edit User Lists {hasVotedUserLists && '✓'}
          </Button>
        </div>
      </div>

      {typeButtons}

      <div className="flex items-center gap-4">
        <Select
          value={selectedCampaign}
          onValueChange={(campaign) => {
            trackEvent(ANALYTICS_EVENTS.PLACEMENTS.CAMPAIGN_SELECT(campaign));
            setSelectedCampaign(campaign);
          }}
        >
          <SelectTrigger className="h-10 w-[500px]">
            <SelectValue placeholder="Select a campaign" />
          </SelectTrigger>
          <SelectContent>
            {campaigns.map(({ Campaign, Impressions }) => (
              <SelectItem
                key={Campaign}
                value={Campaign}
                className="flex items-center justify-between py-2 pr-2"
              >
                <span className="truncate mr-4">
                  {Campaign}
                </span>
                <span className="text-sm font-medium text-muted-foreground">
                  {Impressions.toLocaleString()} impr
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <input
          type="text"
          value={placementFilter}
          onChange={(e) => {
            trackEvent(ANALYTICS_EVENTS.PLACEMENTS.PLACEMENT_FILTER());
            setPlacementFilter(e.target.value);
          }}
          placeholder="Filter placements..."
          className="h-10 px-3 rounded-md border border-input bg-background text-sm w-[300px]"
        />

        <div className="ml-auto w-[150px]">
          <Select
            value={rowsPerPage}
            onValueChange={(value) => {
              trackEvent(ANALYTICS_EVENTS.PLACEMENTS.ROWS_PER_PAGE(Number(value)));
              setRowsPerPage(value);
            }}
          >
            <SelectTrigger>
              <SelectValue>{rowsPerPage} rows</SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="10">10 rows</SelectItem>
              <SelectItem value="25">25 rows</SelectItem>
              <SelectItem value="50">50 rows</SelectItem>
              <SelectItem value="100">100 rows</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="flex items-center justify-end mb-2">
        <div className="flex items-center gap-2">
          <span className="text-sm text-muted-foreground">All</span>
          <Switch
            checked={selectAllChecked}
            onCheckedChange={handleSelectAllToggle}
          />
        </div>
      </div>

      <Dialog open={showExcludeDialog} onOpenChange={setShowExcludeDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Bulk Exclude Placements</DialogTitle>
            <DialogDescription>
              Do you want to exclude only the {visibleCount} visible placements, or all {totalFilteredCount} placements that match your current filters?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex gap-2">
            <Button variant="outline" onClick={() => handleBulkExclude(false)}>
              Visible Only ({visibleCount})
            </Button>
            <Button onClick={() => handleBulkExclude(true)}>
              All Matching ({totalFilteredCount})
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AppInstructionsDialog />

      <DataTable
        data={filteredPlacements}
        pageSize={Number(rowsPerPage)}
        onExclusionToggle={handleExclusionToggle}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />

      <Fredo
        pageData={{
          selectedCampaign,
          selectedType,
          placementFilter,
          rowsPerPage,
          excludedPlacements,
          filteredPlacements,
          totalFilteredCount,
          visibleCount,
          campaigns
        }}
      />
    </div>
  )
} 