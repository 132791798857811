// src/lib/profit-calculations.ts
import type { BusinessMode, ProcessedMetrics } from '@/types/metrics';
import type {
  CurvePoint,
  ProfitDataPoint,
  GenerateCurvePointsConfig,
  OptimalZone
} from '@/types/profit';

// Constants
const SCALE_STEPS = 50
const POWER_FACTOR = 0.4 // For diminishing returns calculation

interface ProfitConfig {
  businessMode: BusinessMode
  cogsAmount: number
  breakEvenCpa: number
}

// Core metrics needed for profit calculation
type ProfitCalcMetrics = {
  cost: number;
  conv: number;
  value: number;
  clicks: number;
  impr: number;
}

// Full metrics needed for curve generation
interface FullMetrics {
  cost: number;
  conv: number;
  value: number;
  clicks: number;
  impr: number;
}

export function calculateProfit(
  metrics: ProfitCalcMetrics,
  config: { businessMode: BusinessMode; cogsAmount: number; breakEvenCpa: number }
): number {
  const { cost, value, conv } = metrics;

  if (config.businessMode === 'ecomm') {
    const revenue = value || 0;
    const cogs = (revenue * (config.cogsAmount / 100)) || 0;
    return revenue - cost - cogs;
  }

  // Lead gen mode
  const conversionValue = conv * config.breakEvenCpa;
  return conversionValue - cost;
}

// Keep full metrics type for other functions
type ProfitBaseMetrics = ProfitCalcMetrics & {
  clicks: number;
  impr: number;
}

export function generateCurvePoints(config: GenerateCurvePointsConfig & FullMetrics): CurvePoint[] {
  const {
    currentCost,
    currentConv,
    currentValue,
    clicks: currentClicks,
    impr: currentImpr,
    responseModel,
    businessMode,
    cogsAmount,
    breakEvenCpa,
    impressionShare = 1
  } = config

  const points: CurvePoint[] = []
  const minCost = currentCost * 0.5 // Make current cost in middle of range
  const maxCost = currentCost * 2
  const step = (maxCost - minCost) / (SCALE_STEPS - 1)

  // Calculate current CPA and ROAS for maintaining ratios
  const currentCPA = currentConv > 0 ? currentCost / currentConv : 0
  const currentROAS = currentCost > 0 ? currentValue / currentCost : 0

  for (let i = 0; i < SCALE_STEPS; i++) {
    const cost = minCost + (i * step)
    const costRatio = cost / currentCost

    // Calculate conversions based on response model
    let conv: number
    switch (responseModel) {
      case 'linear':
        conv = currentConv * costRatio // Linear increase
        break
      case 'independent':
        conv = currentConv // No change
        break
      case 'diminishing':
      default:
        conv = currentConv * Math.pow(costRatio, POWER_FACTOR) // Diminishing returns
    }

    // Calculate value based on business mode and response model
    let value: number
    if (businessMode === 'leadgen') {
      // For leadgen, value is always based on break-even CPA
      value = conv * breakEvenCpa
    } else {
      // For ecomm, value follows same curve as conversions
      switch (responseModel) {
        case 'linear':
          value = currentValue * costRatio // Linear increase
          break
        case 'independent':
          value = currentValue // No change
          break
        case 'diminishing':
        default:
          value = currentValue * Math.pow(costRatio, POWER_FACTOR) // Diminishing returns
      }
    }

    // Calculate profit
    const profit = calculateProfit(
      { cost, conv, value, clicks: 0, impr: 0 },
      { businessMode, cogsAmount, breakEvenCpa }
    )

    // Calculate proportional metrics
    const clicks = conv * (currentClicks / currentConv)
    const impr = conv * (currentImpr / currentConv)

    // Calculate CPA and ROAS
    const cpa = conv > 0 ? cost / conv : 0
    const roas = cost > 0 ? value / cost : 0

    // Calculate marginal metrics if not first point
    let marginalCpa, marginalRoas
    if (i > 0) {
      const prev = points[i - 1]
      const deltaCost = cost - prev.cost
      const deltaConv = conv - prev.conv
      const deltaValue = value - prev.value

      marginalCpa = deltaConv > 0 ? deltaCost / deltaConv : 0
      marginalRoas = deltaCost > 0 ? deltaValue / deltaCost : 0
    }

    points.push({
      cost,
      conv,
      value,
      profit,
      cpa,
      roas,
      marginalCpa,
      marginalRoas,
      clicks,
      impr
    })
  }

  return points
}

export function findOptimalZone(profitData: ProfitDataPoint[], currentCost: number): OptimalZone {
  if (!profitData?.length) {
    return {
      start: currentCost,
      end: currentCost,
      current: currentCost,
      maxProfit: 0,
      targetSpend: currentCost,
      profitAtTarget: 0
    }
  }

  // Find point with maximum profit
  const maxProfitPoint = profitData.reduce((max, point) =>
    point.profit > max.profit ? point : max, profitData[0]
  )

  // Find profitable points (above 95% of max profit)
  const profitThreshold = maxProfitPoint.profit * 0.95
  const profitablePoints = profitData.filter(point => point.profit >= profitThreshold)

  // Get range boundaries
  const start = Math.min(...profitablePoints.map(p => p.cost))
  const end = Math.max(...profitablePoints.map(p => p.cost))

  return {
    start,
    end,
    current: currentCost,
    maxProfit: maxProfitPoint.profit,
    targetSpend: maxProfitPoint.cost,
    profitAtTarget: maxProfitPoint.profit
  }
}

export function calculateIncrementalMetrics(
  points: CurvePoint[]
): Array<{ cost: number, incrementalROAS: number, incrementalProfit: number, incrementalSales: number }> {
  return points.slice(1).map((point, i) => {
    const prev = points[i]
    const incrementalCost = point.cost - prev.cost
    const incrementalValue = point.value - prev.value
    const incrementalConv = point.conv - prev.conv

    return {
      cost: point.cost,
      incrementalROAS: incrementalCost > 0 ? incrementalValue / incrementalCost : 0,
      incrementalProfit: point.profit - prev.profit,
      incrementalSales: incrementalConv
    }
  })
}

// Helper function to calculate current metrics
export function calculateCurrentMetrics(
  metrics: ProfitBaseMetrics,
  businessMode: BusinessMode,
  cogsAmount: number,
  breakEvenCpa: number
): ProcessedMetrics {
  const profit = calculateProfit(
    metrics,
    { businessMode, cogsAmount, breakEvenCpa }
  );

  // Calculate all required metrics
  const CPA = metrics.conv > 0 ? metrics.cost / metrics.conv : 0;
  const ROAS = metrics.cost > 0 ? metrics.value / metrics.cost : 0;
  const CTR = metrics.impr ? (metrics.clicks / metrics.impr) * 100 : 0;
  const CvR = metrics.clicks ? (metrics.conv / metrics.clicks) * 100 : 0;
  const AOV = metrics.conv > 0 ? metrics.value / metrics.conv : 0;

  return {
    ...metrics,
    profit,
    CPA,
    ROAS,
    CTR,
    CvR,
    AOV
  };
}

// Helper to find adjacent points
export function findAdjacentPoints(points: CurvePoint[], currentCost: number): {
  leftPoint: CurvePoint | null
  rightPoint: CurvePoint | null
} {
  if (!points.length) return { leftPoint: null, rightPoint: null }

  let leftPoint = null
  let rightPoint = null

  for (let i = 0; i < points.length - 1; i++) {
    if (points[i].cost <= currentCost && points[i + 1].cost > currentCost) {
      leftPoint = points[i]
      rightPoint = points[i + 1]
      break
    }
  }

  return { leftPoint, rightPoint }
}

// Legacy functions for backward compatibility
export function calculateRevenueFromCost(
  currentCost: number,
  baseCost: number,
  baseRevenue: number,  // This is baseConv for lead gen
  model: string = 'diminishing'
): number {
  const scaleFactor = model === 'diminishing'
    ? Math.pow(currentCost / baseCost, POWER_FACTOR)
    : model === 'linear'
      ? currentCost / baseCost
      : 1;

  return baseRevenue * scaleFactor;  // For lead gen, this returns new conversions
}

export function calculateCostFromRevenue(
  targetRevenue: number,  // This is targetConv in lead gen mode
  baseCost: number,
  baseRevenue: number,  // This is baseConv in lead gen mode
  model: string = 'diminishing',
  businessMode: BusinessMode,
  breakEvenCpa: number,
  baseConversions: number
): number {
  const scaleFactor = model === 'diminishing'
    ? Math.pow(targetRevenue / baseRevenue, 1 / POWER_FACTOR)
    : model === 'linear'
      ? targetRevenue / baseRevenue
      : 1;

  return baseCost * scaleFactor;
}

// Alias for backward compatibility
export const calculateProfitProjections = generateCurvePoints
export const calculateIncrementalData = calculateIncrementalMetrics