// src/lib/constants.ts

import { Account } from '@/types/metrics'

// Sheet tab names - source of truth
export const SHEET_TABS = {
  VERSION: 'version',
  ACCOUNT: 'account',
  DAILY: 'daily',
  HOURLY: 'hourly',
  HOURLY_YEST: 'hourly_yest',
  THIRTY_DAYS: 'thirty_days',
  P_THIRTY_DAYS: 'p_thirty_days',
  SEVEN_DAYS: 'seven_days',
  P_SEVEN_DAYS: 'p_seven_days',
  CAMP_SETTINGS: 'camp_settings',
  PMAX_PERF: 'pmax_perf',
  PRODUCTS: 'products',
  PLACEMENTS: 'placements',
  H_CAMP_30D: 'h_camp_30d',
  H_CAMP_7D: 'h_camp_7d',
  H_DOW_30D: 'h_dow_30d',
  H_DOW_7D: 'h_dow_7d',
  BIDDING: 'bidding',
  QUALITY: 'quality',
  KEYWORD80: 'keyword80',
  SEARCH80: 'search80',
  CAMP_CONV: 'camp_conv',
  CONV_ACTION: 'conv_action',
  ASSET: 'asset',
  GROUPS: 'groups',
  TERMS: 'terms',
  TOTALTERMS: 'totalTerms',
  SUMMARY: 'summary',
  TOTALS: 'totals',
  TNGRAMS: 'tNgrams',
  SNGRAMS: 'sNgrams',
  PATHS: 'paths',
  PTITLE: 'pTitle',
  PTITLECAMPAIGN: 'pTitleCampaign',
  PTITLEID: 'pTitleID'
} as const;

export type SheetTab = keyof typeof SHEET_TABS;

// Get App URL from localStorage with validation
export const getWebAppUrl = (): string | null => {
  const url = localStorage.getItem(STORAGE_KEYS.WEB_APP_URL);
  if (!url) return null;

  // Validate URL format
  if (!url.includes('script.google.com/macros/s/')) {
    console.warn('Invalid Web App URL format found in storage. Clearing...');
    localStorage.removeItem(STORAGE_KEYS.WEB_APP_URL);
    return null;
  }

  // Check for invalid URLs
  if (url.includes('googleusercontent')) {
    console.warn('Invalid googleusercontent URL found in storage. Clearing...');
    localStorage.removeItem(STORAGE_KEYS.WEB_APP_URL);
    return null;
  }

  return url;
};

export const STORAGE_KEYS = {
  CAMPAIGN_DATA: 'campaignData',
  ROW_LIMIT: 'campaign_row_limit',
  WEB_APP_URL: 'webAppUrl',
  SETUP_STATE: 'setupState',
  SETUP_DATA: 'setupData',
  USE_SAMPLE_DATA: 'useSampleData',
  USER_CAMPAIGN_DATA: 'userCampaignData',
  IS_CONFIGURED: 'isConfigured',
  GAME_LEVEL: 'currentGameLevel',
  CURRENT_COGS: 'currentCogs',
  CURRENT_BREAK_EVEN: 'currentBreakEven'
} as const;

// Cache duration in milliseconds
export const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

// Data sources configuration
export const DATA_SOURCES = {
  [SHEET_TABS.VERSION]: { title: 'Version Info', description: 'Account version and configuration' },
  [SHEET_TABS.ACCOUNT]: { title: 'Account Info', description: 'Legacy account information' },
  [SHEET_TABS.DAILY]: { title: 'Daily Data', description: 'Daily campaign metrics.' },
  [SHEET_TABS.THIRTY_DAYS]: { title: '30 Day Data', description: '30-day aggregated metrics' },
  [SHEET_TABS.P_THIRTY_DAYS]: { title: 'Previous 30 Days', description: '31-60 day comparison metrics' },
  [SHEET_TABS.SEVEN_DAYS]: { title: '7 Day Data', description: '7-day aggregated metrics' },
  [SHEET_TABS.P_SEVEN_DAYS]: { title: 'Previous 7 Days', description: '8-14 day comparison metrics' },
  [SHEET_TABS.HOURLY]: { title: "Today's Hourly", description: 'Hourly metrics for today' },
  [SHEET_TABS.HOURLY_YEST]: { title: "Yesterday's Hourly", description: 'Hourly metrics for yesterday' },
  [SHEET_TABS.CAMP_SETTINGS]: { title: 'Campaign Settings', description: 'Campaign settings and configuration' },
  [SHEET_TABS.PMAX_PERF]: { title: 'PMax Performance', description: 'Performance Max campaign performance' },
  [SHEET_TABS.PRODUCTS]: { title: 'Product Data', description: 'Product performance metrics' },
  [SHEET_TABS.SEARCH80]: { title: 'Search Terms 80/20', description: 'Top search terms performance data' },
  [SHEET_TABS.PLACEMENTS]: { title: 'Placements', description: 'Campaign placement performance data' },
  [SHEET_TABS.CAMP_CONV]: { title: 'Campaign Conversions', description: 'Detailed campaign conversion data' },
  [SHEET_TABS.CONV_ACTION]: { title: 'Conversion Actions', description: 'Conversion action configuration and data' },
  [SHEET_TABS.ASSET]: { title: 'Assets', description: 'Asset performance metrics' },
  [SHEET_TABS.GROUPS]: { title: 'Asset Groups', description: 'Asset group performance data' },
  [SHEET_TABS.TERMS]: { title: 'Terms Analysis', description: 'Term categorization and analysis' },
  [SHEET_TABS.TOTALTERMS]: { title: 'Total Terms', description: 'Aggregated term performance' },
  [SHEET_TABS.SUMMARY]: { title: 'Campaign Summary', description: 'Campaign performance summary by channel' },
  [SHEET_TABS.TOTALS]: { title: 'Campaign Totals', description: 'Total campaign performance by channel' },
  [SHEET_TABS.TNGRAMS]: { title: 'Term N-Grams', description: 'Term n-gram analysis' },
  [SHEET_TABS.SNGRAMS]: { title: 'Search N-Grams', description: 'Search term n-gram analysis' },
  [SHEET_TABS.PATHS]: { title: 'Path Analysis', description: 'URL path performance analysis' },
  [SHEET_TABS.PTITLE]: { title: 'Product Titles', description: 'Product title performance' },
  [SHEET_TABS.PTITLECAMPAIGN]: { title: 'Product Titles by Campaign', description: 'Product title performance by campaign' },
  [SHEET_TABS.PTITLEID]: { title: 'Product Titles with IDs', description: 'Product title performance with IDs' }
} as const;


export const CURRENCIES = [
  { symbol: '$', label: 'Dollar' },
  { symbol: '€', label: 'Euro' },
  { symbol: '£', label: 'Pound' },
  { symbol: '¥', label: 'Yen' },
  { symbol: 'zł',label: 'Zloty' },
  { symbol: '₹', label: 'Rupee' },
  { symbol: '₽', label: 'Ruble' },
  { symbol: '₩', label: 'Won' },
  { symbol: '₣', label: 'Franc' },
  { symbol: '₫', label: 'Dong' },
  { symbol: '₱', label: 'Peso' },
  { symbol: 'R$',label: 'Real' },
  { symbol: 'kr',label: 'Krona/Krone' },
  { symbol: '₺', label: 'Turkish Lira' },
  { symbol: '₴', label: 'Hryvnia' },
  { symbol: 'RON', label: 'Romanian Leu' }

] as const;

export const METRIC_FORMATS = {
  CURRENCY: (v: number) => `${Math.round(v).toLocaleString()}`,
  CURRENCY_2_DEC: (v: number) => `${v.toFixed(2)}`,
  NUMBER: (v: number) => Math.round(v).toLocaleString(),
  PERCENT: (v: number) => `${Math.round(v)}%`,
  PERCENT_1_DEC: (v: number) => `${v.toFixed(1)}%`,
  ROAS: (v: number) => `${v.toFixed(1)}x`,
} as const;


export const PMAX_METRICS = [
  {
    key: 'cost',
    label: 'Cost',
    format: (v: number) => `${Math.round(v).toLocaleString()}`
  },
  {
    key: 'cost_share',
    label: 'Cost %',
    format: (v: number) => `${v.toFixed(1)}%`
  },
  {
    key: 'conversions',
    label: 'Conv',
    format: (v: number) => Math.round(v).toLocaleString()
  },
  {
    key: 'conv_share',
    label: 'Conv %',
    format: (v: number) => `${v.toFixed(1)}%`
  },
  {
    key: 'value',
    label: 'Value',
    format: (v: number) => `${Math.round(v).toLocaleString()}`
  },
  {
    key: 'value_share',
    label: 'Value %',
    format: (v: number) => `${v.toFixed(1)}%`
  }
] as const;

export const PMAX_CHANNELS = ['Shopping', 'Display', 'Video', 'Search'] as const;

export type PMaxChannel = typeof PMAX_CHANNELS[number];
export type PMaxMetricKey = typeof PMAX_METRICS[number]['key'];
export type TabKey = keyof typeof DATA_SOURCES;
export type FetchStatus = 'loading' | 'success' | 'error';

// Setup state management
interface SetupState {
  isComplete: boolean;
  sheetUrl?: string;
  webAppUrl?: string;
  completedAt?: string;
  currentStep?: number;
}

// Cache the configuration status
let configurationStatus: boolean | null = null;
let lastCheck = 0;
const CHECK_INTERVAL = 5000; // 5 seconds

export const isConfigured = () => {
  const now = Date.now();

  // Only check if we haven't checked before or if enough time has passed
  if (configurationStatus === null || now - lastCheck > CHECK_INTERVAL) {
    const setupData = localStorage.getItem(STORAGE_KEYS.SETUP_DATA);
    configurationStatus = !!setupData;
    lastCheck = now;
  }

  return configurationStatus;
};

// Add a function to reset the cache when needed
export const resetConfigurationStatus = () => {
  configurationStatus = null;
};

export const getSetupState = (): SetupState | null => {
  try {
    const state = localStorage.getItem(STORAGE_KEYS.SETUP_STATE);
    return state ? JSON.parse(state) : null;
  } catch (error) {
    console.error('Error parsing setup state:', error);
    return null;
  }
};

export const saveSetupState = (state: SetupState): void => {
  try {
    localStorage.setItem(STORAGE_KEYS.SETUP_STATE, JSON.stringify(state));
  } catch (error) {
    console.error('Error saving setup state:', error);
  }
};

export const completeSetup = (sheetUrl: string, webAppUrl: string): SetupState => {
  // Validate URL format
  if (!webAppUrl.includes('script.google.com/macros/s/')) {
    throw new Error('Invalid Web App URL format. Must be a Google Apps Script web app URL.');
  }

  // Prevent googleusercontent URLs
  if (webAppUrl.includes('googleusercontent')) {
    throw new Error('Invalid URL: Contains googleusercontent. Please use the proper Web App URL.');
  }

  const state: SetupState = {
    isComplete: true,
    sheetUrl,
    webAppUrl,
    completedAt: new Date().toISOString(),
    currentStep: 4
  };

  saveSetupState(state);
  localStorage.setItem(STORAGE_KEYS.WEB_APP_URL, webAppUrl);
  localStorage.setItem(STORAGE_KEYS.IS_CONFIGURED, 'true');

  return state;
};

// Add to existing constants
export const MAX_FREE_ACCOUNTS = 5
export const SAMPLE_ACCOUNT_ID = '111-111-1111'
export const SAMPLE_ACCOUNT_SCRIPT_VERSION = '0.4.30'




interface VersionRequirement {
  minVersion: string;
  label?: string;
}

export const PAGE_VERSION_REQUIREMENTS = {
  pages: {
    keywords: {
      minVersion: '0.4.46'
    }
  } satisfies Record<string, VersionRequirement>,
  features: {
    '180-day-trends': {
      minVersion: '0.4.25',
      label: '180 Day History'
    }
  } satisfies Record<string, VersionRequirement>
} as const;

export const SAMPLE_ACCOUNT: Account = {
  id: SAMPLE_ACCOUNT_ID,
  dexieId: SAMPLE_ACCOUNT_ID,
  accountCID: 'sample-cid',
  scriptVersion: SAMPLE_ACCOUNT_SCRIPT_VERSION,
  name: 'Sample Account',
  businessMode: 'ecomm',
  currency: '$',
  cogsAmount: 55,
  breakEvenCpa: 33,
  webAppUrl: '/sample-data',
  isSampleAccount: true
}

// Add DEBUG constant
export const DEBUG = true;

// Generate app category URLs for exclusions
function generateAppCategories() {
  const categories: string[] = [];

  // First range: 60001-60062
  for (let i = 1; i <= 62; i++) {
    categories.push(`mobileappcategory::600${i.toString().padStart(2, '0')}`);
  }

  // Second range: 60501-60587
  for (let i = 1; i <= 87; i++) {
    categories.push(`mobileappcategory::605${i.toString().padStart(2, '0')}`);
  }

  return categories;
}

export const APP_CATEGORIES = generateAppCategories();


export type IgnoredTab =
  | "Settings" | "Advanced" | "Account" | "Account." | "Campaign"
  | "Campaign." | "Categories" | "Categories." | "Geo" | "Assets"
  | "Placements" | "Pages" | "TitleCampaign" | "TitleID" | "idChannel"
  | "How to Use This" | "Asset Groups" | "Asset Groups." | "tNgrams"
  | "sNgrams" | "Comp" | "ID" | "list" | "log" | "l" | "map"
  | "MCC Script" | "AI"

export const IGNORED_TABS: IgnoredTab[] = [
  "Settings",
  "Advanced",
  "Account.",
  "Campaign",
  "Campaign.",
  "Categories",
  "Categories.",
  "Geo",
  "Assets",
  "Placements",
  "Pages",
  "TitleCampaign",
  "TitleID",
  "idChannel",
  "How to Use This",
  "Asset Groups",
  "Asset Groups.",
  "tNgrams",
  "sNgrams",
  "Comp",
  "ID",
  "list",
  "log",
  "l",
  "map",
  "MCC Script",
  "AI"
]

export type KnownTab =
  | "daily" | "hourly" | "hourly_yest" | "thirty_days"
  | "p_thirty_days" | "seven_days" | "p_seven_days" | "pmax_perf"
  | "products" | "placements" | "h_camp_30d"
  | "h_camp_7d" | "h_dow_30d" | "h_dow_7d" | "bidding"
  | "quality" | "keyword80" | "search80"
  | "camp_conv" | "conv_action" | "asset" | "groups"
  | "terms" | "totalTerms" | "summary" | "totals"
  | "tNgrams" | "sNgrams" | "paths"
  | "pTitle" | "pTitleCampaign" | "pTitleID"

export const KNOWN_TABS: KnownTab[] = [
  "daily",
  "hourly",
  "hourly_yest",
  "thirty_days",
  "p_thirty_days",
  "seven_days",
  "p_seven_days",
  "pmax_perf",
  "products",
  "placements",
  "h_camp_30d",
  "h_camp_7d",
  "h_dow_30d",
  "h_dow_7d",
  "bidding",
  "quality",
  "keyword80",
  "search80",
  "camp_conv",
  "conv_action",
  "asset",
  "groups",
  "terms",
  "totalTerms",
  "summary",
  "totals",
  "tNgrams",
  "sNgrams",
  "paths",
  "pTitle",
  "pTitleCampaign",
  "pTitleID"
]