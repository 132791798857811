// src/components/ui/switch.tsx
import React from 'react';

interface SwitchProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void; // Changed to a more semantic prop
  id?: string; // Optional for accessibility
  className?: string; // Optional for styling
}

export const Switch: React.FC<SwitchProps> = ({ checked, onCheckedChange, id, className }) => {
  return (
    <label
      className={`switch ${className || ''}`}
      htmlFor={id}
      style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
    >
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={(e) => onCheckedChange(e.target.checked)}
        style={{ display: 'none' }}
      />
      <span
        className="slider round"
        style={{
          display: 'inline-block',
          width: '40px',
          height: '20px',
          background: checked ? '#4caf50' : '#ccc',
          borderRadius: '20px',
          position: 'relative',
          transition: 'background-color 0.3s',
        }}
      >
        <span
          style={{
            display: 'block',
            width: '16px',
            height: '16px',
            background: '#fff',
            borderRadius: '50%',
            position: 'absolute',
            top: '2px',
            left: checked ? '22px' : '2px',
            transition: 'left 0.3s',
          }}
        ></span>
      </span>
    </label>
  );
};

