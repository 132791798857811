import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { MessageSquarePlus } from "lucide-react";

export function StickyFeedback() {
  return (
    <div className="fixed left-0 top-1/2 z-50 -translate-y-1/2">
      <Button
        asChild
        variant="default"
        size="lg"
        className="rotate-90 origin-bottom-left shadow-lg flex items-center gap-2 px-4"
      >
        <Link to="/feedback" className="flex items-center gap-2">
          <MessageSquarePlus className="rotate-[-90deg]" size={20} />
          <span>Feedback</span>
        </Link>
      </Button>
    </div>
  );
} 