// src/components/keyword-table.tsx
import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { ProcessedKeywordData } from '@/pages/keywords'
import { TablePagination } from "@/components/ui/table-pagination"
import { ArrowUp, ArrowDown } from "lucide-react"
import { getQualityComponentColor, getQualityScoreColor } from '@/lib/quality-score-utils'
import { cn } from '@/lib/utils'

interface ColumnDef {
  key: string
  label: string
  width?: string
  isNumeric?: boolean
  cell?: (row: ProcessedKeywordData) => React.ReactNode
}

const DEFAULT_COLUMNS: ColumnDef[] = [
  {
    key: 'campaign',
    label: 'Campaign',
    width: 'w-[240px]',
    cell: (row: ProcessedKeywordData) => (
      <div className="max-w-[240px]">
        <div className="line-clamp-2">
          {row.campaign}
        </div>
      </div>
    )
  },
  {
    key: 'adGroup',
    label: 'Ad Group',
    width: 'w-[200px]',
    cell: (row: ProcessedKeywordData) => (
      <div className="max-w-[200px]">
        <div className="line-clamp-2">
          {row.adGroup}
        </div>
      </div>
    )
  },
  { key: 'keywordText', label: 'Keyword', width: 'w-[200px]' },
  { key: 'keywordMatchType', label: 'Match', width: 'w-[80px]' },
  { key: 'score', label: 'QS', width: 'w-[60px]', isNumeric: true },
  { key: 'impr', label: 'Impr.', width: 'w-[80px]', isNumeric: true },
  { key: 'clicks', label: 'Clicks', width: 'w-[80px]', isNumeric: true },
  { key: 'CTR', label: 'CTR', width: 'w-[80px]', isNumeric: true },
  { key: 'cost', label: 'Cost', width: 'w-[80px]', isNumeric: true },
  { key: 'CPC', label: 'CPC', width: 'w-[80px]', isNumeric: true },
  { key: 'conv', label: 'Conv.', width: 'w-[80px]', isNumeric: true },
  { key: 'CvR', label: 'CvR', width: 'w-[80px]', isNumeric: true },
  { key: 'value', label: 'Value', width: 'w-[80px]', isNumeric: true },
  { key: 'ROAS', label: 'ROAS', width: 'w-[80px]', isNumeric: true },
  { key: 'CPA', label: 'CPA', width: 'w-[80px]', isNumeric: true },
  { key: 'expCTR', label: 'Exp. CTR', width: 'w-[80px]' },
  { key: 'adRelevance', label: 'Ad Rel.', width: 'w-[80px]' },
  { key: 'landingExp', label: 'Landing', width: 'w-[80px]' }
]

interface KeywordTableProps {
  data: ProcessedKeywordData[]
  visibleColumns: Set<string>
  sort: {
    field: string
    direction: 'asc' | 'desc'
  }
  onSortChange: (field: string) => void
  currentPage: number
  rowsPerPage: number
  onPageChange: (page: number) => void
  accountCurrency: string
}

export function KeywordTable({
  data,
  visibleColumns,
  sort,
  onSortChange,
  currentPage,
  rowsPerPage,
  onPageChange,
  accountCurrency
}: KeywordTableProps) {
  // Format metric value based on type
  const formatValue = (key: string, value: any): string => {
    if (value === null || value === undefined) return 'N/A'

    switch (key) {
      case 'CTR':
      case 'CvR':
        return `${value.toFixed(1)}%`
      case 'cost':
      case 'CPC':
      case 'value':
      case 'CPA':
        return `${accountCurrency}${value.toFixed(2)}`
      case 'ROAS':
        return value.toFixed(2)
      case 'score':
        return value === null ? 'N/A' : value.toString()
      default:
        return typeof value === 'number' ? value.toLocaleString() : value
    }
  }

  // Get cell color based on column type
  const getCellColor = (key: string, value: any): string => {
    switch (key) {
      case 'score':
        return getQualityScoreColor(value)
      case 'expCTR':
      case 'adRelevance':
      case 'landingExp':
        return getQualityComponentColor(value)
      default:
        return ''
    }
  }

  // Calculate start and end indices for pagination
  const startIndex = (currentPage - 1) * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const paginatedData = data.slice(startIndex, endIndex)

  return (
    <div className="space-y-4">
      <div className="rounded-md border dark:border-gray-800">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-transparent border-b-2 dark:border-gray-700">
              {DEFAULT_COLUMNS.filter(col => visibleColumns.has(col.key)).map(column => (
                <TableHead
                  key={column.key}
                  className={cn(
                    column.width,
                    column.isNumeric ? 'text-right' : '',
                    'text-foreground font-semibold bg-gray-100 dark:bg-gray-800/80'
                  )}
                  onClick={() => onSortChange(column.key)}
                >
                  <div className={cn(
                    "flex items-center gap-1 cursor-pointer hover:bg-gray-200 rounded px-1",
                    column.isNumeric ? "justify-end" : "justify-start",
                    "dark:hover:bg-gray-700/80"
                  )}>
                    <span>{column.label}</span>
                    {sort.field === column.key && (
                      sort.direction === 'asc' ?
                        <ArrowUp className="h-3 w-3" /> :
                        <ArrowDown className="h-3 w-3" />
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.map((row, rowIndex) => (
              <TableRow
                key={`${row.campaign}-${row.adGroup}-${row.keywordText}-${rowIndex}`}
                className={cn(
                  "h-[2.5rem] max-h-[2.5rem]",
                  "hover:bg-accent/50 dark:hover:bg-gray-800/50"
                )}
              >
                {DEFAULT_COLUMNS.filter(col => visibleColumns.has(col.key)).map(column => (
                  <TableCell
                    key={column.key}
                    className={cn(
                      "py-2 overflow-hidden",
                      column.isNumeric ? "text-right" : "",
                      getCellColor(column.key, row[column.key as keyof ProcessedKeywordData]),
                      "text-foreground"
                    )}
                  >
                    {column.cell ? (
                      column.cell(row)
                    ) : (
                      formatValue(column.key, row[column.key as keyof ProcessedKeywordData])
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <TablePagination
        totalItems={data.length}
        pageSize={rowsPerPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  )
}