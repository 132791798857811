// src/components/search-filter-controls.tsx
import React, { useState } from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Slider } from '@/components/ui/slider'
import { Filter } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Checkbox } from '@/components/ui/checkbox'
import { createExpScale, OPERATORS, FIELD_TYPES, ENUM_VALUES, type AdvancedFilter } from '@/lib/filter-utils'

interface SearchTermData {
  campaign: string
  searchTerm: string
  searchMatchType: string
  impr: number
  clicks: number
  cost: number
  conv: number
  value: number
  allConv: number
  allValue: number
}

// Match type configuration
const MATCH_TYPES = [
  { value: 'EXACT', label: 'Exact' },
  { value: 'NEAR_EXACT', label: 'Near Exact' },
  { value: 'PHRASE', label: 'Phrase' },
  { value: 'NEAR_PHRASE', label: 'Near Phrase' },
  { value: 'BROAD', label: 'Broad' }
] as const

// Quick filters specific to search terms - updated to match keywords
const QUICK_FILTERS = [
  {
    id: 'no_conv',
    label: '0 Conv',
    filter: (term: SearchTermData) => term.conv === 0 && term.cost > 0
  },
  {
    id: 'no_clicks',
    label: '0 Clicks',
    filter: (term: SearchTermData) => term.clicks === 0 && term.impr > 0
  },
  {
    id: 'low_ctr',
    label: 'CTR<1%',
    filter: (term: SearchTermData) => {
      const ctr = term.impr ? (term.clicks / term.impr) * 100 : 0
      return ctr < 1 && term.impr >= 100
    }
  },
  {
    id: 'low_cvr',
    label: 'CvR<1%',
    filter: (term: SearchTermData) => {
      const cvr = term.clicks ? (term.conv / term.clicks) * 100 : 0
      return term.clicks >= 100 && cvr < 1
    }
  }
] as const

interface SearchFilters {
  textFilters: {
    campaign: string
    searchTerm: string
  }
  matchTypes: Set<string>
  thresholds: {
    impr: number
    cost: number
    conv: number
  }
}

interface SearchFilterControlsProps {
  filters: SearchFilters
  onFiltersChange: (filters: SearchFilters) => void
  activeQuickFilter: string | null
  onQuickFilterChange: (filter: string | null) => void
  data: SearchTermData[]
  accountCurrency: string
  advancedFilters: AdvancedFilter[]
  onAdvancedFiltersChange: (filters: AdvancedFilter[]) => void
}

export function SearchFilterControls({
  filters,
  onFiltersChange,
  activeQuickFilter,
  onQuickFilterChange,
  data,
  accountCurrency,
  advancedFilters,
  onAdvancedFiltersChange
}: SearchFilterControlsProps) {
  const formatThresholdValue = (value: number, metric: 'impr' | 'cost' | 'conv') => {
    return metric === 'cost'
      ? `${accountCurrency}${value}`
      : value.toString()
  }

  // Add state for filter popover
  const [filterOpen, setFilterOpen] = useState(false)
  const [newFilter, setNewFilter] = useState<AdvancedFilter>({
    field: '',
    operator: '',
    value: ''
  })

  // Add handler functions
  const handleFieldChange = (field: string) => {
    const fieldType = FIELD_TYPES[field as keyof typeof FIELD_TYPES]
    const defaultOperator = OPERATORS[fieldType][0].value
    setNewFilter({ field, operator: defaultOperator, value: '' })
  }

  const handleOperatorChange = (operator: string) => {
    setNewFilter(prev => ({ ...prev, operator }))
  }

  const handleValueChange = (value: string) => {
    setNewFilter(prev => ({ ...prev, value }))
  }

  const handleFilterKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && newFilter.field && newFilter.value) {
      onAdvancedFiltersChange([...advancedFilters, newFilter])
      setNewFilter({ field: '', operator: '', value: '' })
      setFilterOpen(false)
    }
  }

  const handleAddFilter = () => {
    if (newFilter.field && newFilter.value) {
      onAdvancedFiltersChange([...advancedFilters, newFilter])
      setNewFilter({ field: '', operator: '', value: '' })
      setFilterOpen(false)
    }
  }

  // Add slider value conversion logic
  const maxValues = {
    impr: Math.max(...data.map(term => term.impr || 0)),
    cost: Math.max(...data.map(term => term.cost || 0)),
    conv: Math.max(...data.map(term => term.conv || 0))
  }

  const scales = {
    impr: createExpScale(maxValues.impr),
    cost: createExpScale(maxValues.cost),
    conv: createExpScale(maxValues.conv)
  }

  const getThresholdValue = (value: number, metric: keyof typeof scales): number => {
    const scale = scales[metric]
    const index = Math.floor((value / 100) * (scale.length - 1))
    return scale[index] || 0
  }

  const getSliderValue = (value: number, metric: keyof typeof scales): number => {
    const scale = scales[metric]
    const index = scale.findIndex(v => v >= value)
    return index >= 0 ? (index / (scale.length - 1)) * 100 : 0
  }

  const formatSliderValue = (value: number, metric: keyof typeof scales) => {
    if (metric === 'cost') {
      return `${accountCurrency}${Math.round(value)}`
    }
    return Math.round(value).toString()
  }

  return (
    <Card>
      <CardContent className="pt-6">
        {/* Top row: All controls */}
        <div className="flex items-center gap-4">
          {/* Match Type Buttons - using blue gradient */}
          <div className="flex gap-1">
            {MATCH_TYPES.map((type) => (
              <Button
                key={type.value}
                size="sm"
                variant={filters.matchTypes.has(type.value) ? "default" : "outline"}
                onClick={() => {
                  const newMatchTypes = new Set(filters.matchTypes)
                  if (newMatchTypes.has(type.value)) {
                    newMatchTypes.delete(type.value)
                  } else {
                    newMatchTypes.add(type.value)
                  }
                  onFiltersChange({
                    ...filters,
                    matchTypes: newMatchTypes
                  })
                }}
                className={`h-8 px-3 ${filters.matchTypes.has(type.value)
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "hover:bg-gray-100"
                  }`}
              >
                {type.label}
              </Button>
            ))}
          </div>

          {/* Quick Filters */}
          <div className="flex items-center gap-2">
            {QUICK_FILTERS.map(filter => (
              <Button
                key={filter.id}
                size="sm"
                variant={activeQuickFilter === filter.id ? "default" : "outline"}
                onClick={() => onQuickFilterChange(activeQuickFilter === filter.id ? null : filter.id)}
                className={`h-7 px-2 w-[80px] ${activeQuickFilter === filter.id
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-gray-100 hover:bg-gray-200 text-gray-500"
                  }`}
              >
                {filter.label}
              </Button>
            ))}
          </div>

          {/* Text Filters */}
          <div className="flex gap-2 flex-1">
            <Input
              value={filters.textFilters.campaign}
              onChange={e => onFiltersChange({
                ...filters,
                textFilters: { ...filters.textFilters, campaign: e.target.value }
              })}
              placeholder="Campaign includes..."
              className="h-7 w-[210px]"
            />
            <Input
              value={filters.textFilters.searchTerm}
              onChange={e => onFiltersChange({
                ...filters,
                textFilters: { ...filters.textFilters, searchTerm: e.target.value }
              })}
              placeholder="Search term includes..."
              className="h-7 w-[210px]"
            />
          </div>

          {/* Advanced Filters Button */}
          <Popover open={filterOpen} onOpenChange={setFilterOpen}>
            <PopoverTrigger asChild>
              <Button variant="outline" size="sm" className="h-7 ml-auto">
                <Filter className="h-4 w-4 mr-2" />
                Filters {advancedFilters.length > 0 && `(${advancedFilters.length})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="w-80">
              <div className="space-y-4">
                <h4 className="font-medium">Add Filter</h4>
                <Select value={newFilter.field} onValueChange={handleFieldChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select metric" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="campaign">Campaign</SelectItem>
                      <SelectItem value="searchTerm">Search Term</SelectItem>
                      <SelectItem value="searchMatchType">Match Type</SelectItem>
                      <SelectItem value="impr">Impressions</SelectItem>
                      <SelectItem value="clicks">Clicks</SelectItem>
                      <SelectItem value="CTR">CTR</SelectItem>
                      <SelectItem value="cost">Cost</SelectItem>
                      <SelectItem value="CPC">CPC</SelectItem>
                      <SelectItem value="conv">Conv.</SelectItem>
                      <SelectItem value="CvR">CvR</SelectItem>
                      <SelectItem value="value">Value</SelectItem>
                      <SelectItem value="ROAS">ROAS</SelectItem>
                      <SelectItem value="CPA">CPA</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>

                {newFilter.field && (
                  <>
                    {FIELD_TYPES[newFilter.field as keyof typeof FIELD_TYPES] === 'enum' ? (
                      <div className="space-y-2">
                        {ENUM_VALUES[newFilter.field as keyof typeof ENUM_VALUES].map(option => (
                          <div key={option.value} className="flex items-center space-x-2">
                            <Checkbox
                              id={`${newFilter.field}-${option.value}`}
                              checked={newFilter.value.split(',').includes(option.value)}
                              onCheckedChange={(checked) => {
                                const values = new Set(newFilter.value ? newFilter.value.split(',') : [])
                                if (checked) {
                                  values.add(option.value)
                                } else {
                                  values.delete(option.value)
                                }
                                handleValueChange(Array.from(values).join(','))
                              }}
                            />
                            <label
                              htmlFor={`${newFilter.field}-${option.value}`}
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <Select value={newFilter.operator} onValueChange={handleOperatorChange}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select operator" />
                          </SelectTrigger>
                          <SelectContent>
                            {OPERATORS[FIELD_TYPES[newFilter.field as keyof typeof FIELD_TYPES]].map(op => (
                              <SelectItem key={op.value} value={op.value}>
                                {op.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <div className="space-y-2">
                          <Input
                            value={newFilter.value}
                            onChange={(e) => handleValueChange(e.target.value)}
                            onKeyDown={handleFilterKeyDown}
                            placeholder={`Enter ${newFilter.field} value...`}
                            type={FIELD_TYPES[newFilter.field as keyof typeof FIELD_TYPES] === 'dimension' ? 'text' : 'number'}
                          />
                        </div>
                      </>
                    )}

                    <div className="flex justify-end gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setNewFilter({ field: '', operator: '', value: '' })
                          setFilterOpen(false)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        onClick={handleAddFilter}
                        disabled={!newFilter.field || (!newFilter.value && FIELD_TYPES[newFilter.field as keyof typeof FIELD_TYPES] !== 'enum')}
                      >
                        Add Filter
                      </Button>
                    </div>
                  </>
                )}

                {advancedFilters.length > 0 && (
                  <div className="space-y-2">
                    <div className="h-px bg-border my-4" />
                    <h4 className="font-medium">Active Filters</h4>
                    <div className="space-y-2">
                      {advancedFilters.map((filter, index) => (
                        <div key={index} className="flex items-center justify-between bg-accent rounded-md px-2 py-1">
                          <span className="text-sm">
                            {filter.field} {filter.operator} {filter.value}
                          </span>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="h-4 w-4 p-0 hover:bg-transparent"
                            onClick={() => onAdvancedFiltersChange(advancedFilters.filter((_, i) => i !== index))}
                          >
                            ×
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </PopoverContent>
          </Popover>
        </div>

        {/* Bottom row: Sliders */}
        <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="flex items-center gap-2">
            <span className="text-sm whitespace-nowrap">Impr ≥ {formatSliderValue(filters.thresholds.impr, 'impr')}</span>
            <Slider
              value={[getSliderValue(filters.thresholds.impr, 'impr')]}
              onValueChange={([value]) => {
                const newValue = getThresholdValue(value, 'impr')
                onFiltersChange({
                  ...filters,
                  thresholds: { ...filters.thresholds, impr: newValue }
                })
              }}
              min={0}
              max={100}
              step={1}
              className="flex-1"
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm whitespace-nowrap">Cost ≥ {formatSliderValue(filters.thresholds.cost, 'cost')}</span>
            <Slider
              value={[getSliderValue(filters.thresholds.cost, 'cost')]}
              onValueChange={([value]) => {
                const newValue = getThresholdValue(value, 'cost')
                onFiltersChange({
                  ...filters,
                  thresholds: { ...filters.thresholds, cost: newValue }
                })
              }}
              min={0}
              max={100}
              step={1}
              className="flex-1"
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm whitespace-nowrap">Conv ≥ {formatSliderValue(filters.thresholds.conv, 'conv')}</span>
            <Slider
              value={[getSliderValue(filters.thresholds.conv, 'conv')]}
              onValueChange={([value]) => {
                const newValue = getThresholdValue(value, 'conv')
                onFiltersChange({
                  ...filters,
                  thresholds: { ...filters.thresholds, conv: newValue }
                })
              }}
              min={0}
              max={100}
              step={1}
              className="flex-1"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}