import { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Mail, Calendar, Copy, Check } from "lucide-react"
import { 
  Dialog,
  DialogContent, 
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

export default function ReminderOptions() {
  const [copied, setCopied] = useState(false)
  
  const reminderText = `Check out Performance Max Analysis Tool:
- Complete channel analysis with 6-month history
- Profit curve optimization 
- AI-powered budget recommendations
- Improved version of the PA script

URL: https://8020agent.com/`

  const emailSubject = "Reminder: Check Performance Max Analysis Tool"
  const emailUrl = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(reminderText)}`
  
  // Generate calendar event URL (supports multiple formats)
  const calendarEvent = {
    title: "Check Performance Max Analysis Tool",
    description: reminderText,
    startDate: new Date(Date.now() + 24 * 60 * 60 * 1000), // Tomorrow
    endDate: new Date(Date.now() + 25 * 60 * 60 * 1000), // 1 hour duration
    url: "https://8020agent.com/"
  }
  
  const googleCalUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(calendarEvent.title)}&details=${encodeURIComponent(calendarEvent.description)}&dates=${calendarEvent.startDate.toISOString().replace(/[-:]/g, '').replace('.000', '')}/${calendarEvent.endDate.toISOString().replace(/[-:]/g, '').replace('.000', '')}`

  const handleCopy = () => {
    navigator.clipboard.writeText(reminderText)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className="space-y-4 text-center">
      <h2 className="text-2xl font-bold">Want to check this out later?</h2>
      
      <Dialog>
        <DialogTrigger asChild>
          <Button size="lg" className="gap-2">
            <Mail className="h-5 w-5" />
            Save for Later
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save a Reminder</DialogTitle>
          </DialogHeader>
          
          <div className="grid gap-4">
            <Button 
              variant="outline" 
              className="w-full gap-2"
              onClick={() => window.open(emailUrl)}
            >
              <Mail className="h-5 w-5" />
              Email Yourself
            </Button>

            <Button
              variant="outline"
              className="w-full gap-2"
              onClick={() => window.open(googleCalUrl)}
            >
              <Calendar className="h-5 w-5" />
              Add to Calendar
            </Button>

            <Button
              variant="outline"
              className="w-full gap-2"
              onClick={handleCopy}
            >
              {copied ? (
                <>
                  <Check className="h-5 w-5" />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="h-5 w-5" />
                  Copy Text
                </>
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <p className="text-sm text-muted-foreground">
        Save a reminder to explore the tool when you're back at your desk
      </p>
    </div>
  )
}