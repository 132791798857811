import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight, GraduationCap, Code, Clock, ExternalLink } from "lucide-react";

interface CourseIntroProps {
    onStart: () => void;
}

const NLEIntro: React.FC<CourseIntroProps> = ({ onStart }) => {
    const features = [
        {
            icon: <GraduationCap className="w-6 h-6 text-blue-500" />,
            title: "No Experience Required",
            description: "Start from absolute zero - I'll guide you step by step"
        },
        {
            icon: <Code className="w-6 h-6 text-green-500" />,
            title: "Real Working Code",
            description: "Copy, paste & run actual scripts that work in your account"
        },
        {
            icon: <Clock className="w-6 h-6 text-purple-500" />,
            title: "Quick to Complete",
            description: "10 super short levels, that take just 1-3 minutes each"
        }
    ];

    return (
        <div className="max-w-6xl mx-auto p-6 space-y-6">
            <Card>
                <CardHeader>
                    <CardTitle className="text-4xl font-bold">
                        Scripts Made Simple - Exclusively for NLE Viewers
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="space-y-8">
                        <div className="flex items-center justify-between">
                            <img
                                src="/images/logo_channable_2.png"
                                alt="Channable Logo"
                                className="h-12 object-contain"
                            />
                            <a
                                href="https://www.channable.com/videos?category=webinars"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center gap-2 text-orange-600 hover:text-orange-700"
                            >
                                Watch More Webinars <ExternalLink className="h-4 w-4" />
                            </a>
                        </div>

                        <div className="prose prose-lg">
                            <p className="text-orange-600 dark:text-orange-400 font-medium">
                                Note: This challenge is just for viewers of the Next Level eCommerce webinar.
                                <br />
                                If you'd like to play with the app either with sample data, or with your own
                                Just click the 'Return to App' link at the top (after the webinar ;)
                            </p>
                        </div>

                        <div className="prose prose-lg text-muted-foreground">
                            <p>
                                Scared of scripts? There's no need to be.
                                <br />
                                These quick challenges will show you just how easy they can be.
                            </p>
                            <p>
                                We'll start super simple (literally 3 lines of code) and build up
                                to creating automated reports and alerts.
                            </p>
                        </div>

                        <div className="bg-muted/50 p-4 rounded-lg space-y-2 my-6">
                            <h3 className="font-semibold text-lg">Before You Start:</h3>
                            <ul className="list-disc list-inside space-y-1 text-muted-foreground">
                                <li>You need a single Google Ads account (not an MCC)</li>
                                <li>You must have standard or edit access (read-only won't work)</li>
                                <li><span className="text-green-600 dark:text-green-400 font-medium">✓ Safe to Use:</span> None of these scripts will make any changes to your account</li>
                            </ul>
                        </div>

                        <div className="grid gap-6 md:grid-cols-3">
                            {features.map((feature, index) => (
                                <Card key={index} className="bg-muted/50">
                                    <CardContent className="pt-6">
                                        <div className="space-y-2">
                                            <div className="flex items-center space-x-2">
                                                {feature.icon}
                                                <h3 className="font-semibold">{feature.title}</h3>
                                            </div>
                                            <p className="text-sm text-muted-foreground">
                                                {feature.description}
                                            </p>
                                        </div>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>

                        <div className="flex justify-center">
                            <Button
                                onClick={onStart}
                                size="lg"
                                className="min-w-[200px] bg-orange-600 hover:bg-orange-700"
                            >
                                Follow Along Now <ArrowRight className="ml-2 h-4 w-4" />
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div >
    );
};

export default NLEIntro; 