// src/components/keyword-filter-controls.tsx
import React, { useState } from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Slider } from '@/components/ui/slider'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { ProcessedKeywordData } from '@/pages/keywords'
import { Filter } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Checkbox } from '@/components/ui/checkbox'
import { createExpScale, OPERATORS, FIELD_TYPES, ENUM_VALUES, type AdvancedFilter } from '@/lib/filter-utils'

// Quick filter types
const QUICK_FILTERS = [
  {
    id: 'no_conv',
    label: '0 Conv',
    filter: (kw: ProcessedKeywordData) => kw.conv === 0 && kw.cost > 0,
  },
  {
    id: 'no_clicks',
    label: '0 Clicks',
    filter: (kw: ProcessedKeywordData) => kw.clicks === 0 && kw.impr > 0,
  },
  {
    id: 'low_ctr',
    label: 'CTR<1%',
    filter: (kw: ProcessedKeywordData) => kw.CTR < 1 && kw.impr >= 100,
  },
  {
    id: 'low_cvr',
    label: 'CvR<1%',
    filter: (kw: ProcessedKeywordData) => kw.clicks >= 100 && kw.CvR < 1,
  }
] as const

interface KeywordFilters {
  qualityScores: {
    great: boolean
    ok: boolean
    poor: boolean
    na: boolean
  }
  textFilters: {
    campaign: string
    adGroup: string
    keyword: string
  }
  thresholds: {
    impr: number
    cost: number
    conv: number
  }
}

interface KeywordFilterControlsProps {
  filters: KeywordFilters
  onFiltersChange: (filters: KeywordFilters) => void
  activeQuickFilter: string | null
  onQuickFilterChange: (filter: string | null) => void
  data: ProcessedKeywordData[]
  accountCurrency: string
  advancedFilters: AdvancedFilter[]
  onAdvancedFiltersChange: (filters: AdvancedFilter[]) => void
}

export function KeywordFilterControls({
  filters,
  onFiltersChange,
  activeQuickFilter,
  onQuickFilterChange,
  data,
  accountCurrency,
  advancedFilters,
  onAdvancedFiltersChange
}: KeywordFilterControlsProps) {
  const [filterOpen, setFilterOpen] = useState(false)
  const [newFilter, setNewFilter] = useState<AdvancedFilter>({
    field: '',
    operator: '',
    value: ''
  })

  const handleFieldChange = (field: string) => {
    const fieldType = FIELD_TYPES[field as keyof typeof FIELD_TYPES]
    const defaultOperator = OPERATORS[fieldType][0].value
    setNewFilter({ field, operator: defaultOperator, value: '' })
  }

  const handleOperatorChange = (operator: string) => {
    setNewFilter(prev => ({ ...prev, operator }))
  }

  const handleValueChange = (value: string) => {
    setNewFilter(prev => ({ ...prev, value }))
  }

  const handleFilterKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && newFilter.field && newFilter.value) {
      onAdvancedFiltersChange([...advancedFilters, newFilter])
      setNewFilter({ field: '', operator: '', value: '' })
      setFilterOpen(false)
    }
  }

  const handleAddFilter = () => {
    if (newFilter.field && newFilter.value) {
      onAdvancedFiltersChange([...advancedFilters, newFilter])
      setNewFilter({ field: '', operator: '', value: '' })
      setFilterOpen(false)
    }
  }

  // Calculate max values for metrics
  const maxValues = {
    impr: Math.max(...data.map(kw => kw.impr || 0)),
    cost: Math.max(...data.map(kw => kw.cost || 0)),
    conv: Math.max(...data.map(kw => kw.conv || 0))
  }

  // Create scales for each metric
  const scales = {
    impr: createExpScale(maxValues.impr),
    cost: createExpScale(maxValues.cost),
    conv: createExpScale(maxValues.conv)
  }

  // Convert slider value (0-100) to actual threshold value
  const getThresholdValue = (value: number, metric: keyof typeof scales): number => {
    const scale = scales[metric]
    const index = Math.floor((value / 100) * (scale.length - 1))
    return scale[index] || 0
  }

  // Convert actual value to slider value (0-100)
  const getSliderValue = (value: number, metric: keyof typeof scales): number => {
    const scale = scales[metric]
    const index = scale.findIndex(v => v >= value)
    return index >= 0 ? (index / (scale.length - 1)) * 100 : 0
  }

  const formatSliderValue = (value: number, metric: keyof typeof scales) => {
    if (metric === 'cost') {
      return `${accountCurrency}${Math.round(value)}`
    }
    return Math.round(value).toString()
  }

  const toggleQualityScore = (score: keyof typeof filters.qualityScores) => {
    onFiltersChange({
      ...filters,
      qualityScores: {
        ...filters.qualityScores,
        [score]: !filters.qualityScores[score]
      }
    })
  }

  return (
    <Card className="mb-4">
      <CardContent className="pt-6">
        {/* Top row: All controls */}
        <div className="flex items-center gap-4">
          {/* QS Buttons */}
          <div className="flex gap-1">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    size="sm"
                    variant={filters.qualityScores.great ? "default" : "outline"}
                    onClick={() => toggleQualityScore('great')}
                    className={`h-7 px-2 ${filters.qualityScores.great
                      ? "bg-green-600 hover:bg-green-700 text-white"
                      : "bg-gray-100 hover:bg-gray-200 text-gray-500"
                      }`}
                  >
                    8-10
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Great Quality Score (8-10)</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    size="sm"
                    variant={filters.qualityScores.ok ? "default" : "outline"}
                    onClick={() => toggleQualityScore('ok')}
                    className={`h-7 px-2 ${filters.qualityScores.ok
                      ? "bg-yellow-600 hover:bg-yellow-700 text-white"
                      : "bg-gray-100 hover:bg-gray-200 text-gray-500"
                      }`}
                  >
                    5-7
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>OK Quality Score (5-7)</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    size="sm"
                    variant={filters.qualityScores.poor ? "default" : "outline"}
                    onClick={() => toggleQualityScore('poor')}
                    className={`h-7 px-2 ${filters.qualityScores.poor
                      ? "bg-red-600 hover:bg-red-700 text-white"
                      : "bg-gray-100 hover:bg-gray-200 text-gray-500"
                      }`}
                  >
                    1-4
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Poor Quality Score (1-4)</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    size="sm"
                    variant={filters.qualityScores.na ? "default" : "outline"}
                    onClick={() => toggleQualityScore('na')}
                    className={`h-7 px-2 ${filters.qualityScores.na
                      ? "bg-blue-600 hover:bg-blue-700 text-white"
                      : "bg-gray-100 hover:bg-gray-200 text-gray-500"
                      }`}
                  >
                    N/A
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>No Quality Score</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          {/* Quick Filters */}
          <div className="flex items-center gap-2">
            {QUICK_FILTERS.map(filter => (
              <Button
                key={filter.id}
                size="sm"
                variant={activeQuickFilter === filter.id ? "default" : "outline"}
                onClick={() => onQuickFilterChange(activeQuickFilter === filter.id ? null : filter.id)}
                className={`h-7 px-2 w-[80px] ${activeQuickFilter === filter.id
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-gray-100 hover:bg-gray-200 text-gray-500"
                  }`}
              >
                {filter.label}
              </Button>
            ))}
          </div>

          {/* Text Filters */}
          <div className="flex gap-2 flex-1">
            <Input
              value={filters.textFilters.campaign}
              onChange={(e) => onFiltersChange({
                ...filters,
                textFilters: { ...filters.textFilters, campaign: e.target.value }
              })}
              placeholder="Campaign includes..."
              className="h-7 w-[210px]"
            />
            <Input
              value={filters.textFilters.keyword}
              onChange={(e) => onFiltersChange({
                ...filters,
                textFilters: { ...filters.textFilters, keyword: e.target.value }
              })}
              placeholder="Keyword includes..."
              className="h-7 w-[210px]"
            />
          </div>

          {/* Advanced Filters Button */}
          <Popover open={filterOpen} onOpenChange={setFilterOpen}>
            <PopoverTrigger asChild>
              <Button variant="outline" size="sm" className="h-7 ml-auto">
                <Filter className="h-4 w-4 mr-2" />
                Filters {advancedFilters.length > 0 && `(${advancedFilters.length})`}
              </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="w-80">
              <div className="space-y-4">
                <h4 className="font-medium">Add Filter</h4>
                <Select value={newFilter.field} onValueChange={handleFieldChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select metric" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="campaign">Campaign</SelectItem>
                      <SelectItem value="adGroup">Ad Group</SelectItem>
                      <SelectItem value="keywordText">Keyword</SelectItem>
                      <SelectItem value="keywordMatchType">Match Type</SelectItem>
                      <SelectItem value="score">Quality Score</SelectItem>
                      <SelectItem value="impr">Impressions</SelectItem>
                      <SelectItem value="clicks">Clicks</SelectItem>
                      <SelectItem value="CTR">CTR</SelectItem>
                      <SelectItem value="cost">Cost</SelectItem>
                      <SelectItem value="CPC">CPC</SelectItem>
                      <SelectItem value="conv">Conv.</SelectItem>
                      <SelectItem value="CvR">CvR</SelectItem>
                      <SelectItem value="value">Value</SelectItem>
                      <SelectItem value="ROAS">ROAS</SelectItem>
                      <SelectItem value="CPA">CPA</SelectItem>
                      <SelectItem value="expCTR">Exp. CTR</SelectItem>
                      <SelectItem value="adRelevance">Ad Relevance</SelectItem>
                      <SelectItem value="landingExp">Landing Exp.</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>

                {newFilter.field && (
                  <>
                    {FIELD_TYPES[newFilter.field as keyof typeof FIELD_TYPES] === 'enum' ? (
                      <div className="space-y-2">
                        {ENUM_VALUES[newFilter.field as keyof typeof ENUM_VALUES].map(option => (
                          <div key={option.value} className="flex items-center space-x-2">
                            <Checkbox
                              id={`${newFilter.field}-${option.value}`}
                              checked={newFilter.value.split(',').includes(option.value)}
                              onCheckedChange={(checked) => {
                                const values = new Set(newFilter.value ? newFilter.value.split(',') : [])
                                if (checked) {
                                  values.add(option.value)
                                } else {
                                  values.delete(option.value)
                                }
                                handleValueChange(Array.from(values).join(','))
                              }}
                            />
                            <label
                              htmlFor={`${newFilter.field}-${option.value}`}
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <Select value={newFilter.operator} onValueChange={handleOperatorChange}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select operator" />
                          </SelectTrigger>
                          <SelectContent>
                            {OPERATORS[FIELD_TYPES[newFilter.field as keyof typeof FIELD_TYPES]].map(op => (
                              <SelectItem key={op.value} value={op.value}>
                                {op.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <div className="space-y-2">
                          <Input
                            value={newFilter.value}
                            onChange={(e) => handleValueChange(e.target.value)}
                            onKeyDown={handleFilterKeyDown}
                            placeholder={`Enter ${newFilter.field} value...`}
                            type={FIELD_TYPES[newFilter.field as keyof typeof FIELD_TYPES] === 'dimension' ? 'text' : 'number'}
                          />
                        </div>
                      </>
                    )}

                    <div className="flex justify-end gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setNewFilter({ field: '', operator: '', value: '' })
                          setFilterOpen(false)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        onClick={handleAddFilter}
                        disabled={!newFilter.field || (!newFilter.value && FIELD_TYPES[newFilter.field as keyof typeof FIELD_TYPES] !== 'enum')}
                      >
                        Add Filter
                      </Button>
                    </div>
                  </>
                )}

                {advancedFilters.length > 0 && (
                  <div className="space-y-2">
                    <div className="h-px bg-border my-4" />
                    <h4 className="font-medium">Active Filters</h4>
                    <div className="space-y-2">
                      {advancedFilters.map((filter, index) => (
                        <div key={index} className="flex items-center justify-between bg-accent rounded-md px-2 py-1">
                          <span className="text-sm">
                            {filter.field} {filter.operator} {filter.value}
                          </span>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="h-4 w-4 p-0 hover:bg-transparent"
                            onClick={() => onAdvancedFiltersChange(advancedFilters.filter((_, i) => i !== index))}
                          >
                            ×
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </PopoverContent>
          </Popover>
        </div>

        {/* Bottom row: Sliders */}
        <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="flex items-center gap-2">
            <span className="text-sm whitespace-nowrap">Impr ≥ {formatSliderValue(filters.thresholds.impr, 'impr')}</span>
            <Slider
              value={[getSliderValue(filters.thresholds.impr, 'impr')]}
              onValueChange={([value]) => {
                const newValue = getThresholdValue(value, 'impr')
                onFiltersChange({
                  ...filters,
                  thresholds: { ...filters.thresholds, impr: newValue }
                })
              }}
              defaultValue={[20]}
              min={0}
              max={100}
              step={1}
              className="flex-1"
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm whitespace-nowrap">Cost ≥ {formatSliderValue(filters.thresholds.cost, 'cost')}</span>
            <Slider
              value={[getSliderValue(filters.thresholds.cost, 'cost')]}
              onValueChange={([value]) => {
                const newValue = getThresholdValue(value, 'cost')
                onFiltersChange({
                  ...filters,
                  thresholds: { ...filters.thresholds, cost: newValue }
                })
              }}
              defaultValue={[20]}
              min={0}
              max={100}
              step={1}
              className="flex-1"
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm whitespace-nowrap">Conv ≥ {formatSliderValue(filters.thresholds.conv, 'conv')}</span>
            <Slider
              value={[getSliderValue(filters.thresholds.conv, 'conv')]}
              onValueChange={([value]) => {
                const newValue = getThresholdValue(value, 'conv')
                onFiltersChange({
                  ...filters,
                  thresholds: { ...filters.thresholds, conv: newValue }
                })
              }}
              defaultValue={[0]}
              min={0}
              max={100}
              step={1}
              className="flex-1"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}