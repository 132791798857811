// src/contexts/account-context.tsx
import { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react'
import { useUser } from '@clerk/clerk-react'
import { db } from '@/services/db'
import { Account } from '@/types/metrics'
import { SAMPLE_ACCOUNT_ID } from '@/lib/constants'
import { loadSampleDataInBackground } from '@/lib/sample-data-utils'
import { clearAllTempStorage } from '@/lib/storage-utils'
import { useCampaignData } from '@/contexts/campaign-data'
import { toast } from '@/components/ui/use-toast'
import type { UserResource } from '@clerk/types'

const DEBUG = process.env.NODE_ENV === 'development'

interface AccountContextType {
  accounts: Account[]
  activeDexieId: string
  activeAccount: Account | undefined
  isLoading: boolean
  error: string | null
  initialized: boolean
  switchAccount: (dexieId: string) => Promise<void>
  addAccount: (account: Account) => Promise<void>
  updateAccount: (account: Account, optimistic?: boolean) => Promise<void>
  deleteAccount: (dexieId: string) => Promise<void>
  debugSyncStatus: () => Promise<void>
}

export const AccountContext = createContext<AccountContextType | null>(null)

function useAccountsInternal() {
  const context = useContext(AccountContext)
  if (!context) {
    throw new Error('useAccounts must be used within AccountProvider')
  }
  return context
}

export function useAccounts() {
  try {
    return useAccountsInternal()
  } catch (err) {
    console.error('AccountProvider Error:', err)
    console.error('Component Tree:', 
      document.getElementById('root')?.innerHTML
    )
    throw err
  }
}

// Define a type for Clerk metadata structure
interface ClerkAccountMetadata {
  accounts?: Array<{
    dexieId: string
    accountCID: string
    name: string
    businessMode: 'ecomm' | 'leadgen'
    currency: string
    cogsAmount: number
    breakEvenCpa: number
  }>
  lastActiveDexieId?: string
  [key: string]: any
}

// Type guard to check metadata structure
function isValidMetadata(metadata: unknown): metadata is ClerkAccountMetadata {
  if (!metadata || typeof metadata !== 'object') return false
  return true
}

export function AccountProvider({ children }: { children: React.ReactNode }) {
  const { user, isLoaded: userLoaded } = useUser()
  const [accounts, setAccounts] = useState<Account[]>([])
  const [activeDexieId, setActiveDexieId] = useState<string>(SAMPLE_ACCOUNT_ID)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [initialized, setInitialized] = useState(false)
  const isSignedIn = user !== null

  // Simple initialization - always load sample first
  useEffect(() => {
    let mounted = true

    const init = async () => {
      if (!userLoaded) return

      try {
        setIsLoading(true)
        setError(null)

        // 0. Clean up Clerk metadata first
        if (isSignedIn && user) {
          const clerkMetadata = user.unsafeMetadata as any
          const currentAccounts = clerkMetadata?.accounts || []
          
          // Remove duplicates by accountCID and ensure scriptVersion is preserved
          const uniqueAccounts = currentAccounts.reduce((acc: Account[], curr: Account) => {
            const existingIdx = acc.findIndex(a => a.accountCID === curr.accountCID)
            if (existingIdx === -1) {
              acc.push(curr)
            } else if (curr.scriptVersion && !acc[existingIdx].scriptVersion) {
              acc[existingIdx] = { ...acc[existingIdx], scriptVersion: curr.scriptVersion }
            }
            return acc
          }, [])

          // Only update if we removed duplicates or fixed scriptVersion
          if (JSON.stringify(uniqueAccounts) !== JSON.stringify(currentAccounts)) {
            await user.update({
              unsafeMetadata: {
                ...clerkMetadata,
                accounts: uniqueAccounts
              }
            })
            console.log('Updated accounts in Clerk:', uniqueAccounts)
          }

          // Get cleaned accounts
          const clerkAccounts = uniqueAccounts.filter(a => !a.isSampleAccount)
          
          // 2. Ensure sample account exists (but not in Clerk)
          const sampleAccount = await db.ensureSampleAccount()
          if (!mounted) return

          // 3. Sync Dexie with Clerk (excluding sample account)
          await db.accounts
            .filter(a => !a.isSampleAccount)
            .filter(a => !clerkAccounts.some(ca => ca.accountCID === a.accountCID))
            .delete()

          // Add/update accounts from Clerk
          await Promise.all(
            clerkAccounts.map(async (account) => {
              const existing = await db.getAccount(account.accountCID)
              if (!existing) {
                await db.addAccount(account)
              } else if (JSON.stringify(existing) !== JSON.stringify(account)) {
                await db.updateAccount(account)
              }
            })
          )

          // 4. Set final account list
          setAccounts([sampleAccount, ...clerkAccounts])
          
          // 5. Set active account
          if (clerkAccounts.length) {
            setActiveDexieId(clerkAccounts[0].dexieId)
          } else {
            setActiveDexieId(SAMPLE_ACCOUNT_ID)
          }
        } else {
          // Not signed in - just load sample account
          const sampleAccount = await db.ensureSampleAccount()
          setAccounts([sampleAccount])
          setActiveDexieId(SAMPLE_ACCOUNT_ID)
        }

        setInitialized(true)

      } catch (err) {
        console.error('Failed to initialize accounts:', err)
        setError(err instanceof Error ? err.message : 'Failed to initialize')
      } finally {
        if (mounted) setIsLoading(false)
      }
    }

    init()
    return () => { mounted = false }
  }, [userLoaded, isSignedIn, user])

  // Simple account switching
  const switchAccount = async (dexieId: string) => {
    if (dexieId === activeDexieId) return
    
    try {
      const account = await db.getAccount(dexieId)
      if (!account) {
        throw new Error(`Account not found: ${dexieId}`)
      }

      setActiveDexieId(dexieId)
      await db.setLastActiveAccount(dexieId)

    } catch (err) {
      console.error('Failed to switch account:', err)
      toast({
        variant: "destructive",
        title: "Account Switch Failed",
        description: "Switching to sample account..."
      })
      setActiveDexieId(SAMPLE_ACCOUNT_ID)
    }
  }

  const updateAccount = async (account: Account, optimistic = false) => {
    try {
      // Update local state first
      setAccounts(prev => prev.map(a => 
        a.dexieId === account.dexieId ? { ...a, ...account } : a
      ))

      // Save to DB without triggering refresh
      if (!optimistic) {
        await db.updateAccount(account)
        
        // Update Clerk metadata to include scriptVersion
        if (user && !account.isSampleAccount) {
          const clerkMetadata = user.unsafeMetadata as any
          const currentAccounts = clerkMetadata?.accounts || []
          const updatedAccounts = currentAccounts.map((a: Account) => 
            a.dexieId === account.dexieId ? { ...a, scriptVersion: account.scriptVersion } : a
          )
          
          await user.update({
            unsafeMetadata: {
              ...clerkMetadata,
              accounts: updatedAccounts
            }
          })
        }
      } else {
        db.updateAccount(account).catch(err => {
          console.error('Background save failed:', err)
          // Revert on error
          setAccounts(prev => prev.map(a => 
            a.dexieId === account.dexieId ? prev.find(pa => pa.dexieId === account.dexieId)! : a
          ))
        })
      }
    } catch (err) {
      console.error('Failed to update account:', err)
      throw err
    }
  }

  const addAccount = useCallback(async (newAccount: Account): Promise<void> => {
    if (!user) throw new Error('User not signed in')

    // Check for duplicate accountCID in existing accounts
    const existingAccount = accounts.find(a => 
      a.accountCID === newAccount.accountCID && !a.isSampleAccount
    )
    
    if (existingAccount) {
      throw new Error(`Account with CID ${newAccount.accountCID} already exists`)
    }

    // Ensure dexieId matches accountCID for consistency
    newAccount.dexieId = newAccount.accountCID

    try {
      // 1. Add to Clerk first (source of truth)
      const updatedAccounts = [...accounts, newAccount]
      await user.update({
        unsafeMetadata: {
          ...user.unsafeMetadata,
          accounts: updatedAccounts
        }
      })

      // 2. Add to Dexie only after Clerk success
      await db.addAccount(newAccount)
      
      // 3. Update local state
      setAccounts(updatedAccounts)
      setActiveDexieId(newAccount.dexieId)

    } catch (err) {
      console.error('Failed to add account:', err)
      throw err
    }
  }, [accounts, user])

  const debugSyncStatus = async () => {
    if (!user) return

    const dexieAccounts = await db.accounts.toArray()
    const clerkAccounts = ((user.unsafeMetadata as any)?.accounts || []) as Account[]
    
    console.group('Account Sync Status')
    console.log('Clerk Accounts:', clerkAccounts)
    console.log('Dexie Accounts:', dexieAccounts)
    
    // Find mismatches
    const clerkOnly = clerkAccounts.filter(ca => 
      !dexieAccounts.some(da => da.accountCID === ca.accountCID)
    )
    const dexieOnly = dexieAccounts.filter(da => 
      !da.isSampleAccount && !clerkAccounts.some(ca => ca.accountCID === da.accountCID)
    )
    
    if (clerkOnly.length) {
      console.warn('Accounts in Clerk but not in Dexie:', clerkOnly)
    }
    if (dexieOnly.length) {
      console.warn('Accounts in Dexie but not in Clerk:', dexieOnly)
    }
    console.groupEnd()

    // Auto-fix mismatches
    if (clerkOnly.length || dexieOnly.length) {
      try {
        // 1. Remove extra Dexie accounts
        await Promise.all(
          dexieOnly.map(account => 
            db.deleteAccount(account.dexieId)
          )
        )

        // 2. Add missing Dexie accounts
        await Promise.all(
          clerkOnly.map(account =>
            db.addAccount(account)
          )
        )

        // 3. Refresh accounts list
        const sampleAccount = await db.ensureSampleAccount()
        setAccounts([sampleAccount, ...clerkAccounts])

        console.log('Auto-fixed account sync issues')
      } catch (err) {
        console.error('Failed to auto-fix sync issues:', err)
      }
    }
  }

  const contextValue = {
    accounts,
    activeDexieId,
    activeAccount: accounts.find(a => a.dexieId === activeDexieId),
    isLoading,
    error,
    initialized,
    switchAccount,
    addAccount,
    updateAccount,
    deleteAccount: async (dexieId: string) => {
      try {
        // Delete from DB first
        await db.deleteAccount(dexieId)
        setAccounts(prevAccounts => prevAccounts.filter(a => a.dexieId !== dexieId))

        // Update Clerk metadata
        if (user) {
          const currentMetadata = isValidMetadata(user.unsafeMetadata) 
            ? user.unsafeMetadata 
            : { accounts: [] }

          await user.update({
            unsafeMetadata: {
              ...currentMetadata,
              accounts: currentMetadata.accounts?.filter(a => a.dexieId !== dexieId) || [],
              lastActiveDexieId: currentMetadata.lastActiveDexieId === dexieId 
                ? undefined 
                : currentMetadata.lastActiveDexieId
            }
          })
        }
      } catch (err) {
        console.error('Failed to delete account:', err)
        throw err
      }
    },
    debugSyncStatus
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-lg font-semibold mb-2">Failed to load accounts</h2>
          <p className="text-sm text-muted-foreground">{error}</p>
        </div>
      </div>
    )
  }

  if (!initialized || isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-lg font-semibold mb-2">Loading accounts...</h2>
        </div>
      </div>
    )
  }

  return (
    <AccountContext.Provider value={contextValue as AccountContextType}>
      {children}
    </AccountContext.Provider>
  )
}