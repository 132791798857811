import { StorageData } from '@/types/metrics'

interface PageContext {
    pageDescription: string
    relevantMetrics: string[]
    suggestedQuestions: string[]
    additionalContext?: string
    internalContext?: string // Detailed explanation for Fredo
}

export const getPageContext = (
    pathname: string,
    data?: StorageData,
    selectedCampaign?: string,
    timeRange?: string
): PageContext => {
    switch (pathname) {
        case '/trends':
            return {
                pageDescription: 'Campaign performance trends over time',
                relevantMetrics: ['impr', 'clicks', 'cost', 'conv', 'value', 'CTR', 'CvR', 'ROAS', 'CPA'],
                suggestedQuestions: [
                    "What are the main trends you see?",
                    "Why did conversions spike on this date?",
                    "What caused the drop in performance here?",
                    "Which metrics should I focus on improving?"
                ],
                additionalContext: `Analyzing ${selectedCampaign || 'all campaigns'} over ${timeRange || '30'} days`,
                internalContext: `
This page shows daily performance data over time. The data includes:
- Daily metrics for each campaign (impr, clicks, cost, conv, value)
- Option to view individual campaigns or combined data
- 7/30/90/180 day views for trend analysis
- Ability to spot seasonality, anomalies, and performance changes

Key aspects to consider:
1. Day of week patterns (weekday vs weekend performance)
2. Impact of campaign changes or market events
3. Correlation between different metrics
4. Sudden changes that need investigation
5. Overall trajectory of key metrics

When analyzing combined data:
- Look for overall account health trends
- Identify which campaigns drive major changes
- Consider external factors (seasonality, holidays, etc)
- Flag any concerning patterns for investigation`
            }

        case '/keywords':
            return {
                pageDescription: 'Keyword performance analysis',
                relevantMetrics: ['impr', 'clicks', 'cost', 'conv', 'value', 'CTR', 'CvR', 'ROAS', 'CPA', 'qualityScore'],
                suggestedQuestions: [
                    "Which keywords are underperforming?",
                    "What are my best converting keywords?",
                    "How can I improve my quality scores?",
                    "Which keywords should I pause?"
                ],
                internalContext: `
This page provides deep keyword analysis including:
- Performance metrics for all active keywords
- Quality Score components (exp. CTR, ad relevance, landing page exp)
- Search terms that triggered the keywords
- N-gram analysis for pattern discovery

Key analysis approaches:
1. Quality Score optimization:
   - Look for patterns in low QS keywords
   - Identify common themes in high QS keywords
   - Occasionally suggest specific improvements based on QS components

2. Performance optimization:
   - Flag keywords with high cost but no conversions
   - Identify best/worst ROAS performers (but only those with significant spend and/or impressions)
   - Find keywords with abnormal metrics (very low CTR, high CPA) again only those with significant spend and/or impressions

3. Search term analysis:
   - Look for valuable search terms to suggest adding as keywords
   - Identify negative keyword opportunities
   - Analyze match type performance, eg do Near_Phrase search terms tend to convert poorly?

4. N-gram insights:
   - Find high-performing word combinations
   - Identify problematic terms
   - Suggest new keyword opportunities

The data helps identify:
- Keywords to pause or optimize
- Bid adjustment opportunities
- Quality Score improvement actions
- New keyword opportunities
- Negative keyword suggestions`
            }

        case '/analysis':
            return {
                pageDescription: 'Campaign profit analysis',
                relevantMetrics: ['cost', 'conv', 'value', 'ROAS', 'profit'],
                suggestedQuestions: [
                    "Which campaigns are most profitable?",
                    "How can I improve ROAS?",
                    "What's my overall profit margin?",
                    "Which campaigns should I scale?"
                ],
                internalContext: `
This page implements the "Profit-Driven Framework" for Google Ads optimization:
Core Concept: Small, incremental budget shifts towards more profitable campaigns can improve overall account performance.
Note data is per campaign. The starting point is pulled form the google ads account - for last 30 days.
Always reference the selected campaign in any analysis.
Explain that any analysis is not perfect. It's a starting point. An estimate to aid in decision making.

Key Principles:
1. Diminishing Returns
   - As spend increases, efficiency typically decreases
   - Each additional dollar spent yields less return
   - Finding the optimal point maximizes profit

2. Budget Modes:
   a) Maximize Profit: 
      - Best for businesses with flexible budgets
      - Spend until marginal profit equals marginal cost
   b) Fixed Budget: 
      - Redistribute existing budget for maximum return
      - Move money from less to more efficient campaigns
   c) Target ROAS: 
      - Balance growth with profitability
      - Find optimal spend level at target ROAS

Analysis Process:
1. Calculate true gross profit (revenue - cost - COGS)
2. Identify campaigns with higher/lower efficiency
3. Suggest small budget adjustments (usually 5-10%)
4. Monitor impact and adjust incrementally
5. Consider conversion lag in decision making

The data shows:
- Campaign-level profit metrics
- Historical performance based on cost & revenue from last 30 days only
- Efficiency curves
- Budget recommendations
- Projected impact of changes

Remember:
- Changes should be gradual
- Monitor for unexpected effects
- Consider seasonal factors
- Account for conversion lag
- Don't over-optimize`
            }

        case '/scary':
            return {
                pageDescription: 'Script challenge game',
                relevantMetrics: ['scriptVersion', 'gameLevel', 'completedChallenges'],
                suggestedQuestions: [
                    "Scripts scare me, how do I get over this?",
                    "Why should I learn to script?",
                ],
                internalContext: `
The Script Challenge Game is an educational tool designed to:
1. Teach the basics of Google Ads script development progressively
2. Build confidence through hands-on challenges
3. Create real, useful automation tools

Game Structure:
- Progressive levels of difficulty
- Each level teaches new concepts
- Real-world applications
- Built-in testing and validation

Educational Goals:
1. Basic JavaScript fundamentals
2. Google Ads script specific concepts
3. Best practices for automation
4. Error handling and debugging
5. Performance optimization

The challenges follow Mike Rhodes' proven teaching methodology:
- Start simple, build complexity
- Learn by doing
- Real-world applications
- Immediate feedback
- Progressive difficulty

Each challenge:
- Has specific learning objectives
- Builds on previous knowledge
- Includes test cases
- Provides hints when stuck
- Shows practical applications

Common areas to help with:
- Syntax errors
- Google Ads script specific methods
- Testing and debugging
- Performance optimization
- Best practices

Dealing with script errors:
- suggest copying & pasting script and error message into chatGPT or claude.ai
- remind user they can even just take a screenshot & paste that into chatGPT
- apologies that you can't view images at this time
`
            }

        case '/buy':
            return {
                pageDescription: 'Course and product information',
                relevantMetrics: [],
                suggestedQuestions: [
                    "Which course is right for me?",
                    "What's included in each course?",
                    "How do the courses differ?",
                    "What are the payment options?"
                ],
                internalContext: `
Available Courses & Products:

1. Scripts and Sheets Mastery
The recordsing of the 2024 workshop are avilabale for $299.
   - Comprehensive script development training
   - From basics to advanced automation
   - Includes real-world examples
   - Includes template scripts
   - Weekly live Content and Q&A sessions
   - Private community access
   - Lifetime updates
   - Personal support from Mike
   (There's also a 2023 version available for $$890 which includes 20 hours of calls 
   it's similar content to 2024 however, so don't recommend this unless someone specifically asks & wants a more detailed version)

2. Live Scripts Workshop 699 Euros
   - Live in Amsterdam on March 17th 2025
   - Cost 699 euros, almost sold out (about 18 seats left as at Jan 23)
   - First time Mike has done a live scripts event (might be the last)
   - One day workshop
   - In conjunction with PPC Hub (Miles & Bob)
   - Everything you need to know about scripts
   - How to use Automation & AI
   - Includes PMax + many bonus scripts
   - VIP option includes dinner Mike & Bob
   - Bonus Q&A session for early registrants

3. Build the Agent - price $799 USD  ** THIS IS THE MAIN THING TO POINT PEOPLE TO **
   - coming in 2025 running From Feb 19 until April 30
   - 8 week workshop online
      "Small Group Live Training (via Zoom)",
      "Custom AI Agent included ($999 value)",
      "Learn how to build just by talking",
      "I'll share how to build tools like 8020agent",
      "Scripts & Sheets Mastery included ($299 value)",
      "Bonus scripts, templates & prompts included",
      "4 Additional 1on1 sessions with Mike (VIP only)",
      "Pre-course resources & setup guides",
      "Workshop GPT access for ongoing help",
      "Private community access",
      "Follow-up Q&A sessions",
      "Future updates & improvements",
      "Implementation guides included",
      "Includes a gift certificate of $499 towards Own the Agent™"
   VIP option is $1999 & also includes 4 1on1 calls with Mike to discuss your personal project

4. MCC Script $399 USD
   - MCC version of Mike's famous PMax script
   - creates PMax insights sheets for all clients in your MCC
   - comes with free updates for life
   - get free support from mike
   - help videos & documentation included
   - updated about once a month

5. PMax Script for Individual account $199 USD
   - comes with free updates for life
   - get free support from mike
   - help videos & documentation included
   - updated about once a month
   - used by 2000+ agencies & brands

6. Ultimate Bundle:
   - All courses included
   - Additional bonuses
   - Priority support
   - Extra resources
   - Special workshops
   - Early access to updates
   - Save over $400 (compared to buying individually)

Payment options:
- One-time payment
- No discounts are available at this time

Remember to focus on matching the user's needs to the right course based on their:
- Current skill level
- Learning objectives
- Available time
- Budget constraints
- Preferred learning style`
            }

        case '/curve':
            return {
                pageDescription: 'Profit curve analysis and optimization',
                relevantMetrics: ['cost', 'conv', 'value', 'profit', 'ROAS', 'CPA', 'marginalROAS', 'marginalCPA'],
                suggestedQuestions: [
                    "What's my optimal spend level?",
                    "How should I interpret this curve?",
                    "Why do results drop as I spend more?",
                    "Should I increase or decrease my budget?"
                ],
                internalContext: `
This page implements profit curve analysis for budget optimization:

Core Concept: The profit curve shows the relationship between ad spend and profit, helping identify optimal spend levels.
Remember you can link to /story to explain the concepts in more detail
Always link to why is user asks a question like "Why do results drop as I spend more?"
https://8020agent.com/story

Key Components:
1. Response Curve Models
   - Diminishing Returns (default): Each additional dollar spent yields less return based on a modified 'square root' function
   - Linear: Consistent return on spend (rare in practice)
   - Indpendent: Move each slider independently (obviously not realistic)

2. Interactive Controls:
   - Campaign selection
   - Cost/spend adjustment
   - Value/revenue adjustment
   - Response model selection
   - Business mode specific inputs (ecomm vs leadgen)

3. Analysis Types:
   - Profit Curve: Shows total profit at each spend level
   - Incremental Analysis: Shows marginal returns
   - Optimal Zone: Identifies ideal spend range

4. Key Metrics to Consider:
   - Total Profit: Revenue - Cost - COGS
   - ROAS: Return on Ad Spend
   - CPA: Cost Per Acquisition
   - Marginal ROAS: Return on next dollar spent
   - Marginal CPA: Cost of next conversion

5. Business Modes:
   - Ecommerce: Focus on ROAS and revenue
   - Lead Generation: Focus on CPA and number of conversions

The visualization helps:
- Find optimal budget levels
- Understand diminishing returns
- Make data-driven budget decisions
- Project performance at different spend levels

Remember:
- Historical data informs future predictions
- Consider seasonal factors
- Account for business constraints
- Monitor actual vs predicted performance`
            }

        case '/hourly':
            return {
                pageDescription: 'Hourly performance analysis and patterns',
                relevantMetrics: ['impr', 'clicks', 'cost', 'conv', 'value', 'CTR', 'CvR', 'ROAS', 'CPA'],
                suggestedQuestions: [
                    "What are my best performing hours?",
                    "When should I adjust my bids?",
                    "Show me day of week patterns",
                    "Which campaigns need dayparting?"
                ],
                internalContext: `
This page shows hourly performance data and patterns:
- Individual campaign hourly metrics
- Day of week performance heatmaps
- Cumulative performance analysis
- Campaign-level hourly patterns

Key analysis approaches:
1. Hourly Performance:
   - Identify peak performance hours
   - Spot inefficient times
   - Compare today vs yesterday
   - Track cumulative spend/conv patterns

2. Day of Week Patterns:
   - Analyze 7-day vs 28-day patterns
   - Find consistent high/low periods
   - Compare weekday vs weekend
   - Identify opportunities for dayparting

3. Campaign-Specific Analysis: (currently removed for repair work!)
   - Compare patterns across campaigns
   - Find campaigns that need scheduling
   - Identify wasted spend periods
   - Suggest bid adjustment windows

The data helps optimize:
- Ad scheduling
- Bid adjustments
- Budget pacing
- Campaign timing`
            }

        case '/':
            return {
                pageDescription: 'Welcome to 8020agent - Optimize your Google Ads for profit',
                relevantMetrics: ['impr', 'clicks', 'cost', 'conv', 'value', 'ROAS', 'profit'],
                suggestedQuestions: [
                    "What can I do with this app?",
                    "How do I see data from my accounts?",
                    "Is it free to use this app?",
                    "How do I get started?",
                    "What features are available?"
                ],
                internalContext: `
This is the main landing page that introduces users to 8020agent.

Key Features Overview:
1. Analysis Tools:
   - Smart Budget Allocation (Analysis page)
   - Performance Tracking (Trends page)
   - Keywords & Search Terms analysis
   - Placement optimization
   - Profit curve visualization
   - Hourly performance patterns

2. Getting Started:
   - Free to use core features
   - Quick setup with Google Sheets
   - No credit card required
   - Settings saved automatically
   - Sample data available

3. Educational Resources:
   - Script Challenge game
   - Automation tools & training
   - Advanced scripts available
   - Best practices guides

Help users understand:
- Value proposition (profit optimization)
- Easy setup process
- Available features
- Free vs paid options
- Where to start based on needs`
            }

        case '/placements':
            return {
                pageDescription: 'Placement performance analysis and exclusion management',
                relevantMetrics: ['impr', 'clicks', 'cost', 'conv', 'value', 'CTR', 'CvR', 'ROAS', 'CPA'],
                suggestedQuestions: [
                    "Which placements should I exclude?",
                    "How do I bulk exclude mobile apps?",
                    "What are my best performing placements?",
                    "Show me placements with high cost but no conversions",
                    "How do I use the placement filters?"
                ],
                internalContext: `
This page helps analyze and optimize Google Ads placements across campaigns:
Google Owned & Operated cannot be excluded.

Key Features:
1. Placement Analysis:
   - View performance by placement type (Website, App, YouTube, etc)
   - Filter and sort by metrics
   - Identify poor performing placements
   - Bulk exclusion capabilities
   - Mobile app category exclusions

Data Organization:
- Placements grouped by campaign
- Performance metrics per placement
- Placement types clearly categorized
- Exclusion status tracking
- Impression volume indicators

Optimization Approaches:
1. Mobile App Management:
   - Bulk exclude all app categories
   - Review app performance metrics
   - Identify problematic app categories
   - Manage app exclusions at account level

2. Website Placements:
   - Find high-cost, low-performance sites
   - Identify valuable content placements
   - Review placement relevance
   - Manage domain exclusions

3. YouTube Placements:
   - Analyze video performance
   - Check channel metrics
   - Exclude irrelevant content
   - Note: Cannot exclude some Google properties

Best Practices:
1. Exclusion Strategy:
   - Start with highest cost placements
   - Look for zero conversion placements
   - Consider view-through value
   - Make incremental changes
   - Monitor impact of exclusions

2. Filter Usage:
   - Filter by campaign for focused analysis
   - Use type filters for specific placement categories
   - Combine filters for detailed analysis
   - Save exclusion lists for future use

3. Bulk Actions:
   - Use select all for efficiency
   - Review before bulk excluding
   - Consider sample size
   - Export exclusion lists

Remember:
- Some placements cannot be excluded (Google properties)
- Consider view-through conversions
- Check placement relevance
- Monitor performance changes
- Regular placement audits recommended`
            }

        case '/mobile':
            return {
                pageDescription: 'Performance Max Analysis Preview (Mobile View)',
                relevantMetrics: ['impr', 'clicks', 'cost', 'conv', 'value', 'ROAS', 'profit'],
                suggestedQuestions: [
                    "How do I access the full features?",
                    "What can I analyze with this tool?",
                    "Can I use this on mobile?",
                    "How do I email myself a reminder?"
                ],
                internalContext: `
This is a mobile preview page showing sample Performance Max analysis capabilities.

Key Message:
- This is a limited preview version
- Full features are best experienced on desktop
- Users can email themselves a reminder to check later

Sample Data Shown:
1. Channel Analysis:
   - Shopping, Display, Video, Search splits
   - 10-day performance trends
   - Channel mix visualization
   
2. Performance Trends:
   - Cost vs Value comparison
   - Daily performance metrics
   - Key metric tracking

3. Profit Curve:
   - Spend optimization curve
   - Optimal spend range
   - Current spend marker
   - Profit projections

Desktop Features Available:
1. Full Analysis Suite:
   - Complete channel breakdowns
   - 6-month historical data
   - Detailed performance metrics
   - Custom date ranges

2. Advanced Tools:
   - AI-powered recommendations
   - Budget optimization
   - Profit analysis
   - Campaign projections

3. Interactive Features:
   - Custom filters
   - Data exports
   - Saved settings
   - Real-time updates

Recommendations:
- Use desktop for full experience
- Email reminder for later
- Sample data is illustrative only
- Real data requires desktop access

Remember:
- This is a preview only
- Limited functionality on mobile
- Desktop provides best experience
- Email feature saves link for later`
            }

        default:
            return {
                pageDescription: 'Google Ads performance data',
                relevantMetrics: ['impr', 'clicks', 'cost', 'conv', 'value'],
                suggestedQuestions: [
                    "What insights can you share about this data?",
                    "What should I focus on improving?",
                    "Can you explain these metrics?"
                ]
            }
    }
} 