import React from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ArrowRight, GraduationCap, Code, Clock } from "lucide-react";

interface PSAIntroProps {
  onStart: () => void;
}

export default function PSAIntro({ onStart }: PSAIntroProps) {
  const features = [
    {
      icon: <GraduationCap className="w-6 h-6 text-blue-500" />,
      title: "Step-by-Step Learning",
      description: "Master Google Ads Scripts with clear, guided examples"
    },
    {
      icon: <Code className="w-6 h-6 text-green-500" />,
      title: "Ready-to-Use Scripts",
      description: "Copy, paste & run working scripts in your account"
    },
    {
      icon: <Clock className="w-6 h-6 text-purple-500" />,
      title: "Quick Progress",
      description: "Learn at your own pace with bite-sized lessons"
    }
  ];

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-4xl font-bold">
            Are you ready to never be scared of scripts again?
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-8">
            <div className="flex items-center justify-between">
              <img
                src="/images/psa-logo.png"
                alt="PSA Logo"
                className="h-24 object-contain"
              />
              <img 
                src="/images/mri-logo.png"
                alt="MRI Logo"
                className="h-12 object-contain"
              />
            </div>

            <div className="prose prose-2xl">
              <p className="text-orange-600 dark:text-orange-400 font-large text-3xl">
                Learn how to automate your Google Ads account as part of PSAC25
              </p>
            </div>

            <div className="prose prose-lg text-muted-foreground">
              <p>
                Google Ads Scripts are a powerful way to automate your Google Ads account.
                In this short 9-part challenge, you'll learn how to:
              </p>
              • Set up and run Google Ads scripts<br />
              • Automate performance monitoring <br />
              • Create custom alerts and reports<br />
              • Schedule automated optimizations<br />
              <br />
              <p>
                Don't worry if you're brand new to scripts - I'll guide you through each step.
                By the end, you'll be comfortable with scripts & ready to automate routine tasks and focus on strategy.
              </p>
            </div>

            <div className="grid gap-6 md:grid-cols-3">
              {features.map((feature, index) => (
                <Card key={index} className="bg-muted/50">
                  <CardContent className="pt-6">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        {feature.icon}
                        <h3 className="font-semibold">{feature.title}</h3>
                      </div>
                      <p className="text-sm text-muted-foreground">
                        {feature.description}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>

            <div className="flex justify-center">
              <Button
                onClick={onStart}
                size="lg"
                className="min-w-[200px] bg-orange-600 hover:bg-orange-700"
              >
                <span className="text-2xl">Start Challenge</span> <ArrowRight className="ml-2 h-8 w-8" />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
} 