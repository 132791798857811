// src/components/profit-analysis/lead-gen-controls.tsx
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Slider } from '@/components/ui/slider';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { findCampaign, Range } from '@/lib/campaign-utils';
import type { CampaignWithShare, ResponseModel, BusinessMode } from '@/types/metrics';
import { 
  calculateRevenueFromCost,
  calculateCostFromRevenue
} from '@/lib/profit-calculations';

interface LeadGenControlsProps {
  campaigns: CampaignWithShare[];
  selectedCampaign: string;
  responseModel: ResponseModel;
  currentCost: number;
  currentConversions: number;
  costRange: Range;
  selectedCurrency: string;
  onCampaignSelect: (campaign: string) => void;
  onResponseModelChange: (model: ResponseModel) => void;
  onCostChange: (cost: number) => void;
  onConversionsChange: (conv: number) => void;
}

export function LeadGenControls(props: LeadGenControlsProps) {


  const campaign = findCampaign(props.campaigns, props.selectedCampaign);


  const handleCostChange = (value: number[]) => {
    const newCost = Math.min(props.costRange.max, Math.max(props.costRange.min, value[0]));
    props.onCostChange(newCost);

    if (props.responseModel !== 'independent') {
      const campaign = findCampaign(props.campaigns, props.selectedCampaign);
      if (campaign) {
        const newConv = calculateRevenueFromCost(
          newCost,
          campaign.cost,
          campaign.conv,
          props.responseModel
        );
        console.log('LEAD GEN CONTROLS - Cost -> Conv Calculation:', {
          newCost,
          baseCost: campaign.cost,
          baseConv: campaign.conv,
          newConv,
          model: props.responseModel
        });
        props.onConversionsChange(newConv);
      }
    }
  };

  const handleConversionsChange = (value: number[]) => {
    const newConv = value[0];
    console.log('LEAD GEN CONTROLS - Conv Change:', {
      from: props.currentConversions,
      to: newConv
    });
    props.onConversionsChange(newConv);

    if (props.responseModel !== 'independent') {
      const campaign = findCampaign(props.campaigns, props.selectedCampaign);
      if (campaign) {
        console.log('LEAD GEN CONTROLS - Conv -> Cost Input:', {
          targetConv: newConv,
          baseCost: campaign.cost,
          baseConv: campaign.conv,
          model: props.responseModel
        });

        const newCost = calculateCostFromRevenue(
          newConv,
          campaign.cost,
          campaign.conv,
          props.responseModel,
          'leadgen' as BusinessMode,
          0,
          campaign.conv
        );

        const boundedCost = Math.min(
          props.costRange.max,
          Math.max(props.costRange.min, newCost)
        );

        console.log('LEAD GEN CONTROLS - Conv -> Cost Result:', {
          calculatedCost: newCost,
          boundedCost,
          min: props.costRange.min,
          max: props.costRange.max
        });

        props.onCostChange(boundedCost);
      }
    }
  };

  return (
    <Card className="h-full">
      <CardHeader>
        <CardTitle>Campaign Level Controls - Data is last 30 days</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="space-y-2">
            <Label>Campaign</Label>
            <Select 
              value={props.selectedCampaign} 
              onValueChange={(campaign) => {
                console.log('LEAD GEN CONTROLS - Campaign Select:', campaign);
                props.onCampaignSelect(campaign);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a campaign" />
              </SelectTrigger>
              <SelectContent>
                {props.campaigns.map((campaign) => (
                  <SelectItem
                    key={campaign.campaign}
                    value={campaign.campaign}
                    className="flex items-center justify-between py-2 pr-2"
                  >
                    <span className="truncate mr-4">
                      {campaign.campaign}
                    </span>
                    <span className="text-sm font-medium text-muted-foreground">
                      {props.selectedCurrency}{Math.round(campaign.cost).toLocaleString()}
                    </span>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label>Response Model</Label>
            <Tabs 
              value={props.responseModel} 
              onValueChange={(value) => {
                console.log('LEAD GEN CONTROLS - Model Change:', value);
                props.onResponseModelChange(value as ResponseModel);
              }}
            >
              <TabsList className="grid grid-cols-3 gap-2 bg-transparent">
                <TabsTrigger
                  value="diminishing"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                >
                  Diminishing
                </TabsTrigger>
                <TabsTrigger
                  value="linear"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                >
                  Linear
                </TabsTrigger>
                <TabsTrigger
                  value="independent"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                >
                  Independent
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          <div className="space-y-2">
            <Label>Cost ({props.selectedCurrency})</Label>
            <Slider
              min={props.costRange.min}
              max={props.costRange.max}
              step={Math.max(1, (props.costRange.max - props.costRange.min) / 100)}
              value={[Math.min(props.costRange.max, Math.max(props.costRange.min, props.currentCost))]}
              onValueChange={handleCostChange}
            />
            <div className="text-right text-sm text-muted-foreground">
              {props.selectedCurrency}{Math.round(props.currentCost).toLocaleString()}
            </div>
          </div>

          <div className="space-y-2">
            <Label>Conversions</Label>
            <Slider
              min={1}
              max={(() => {
                const campaign = findCampaign(props.campaigns, props.selectedCampaign)
                if (!campaign) return props.currentConversions * 1.5
                return campaign.conv * 2
              })()}
              step={1}
              value={[props.currentConversions]}
              onValueChange={handleConversionsChange}
            />
            <div className="text-right text-sm text-muted-foreground">
              {props.currentConversions.toFixed(1)}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
} 