// src/pages/sales/pmax.tsx
import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import {
  ArrowRight, BarChart3, CheckCircle2,
  RefreshCw, ArrowRightCircle, Sparkles, ChevronDown
} from "lucide-react";

export default function ScriptPage() {
  const [openFaq, setOpenFaq] = useState<number | null>(null);

  const toggleFaq = (index: number) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-[#FFFFFF]">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-b from-primary to-primary text-white py-8">
        <div className="container mx-auto px-4 max-w-5xl relative z-10">
          <div className="text-center mb-4">
            <Badge variant="secondary" className="mb-2 bg-white/30 backdrop-blur-sm">
              Trusted by 8400+ agencies & brands
            </Badge>
            <h1 className="text-4xl font-bold mb-3">Mike's PMax Script - for Individual Accounts</h1>
            <p className="text-lg mb-4">
              Unlimited uses on unlimited accounts. No ongoing fees. Fully commented code.
            </p>
            <p className="text-base mb-6 text-white/90">
              Keep an eye on your PMax campaigns, get insights & data you can't get in the UI.
              All with one simple script that you control.
            </p>

            <div className="flex justify-center gap-4 flex-wrap mb-4">
              <a href="https://mikerhodes.circle.so/checkout/latest-script">
                <Button size="lg" className="bg-white text-primary hover:bg-white/90">
                  Get Started for $199
                  <ArrowRightCircle className="ml-2 h-4 w-4" />
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16 max-w-5xl">
        {/* Video/Demo Section */}
        <div className="aspect-video w-full bg-black/5 rounded-lg mb-12">
          <iframe
            src="https://www.loom.com/embed/b354fd6616104531a7993cd9fcef1700?sid=8c3db692-98b1-4774-bc98-1eaa943089d1"
            frameBorder="0"
            allowFullScreen
            className="w-full h-full rounded-lg"
          ></iframe>
        </div>

        {/* Trusted By Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-[#1A237E] mb-6">
            Trusted by 8400+ agencies & brands worldwide
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            <img src="/images/pmax/b-bupa.svg" alt="Bupa" className="h-20 object-contain" />
            <img src="/images/pmax/b-thinkific.svg" alt="Thinkific" className="h-20 object-contain" />
            <img src="/images/pmax/b-hugoboss.svg" alt="Hugo Boss" className="h-20 object-contain" />
            <img src="/images/pmax/b-sephora.svg" alt="Sephora" className="h-20 object-contain" />
            <img src="/images/pmax/b-hellyhansen.svg" alt="H&H" className="h-20 object-contain" />
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <BarChart3 className="h-6 w-6 text-primary" />
                What is it?
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <p className="text-lg text-muted-foreground">
                The PMax script is ~3200 lines of code that you copy & paste into your Google Ads account.
                It creates & automatically updates a Spreadsheet with data about:
              </p>
              <ul className="space-y-2">
                <li className="flex items-start gap-2">
                  <CheckCircle2 className="h-5 w-5 text-primary mt-1" />
                  <span>Your products, campaigns, and search categories</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle2 className="h-5 w-5 text-primary mt-1" />
                  <span>Asset groups, assets, and placements</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle2 className="h-5 w-5 text-primary mt-1" />
                  <span>Geo, pages & changes all in one place</span>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Sparkles className="h-6 w-6 text-primary" />
                Getting Started
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <span className="bg-primary/10 w-8 h-8 flex items-center justify-center rounded-full text-primary font-bold flex-shrink-0">1</span>
                  <div>
                    <h4 className="font-semibold">Copy the template Google Sheet</h4>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-primary/10 w-8 h-8 flex items-center justify-center rounded-full text-primary font-bold flex-shrink-0">2</span>
                  <div>
                    <h4 className="font-semibold">Paste the code into your Adsaccount</h4>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-primary/10 w-8 h-8 flex items-center justify-center rounded-full text-primary font-bold flex-shrink-0">3</span>
                  <div>
                    <h4 className="font-semibold">Hit Run & wait for a minute or two</h4>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-primary/10 w-8 h-8 flex items-center justify-center rounded-full text-primary font-bold flex-shrink-0">4</span>
                  <div>
                    <h4 className="font-semibold">Explore data you can't find anywhere else</h4>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="bg-primary/10 w-8 h-8 flex items-center justify-center rounded-full text-primary font-bold flex-shrink-0">5</span>
                  <div>
                    <h4 className="font-semibold">Ask your boss for a payrise :)</h4>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Features Section */}
        <div className="space-y-16">
          {/* Spend by Channel */}
          <div>
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Spend by Channel
            </h2>
            <p className="text-gray-700 mb-4">
              See actual and percentage spend across shopping, video, display & search/other channels.
              Track how changes affect performance.
            </p>
            <img
              src="/images/pmax/spend-chart.webp"
              alt="Spend by Channel Chart"
              className="w-full rounded-lg shadow-md mb-8"
            />
            <img
              src="/images/pmax/cost-per-over-time.webp"
              alt="Cost per Channel over time"
              className="w-full rounded-lg shadow-md mb-8"
            />
          </div>

          {/* Search Categories Section */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Search Categories
            </h2>
            <p className="text-gray-700 mb-4">
              Very similar to the data available in your Insights tab (in the UI), the search categories (not search terms) are categorised into one of 4 buckets. You define your brand term/s & the script will identify brand, close-to-brand & non-brand (as well as those pesky 'blank' categories... think of these like the 'other terms' in your SQR).
            </p>
            <p className="text-gray-700 mb-8">
              If you don't have access to campaign negative yet, the sheet links to the Google PMax Exclusions form to make it easy for you to request changes from Google.
            </p>

            {/* Search Categories Table Image */}
            <img
              src="/images/pmax/top-search-cat.webp"
              alt="Search Categories Table"
              className="w-full rounded-lg mb-8"
            />

            {/* Search Category Donut Charts */}
            <img
              src="/images/pmax/search-cat-buckets.webp"
              alt="Search Category Analysis"
              className="w-full rounded-lg mb-8"
            />

            {/* Search Category Bar Chart */}
            <img
              src="/images/pmax/search-cat-perc.webp"
              alt="Search Category Breakdown"
              className="w-full rounded-lg mb-8"
            />
          </div>

          {/* Product Matrix Section */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Product Matrix
            </h2>
            <p className="text-gray-700 mb-4">
              The super-popular Product Matrix gives multiple ways of analysing your product data over the date range you pick. Products are categorised into 1 of 6 buckets (zombies, zero conv, meh, flukes, costly & profitable) for each campaign.
            </p>
            <p className="text-gray-700 mb-8">
              This also includes data from your Shopping campaigns as well as PMax. Plus there's a sortable/filterable table so you can see exactly which products are your best/worst performers.
            </p>

            {/* Performance Matrix Charts */}
            <img
              src="/images/pmax/three-donuts.webp"
              alt="Product Performance Matrix"
              className="w-full rounded-lg mb-8"
            />

            {/* Products Table */}
            <img
              src="/images/pmax/product-buckets.webp"
              alt="Product Performance Table"
              className="w-full rounded-lg mb-8"
            />
          </div>

          {/* Product ID Buckets Section */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Product ID buckets
            </h2>
            <p className="text-gray-700 mb-8">
              Want to create a supplemental feed with those product 'buckets' it's easy. With a new Google Sheet that links to your PMax Sheet, you'll beready to upload to your Merchant Centre account. You can even schedule that to run daily, if you prefer, to run hands-off.
            </p>

            {/* Product ID Table */}
            <img
              src="/images/pmax/product-ids.webp"
              alt="Product ID Buckets"
              className="w-full rounded-lg mb-8"
            />
          </div>

          {/* Placements Section */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Placements
            </h2>
            <p className="text-gray-700 mb-8">
              Not sure which websites, Youtube channels, or mobile apps your PMax ads are showing on? Now you'll know. Get the list of the highest spenders (by default, placements with {'>'}10c/day are shown, but you can change that if needed). Again, use the Exclusions form to request changes based on this data as needed.
            </p>

            {/* Placements Table */}
            <img
              src="/images/pmax/placements.png"
              alt="Placements Data"
              className="w-full rounded-lg mb-8"
            />
          </div>

          {/* Change History Section */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Change History
            </h2>
            <p className="text-gray-700 mb-8">
              Yes you can find this data easily in the Ads UI, but early users of the script requested to have it all in the same place as all the other data. It's not the easiest data to interpret in its raw form, so I'll also give you the prompt I use with this data in chatGPT to help make sense of it.
            </p>

            {/* Change History Table */}
            <img
              src="/images/pmax/change-history.webp"
              alt="Change History Data"
              className="w-full rounded-lg mb-8"
            />
          </div>

          {/* Assets Section */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Assets
            </h2>
            <p className="text-gray-700 mb-4">
              Which videos get the most spend? Are they yours, or were they auto generated by Google's AI? Need to see a thumbnail to remind you what the video was? Done.
            </p>
            <p className="text-gray-700 mb-8">
              Get the answers on your Asset tab, along with clickable links to view the auto generated videos, key metrics & some broader data to give you context. A few stats (and even images) are also available for your Display assets.
            </p>

            {/* Assets Table */}
            <img
              src="/images/pmax/videos.webp"
              alt="Assets Performance Data"
              className="w-full rounded-lg mb-8"
            />
          </div>

          {/* Asset Groups Section */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Asset Groups
            </h2>
            <p className="text-gray-700 mb-8">
              Again, this data is available in the Ads UI, but users requested to have it in the Sheet. Get all the key metrics for your Asset Groups plus AOV. This is a total of your spend in Listing Groups only – ie just Shopping Ads
            </p>

            {/* Asset Groups Table */}
            <img
              src="/images/pmax/asset-groups.webp"
              alt="Asset Groups Performance"
              className="w-full rounded-lg mb-8"
            />
          </div>

          {/* Lead Gen Asset Groups Section */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-[#1A237E] mb-4 border-l-4 border-blue-600 pl-4">
              Lead Gen Asset Groups
            </h2>
            <p className="text-gray-700 mb-8">
              As of v52 of the script, now you can see the Asset Group data even in accounts that don't use a product feed (ie Lead Gen accounts). There's also a switch in the settings for 'leadgen' which hides the product focused reports & changes other reports to use CPA instead of ROAS.
            </p>
          </div>
        </div>

        {/* Requirements Section */}
        <Card className="mb-16 mt-16" id="requirements">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <CheckCircle2 className="h-6 w-6 text-primary" />
              Requirements
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <p>The PMax Script is designed for a single account (not an MCC or Manager Account - there's a separate script for that).</p>
            <ul className="space-y-2">
              <li className="flex items-center gap-2">
                <CheckCircle2 className="h-5 w-5 text-primary" />
                Standard or Admin access
              </li>
              <li className="flex items-center gap-2">
                <CheckCircle2 className="h-5 w-5 text-primary" />
                Same email for Google Ads & Sheets (you control who has access to the sheet)
              </li>
              <li className="flex items-center gap-2">
                <CheckCircle2 className="h-5 w-5 text-primary" />
                1-30 minutes runtime (depends on account size, there's a setting for huge accounts so the script doesn't time out)
              </li>
            </ul>
            <p className="text-sm text-muted-foreground mt-4">
              The script has been tested with accounts containing over 6,000,000 products. So it'll probably work just fine for you :\
            </p>
          </CardContent>
        </Card>

        {/* FAQ Section with Accordion */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-[#1A237E] mb-4">
            Frequently asked questions
          </h2>

          <div className="space-y-4">
            {/* Support FAQ */}
            <div className="border-b border-gray-200">
              <button
                className="w-full text-left py-4 flex justify-between items-center"
                onClick={() => toggleFaq(0)}
              >
                <span className="font-semibold">Is customer support included?</span>
                <ChevronDown className={cn("h-5 w-5 transition-transform", openFaq === 0 ? "rotate-180" : "")} />
              </button>
              <div className={cn(
                "overflow-hidden transition-all duration-300",
                openFaq === 0 ? "max-h-[500px] pb-4" : "max-h-0"
              )}>
                <div className="text-gray-700">
                  Support is included, yes. Mike (the creator of the script & Google Ads user/teacher since 2004) will answer your questions personally on the MikeRhodes.com.au site. The only exception is if you've made alterations to the code itself or the Google Sheet. We can't troubleshoot complex custom installations, or fix stuff you broke when you changed the code. Please make feature requests in the members' site at MikeRhodes.com.au
                </div>
              </div>
            </div>

            {/* Updates FAQ */}
            <div className="border-b border-gray-200">
              <button
                className="w-full text-left py-4 flex justify-between items-center"
                onClick={() => toggleFaq(1)}
              >
                <span className="font-semibold">How do updates work? Do they cost extra?</span>
                <ChevronDown className={cn("h-5 w-5 transition-transform", openFaq === 1 ? "rotate-180" : "")} />
              </button>
              <div className={cn(
                "overflow-hidden transition-all duration-300",
                openFaq === 1 ? "max-h-[500px] pb-4" : "max-h-0"
              )}>
                <div className="text-gray-700">
                  Nope never. We make updates pretty regularly (typically at least monthly, maybe more often) based on requests inside the 'Mike Rhodes Ideas' community site (which you get access to when you buy the script). That's where you'll find the updates. Mike usually posts about the update on LinkedIn & we're considering creating an email list to let buyers know about updates as soon as they roll out. Of course you don't have to upgrade, but if & when you do you'll have access to the latest features.
                </div>
              </div>
            </div>

            {/* Multiple Accounts FAQ */}
            <div className="border-b border-gray-200">
              <button
                className="w-full text-left py-4 flex justify-between items-center"
                onClick={() => toggleFaq(2)}
              >
                <span className="font-semibold">Does each account/client have to buy or download it?</span>
                <ChevronDown className={cn("h-5 w-5 transition-transform", openFaq === 2 ? "rotate-180" : "")} />
              </button>
              <div className={cn(
                "overflow-hidden transition-all duration-300",
                openFaq === 2 ? "max-h-[500px] pb-4" : "max-h-0"
              )}>
                <div className="text-gray-700">
                  Nope — one person (you, your IT person, etc.) buys it, downloads it, and installs it in a Google Ads account that you control. Once it's installed and working, you can schedule it to run every day if you like. All team members at your business/agency can use the script on other campaigns or accounts. All we ask is that if you have multiple business entitles (like a holding agency) you buy a copy of the script for each entity.
                </div>
              </div>
            </div>

            {/* Pricing FAQ */}
            <div className="border-b border-gray-200">
              <button
                className="w-full text-left py-4 flex justify-between items-center"
                onClick={() => toggleFaq(3)}
              >
                <span className="font-semibold">Just $199, once, really? No per-user or per-account fees?</span>
                <ChevronDown className={cn("h-5 w-5 transition-transform", openFaq === 3 ? "rotate-180" : "")} />
              </button>
              <div className={cn(
                "overflow-hidden transition-all duration-300",
                openFaq === 3 ? "max-h-[500px] pb-4" : "max-h-0"
              )}>
                <div className="text-gray-700">
                  Yes, the PMax Script is just $199, once. There are no ongoing fees, no monthly fees, no annual fees, no per-user fees, no account fees, no support contracts, nothing. You don't rent the Script. It's not a SaaS product — you buy it, it's yours. Like a can of Diet Coke… but good for you.
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Brand Trust Section */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-[#1A237E] mb-6">
            The PMax Script is trusted by:
          </h2>
          <p className="text-gray-700 mb-8">
            Well over eight thousand brands & agencies. From global names to individual freelancers.
            <br />
            Join these businesses and elevate your PMax game.
          </p>

          <div className="space-y-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Brands:</h3>
              <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
                <img src="/images/pmax/b-bupa.svg" alt="Bupa" className="h-20 object-contain" />
                <img src="/images/pmax/b-thinkific.svg" alt="Thinkific" className="h-20 object-contain" />
                <img src="/images/pmax/b-hugoboss.svg" alt="Hugo Boss" className="h-20 object-contain" />
                <img src="/images/pmax/b-sephora.svg" alt="Sephora" className="h-20 object-contain" />
                <img src="/images/pmax/b-hellyhansen.svg" alt="H&H" className="h-20 object-contain" />
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Agencies:</h3>
              <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
                <img src="/images/pmax/a-dentsu.svg" alt="Dentsu" className="h-20 object-contain" />
                <img src="/images/pmax/a-merkle.svg" alt="Merkle" className="h-20 object-contain" />
                <img src="/images/pmax/a-publicis.svg" alt="Publicis" className="h-20 object-contain" />
                <img src="/images/pmax/a-groupm.svg" alt="GroupM" className="h-20 object-contain" />
                <img src="/images/pmax/a-jellyfish.svg" alt="Jellyfish" className="h-20 object-contain" />
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center space-y-6 py-8">
          <h2 className="text-3xl font-bold">Ready to get started?</h2>
          <p className="text-xl text-muted-foreground">One-time payment • Lifetime updates • Personal support</p>
          <div className="flex flex-col items-center gap-4">
            <div className="text-4xl font-bold text-primary">$199</div>
            <a href="https://mikerhodes.circle.so/checkout/latest-script">
              <Button size="lg" className="bg-primary hover:bg-primary/90">
                Get Instant Access
                <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </a>
            <p className="text-sm text-muted-foreground">
              Questions? Ask me at{" "}
              <a href="mhttps://mikerhodes.circle.so/c/help/" className="text-primary hover:underline">
                https://mikerhodes.circle.so/c/help/
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function FeatureCard({ title, description, icon: Icon }) {
  return (
    <Card className="relative overflow-hidden">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Icon className="h-6 w-6 text-primary" />
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-muted-foreground">{description}</p>
      </CardContent>
    </Card>
  );
}
