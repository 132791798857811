// src/services/db.ts
import Dexie, { Table, Transaction } from 'dexie';
import { StorageData, Account } from '@/types/metrics';
import { MAX_FREE_ACCOUNTS, SAMPLE_ACCOUNT_ID, SAMPLE_ACCOUNT } from '@/lib/constants';
import { loadSampleData, loadSampleDataInBackground } from '@/lib/sample-data-utils';
import { debounce } from 'lodash'

const DEBUG = process.env.NODE_ENV === 'development';
const HOUR = 60 * 60 * 1000;

function log(action: string, details?: any) {
  if (action.startsWith('Init') || DEBUG) {
    console.log(`%c[Dexie ${action}]`, 'color: #8b5cf6',
      typeof details === 'string' ? details : {
        ...details,
        timestamp: new Date().toISOString()
      }
    );
  }
}

export interface DataStore extends StorageData {
  id?: number;
  lastUpdated: string;
}

interface AccountTable extends Table<Account> {
  // Add any table-specific methods here
}

interface PMMaxDatabaseTables {
  accounts: AccountTable;
  campaignData: Table<DataStore>;
}

export class PMMaxDatabase extends Dexie {
  campaignData!: Table<DataStore>;
  accounts!: AccountTable;
  private initialized = false;
  private initPromise: Promise<void>;
  metadata!: Table<{ key: string; value: string }>;

  private debouncedUpdate = debounce(
    async (account: Account) => {
      try {
        await this.accounts.put(account)
        log('Background Update', {
          dexieId: account.dexieId,
          success: true
        })
      } catch (err) {
        console.error('Background update failed:', err)
        throw err
      }
    },
    1000 // 1 second delay
  )

  constructor() {
    console.log('PMMaxDatabase: Constructor starting');
    super('pmaxData');

    // Simple schema - no forced sample data
    this.version(1).stores({
      campaignData: '++id,lastUpdated,dexieId',
      accounts: '++id,accountCID,dexieId',
      metadata: 'key'
    });

    // Initialize database and sample data
    this.initPromise = this.initialize();
  }

  private async initialize() {
    try {
      console.log('DB initialize starting')
      await this.open()
      console.log('DB opened successfully')

      const hasAccounts = await this.isInitialized()
      console.log('DB initialization check:', { hasAccounts })

      if (!hasAccounts) {
        console.log('Loading sample account...')
        await this.loadSampleAccount()
        console.log('Sample account loaded')
      }

      this.initialized = true
      console.log('DB initialization complete')
    } catch (err) {
      console.error('DB initialization failed:', err)
      throw err
    }
  }

  public async ready() {
    await this.initPromise;
    return this.initialized;
  }

  async getAllAccounts(): Promise<Account[]> {
    await this.ready();
    return this.accounts.toArray();
  }

  async getAccount(dexieId: string): Promise<Account | undefined> {
    console.log('getAccount called:', { dexieId })
    if (!dexieId) {
      console.log('No dexieId provided')
      return undefined
    }
    const account = await this.accounts.get(dexieId)
    console.log('getAccount result:', {
      found: !!account,
      account: account ? {
        dexieId: account.dexieId,
        name: account.name
      } : null
    })
    return account
  }

  async isInitialized(): Promise<boolean> {
    try {
      const accountCount = await this.accounts.count();
      const sampleAccount = await this.getAccount(SAMPLE_ACCOUNT_ID);
      return accountCount > 0 && !!sampleAccount;
    } catch (err) {
      console.error('Database check failed:', err);
      return false;
    }
  }

  async getAccountData(dexieId: string): Promise<DataStore | undefined> {
    const data = await this.campaignData
      .where('dexieId')
      .equals(dexieId)
      .reverse() // Get most recent first
      .first();

    if (data) {
      // Ensure timestamp exists and is in ISO format
      data.timestamp = data.timestamp || data.lastUpdated || new Date().toISOString()
      delete data.lastUpdated // Clean up old timestamp field
    }

    log('Read Account Data', {
      dexieId,
      timestamp: data?.timestamp,
      dataSize: data ? JSON.stringify(data).length / 1024 + 'KB' : 0
    });

    return data;
  }

  async saveAccountData(dexieId: string, data: StorageData): Promise<void> {
    try {
      const dataToStore = {
        ...data,
        lastUpdated: new Date().toISOString(),
        dexieId
      }

      // Delete existing data first
      await this.campaignData
        .where('dexieId')
        .equals(dexieId)
        .delete()

      // Add new data
      await this.campaignData.add(dataToStore)

      log('Write', {
        operation: 'save',
        dexieId,
        timestamp: dataToStore.lastUpdated,
        dataSize: JSON.stringify(dataToStore).length / 1024 + 'KB'
      })
    } catch (err) {
      console.error(`Failed to save data for ${dexieId}:`, err)
      throw err
    }
  }

  async getSampleData(): Promise<DataStore | undefined> {
    try {
      const data = await this.campaignData
        .where('dexieId')
        .equals(SAMPLE_ACCOUNT_ID)
        .last();

      log('Read Sample Data', {
        exists: !!data,
        timestamp: data?.lastUpdated,
        dataSize: data ? JSON.stringify(data).length / 1024 + 'KB' : 0
      });

      return data;
    } catch (err) {
      console.error('Failed to get sample data:', err);
      return undefined;
    }
  }

  async saveData(data: StorageData, isSampleData: boolean = false): Promise<void> {
    if (isSampleData) {
      await this.clearSampleData();
    } else {
      await this.clearUserData();
    }

    const dataToStore: DataStore = {
      ...data,
      lastUpdated: new Date().toISOString(),
      dexieId: isSampleData ? SAMPLE_ACCOUNT_ID : data.dexieId
    };

    log('Write', {
      operation: 'save',
      isSample: isSampleData,
      timestamp: dataToStore.lastUpdated,
      dataSize: JSON.stringify(dataToStore).length / 1024 + 'KB'
    });

    await this.campaignData.add(dataToStore);

    const savedData = await this.campaignData
      .where('lastUpdated')
      .equals(dataToStore.lastUpdated)
      .first();

    if (!savedData) {
      throw new Error('Failed to save data to database');
    }
  }

  async clearUserData(): Promise<void> {
    log('Clear', 'Clearing user data');
    const count = await this.campaignData
      .where('dexieId')
      .equals(SAMPLE_ACCOUNT_ID)
      .delete();

    log('Clear Complete', {
      type: 'user',
      recordsDeleted: count
    });
  }

  async clearSampleData(): Promise<void> {
    log('Clear', 'Clearing sample data');
    const count = await this.campaignData
      .where('dexieId')
      .equals(SAMPLE_ACCOUNT_ID)
      .delete();

    log('Clear Complete', {
      type: 'sample',
      recordsDeleted: count
    });
  }

  async getStats(): Promise<{
    accounts: number,
    totalRecords: number,
    totalSize: number
  }> {
    const [accounts, allData] = await Promise.all([
      this.accounts.count(),
      this.campaignData.toArray()
    ]);

    const totalSize = JSON.stringify(allData).length / 1024;

    log('Stats', {
      accounts,
      records: allData.length,
      totalSize: `${totalSize.toFixed(2)}KB`
    });

    return {
      accounts,
      totalRecords: allData.length,
      totalSize
    };
  }

  async saveAccount(account: Account): Promise<void> {
    if (!account.dexieId) {
      throw new Error('Account missing dexieId')
    }
    await this.accounts.put(account)
  }

  async deleteAccount(dexieId: string): Promise<void> {
    const account = await this.getAccount(dexieId)
    if (!account) throw new Error('Account not found')

    if (account.isSampleAccount && process.env.NODE_ENV !== 'development') {
      throw new Error('Cannot delete sample account')
    }

    await this.accounts.delete(dexieId)
    await this.campaignData
      .where('dexieId')
      .equals(dexieId)
      .delete()
  }

  async addAccount(account: Account): Promise<Account> {
    if (!account.isSampleAccount) {
      const userAccounts = await this.accounts
        .filter(a => !a.isSampleAccount)
        .count()

      if (userAccounts >= MAX_FREE_ACCOUNTS) {
        throw new Error(`Free accounts limited to ${MAX_FREE_ACCOUNTS}`)
      }
    }

    // Create the account with all required fields
    const accountToAdd: Account = {
      ...account,
      id: account.accountCID,
      dexieId: account.accountCID,
      accountCID: account.accountCID
    }

    // Add account first
    await this.accounts.put(accountToAdd)

    // Don't initialize empty data - let the campaign context handle data fetching
    return accountToAdd
  }

  async updateAccount(account: Account, background = false): Promise<void> {
    if (!account.dexieId) {
      throw new Error('dexieId is required for account updates')
    }

    try {
      // Skip reading existing account - we don't need it for simple field updates
      // Just preserve system fields that shouldn't change
      const updatedAccount = {
        ...account,
        id: account.dexieId,
        dexieId: account.dexieId,
        accountCID: account.accountCID || account.dexieId,
        isSampleAccount: account.isSampleAccount ?? false,
        webAppUrl: account.webAppUrl
      }

      if (DEBUG) {
        console.log('DB Update:', {
          dexieId: updatedAccount.dexieId,
          updates: account,
          result: updatedAccount,
          changedFields: Object.keys(account)
        })
      }

      if (background) {
        this.debouncedUpdate(updatedAccount)
        return
      }

      await this.accounts.put(updatedAccount)

    } catch (err) {
      console.error('DB update failed:', err)
      throw err
    }
  }

  async inspectDB() {
    console.group('🔍 Dexie DB Inspection')
    try {
      const accounts = await this.accounts.toArray()
      console.log('Raw accounts table:', accounts)

      const campaignData = await this.campaignData.toArray()
      console.log('Raw campaign data:', campaignData)

      // Show any accounts with sample-account as dexieId
      const brokenAccounts = accounts.filter(a => a.dexieId === 'sample-account')
      if (brokenAccounts.length) {
        console.warn('⚠️ Found accounts with hardcoded sample-account ID:', brokenAccounts)
      }

      // Show actual Dexie-generated IDs
      console.log('All dexieIds:', accounts.map(a => ({
        dexieId: a.dexieId,
        name: a.name,
        accountCID: a.accountCID
      })))

    } catch (err) {
      console.error('Failed to inspect DB:', err)
    }
    console.groupEnd()
  }

  async loadSampleAccount(): Promise<Account> {
    const existing = await this.getAccount(SAMPLE_ACCOUNT_ID)
    if (existing) return existing

    const sampleAccount: Account = {
      id: SAMPLE_ACCOUNT_ID,
      dexieId: SAMPLE_ACCOUNT_ID,
      accountCID: SAMPLE_ACCOUNT_ID,
      name: 'Sample Account',
      isSampleAccount: true,
      businessMode: 'ecomm',
      currency: '$',
      cogsAmount: 55,
      breakEvenCpa: 33,
      webAppUrl: '/sample-data'
    }

    await this.accounts.add(sampleAccount)

    // Load sample data immediately to prevent errors
    try {
      const { loadSampleData } = await import('@/lib/sample-data-utils')
      const sampleData = await loadSampleData()
      await this.saveAccountData(SAMPLE_ACCOUNT_ID, sampleData)
      console.log('Sample data loaded successfully')
    } catch (err) {
      console.error('Failed to load initial sample data:', err)
    }

    return sampleAccount
  }

  async ensureSampleAccount(): Promise<Account> {
    try {
      const existing = await this.getAccount(SAMPLE_ACCOUNT_ID)
      if (existing) return existing

      // If no sample account exists, create one
      return await this.loadSampleAccount()
    } catch (err) {
      console.error('Failed to ensure sample account:', err)
      // Create a new sample account as fallback
      const sampleAccount: Account = {
        id: SAMPLE_ACCOUNT_ID,
        dexieId: SAMPLE_ACCOUNT_ID,
        accountCID: SAMPLE_ACCOUNT_ID,
        name: 'Sample Account',
        isSampleAccount: true,
        businessMode: 'ecomm',
        currency: '$',
        cogsAmount: 55,
        breakEvenCpa: 33,
        webAppUrl: '/sample-data'
      }

      // Add to DB
      await this.accounts.add(sampleAccount)
      return sampleAccount
    }
  }

  async getLastActiveAccount(): Promise<string | null> {
    const record = await this.metadata.get('lastActiveAccount');
    return record?.value || null;
  }

  async setLastActiveAccount(dexieId: string): Promise<void> {
    await this.metadata.put({
      key: 'lastActiveAccount',
      value: dexieId
    });
  }

  async updateAccountRefreshTime(dexieId: string): Promise<void> {
    const account = await this.accounts.get(dexieId);
    if (account) {
      await this.accounts.put({
        ...account,
        lastRefreshed: new Date().toISOString()
      });
    }
  }
}

export const db = new PMMaxDatabase();

// Export ready promise for easier initialization checking
export const dbReady = db.ready();