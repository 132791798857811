interface Testimonial {
    quote: string;
    author: string;
    role: string;
    image: string;
}

export const testimonials: Testimonial[] = [
    {
        quote: "After a few different people showed me the output of Mike's PMax script in their accounts, I knew I had to **get over my fear of Google Ads scripts** and try it out for myself. It was **surprisingly easy to set up** in my MCC, and now I use it for **all of my account audits**. I'm able to **quickly find insights** that I wouldn't have found otherwise, and make better recommendations for my clients. **Performance Max isn't such a black box anymore**! Any Google Ads practitioner worth their salt should 100% be using Mike's scripts.",
        author: "Jyll",
        role: "Google Ads Coach & Inside Google Ads founder",
        image: "/images/people/jyll.jpeg"
    },
    {
        quote: "Mike's workshop has **completely transformed** how we handle Google Ads data. His **QTS system** and hands-on examples turned what I thought was 'technical wizardry' into something I can **apply to every client**. We can now **uncover insights** in Performance Max that used to be buried, and we're using that data to **make strategic decisions faster** than ever. The knowledge and resources you gain will open the door to creating **automation tools that genuinely move the needle** for your clients.",
        author: "Craig",
        role: "Founder of Grayvault Consulting",
        image: "/images/people/craig.jpeg"
    },
    {
        quote: "Mike's Scripts & Sheets Mastery course has helped me **up my Google Ads game**, particularly when it comes to data analysis. The platform can be so clunky, and Mike's course gave me the understanding and tools to **pull my own data** from the platform super easily, and connect what I needed to in a sheet. If you know the what data you need from Google Ads, Mike's course will help you get it out **fast** - and help you turn it into **actionable insights** for your clients!",
        author: "Stacey",
        role: "Digital Marketing Director",
        image: "/images/people/stacey.jpeg"
    },
    {
        quote: "Signing up to the Scripts and Sheets Mastery workshop allowed me to grab **large amounts of specific data** from Google Ads and add it too a Google Sheet to make **better decisions** on client accounts. Before taking the course I was relying on other peoples scripts but now I have the **skillset to manage my own** unique scripts. Mike explains things clearly and thoroughly I really enjoyed this content.",
        author: "Jimmy",
        role: "Agency Owner",
        image: "/images/people/jimmy.jpeg"
    },
    {
        quote: "Using the techniques from the workshop, I've **built a production tool** that records and transcribes data, analyzes it with OpenAI, and presents everything in a dynamic Google Sheet. When I hit Sheet limits, I even managed to **integrate MySQL** using the prompting methods Mike taught. The workshop set me up for success in ways I didn't anticipate - I'm now **building tools I never thought possible**!",
        author: "Cam",
        role: "Founder, Head Coach & PPC Consultant",
        image: "/images/people/cam.jpeg"
    },
    {
        quote: "Mike's workshop and scripts have **completely changed** the way I work with Google Ads data. His **laid-back, friendly teaching style** makes even the most complex topics easy to understand and apply. Beyond being an exceptional teacher, Mike is **approachable and always willing to help**, making sure you never feel like you should already know something. The knowledge and resources you gain will empower you to build **automation tools that truly make a difference**—improving both client results and business efficiency.",
        author: "Isadora",
        role: "Freelance Digital Marketing Specialist",
        image: "/images/people/isadora.jpeg"
    },
    {
        quote: "I've known Mike for many years and have completed many of his training courses, he has a **laid back teaching style** that's both **friendly & easy to follow**. He's not afraid to **speak the truth** about the big 'G'. Mike is very **approachable** and always goes **above and beyond** with everything he puts out into the world. I'd recommend Mike any day of the week",
        author: "Peter",
        role: "The Online Retail Guy",
        image: "/images/people/peter.jpeg"
    },
    {
        quote: "As someone who had **never worked with scripts** before, I was initially intimidated. However, this course turned out to be one of the **best decisions** for my professional growth. The **hands-on approach** and **ready-to-use code** made it easy to follow along. If you've ever felt that scripting is out of your league, this course will **change your mind**. I highly recommend this to anyone looking to **level up** their Google Ads management.",
        author: "Rob",
        role: "Google Ads Expert for Shopify Stores",
        image: "/images/people/rob.jpeg"
    },
    {
        quote: "The Scripts & Sheets Mastery course from Mike was **absolutely amazing**! I already had some scripting knowledge, but his approach made everything **so much simpler**. His **QTS system** turned something that seemed really complicated into something easy to understand. After completing the course, I **built a custom KPI reporting platform** for our team, a scoring script, and even went a step further by **soft-launching my production-ready tool**.",
        author: "Alfred",
        role: "Senior Digital Advertising Marketeer at Adwise",
        image: "/images/people/alfred.jpeg"
    },
    {
        quote: "Mike delivers **so much value** in the Scripts & Sheets program your head will explode. Things that you once said you want to learn one day will seem **easy**. So, If you put the work in you will get **10x the cost back easily**. Not only during the course, but ongoing as a member of his **circle community**. Get on this now.",
        author: "Ant",
        role: "Owner | Founder",
        image: "/images/people/ant.jpeg"
    },
    {
        quote: "If you are running shopping or pmax campaigns and **not using Mike's PMax script....you are blowing it**. Beyond the pmax insights (which are a **must**) it gives you **product-level data** that gives value to any e-commerce campaign no matter the campaign type. I have it in **all my accounts**.",
        author: "Joey",
        role: "Freelance Google Ads Manager",
        image: "/images/people/joey.jpeg"
    }
]; 