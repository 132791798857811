import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

export default function Privacy() {
  return (
    <div className="container mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle>Privacy Policy</CardTitle>
        </CardHeader>
        <CardContent className="prose">
          <p className="text-muted-foreground mb-6">Last updated: {new Date().toLocaleDateString()}</p>

          <div className="space-y-6">
            <section>
              <h2 className="text-xl font-semibold mb-3">Google User Data Collection</h2>
              <p>
                We collect the following Google user data through our application:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Google Ads campaign data accessed through our script in your Google Sheet</li>
                <li>Basic Google account information for authentication purposes</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Data Usage</h2>
              <p>
                Your Google user data is used exclusively for:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Analyzing and optimizing your Google Ads performance within our application</li>
                <li>Providing personalized recommendations to improve your advertising results</li>
                <li>Maintaining and improving our service functionality</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Data Sharing and Transfer</h2>
              <p>
                We do not sell, rent, or trade your Google user data with any third parties. Your data may be shared with:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Clerk (our authentication provider) - receives only basic account information necessary for authentication</li>
                <li>Your own Google Sheets - where your campaign data is stored under your control</li>
                <li>Fathom Analytics - receives only anonymous usage data for improving our service</li>
              </ul>
              <p className="mt-2">
                Any sharing of data is strictly limited to what is necessary for the functioning of our service and is protected by appropriate data protection agreements.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Data Protection</h2>
              <p>
                We implement industry-standard security measures to protect your Google user data:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>End-to-end encryption for all data transmission</li>
                <li>Secure authentication and access controls</li>
                <li>Regular security audits and updates</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Data Retention and Deletion</h2>
              <p>
                Our data retention and deletion policies are as follows:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>We retain your Google user data only for as long as necessary to provide our service</li>
                <li>You can request deletion of your data at any time by contacting us at privacy@8020.agency</li>
                <li>Upon account deletion, all associated Google user data is permanently removed from our systems within 30 days</li>
                <li>We retain certain data as required by law, but only for the duration specified by applicable regulations</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Your Rights</h2>
              <p>
                You have the following rights regarding your Google user data:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Access your data at any time through your account dashboard</li>
                <li>Request a copy of your data</li>
                <li>Request deletion of your data</li>
                <li>Revoke access to your Google account at any time through Google's security settings</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
              <p>
                For any privacy-related questions or to exercise your data rights, please contact us at:
                <br />
                support@8020agent.com
              </p>
            </section>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}