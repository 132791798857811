export const formatQualityComponent = (value: string | null): string => {
    if (!value) return 'N/A'

    switch (value.toUpperCase()) {
        case 'ABOVE_AVERAGE':
            return 'Above Avg'
        case 'BELOW_AVERAGE':
            return 'Below Avg'
        case 'AVERAGE':
            return 'Average'
        default:
            return value
    }
}

export const getQualityComponentColor = (value: string | null): string => {
    if (!value) return 'text-gray-400'

    switch (value.toUpperCase()) {
        case 'ABOVE AVG':
            return 'text-green-600 dark:text-green-500'
        case 'BELOW AVG':
            return 'text-red-600 dark:text-red-500'
        case 'AVERAGE':
            return 'text-yellow-600 dark:text-yellow-500'
        default:
            return 'text-gray-600 dark:text-gray-400'
    }
}

export const getQualityScoreColor = (score: number | null): string => {
    if (!score) return 'text-gray-400'

    if (score >= 8) return 'text-green-600 dark:text-green-500'
    if (score >= 5) return 'text-yellow-600 dark:text-yellow-500'
    return 'text-red-600 dark:text-red-500'
} 