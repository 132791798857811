import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import { SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { Button } from "@/components/ui/button";
import { Settings, HelpCircle, ArrowLeft } from "lucide-react";
import { ThemeToggle } from "@/components/theme-toggle";
import { useUser } from "@clerk/clerk-react";
import { salesNav } from "@/config/menu";

export function SalesNavbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();

  // Get level from user metadata if available, otherwise default to 0
  const currentGameLevel = (user?.unsafeMetadata?.currentGameLevel as number) ?? 0;

  // Function to get the level display text
  const getLevelText = () => {
    if (currentGameLevel >= 10) return "Mastery!";
    return `Level ${currentGameLevel + 1}`;
  };

  return (
    <header className="sticky top-0 z-40 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 items-center">
        <div className="flex items-center space-x-6">
          {/* Back button with hover effect */}
          <Button
            variant="ghost"
            onClick={() => navigate('/')}
            className="gap-2 mr-6"
          >
            <ArrowLeft className="h-4 w-4" />
            Back to 8020agent
          </Button>

          {/* Navigation items with enhanced hover effects */}
          <nav className="flex items-center space-x-6 text-sm font-medium">
            {salesNav.map((item) => (
              <Link
                key={item.title}
                to={item.href}
                className={cn(
                  "relative py-1 transition-all duration-200",
                  "hover:text-foreground hover:translate-y-[-1px]",
                  "active:translate-y-[1px]",
                  location.pathname === item.href
                    ? [
                      "text-foreground",
                      "after:absolute after:bottom-0 after:left-0 after:right-0",
                      "after:h-[2px] after:bg-foreground",
                      "after:transition-all after:duration-200"
                    ].join(" ")
                    : "text-foreground/60"
                )}
              >
                {item.title}
              </Link>
            ))}
          </nav>
        </div>

        <div className="flex items-center space-x-4 ml-auto">
          <ThemeToggle />

          {/* Help & Feedback icon with enhanced hover effect */}
          <Link
            to="/help"
            className={cn(
              "p-2 rounded-md transition-all duration-200",
              "hover:translate-y-[-1px] active:translate-y-[1px]",
              location.pathname === '/help'
                ? "bg-accent text-accent-foreground"
                : "hover:bg-accent/50"
            )}
            title="Help & Feedback"
          >
            <HelpCircle className="h-5 w-5" />
          </Link>

          {/* Settings icon with enhanced hover effect */}
          <Link
            to="/settings"
            className={cn(
              "p-2 rounded-md transition-all duration-200",
              "hover:translate-y-[-1px] active:translate-y-[1px]",
              location.pathname === '/settings'
                ? "text-accent-foreground"
                : "hover:bg-accent/50"
            )}
            title="Settings"
          >
            <Settings className="h-5 w-5" />
          </Link>

          {/* Auth buttons */}
          <SignedOut>
            <Button variant="outline" asChild>
              <Link to="/sign-in">Sign In</Link>
            </Button>
          </SignedOut>
          <div className="relative z-9999 mb-[-20px]">
            <SignedIn>
              <UserButton
                appearance={{
                  elements: {
                    avatarBox: "w-8 h-8"
                  }
                }}
              />
            </SignedIn>
          </div>
        </div>
      </div>
    </header>
  );
} 