// src/components/version-gate.tsx
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { PAGE_VERSION_REQUIREMENTS } from "@/lib/constants"
import { useVersionCheck } from "@/hooks/use-version-check"

const SETUP_INSTRUCTIONS = [
  'Create a new template from the latest version',
  'Download the new script (you can replace the existing 8020 script)',
  'Remove any existing schedule from the old script',
  'Deploy the new web app',
  'Paste the new web app URL in the setup page'
] as const;


type PageName = keyof (typeof PAGE_VERSION_REQUIREMENTS)['pages']
type FeatureName = keyof (typeof PAGE_VERSION_REQUIREMENTS)['features']

interface VersionGateProps {
  name: PageName | FeatureName
  type: 'page' | 'feature'
  children: React.ReactNode
  scriptVersion?: string
}

export function VersionGate({
  name,
  type = 'page',
  children,
  scriptVersion
}: VersionGateProps) {
  const {
    needsUpdate,
    currentVersion,
    minVersion,
    label,
    deleteAccount,
    isPmax
  } = useVersionCheck(name, type, scriptVersion)

  // Allow access for pmax accounts
  if (isPmax || !needsUpdate) {
    return <>{children}</>
  }

  return (
    <div className="container mx-auto py-6">
      <Alert variant="destructive" className="mb-6">
        <AlertTitle className="mb-4">Script Update Required</AlertTitle>
        <AlertDescription>
          <p className="mb-4">
            {type === 'feature'
              ? `The ${label || name} feature requires`
              : 'This page requires'} the Google Ads script to be
            version {minVersion} or higher. You're currently using
            version {currentVersion}.
          </p>
          <div className="space-y-2 mb-6">
            <p className="font-medium">To update:</p>
            <ol className="list-decimal list-inside space-y-1">
              {SETUP_INSTRUCTIONS.map((instruction, i) => (
                <li key={i}>{instruction}</li>
              ))}
            </ol>
          </div>
          <div className="flex">
            <Button onClick={deleteAccount}>
              Delete Account & Set Up New Version
            </Button>
          </div>
        </AlertDescription>
      </Alert>
    </div>
  )
}