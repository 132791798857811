import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { SearchTermData } from "@/types/metrics"
import { TablePagination } from "@/components/ui/table-pagination"
import { ArrowUp, ArrowDown } from "lucide-react"
import { cn } from '@/lib/utils'

interface ColumnDef {
  key: string
  label: string
  width?: string
  isNumeric?: boolean
  cell?: (row: SearchTermData) => React.ReactNode
}

interface SearchTableProps {
  data: SearchTermData[]
  visibleColumns: Set<string>
  sort: {
    field: string
    direction: 'asc' | 'desc'
  }
  onSortChange: (field: string) => void
  currentPage: number
  rowsPerPage: number
  onPageChange: (page: number) => void
  accountCurrency: string
}

const DEFAULT_COLUMNS: ColumnDef[] = [
  {
    key: 'campaign',
    label: 'Campaign',
    width: 'w-[240px]',
    cell: (row: SearchTermData) => (
      <div className="max-w-[240px]">
        <div className="line-clamp-2">{row.campaign}</div>
      </div>
    )
  },
  {
    key: 'searchMatchType',
    label: 'Match Type',
    width: 'w-[100px]'
  },
  {
    key: 'searchTerm',
    label: 'Search Term',
    width: 'w-[240px]',
    cell: (row: SearchTermData) => (
      <div className="max-w-[240px]">
        <div className="truncate" title={row.searchTerm}>
          {row.searchTerm.length > 50 ? `${row.searchTerm.slice(0, 50)}...` : row.searchTerm}
        </div>
      </div>
    )
  },
  { key: 'impr', label: 'Impr.', width: 'w-[80px]', isNumeric: true },
  { key: 'clicks', label: 'Clicks', width: 'w-[80px]', isNumeric: true },
  { key: 'CTR', label: 'CTR', width: 'w-[80px]', isNumeric: true },
  { key: 'cost', label: 'Cost', width: 'w-[80px]', isNumeric: true },
  { key: 'CPC', label: 'CPC', width: 'w-[80px]', isNumeric: true },
  { key: 'conv', label: 'Conv.', width: 'w-[80px]', isNumeric: true },
  { key: 'CvR', label: 'CvR', width: 'w-[80px]', isNumeric: true },
  { key: 'value', label: 'Value', width: 'w-[80px]', isNumeric: true },
  { key: 'ROAS', label: 'ROAS', width: 'w-[80px]', isNumeric: true },
  { key: 'CPA', label: 'CPA', width: 'w-[80px]', isNumeric: true }
]

export function SearchTable({
  data,
  visibleColumns,
  sort,
  onSortChange,
  currentPage,
  rowsPerPage,
  onPageChange,
  accountCurrency
}: SearchTableProps) {
  // Format metric value based on type
  const formatValue = (key: string, value: any): string => {
    if (value === null || value === undefined) return 'N/A'

    switch (key) {
      case 'CTR':
      case 'CvR':
        return `${value.toFixed(1)}%`
      case 'cost':
      case 'CPC':
      case 'value':
      case 'CPA':
        return `${accountCurrency}${value.toFixed(2)}`
      case 'conv':
      case 'ROAS':
        return value.toFixed(1)
      case 'searchMatchType':
        switch (value?.toUpperCase()) {
          case 'EXACT':
            return 'Exact'
          case 'PHRASE':
            return 'Phrase'
          case 'BROAD':
            return 'Broad'
          case 'NEAR_PHRASE':
            return 'Near Phrase'
          case 'NEAR_EXACT':
            return 'Near Exact'
          default:
            return value || 'N/A'
        }
      default:
        return typeof value === 'number' ? value.toLocaleString() : value
    }
  }

  const startIndex = (currentPage - 1) * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const paginatedData = data.slice(startIndex, endIndex)

  return (
    <div className="space-y-4">
      <div className="rounded-md border dark:border-gray-800">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-transparent border-b-2 dark:border-gray-700">
              {DEFAULT_COLUMNS.map(column => (
                visibleColumns.has(column.key) && (
                  <TableHead
                    key={column.key}
                    className={cn(
                      column.width,
                      column.isNumeric ? 'text-right' : '',
                      'text-foreground font-semibold bg-gray-100 dark:bg-gray-800/80'
                    )}
                    onClick={() => onSortChange(column.key)}
                  >
                    <div className={cn(
                      "flex items-center gap-1 cursor-pointer hover:bg-gray-200 rounded px-1",
                      column.isNumeric ? "justify-end" : "justify-start",
                      "dark:hover:bg-gray-700/80"
                    )}>
                      <span>{column.label}</span>
                      {sort.field === column.key && (
                        sort.direction === 'asc' ?
                          <ArrowUp className="h-3 w-3" /> :
                          <ArrowDown className="h-3 w-3" />
                      )}
                    </div>
                  </TableHead>
                )
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.map((row, rowIndex) => (
              <TableRow
                key={`${row.campaign}-${row.searchTerm}-${rowIndex}`}
                className={cn(
                  "h-[2.5rem] max-h-[2.5rem]",
                  "hover:bg-accent/50 dark:hover:bg-gray-800/50"
                )}
              >
                {DEFAULT_COLUMNS.map(column => (
                  visibleColumns.has(column.key) && (
                    <TableCell
                      key={column.key}
                      className={cn(
                        "py-2 overflow-hidden",
                        column.isNumeric ? "text-right" : "",
                        "text-foreground"
                      )}
                    >
                      {column.cell ? (
                        column.cell(row)
                      ) : (
                        formatValue(column.key, row[column.key as keyof SearchTermData])
                      )}
                    </TableCell>
                  )
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <TablePagination
        totalItems={data.length}
        pageSize={rowsPerPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  )
}