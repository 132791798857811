// src/components/profit-analysis/chart.tsx
import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip,
  ResponsiveContainer, ReferenceLine, ReferenceArea } from 'recharts';
import { ChartThumbnail } from "@/components/chart-thumbnail";
import type { ChartType, OptimalZone, ProfitDataPoint, IncrementalDataPoint, BusinessMode } from '@/types/metrics';

// Define the IncrementalData type locally since it matches the return type from calculateIncrementalData
interface IncrementalData {
  cost: number;
  incrementalProfit: number;
  incrementalSales: number;
  incrementalROAS: number;
}

interface ChartProps {
  activeChart: ChartType;
  data: ProfitDataPoint[];
  incrementalData: IncrementalDataPoint[];
  currentCost: number;
  optimalZone: OptimalZone;
  currentProfit: number;
  selectedCurrency: string;
  onChartTypeChange: (type: ChartType) => void;
  businessMode: BusinessMode;
}

// Format large numbers with k/M/B suffixes
function formatNumber(value: number): string {
  const absValue = Math.abs(value);
  const sign = value < 0 ? '-' : '';
  
  if (absValue >= 1e9) return `${sign}${(absValue / 1e9).toFixed(1)}B`;
  if (absValue >= 1e6) return `${sign}${(absValue / 1e6).toFixed(1)}M`;
  if (absValue >= 1e3) return `${sign}${(absValue / 1e3).toFixed(1)}k`;
  return `${sign}${absValue.toFixed(0)}`;
}

// Chart config interface
interface ChartConfig {
  dataKey: string
  stroke: string
  label: string
  tooltipFormatter: (value: number) => string
  xAxisDataKey?: string
  xAxisFormatter?: (value: number) => string
}

// Get chart configuration based on type and mode
function getChartConfig(chartType: ChartType, currency: string, businessMode: BusinessMode): ChartConfig {
  const isEcomm = businessMode === 'ecomm'

  const configs: Record<ChartType, ChartConfig> = {
    'profit-curve': {
      dataKey: 'profit',
      stroke: '#2563eb',
      label: 'Profit',
      tooltipFormatter: (value: number) => `${currency}${formatNumber(value)}`
    },
    'incremental-profit': {
      dataKey: 'incrementalProfit',
      stroke: '#6366f1',
      label: 'Incremental Profit',
      tooltipFormatter: (value: number) => `${currency}${formatNumber(value)}`
    },
    'profit-vs-roas': {
      dataKey: isEcomm ? 'roas' : 'cpa',
      stroke: '#8b5cf6',
      label: isEcomm ? 'ROAS' : 'CPA',
      tooltipFormatter: (value: number) => isEcomm ? `${value.toFixed(1)}x` : `${currency}${formatNumber(value)}`,
      xAxisDataKey: 'profit',
      xAxisFormatter: (value: number) => formatNumber(value)
    },
    'profit-vs-cpa': {
      dataKey: 'cpa',
      stroke: '#8b5cf6',
      label: 'CPA',
      tooltipFormatter: (value: number) => `${currency}${formatNumber(value)}`,
      xAxisDataKey: 'profit',
      xAxisFormatter: (value: number) => formatNumber(value)
    },
    'marginal-roas': {
      dataKey: isEcomm ? 'marginalRoas' : 'marginalCpa',
      stroke: '#ec4899',
      label: isEcomm ? 'Marginal ROAS' : 'Marginal CPA',
      tooltipFormatter: (value: number) => isEcomm ? `${value.toFixed(1)}x` : `${currency}${formatNumber(value)}`,
      xAxisDataKey: 'cost',
      xAxisFormatter: (value: number) => formatNumber(value)
    },
    'marginal-cpa': {
      dataKey: 'marginalCpa',
      stroke: '#ec4899',
      label: 'Marginal CPA',
      tooltipFormatter: (value: number) => `${currency}${formatNumber(value)}`,
      xAxisDataKey: 'cost',
      xAxisFormatter: (value: number) => formatNumber(value)
    }
  }

  return configs[chartType]
}

// Fix tooltip value type issues
interface TooltipPayload {
  value: number
  dataKey: string
}

export function ProfitAnalysisChart({
  activeChart,
  data,
  incrementalData,
  currentCost,
  optimalZone,
  currentProfit,
  selectedCurrency,
  onChartTypeChange,
  businessMode
}: ChartProps) {
  const isEcomm = businessMode === 'ecomm';

  // Map the chart type based on business mode
  const actualChartType = useMemo(() => {
    if (activeChart === 'profit-vs-roas' && !isEcomm) {
      return 'profit-vs-cpa' as ChartType;
    }
    if (activeChart === 'marginal-roas' && !isEcomm) {
      return 'marginal-cpa' as ChartType;
    }
    return activeChart;
  }, [activeChart, isEcomm]);

  const config = getChartConfig(actualChartType, selectedCurrency, businessMode);

  // Find nearest cost value for reference line
  const findNearestCostValue = (data: any[], targetCost: number): number => {
    if (!data?.length) return 0;
    return data.reduce((prev, curr) => 
      Math.abs(curr.cost - targetCost) < Math.abs(prev.cost - targetCost) ? curr : prev
    ).cost;
  };

  const nearestCost = findNearestCostValue(data, currentCost);

  const getChartTitle = () => {
    switch (actualChartType) {
      case 'profit-curve':
        return 'Profit Curve';
      case 'incremental-profit':
        return 'Incremental Profit';
      case 'profit-vs-roas':
        return isEcomm ? 'Profit vs ROAS' : 'Profit vs CPA';
      case 'profit-vs-cpa':
        return 'Profit vs CPA';
      case 'marginal-roas':
        return isEcomm ? 'Marginal ROAS' : 'Marginal CPA';
      case 'marginal-cpa':
        return 'Marginal CPA';
      default:
        return '';
    }
  };

  const chartData = actualChartType === 'incremental-profit' ? incrementalData : data;

  return (
    <div className="h-full">
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 20, bottom: 5, left: 20 }}
            className="text-foreground"
          >
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke="currentColor"
              opacity={0.1}
            />
            <XAxis
              dataKey={config.xAxisDataKey || "cost"}
              tickFormatter={config.xAxisFormatter || formatNumber}
              stroke="currentColor"
              domain={['dataMin', 'dataMax']}
              padding={{ left: 20, right: 20 }}
              style={{ fontSize: 9 }}
            />
            <YAxis
              tickFormatter={config.tooltipFormatter}
              stroke="currentColor"
              width={50}
              domain={['auto', 'auto']}
              allowDataOverflow={false}
              style={{ fontSize: 9 }}
            />
            <RechartsTooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  const typedPayload = payload as TooltipPayload[]
                  return (
                    <div className="p-2 bg-background border border-border rounded-lg">
                      {config.xAxisDataKey ? (
                        <>
                          <p>{config.label}: {config.tooltipFormatter(typedPayload[0].value)}</p>
                          <p>Cost: {config.xAxisFormatter ? config.xAxisFormatter(Number(label)) : formatNumber(Number(label))}</p>
                        </>
                      ) : (
                        <>
                          <p>Cost: {selectedCurrency}{formatNumber(Number(label))}</p>
                          <p>{config.label}: {config.tooltipFormatter(typedPayload[0].value)}</p>
                        </>
                      )}
                    </div>
                  )
                }
                return null
              }}
            />
            <ReferenceArea
              x1={optimalZone.start}
              x2={optimalZone.end}
              fill="#22c55e"
              fillOpacity={0.1}
            />
            <ReferenceLine
              x={nearestCost}
              stroke="hsl(var(--foreground))"
              strokeWidth={2}
              strokeDasharray="3 3"
              label={{
                value: "Current Cost",
                position: "top",
                fill: "hsl(var(--foreground))",
                fontSize: 12,
              }}
              isFront={true}
            />
            <ReferenceLine
              y={0}
              stroke="hsl(var(--foreground))"
              strokeWidth={2}
              strokeDasharray="3 3"
              isFront={true}
            />
            <text
              x="95%"
              y="5%"
              textAnchor="end"
              className={`font-semibold ${currentProfit > 0 ? 'fill-green-500' : 'fill-red-500'}`}
            >
              {selectedCurrency}{Math.round(currentProfit).toLocaleString()}
            </text>
            <Line
              type="monotone"
              dataKey={config.dataKey}
              stroke={config.stroke}
              dot={false}
              strokeWidth={2}
              animationDuration={0}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>


      {/* TODO: Fix logic for incremental profit, profit vs ROAS, and marginal ROAS charts before re-enabling thumbnails 
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
        <ChartThumbnail
          title="Profit Curve"
          isActive={activeChart === 'profit-curve'}
          onClick={() => onChartTypeChange('profit-curve')}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <Line type="monotone" dataKey="profit" stroke="#8884d8" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </ChartThumbnail>
        <ChartThumbnail
          title="Incremental Profit"
          isActive={activeChart === 'incremental-profit'}
          onClick={() => onChartTypeChange('incremental-profit')}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={incrementalData}>
              <Line type="monotone" dataKey="incrementalProfit" stroke="#22c55e" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </ChartThumbnail>
        <ChartThumbnail
          title={isEcomm ? "Profit vs ROAS" : "Profit vs CPA"}
          isActive={activeChart === 'profit-vs-roas'}
          onClick={() => onChartTypeChange('profit-vs-roas')}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <Line 
                type="monotone" 
                dataKey={isEcomm ? "roas" : "cpa"} 
                stroke="#8b5cf6" 
                dot={false} 
              />
            </LineChart>
          </ResponsiveContainer>
        </ChartThumbnail>
        <ChartThumbnail
          title={isEcomm ? "Marginal ROAS" : "Marginal CPA"}
          isActive={activeChart === 'marginal-roas'}
          onClick={() => onChartTypeChange('marginal-roas')}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <Line 
                type="monotone" 
                dataKey={isEcomm ? "marginalRoas" : "marginalCpa"} 
                stroke="#ef4444" 
                dot={false} 
              />
            </LineChart>
          </ResponsiveContainer>
        </ChartThumbnail>
      </div>
      */}

      
    </div>
  );
}