import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { trackEvent, ANALYTICS_EVENTS } from '@/lib/analytics'

export default function HelpPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Story & Vision Section */}
      <Card className="mb-8 bg-gradient-to-r from-blue-50 to-purple-50 dark:from-blue-950 dark:to-purple-950">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-3xl">Why I Created 8020agent</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <p className="text-lg">
            In 2025, there will be two types of businesses: those still using AI like it's 2022, and those running what looks like magic to everyone else.
          </p>
          <p className="text-lg">
            I created 8020agent to help you be in the second group.
          </p>
          <Link 
            to="/why" 
            onClick={() => trackEvent(ANALYTICS_EVENTS.HELP.STORY_VIEW())}
            className="inline-block font-bold text-primary hover:underline"
          >
            Read the full story →
          </Link>
        </CardContent>
      </Card>

      <div className="flex items-center justify-between mb-6">
        <h1 className="text-3xl font-bold">Help & Support</h1>
        <Button
          variant="default"
          size="lg"
          asChild
          onClick={() => trackEvent(ANALYTICS_EVENTS.HELP.FEEDBACK_CLICK())}
        >
          <Link to="/feedback?returnTo=/help">Please give feedback!</Link>
        </Button>
      </div>

      {/* Introduction Section */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
        <p className="text-lg">
          Welcome to 8020agent! This application helps you optimize the most important 80/20 of your Google Ads
          <br />
          campaigns by providing insights and recommendations based on your performance data.
        </p>
      </section>

      {/* Getting Started Guide */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Getting Started</h2>
        <ol className="list-decimal list-inside text-lg">
          <li>Create an account or sign in to access the full features of the app.</li>
          <li>Configure your Google Ads integration in the <Link to="/settings" className="text-primary">Settings</Link> page.</li>
          <li>Start analyzing your campaign data on the <Link to="/analysis" className="text-primary">Analysis</Link> page.</li>
        </ol>

        {/* <Button 
        
        TODO: add back in
        
          variant="link" 
          className="mt-4" 
          onClick={() => {
            trackEvent(ANALYTICS_EVENTS.HELP.TUTORIAL_VIEW());
            window.open('https://example.com/tutorial', '_blank');
          }}
        >
          Watch the Setup Tutorial (coming soon)
        </Button> */}
      </section>

      {/* Detailed Page Guides */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Page Guides</h2>

        {/* Analysis Page Guide */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Analysis Page</h3>
          <p className="text-lg">
            The Analysis page provides detailed insights into your campaign performance. Here, you can:
          </p>
          <ul className="list-disc list-inside text-lg">
            <li>View key metrics like impressions, clicks, and conversions.</li>
            <li>Use graphs to visualize performance trends over time.</li>
            <li>Compare current performance with previous periods.</li>
            <li>Receive AI-driven optimization suggestions.</li>
          </ul>
        </div>

        {/* Curve Page Guide */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Curve Page</h3>
          <p className="text-lg">
            The Curve page helps you visualize and optimize your profit curves. Features include:
          </p>
          <ul className="list-disc list-inside text-lg">
            <li>Graphical representation of profit against various metrics.</li>
            <li>Break-even analysis to identify revenue and cost balance points.</li>
            <li>Scenario testing to simulate different outcomes.</li>
          </ul>
        </div>
      </section>

      {/* Common Use Cases */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Common Use Cases</h2>
        <p className="text-lg">
          Here are some common tasks you can perform with the app:
        </p>
        <ul className="list-disc list-inside text-lg">
          <li>Optimize a campaign by adjusting budgets and bids based on analysis insights.</li>
          <li>Analyze performance trends to make data-driven decisions.</li>
          <li>Use the curve page to find the optimal profit point for your campaigns.</li>
        </ul>
      </section>

      {/* Troubleshooting */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Troubleshooting</h2>
        <p className="text-lg">
          If you encounter any issues, visit our <a href="https://mikerhodes.circle.so/c/help/" target="_blank" rel="noopener noreferrer" className="text-primary">Troubleshooting</a> page for assistance.
        </p>
      </section>

      {/* Feedback */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Feature Requests & Feedback</h2>
        <p className="text-lg">
          We're constantly improving 8020agent based on your feedback. Help us prioritize new features by visiting our{' '}
          <Link
            to="/feedback?returnTo=/help"
            className="text-primary hover:underline"
          >
            feedback page
          </Link>.
        </p>
      </section>

      {/* Interactive Learning */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Interactive Learning</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <p className="text-lg mb-4">
              Take the interactive challenge to make you less scared about using scripts & see what they can do.
            </p>
            <Button 
              variant="default" 
              className="text-xl font-bold py-3 px-6"
              asChild 
              onClick={() => trackEvent(ANALYTICS_EVENTS.HELP.CHALLENGE_START())}
            >
              <Link to="/scary">Start The Scary Script Challenge</Link>
            </Button>
            <p className="text-lg mt-4">
              This is a 14 minute script challenge to boost your confidence with automation.
              <br />
              Once you've completed it, you'll be able to confidently automate your Google Ads campaigns.
              <br />
              And scripts won't be scary anymore.
            </p>
          </div>

          <div>
            <p className="text-lg mb-4">
              Learn how to optimize your campaigns with our interactive profit curve visualization.
            </p>
            <Button 
              variant="default" 
              className="text-xl font-bold py-3 px-6"
              asChild 
              onClick={() => trackEvent(ANALYTICS_EVENTS.HELP.CURVE_STORY())}
            >
              <Link to="/story">Explore The Profit Curve Story</Link>
            </Button>
            <p className="text-lg mt-4">
              This interactive visualization will help you understand:
              <br />
              • How to find your optimal CPA
              <br />
              • When to scale up or down
              <br />
              • The relationship between volume and profit
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}