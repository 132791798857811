import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowLeft, ListChecks, AlertCircle, Lightbulb } from "lucide-react";
import { useGameState } from "@/contexts/game-state";
import { levels } from "@/lib/script-levels";

export default function ScriptHelp() {
    const navigate = useNavigate();
    const { currentGameLevel } = useGameState();
    const currentLevel = levels[currentGameLevel];

    return (
        <div className="max-w-4xl mx-auto p-6 space-y-6">
            <Card>
                <CardHeader>
                    <CardTitle className="text-3xl">Don't Worry - Let's Get Your Script Working!</CardTitle>
                </CardHeader>
                <CardContent className="space-y-8">
                    <div className="prose prose-lg">
                        <p>
                            Not seeing what you expected? No problem! Most script issues have simple solutions.
                            Let's walk through the most common fixes.
                        </p>
                    </div>

                    <div className="grid gap-6">
                        <Card className="bg-muted/50">
                            <CardContent className="pt-6">
                                <div className="flex items-start gap-4">
                                    <ListChecks className="h-6 w-6 text-blue-500 mt-1" />
                                    <div>
                                        <h3 className="font-semibold text-lg mb-2">Quick Fixes (Try These First)</h3>
                                        <ul className="space-y-2 text-muted-foreground">
                                            <li>• Click "Preview" (not "Run") as it's easier to see what's happening and wait 30 seconds</li>
                                            <li>• Check you're in a Google Ads account that has campaigns running</li>
                                            <li>• Delete any old script code before pasting new code</li>
                                        </ul>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <Card className="bg-muted/50">
                            <CardContent className="pt-6">
                                <div className="flex items-start gap-4">
                                    <AlertCircle className="h-6 w-6 text-orange-500 mt-1" />
                                    <div>
                                        <h3 className="font-semibold text-lg mb-2">Account Requirements</h3>
                                        <ul className="space-y-2 text-muted-foreground">
                                            <li>• Need at least one active search campaign (for most of the scripts)</li>
                                            <li>• Use the same Google account for ads and sheets</li>
                                            <li>• Check you have at least standard access to the sheets</li>
                                            <li>• Make sure it's an individual account, not an MCC</li>
                                        </ul>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <Card className="bg-muted/50">
                            <CardContent className="pt-6">
                                <div className="flex items-start gap-4">
                                    <Lightbulb className="h-6 w-6 text-yellow-500 mt-1" />
                                    <div>
                                        <h3 className="font-semibold text-lg mb-2">Get AI Help</h3>
                                        <p className="text-muted-foreground">
                                            Still stuck? AI can help! 
                                            <br />
                                            Take a screenshot of any error message and visit claude.ai or chat.openai.com.
                                            <br />
                                            Ask "Why isn't this Google Ads script working?" and include what you were 
                                            trying to do. AI helpers are great at spotting common script issues!
                                        </p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    <div className="flex justify-between items-center pt-4">
             
                        <Button
                            onClick={() => {
                                navigate('/nle');
                            }}
                            className="gap-2"
                        >
                            Go back to the Script Challenge
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
} 