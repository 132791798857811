// src/components/layouts/sales-layout.tsx
import { Outlet } from 'react-router-dom';
import { SalesNavbar } from '@/components/navigation/sales-navbar';
import ErrorBoundary from '@/components/error-boundary';
import { Toaster } from '@/components/ui/toaster';
import { Footer } from '@/components/navigation/footer';

export default function SalesLayout() {
  return (
    <ErrorBoundary>
      <div className="relative min-h-screen bg-background font-sans antialiased">
        <div className="relative flex min-h-screen flex-col">
          <SalesNavbar />
          <main className="flex-1 container mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 py-8">
            <Outlet />
          </main>
          <Footer />
        </div>
        <Toaster />
      </div>
    </ErrorBoundary>
  );
} 