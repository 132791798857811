import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import { Ghost } from "lucide-react"

export default function NotFound() {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] px-4">
      <Ghost className="h-24 w-24 text-muted-foreground animate-float mb-8" />
      <h1 className="text-4xl font-bold mb-4">404</h1>
      <p className="text-xl text-muted-foreground mb-8 text-center">
        Oops! Looks like this page got lost in the cloud...
      </p>
      <div className="flex gap-4">
        <Button onClick={() => navigate(-1)}>Go Back</Button>
        <Button variant="secondary" onClick={() => navigate("/")}>
          Home
        </Button>
      </div>
    </div>
  )
}