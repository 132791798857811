import { useState, useEffect, useRef } from 'react'
import { X, Maximize2, Minimize2 } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'

type Language = 'en' | 'it' | 'fr' | 'es' | 'nl' | 'de'

interface Message {
    role: 'user' | 'assistant'
    content: string
}

interface ChatWindowProps {
    onClose: () => void
    isOpen: boolean
    onSendMessage: (message: string) => Promise<void>
    messages: Message[]
    isLoading?: boolean
    suggestedQuestions?: string[]
    language: Language
    onLanguageChange: (language: Language) => void
}

const LANGUAGE_FLAGS: Record<Language, string> = {
    en: '🇬🇧',
    it: '🇮🇹',
    fr: '🇫🇷',
    es: '🇪🇸',
    nl: '🇳🇱',
    de: '🇩🇪'
}

export function ChatWindow({
    onClose,
    isOpen,
    onSendMessage,
    messages,
    isLoading,
    suggestedQuestions = [
        "Explain this data to me",
        "What questions should I ask about this data?"
    ],
    language,
    onLanguageChange
}: ChatWindowProps) {
    const [input, setInput] = useState('')
    const [isExpanded, setIsExpanded] = useState(false)
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!input.trim() || isLoading) return

        const message = input
        setInput('')
        await onSendMessage(message)
    }

    const handleSuggestedQuestion = async (question: string) => {
        if (isLoading) return
        await onSendMessage(question)
    }

    return (
        <Card className={cn(
            "fixed bottom-4 right-4 flex flex-col p-4 transition-all duration-200 ease-in-out z-50",
            isExpanded ? "w-[800px] h-[800px]" : "w-[400px] h-[600px]",
            isOpen
                ? "translate-y-0 opacity-100"
                : "translate-y-4 opacity-0 pointer-events-none"
        )}>
            {/* Header */}
            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                    <img
                        src="/images/fredo.png"
                        alt="Fredo"
                        className="w-8 h-8 rounded-full"
                    />
                    <h3 className="font-semibold">Fredo</h3>
                </div>
                <div className="flex items-center gap-2">
                    {/* Language Selection */}
                    <div className="flex gap-1 mr-2">
                        {Object.entries(LANGUAGE_FLAGS).map(([code, flag]) => (
                            <button
                                key={code}
                                onClick={() => onLanguageChange(code as Language)}
                                className={cn(
                                    "text-lg hover:scale-110 transition-transform",
                                    code === language ? "scale-110" : "opacity-50"
                                )}
                                title={code.toUpperCase()}
                            >
                                {flag}
                            </button>
                        ))}
                    </div>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="h-8 w-8"
                    >
                        {isExpanded ? (
                            <Minimize2 className="h-4 w-4" />
                        ) : (
                            <Maximize2 className="h-4 w-4" />
                        )}
                    </Button>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={onClose}
                        className="h-8 w-8"
                    >
                        <X className="h-4 w-4" />
                    </Button>
                </div>
            </div>

            {/* Messages */}
            <div className="flex-1 overflow-y-auto space-y-4 mb-4">
                {messages.length === 0 && suggestedQuestions.length > 0 && (
                    <div className="flex flex-col gap-2">
                        <p className="text-sm text-muted-foreground">
                            Hi! I'm Fredo, your AI assistant. I can help you analyze your Google Ads data.
                            Here are some questions you might want to ask:
                        </p>
                        <div className="flex flex-col gap-2">
                            {suggestedQuestions.map((question, i) => (
                                <Button
                                    key={i}
                                    variant="outline"
                                    className="justify-start"
                                    onClick={() => handleSuggestedQuestion(question)}
                                    disabled={isLoading}
                                >
                                    {question}
                                </Button>
                            ))}
                        </div>
                    </div>
                )}
                {messages.map((message, i) => (
                    <div
                        key={i}
                        className={cn(
                            "flex flex-col",
                            message.role === 'assistant' ? "items-start" : "items-end"
                        )}
                    >
                        <div
                            className={cn(
                                "rounded-lg px-3 py-2 max-w-[90%] whitespace-pre-wrap text-sm",
                                message.role === 'assistant'
                                    ? "bg-muted"
                                    : "bg-primary text-primary-foreground"
                            )}
                        >
                            {message.content}
                        </div>
                    </div>
                ))}
                {isLoading && (
                    <div className="flex items-center gap-2 text-muted-foreground">
                        <div className="animate-pulse">●</div>
                        <div className="animate-pulse animation-delay-200">●</div>
                        <div className="animate-pulse animation-delay-400">●</div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>

            {/* Input */}
            <form onSubmit={handleSubmit} className="flex gap-2">
                <Input
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Ask me anything..."
                    disabled={isLoading}
                />
                <Button type="submit" disabled={isLoading || !input.trim()}>
                    Send
                </Button>
            </form>
        </Card>
    )
} 