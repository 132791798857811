// src/components/profit-analysis/controls.tsx
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Slider } from '@/components/ui/slider';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { RotateCcw } from 'lucide-react';
import { findCampaign, Range } from '@/lib/campaign-utils';
import type { CampaignWithShare, BusinessMode, ResponseModel } from '@/types/metrics';
import { 
  calculateRevenueFromCost,
  calculateCostFromRevenue
} from '@/lib/profit-calculations';

interface ControlsProps {
  campaigns: CampaignWithShare[];
  selectedCampaign: string;
  responseModel: ResponseModel;
  currentCost: number;
  currentValue: number;
  currentConversions?: number;
  costRange: Range;
  valueRange: Range;
  selectedCurrency: string;
  businessMode: BusinessMode;
  onCampaignSelect: (campaign: string) => void;
  onResponseModelChange: (model: ResponseModel) => void;
  onCostChange: (cost: number) => void;
  onValueChange: (value: number) => void;
}

export function ProfitAnalysisControls({
  campaigns,
  selectedCampaign,
  responseModel,
  currentCost,
  currentValue,
  currentConversions = 0,
  costRange,
  valueRange,
  selectedCurrency,
  businessMode,
  onCampaignSelect,
  onResponseModelChange,
  onCostChange,
  onValueChange,
}: ControlsProps) {
  const handleCostChange = (values: number[]) => {
    if (!values.length) return;
    const newCost = Math.min(
      costRange.max,
      Math.max(costRange.min, values[0])
    );
    onCostChange(newCost);

    if (responseModel !== 'independent') {
      const campaign = findCampaign(campaigns, selectedCampaign);
      if (campaign) {
        const costRatio = newCost / campaign.cost;
        let newValue = campaign.value;
        
        switch (responseModel) {
          case 'linear':
            newValue = campaign.value * costRatio;
            break;
          case 'diminishing':
            newValue = campaign.value * Math.pow(costRatio, 0.4);
            break;
        }
        
        const boundedValue = Math.min(
          valueRange.max,
          Math.max(valueRange.min, newValue)
        );
        onValueChange(boundedValue);
      }
    }
  };

  const handleValueChange = (values: number[]) => {
    if (!values.length) return;
    const newValue = Math.min(
      valueRange.max,
      Math.max(valueRange.min, values[0])
    );
    onValueChange(newValue);

    if (responseModel !== 'independent') {
      const campaign = findCampaign(campaigns, selectedCampaign);
      if (campaign) {
        const valueRatio = newValue / campaign.value;
        let newCost = campaign.cost;
        
        switch (responseModel) {
          case 'linear':
            newCost = campaign.cost * valueRatio;
            break;
          case 'diminishing':
            newCost = campaign.cost * Math.pow(valueRatio, 1/0.4);
            break;
        }
        
        const boundedCost = Math.min(
          costRange.max,
          Math.max(costRange.min, newCost)
        );
        onCostChange(boundedCost);
      }
    }
  };

  const handleReset = () => {
    const campaign = findCampaign(campaigns, selectedCampaign);
    if (campaign) {
      onCostChange(campaign.cost);
      if (businessMode === 'ecomm') {
        onValueChange(campaign.value);
      } else {
        onValueChange(campaign.conv);
      }
    }
  };

  return (
    <Card className="h-full">
      <CardHeader>
        <CardTitle>Try Changing Values - Data is last 30 days</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="space-y-2">
            <Label>Campaign</Label>
            <Select value={selectedCampaign} onValueChange={onCampaignSelect}>
              <SelectTrigger>
                <SelectValue placeholder="Select a campaign" />
              </SelectTrigger>
              <SelectContent>
                {campaigns.map((campaign) => (
                  <SelectItem
                    key={campaign.campaign}
                    value={campaign.campaign}
                    className="flex items-center justify-between py-2 pr-2"
                  >
                    <span className="truncate mr-4">
                      {campaign.campaign}
                    </span>
                    <span className="text-sm font-medium text-muted-foreground">
                      {selectedCurrency}{Math.round(campaign.cost).toLocaleString()}
                    </span>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label>Response Model</Label>
            <Tabs value={responseModel} onValueChange={(value) => onResponseModelChange(value as ResponseModel)}>
              <TabsList className="grid grid-cols-3 gap-2 bg-transparent">
                <TabsTrigger
                  value="diminishing"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                >
                  Diminishing
                </TabsTrigger>
                <TabsTrigger
                  value="linear"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                >
                  Linear
                </TabsTrigger>
                <TabsTrigger
                  value="independent"
                  className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                >
                  Independent
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          <div className="space-y-2">
            <Label>Cost ({selectedCurrency})</Label>
            <Slider
              min={costRange.min}
              max={costRange.max}
              step={Math.max(1, (costRange.max - costRange.min) / 100)}
              value={[Math.min(costRange.max, Math.max(costRange.min, currentCost))]}
              onValueChange={handleCostChange}
            />
            <div className="text-right text-sm text-muted-foreground">
              {selectedCurrency}{Math.round(currentCost).toLocaleString()}
            </div>
          </div>

          {businessMode === 'ecomm' ? (
            <div className="space-y-2">
              <Label>Revenue ({selectedCurrency})</Label>
              <Slider
                min={valueRange.min}
                max={valueRange.max}
                step={Math.max(1, (valueRange.max - valueRange.min) / 100)}
                value={[Math.min(valueRange.max, Math.max(valueRange.min, currentValue))]}
                onValueChange={handleValueChange}
              />
              <div className="text-right text-sm text-muted-foreground">
                {selectedCurrency}{Math.round(currentValue).toLocaleString()}
              </div>
            </div>
          ) : (
            <div className="space-y-2">
              <Label>Conversions</Label>
              <Slider
                min={1}
                max={(() => {
                  const campaign = findCampaign(campaigns, selectedCampaign)
                  if (!campaign) return currentConversions * 1.5
                  return campaign.conv * 2
                })()}
                step={1}
                value={[currentConversions]}
                onValueChange={value => onValueChange(value[0])}
              />
              <div className="text-right text-sm text-muted-foreground">
                {currentConversions.toFixed(1)}
              </div>
            </div>
          )}

          <div className="flex justify-end pt-2">
            <Button
              variant="outline"
              size="sm"
              onClick={handleReset}
              className="text-muted-foreground hover:text-foreground"
            >
              <RotateCcw className="h-4 w-4 mr-1" />
              Reset
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}