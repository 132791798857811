import { Link } from "react-router-dom"
import { version } from '../../../package.json'

export function Footer() {
  return (
    <footer className="border-t py-6 md:py-0">
      <div className="container flex flex-col items-center gap-4 md:h-14 md:flex-row md:justify-between">
        <div className="text-center md:text-left">
          <p className="text-sm leading-loose text-muted-foreground">
            © {new Date().getFullYear()} 8020agent. All rights reserved.
          </p>
        </div>
        <div className="flex items-center gap-4 text-sm text-muted-foreground">
          <Link to="/privacy" className="hover:underline">
            Privacy
          </Link>
          <span>•</span>
          <Link to="/terms" className="hover:underline">
            Terms
          </Link>
          <span>•</span>
          <a
            href="https://mikerhodes.circle.so/c/help/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            Support
          </a>
          <span>•</span>
          <span>v{version}</span>
        </div>
      </div>
    </footer>
  )
} 