import { Settings2 } from "lucide-react"
import { Popover, PopoverContent, PopoverTrigger } from "./popover"
import { Checkbox } from "./checkbox"

interface ColumnConfig {
  key: string
  label: string
  width: string
  isNumeric?: boolean
  isMetric?: boolean
  defaultHidden?: boolean
}

interface ColumnChooserProps {
  columns: ColumnConfig[]
  visibleColumns: Set<string>
  onColumnToggle: (columnKey: string) => void
}

export function ColumnChooser({ columns, visibleColumns, onColumnToggle }: ColumnChooserProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex items-center gap-1 h-7 px-3 bg-gray-100 hover:bg-gray-200 text-gray-500 rounded-md cursor-pointer">
          <Settings2 className="h-4 w-4" />
          <span>Columns</span>
        </div>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-[200px]">
        <div className="space-y-2">
          {columns.map(col => (
            <div key={col.key} className="flex items-center space-x-2">
              <Checkbox
                id={col.key}
                checked={visibleColumns.has(col.key)}
                onCheckedChange={() => onColumnToggle(col.key)}
              />
              <label
                htmlFor={col.key}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {col.label}
              </label>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
} 