// src/pages/terms.tsx
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

export default function Terms() {
  return (
    <div className="container mx-auto py-6">
      <Card>
        <CardHeader>
          <CardTitle>Terms of Service</CardTitle>
        </CardHeader>
        <CardContent className="prose">
          <p className="text-muted-foreground mb-6">Last updated: {new Date().toLocaleDateString()}</p>

          <div className="space-y-6">
            <section>
              <h2 className="text-xl font-semibold mb-3">1. Service Description</h2>
              <p>
                8020 provides a Google Ads optimization service that helps users analyze and improve their advertising performance.
                By using our service, you agree to these terms and our privacy policy.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">2. User Responsibilities</h2>
              <p>You agree to:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Provide accurate account information</li>
                <li>Maintain the security of your account</li>
                <li>Use the service in compliance with Google Ads policies</li>
                <li>Not misuse or attempt to manipulate the service</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">3. Data Usage</h2>
              <p>
                By using our service, you grant us permission to:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Access your Google Ads data through our script</li>
                <li>Store and analyze your campaign data</li>
                <li>Provide optimization recommendations</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">4. Service Limitations</h2>
              <p>
                Our service:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Provides recommendations but does not guarantee results</li>
                <li>May have occasional downtime for maintenance</li>
                <li>Requires a stable internet connection</li>
                <li>Has a limit of 5 accounts per user</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">5. Termination</h2>
              <p>
                We reserve the right to terminate or suspend access to our service:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>For violation of these terms</li>
                <li>For abuse of the service</li>
                <li>At our discretion with reasonable notice</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">6. Changes to Terms</h2>
              <p>
                We may modify these terms at any time. Continued use of the service after changes 
                constitutes acceptance of the new terms. We will notify users of significant changes.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">7. Contact</h2>
              <p>
                For questions about these terms, please contact us at:
                <br />
                <a href="https://mikerhodes.circle.so/c/help/" className="text-primary hover:underline">
                  support@8020.agency
                </a>
              </p>
            </section>
          </div>
        </CardContent>
      </Card>
    </div>
  )
} 