// src/config/ai-models.ts
import { Provider, Model } from '@/types/ai'

export interface DataSourceConfig {
    label: string
    description: string
    defaultSort: {
        column: string
        direction: 'asc' | 'desc'
    }
}

export const DATA_SOURCES: Record<string, DataSourceConfig> = {
    'daily': {
        label: 'Daily Performance',
        description: 'Daily campaign metrics including landing page performance',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'hourly': {
        label: 'Hourly Performance',
        description: 'Hour-by-hour performance data for today and yesterday',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'products': {
        label: 'Product Performance',
        description: 'Product-level performance data including titles & metrics',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'placement': {
        label: 'Placement Performance',
        description: 'Website placement performance data (top 100 by cost)',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'changes': {
        label: 'Change History',
        description: 'Recent account changes and modifications',
        defaultSort: { column: 'date', direction: 'desc' }
    },
    'tNgrams': {
        label: 'Title nGram Analysis',
        description: 'Word/phrase frequency analysis for product titles',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'sNgrams': {
        label: 'Search nGram Analysis',
        description: 'Word/phrase frequency analysis for search terms',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'assets': {
        label: 'Asset Performance',
        description: 'Creative asset performance (images, videos, text)',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'groups': {
        label: 'Asset Groups',
        description: 'Asset group performance metrics',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'terms': {
        label: 'Search Terms',
        description: 'Search term performance analysis',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'quality': {
        label: 'Quality Score',
        description: 'Quality score and component metrics',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'bidding': {
        label: 'Bidding Data',
        description: 'Bidding strategy and target data',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'conv_action': {
        label: 'Conversion Actions',
        description: 'Conversion action settings and performance',
        defaultSort: { column: 'allConv', direction: 'desc' }
    },
    'camp_conv': {
        label: 'Campaign Conversions',
        description: 'Campaign-level conversion data',
        defaultSort: { column: 'conversions', direction: 'desc' }
    },
    'summary': {
        label: 'Performance Summary',
        description: 'Account-wide performance summary',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'paths': {
        label: 'Customer Paths',
        description: 'Customer journey and path analysis',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'pTitle': {
        label: 'Product Titles',
        description: 'Product title performance across account',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'pTitleCampaign': {
        label: 'Product Titles by Campaign',
        description: 'Product title performance broken down by campaign',
        defaultSort: { column: 'impr', direction: 'desc' }
    },
    'pTitleID': {
        label: 'Product Titles with IDs',
        description: 'Product title performance with product IDs',
        defaultSort: { column: 'impr', direction: 'desc' }
    }
} as const;

export type DataSource = keyof typeof DATA_SOURCES;

export type RecordLimit = 10 | 50 | 100 | 250 | 500 | 1000 | 'all'

// Map of data sources to their display names
export const DATA_SOURCES_MAP: Record<DataSource, string> = Object.fromEntries(
    Object.entries(DATA_SOURCES).map(([key, config]) => [key, config.label])
) as Record<DataSource, string>;

export const MODELS: Record<Provider, Model[]> = {
    gemini: [
        { id: 'gemini-2.0-flash-exp', name: 'Gemini 2.0 Flash', inputPrice: 0, outputPrice: 0 },
        { id: 'gemini-1.5-pro', name: 'Gemini 1.5 Pro', inputPrice: 0.125, outputPrice: 0.5 }
    ],
    openai: [
        { id: 'gpt-4o-mini', name: 'GPT-4o Mini', inputPrice: 0.15, outputPrice: 0.6 },
        { id: 'gpt-4o', name: 'GPT-4o', inputPrice: 2.5, outputPrice: 10 }
    ],
    anthropic: [
        { id: 'claude-3-5-haiku-20241022', name: 'Claude 3.5 Haiku', inputPrice: 0.8, outputPrice: 4 },
        { id: 'claude-3-5-sonnet-20241022', name: 'Claude 3.5 Sonnet', inputPrice: 3, outputPrice: 15 },
    ]
}

export const DEBUG = import.meta.env.MODE === 'development' 