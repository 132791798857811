// src/hooks/use-version-check.ts
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCampaignData } from '@/contexts/campaign-data'
import { useAccounts } from '@/contexts/account-context'
import { PAGE_VERSION_REQUIREMENTS, SAMPLE_ACCOUNT_ID } from '@/lib/constants'
import { useUser } from '@clerk/clerk-react'
import { Account } from '@/types/metrics'

// Helper to identify pmax accounts by version format
function isPmaxVersion(version: string): boolean {
  return version.startsWith('v')
}

// Helper to normalize pmax version for comparison
function normalizePmaxVersion(version: string): string {
  // Strip 'v' prefix and everything after '.' if present
  return version.replace('v', '').split('.')[0]
}

// Helper to compare versions
function compareVersions(v1: string, v2: string) {
  // Handle pmax versions (e.g. v82.2)
  if (isPmaxVersion(v1)) {
    return 1 // Pmax versions are always considered up to date
  }

  // For 8020 versions (e.g. 0.4.46)
  const parts1 = v1.split('.').map(Number)
  const parts2 = v2.split('.').map(Number)

  for (let i = 0; i < 3; i++) {
    const part1 = parts1[i] || 0
    const part2 = parts2[i] || 0
    if (part1 > part2) return 1
    if (part1 < part2) return -1
  }
  return 0
}

interface VersionRequirement {
  minVersion: string;
  label?: string;
}

type PageName = keyof (typeof PAGE_VERSION_REQUIREMENTS)['pages']
type FeatureName = keyof (typeof PAGE_VERSION_REQUIREMENTS)['features']

export function useVersionCheck(
  name: PageName | FeatureName,
  type: 'page' | 'feature',
  overrideVersion?: string
) {
  const navigate = useNavigate()
  const { activeDexieId, data } = useCampaignData()
  const { deleteAccount: deleteDexieAccount } = useAccounts()
  const { user } = useUser()

  const account = useMemo(() => {
    if (!data?.account) return null
    return Array.isArray(data.account) ? data.account[0] : data.account
  }, [data?.account])

  const scriptVersion = overrideVersion || account?.scriptVersion
  const requirement = type === 'page'
    ? PAGE_VERSION_REQUIREMENTS.pages[name as PageName] as VersionRequirement
    : PAGE_VERSION_REQUIREMENTS.features[name as FeatureName] as VersionRequirement

  const deleteAccount = async () => {
    if (!activeDexieId) return

    try {
      // 1. Delete from Dexie
      await deleteDexieAccount(activeDexieId)

      // 2. Update Clerk metadata
      if (user) {
        const currentAccounts = (user.unsafeMetadata.accounts || []) as Account[]
        await user.update({
          unsafeMetadata: {
            ...user.unsafeMetadata,
            accounts: currentAccounts.filter(a => a.dexieId !== activeDexieId)
          }
        })
      }

      // 3. Navigate to setup
      navigate('/setup')
    } catch (err) {
      console.error('Failed to delete account:', err)
      // Still navigate to setup even if deletion fails
      navigate('/setup')
    }
  }

  const needsUpdate = useMemo(() => {
    // Skip check for sample data
    if (activeDexieId === SAMPLE_ACCOUNT_ID) return false
    if (!scriptVersion || !requirement) return false

    // Skip version check for pmax accounts
    if (isPmaxVersion(scriptVersion)) return false

    return compareVersions(scriptVersion, requirement.minVersion) < 0
  }, [activeDexieId, scriptVersion, requirement])

  return {
    needsUpdate,
    currentVersion: scriptVersion || 'unknown',
    minVersion: requirement?.minVersion,
    label: requirement?.label,
    deleteAccount,
    isPmax: scriptVersion ? isPmaxVersion(scriptVersion) : false
  }
}

// Export for use in other files
export { compareVersions, isPmaxVersion }