// src/pages/keywords.tsx
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useCampaignData } from "@/contexts/campaign-data"
import { SHEET_TABS } from "@/lib/constants"
import { LoadingSpinner } from "@/components/ui/loading"
import { KeywordData, QualityData } from "@/types/metrics"
import { useAccounts } from "@/contexts/account-context"
import { VersionGate } from "@/components/version-gate"
import { KeywordFilterControls } from '@/components/keyword-filter-controls'
import { DataDisplay } from '@/components/data-display'
import { TreeVisualization } from '@/components/tree-visualization'
import { KeywordTable } from '@/components/keyword-table'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { SearchTable } from '@/components/search-table'
import { SearchTermData } from "@/types/metrics"
import { SearchFilterControls } from '@/components/search-filter-controls'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { formatQualityComponent } from '@/lib/quality-score-utils'
import { applyAdvancedFilter } from '@/lib/filter-utils'
import { NgramTable } from '@/components/ngram-table'
import { processNgrams, processSearchTermNgrams } from '@/lib/ngram-utils'
import { Fredo } from '@/components/fredo-chat'

// Interface for processed keyword data
export interface ProcessedKeywordData extends KeywordData {
  score: number | null
  CTR: number
  CPC: number
  CvR: number
  ROAS: number
  CPA: number
  expCTR?: string
  adRelevance?: string
  landingExp?: string
}

interface ProcessedSearchTermData extends SearchTermData {
  CTR: number
  CPC: number
  CvR: number
  ROAS: number
  CPA: number
  matchType: string
}

interface SearchFilters {
  textFilters: {
    campaign: string
    searchTerm: string
  }
  matchTypes: Set<string>
  thresholds: {
    impr: number
    cost: number
    conv: number
  }
}

type DataType = 'keywords' | 'searchTerms'
type SortDirection = 'asc' | 'desc'

interface AdvancedFilter {
  field: string
  operator: string
  value: string
}

// Quick filter types
const QUICK_FILTERS = {
  keywords: [
    {
      id: 'no_conv',
      label: '0 Conv',
      filter: (kw: ProcessedKeywordData) => kw.conv === 0 && kw.cost > 0,
    },
    {
      id: 'no_clicks',
      label: '0 Clicks',
      filter: (kw: ProcessedKeywordData) => kw.clicks === 0 && kw.impr > 0,
    },
    {
      id: 'low_ctr',
      label: 'CTR<1%',
      filter: (kw: ProcessedKeywordData) => kw.CTR < 1 && kw.impr >= 100,
    },
    {
      id: 'low_cvr',
      label: 'CvR<1%',
      filter: (kw: ProcessedKeywordData) => kw.clicks >= 100 && kw.CvR < 1,
    }
  ],
  searchTerms: [
    {
      id: 'no_conv',
      label: '0 Conv',
      filter: (st: SearchTermData) => st.conv === 0 && st.cost > 0,
    },
    {
      id: 'no_clicks',
      label: '0 Clicks',
      filter: (st: SearchTermData) => st.clicks === 0 && st.impr > 0,
    },
    {
      id: 'low_ctr',
      label: 'CTR<1%',
      filter: (st: SearchTermData) => st.CTR < 1 && st.impr >= 100,
    },
    {
      id: 'low_cvr',
      label: 'CvR<1%',
      filter: (st: SearchTermData) => st.clicks >= 100 && st.CvR < 1,
    }
  ]
} as const

export default function KeywordsPage() {
  const { data, loadingStatus } = useCampaignData()
  const { activeAccount } = useAccounts()
  const accountCurrency = activeAccount?.currency || '$'
  const scriptVersion = activeAccount?.scriptVersion

  // states
  const [viewType, setViewType] = useState<'keywords' | 'searchTerms'>('keywords')
  const [displayType, setDisplayType] = useState<'tree' | 'table' | 'ngram'>('tree')
  const [maxLength, setMaxLength] = useState(40)
  const [wordCount, setWordCount] = useState(1)
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())
  const [activeQuickFilter, setActiveQuickFilter] = useState<string | null>(null)
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilter[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState({
    qualityScores: {
      great: false,
      ok: true,
      poor: true,
      na: false
    },
    textFilters: {
      campaign: '',
      adGroup: '',
      keyword: ''
    },
    thresholds: {
      impr: 10,
      cost: 5,
      conv: 0
    }
  })

  const collapseAll = useCallback(() => {
    setExpandedRows(new Set())
  }, [])

  // Reset pagination when filters change
  useEffect(() => {
    setCurrentPage(1)
  }, [filters, activeQuickFilter, advancedFilters, viewType])

  // Join keyword and quality score data
  const processedData = useMemo(() => {
    if (!data) return []

    const keywordData = data[SHEET_TABS.KEYWORD80] || []
    const qualityData = data[SHEET_TABS.QUALITY] || []

    // Create index for quality data
    const qualityIndex = new Map<string, QualityData>()
    qualityData.forEach(q => {
      // Use keywordText from quality data for join
      const key = `${q.campaign}|${q.adGroup}|${q.keyword}`
      qualityIndex.set(key, {
        ...q,
        score: Number(q.qualityScore || q.score || 0)
      })
    })

    // Join and process data
    const processed = keywordData.map(kw => {
      // Calculate derived metrics
      const CTR = kw.impr ? (kw.clicks / kw.impr) * 100 : 0
      const CPC = kw.clicks ? kw.cost / kw.clicks : 0
      const CvR = kw.clicks ? (kw.conv / kw.clicks) * 100 : 0
      const ROAS = kw.cost ? kw.value / kw.cost : 0
      const CPA = kw.conv ? kw.cost / kw.conv : 0

      // Find matching quality data using keywordText
      const key = `${kw.campaign}|${kw.adGroup}|${kw.keywordText}`
      const qualityInfo = qualityIndex.get(key)

      // Format quality components
      const expCTR = formatQualityComponent(qualityInfo?.expCTR)
      const adRelevance = formatQualityComponent(qualityInfo?.adRelevance)
      const landingExp = formatQualityComponent(qualityInfo?.landingExp)

      return {
        ...kw,
        score: qualityInfo?.score || null,
        expCTR,
        adRelevance,
        landingExp,
        CTR,
        CPC,
        CvR,
        ROAS,
        CPA
      } as ProcessedKeywordData
    })

    // Debug processed data
    if (process.env.NODE_ENV === 'development') {
      console.log('Processed data stats:', {
        processedCount: processed.length,
        withQualityScore: processed.filter(p => p.score !== null).length,
        processedSample: processed.slice(0, 2)
      })
    }

    return processed
  }, [data])

  // Process search data with calculated metrics
  const processedSearchData = useMemo(() => {
    if (!data) return []

    const searchData = data[SHEET_TABS.SEARCH80] || []
    console.log('Raw search data:', {
      count: searchData.length,
      sample: searchData.slice(0, 2)
    })

    const processed = searchData.map(row => ({
      ...row,
      CTR: row.impr ? (row.clicks / row.impr) * 100 : 0,
      CPC: row.clicks ? row.cost / row.clicks : 0,
      CvR: row.clicks ? (row.conv / row.clicks) * 100 : 0,
      ROAS: row.cost ? row.value / row.cost : 0,
      CPA: row.conv ? row.cost / row.conv : 0,
      matchType: row.searchMatchType
    }))

    console.log('Processed search data:', {
      count: processed.length,
      sample: processed.slice(0, 2)
    })

    return processed
  }, [data])

  // Initialize search filters with all match types on
  const [searchFilters, setSearchFilters] = useState<SearchFilters>({
    matchTypes: new Set(['EXACT', 'NEAR_EXACT', 'PHRASE', 'NEAR_PHRASE', 'BROAD']),
    textFilters: {
      campaign: '',
      searchTerm: ''
    },
    thresholds: {
      impr: 5,
      cost: 2,
      conv: 0
    }
  })

  const [sort, setSort] = useState<{ field: string; direction: SortDirection }>({
    field: displayType === 'ngram' ? 'impr' : 'cost',
    direction: 'desc'
  })
  const [visibleColumns, setVisibleColumns] = useState(new Set([
    'campaign', 'adGroup', 'keywordText', 'keywordMatchType', 'score',
    'impr', 'clicks', 'CTR', 'cost', 'CPC', 'conv', 'value', 'ROAS', 'CPA'
  ]))
  const [searchVisibleColumns, setSearchVisibleColumns] = useState(new Set([
    'campaign', 'searchMatchType', 'searchTerm', 'impr', 'clicks', 'CTR', 'cost', 'CPC', 'conv', 'value', 'ROAS', 'CPA'
  ]))
  const [ngramVisibleColumns, setNgramVisibleColumns] = useState(new Set([
    'ngram', 'wordCount', 'count', 'impr', 'clicks', 'CTR', 'cost', 'CPC', 'conv', 'CvR', 'value', 'ROAS'
  ]))
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const MAX_TREE_NODES = 1000

  // Filter and process data
  const filteredData = useMemo(() => {
    const sourceData = viewType === 'keywords' ? processedData : processedSearchData || []

    if (!sourceData?.length) return []

    const filtered = sourceData.filter(row => {
      // For ngram view of search terms, only apply basic filters
      if (viewType === 'searchTerms' && displayType === 'ngram') {
        const searchRow = row as SearchTermData
        const { campaign, searchTerm } = searchFilters.textFilters

        // Only apply text filters
        if (campaign && !searchRow.campaign.toLowerCase().includes(campaign.toLowerCase())) return false
        if (searchTerm && !searchRow.searchTerm.toLowerCase().includes(searchTerm.toLowerCase())) return false

        return true
      }

      // Regular filtering logic for other views
      if (activeQuickFilter) {
        const quickFilter = QUICK_FILTERS[viewType].find(f => f.id === activeQuickFilter)
        if (quickFilter && !quickFilter.filter(row as any)) return false
      }

      // Apply advanced filters
      if (advancedFilters.length > 0) {
        const passesAllFilters = advancedFilters.every(filter =>
          applyAdvancedFilter(row, filter, viewType)
        )
        if (!passesAllFilters) return false
      }

      if (viewType === 'searchTerms') {
        const searchRow = row as SearchTermData
        const { campaign, searchTerm } = searchFilters.textFilters

        // Apply text filters for search terms
        if (campaign && !searchRow.campaign.toLowerCase().includes(campaign.toLowerCase())) return false
        if (searchTerm && !searchRow.searchTerm.toLowerCase().includes(searchTerm.toLowerCase())) return false

        // Apply match type filters for search terms
        if (searchFilters.matchTypes.size > 0 && !searchFilters.matchTypes.has(searchRow.searchMatchType)) {
          return false
        }

        // Apply threshold filters for search terms
        if (searchRow.impr < searchFilters.thresholds.impr) return false
        if (searchRow.cost < searchFilters.thresholds.cost) return false
        if (searchRow.conv < searchFilters.thresholds.conv) return false
      } else {
        // Apply keyword filters
        const keywordRow = row as ProcessedKeywordData

        // Apply quality score filters for keywords
        if (keywordRow.score !== null) {
          if (keywordRow.score >= 8 && !filters.qualityScores.great) return false
          if (keywordRow.score >= 5 && keywordRow.score < 8 && !filters.qualityScores.ok) return false
          if (keywordRow.score < 5 && !filters.qualityScores.poor) return false
        } else if (!filters.qualityScores.na) {
          return false
        }

        // Apply text filters for keywords
        if (filters.textFilters.campaign &&
          !keywordRow.campaign.toLowerCase().includes(filters.textFilters.campaign.toLowerCase())) return false
        if (filters.textFilters.adGroup &&
          !keywordRow.adGroup.toLowerCase().includes(filters.textFilters.adGroup.toLowerCase())) return false
        if (filters.textFilters.keyword &&
          !keywordRow.keywordText.toLowerCase().includes(filters.textFilters.keyword.toLowerCase())) return false

        // Apply threshold filters for keywords
        if (keywordRow.impr < filters.thresholds.impr) return false
        if (keywordRow.cost < filters.thresholds.cost) return false
        if (keywordRow.conv < filters.thresholds.conv) return false
      }

      return true
    })

    return filtered
  }, [processedData, processedSearchData, activeQuickFilter, filters, searchFilters, advancedFilters, viewType, displayType])

  // Process ngrams
  const ngramData = useMemo(() => {

    let processedNgrams = viewType === 'keywords'
      ? processNgrams(filteredData as ProcessedKeywordData[], 3, wordCount)
      : processSearchTermNgrams(filteredData as SearchTermData[], 3, wordCount)

    // Sort ngrams
    const sorted = [...processedNgrams].sort((a, b) => {
      const aValue = a[sort.field as keyof typeof a]
      const bValue = b[sort.field as keyof typeof b]
      const multiplier = sort.direction === 'asc' ? 1 : -1

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return multiplier * aValue.localeCompare(bValue)
      }
      return multiplier * ((Number(aValue) || 0) - (Number(bValue) || 0))
    })

    return sorted
  }, [filteredData, viewType, sort, wordCount])

  // For ngram view, we need to apply metric filters to the ngram data itself
  const filteredNgramData = useMemo(() => {
    if (displayType !== 'ngram') return ngramData

    return ngramData.filter(ngram => {
      // Apply threshold filters to ngram aggregates
      if (viewType === 'searchTerms') {
        if (ngram.impr < searchFilters.thresholds.impr) return false
        if (ngram.cost < searchFilters.thresholds.cost) return false
        if (ngram.conv < searchFilters.thresholds.conv) return false
      } else {
        if (ngram.impr < filters.thresholds.impr) return false
        if (ngram.cost < filters.thresholds.cost) return false
        if (ngram.conv < filters.thresholds.conv) return false
      }

      // Apply quick filters to ngram aggregates
      if (activeQuickFilter) {
        switch (activeQuickFilter) {
          case 'no_conv':
            return ngram.conv === 0 && ngram.cost > 0
          case 'no_clicks':
            return ngram.clicks === 0 && ngram.impr > 0
          case 'low_ctr':
            return ngram.CTR < 1 && ngram.impr >= 100
          case 'low_cvr':
            return ngram.clicks >= 100 && ngram.CvR < 1
        }
      }

      return true
    })
  }, [ngramData, displayType, viewType, filters, searchFilters, activeQuickFilter])

  // Update sort when switching to ngram view
  useEffect(() => {
    if (displayType === 'ngram') {
      setSort({ field: 'impr', direction: 'desc' })
    }
  }, [displayType])

  // Sort data (for table and tree views)
  const sortedData = useMemo(() => {
    if (displayType === 'ngram') return filteredData

    return [...filteredData].sort((a, b) => {
      const aValue = a[sort.field as keyof typeof a]
      const bValue = b[sort.field as keyof typeof b]
      const multiplier = sort.direction === 'asc' ? 1 : -1

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return multiplier * aValue.localeCompare(bValue)
      }
      return multiplier * ((Number(aValue) || 0) - (Number(bValue) || 0))
    })
  }, [filteredData, sort, displayType])

  const handleQuickFilterChange = (filter: string | null) => {
    setActiveQuickFilter(filter)

    // Reset relevant thresholds based on quick filter
    if (viewType === 'searchTerms') {
      if (filter === 'no_clicks') {
        setSearchFilters(prev => ({
          ...prev,
          thresholds: { ...prev.thresholds, cost: 0 }
        }))
      } else if (filter === 'no_conv') {
        setSearchFilters(prev => ({
          ...prev,
          thresholds: { ...prev.thresholds, conv: 0 }
        }))
      } else {
        // Reset to defaults if filter is cleared
        setSearchFilters(prev => ({
          ...prev,
          thresholds: {
            impr: 5,
            cost: 2,
            conv: 0
          }
        }))
      }
    } else {
      if (filter === 'no_clicks') {
        setFilters(prev => ({
          ...prev,
          thresholds: { ...prev.thresholds, cost: 0 }
        }))
      } else if (filter === 'no_conv') {
        setFilters(prev => ({
          ...prev,
          thresholds: { ...prev.thresholds, conv: 0 }
        }))
      }
    }
  }

  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    return (
      <div className="flex items-center justify-center h-96">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <VersionGate
      name="keywords"
      type="page"
      scriptVersion={scriptVersion}
    >
      <div className="container mx-auto py-6 max-w-[1600px] min-h-screen">
        {/* View type selector */}
        <div className="flex justify-between items-center gap-2 mb-4">
          <div className="flex gap-2">
            <Button
              variant={viewType === 'keywords' ? 'default' : 'outline'}
              onClick={() => setViewType('keywords')}
            >
              Keywords
            </Button>
            <Button
              variant={viewType === 'searchTerms' ? 'default' : 'outline'}
              onClick={() => setViewType('searchTerms')}
            >
              Search Terms
            </Button>
          </div>
          <span className="text-sm text-muted-foreground">Data is for past 30 days</span>
        </div>

        {/* Filter controls */}
        <div className="mb-4">
          {viewType === 'keywords' ? (
            <KeywordFilterControls
              filters={filters}
              onFiltersChange={setFilters}
              activeQuickFilter={activeQuickFilter}
              onQuickFilterChange={handleQuickFilterChange}
              data={sortedData as ProcessedKeywordData[]}
              accountCurrency={accountCurrency}
              advancedFilters={advancedFilters}
              onAdvancedFiltersChange={setAdvancedFilters}
            />
          ) : (
            <SearchFilterControls
              filters={searchFilters}
              onFiltersChange={setSearchFilters}
              activeQuickFilter={activeQuickFilter}
              onQuickFilterChange={handleQuickFilterChange}
              data={sortedData as SearchTermData[]}
              accountCurrency={accountCurrency}
              advancedFilters={advancedFilters}
              onAdvancedFiltersChange={setAdvancedFilters}
            />
          )}
        </div>

        {/* Data display */}
        <div className="bg-background rounded-lg mb-4">
          <DataDisplay
            viewType={displayType}
            onViewTypeChange={setDisplayType}
            data={displayType === 'ngram' ? filteredNgramData : sortedData}
            treeComponent={(props) => (
              <>
                {sortedData.length > MAX_TREE_NODES && (
                  <Alert className="mb-4">
                    <AlertDescription>
                      Showing top {MAX_TREE_NODES} of {sortedData.length} matching items.
                      Please adjust filters to see other results.
                    </AlertDescription>
                  </Alert>
                )}
                <TreeVisualization
                  data={sortedData.slice(0, MAX_TREE_NODES)}
                  type={viewType}
                  maxLength={maxLength}
                  onMaxLengthChange={setMaxLength}
                  {...props}
                />
              </>
            )}
            tableComponent={viewType === 'keywords' ? (
              <KeywordTable
                data={sortedData as ProcessedKeywordData[]}
                visibleColumns={visibleColumns}
                sort={sort}
                onSortChange={(field) => {
                  setSort(prev => ({
                    field,
                    direction: prev.field === field
                      ? (prev.direction === 'asc' ? 'desc' : 'asc')
                      : 'desc'
                  }))
                }}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                onPageChange={setCurrentPage}
                accountCurrency={accountCurrency}
              />
            ) : (
              <SearchTable
                data={sortedData as SearchTermData[]}
                visibleColumns={searchVisibleColumns}
                sort={sort}
                onSortChange={(field) => {
                  setSort(prev => ({
                    field,
                    direction: prev.field === field
                      ? (prev.direction === 'asc' ? 'desc' : 'asc')
                      : 'desc'
                  }))
                }}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                onPageChange={setCurrentPage}
                accountCurrency={accountCurrency}
              />
            )}
            ngramComponent={
              <NgramTable
                data={filteredNgramData}
                visibleColumns={ngramVisibleColumns}
                sort={sort}
                onSortChange={(field) => {
                  setSort(prev => ({
                    field,
                    direction: prev.field === field
                      ? (prev.direction === 'asc' ? 'desc' : 'asc')
                      : 'desc'
                  }))
                }}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                onPageChange={setCurrentPage}
                accountCurrency={accountCurrency}
                type={viewType}
                expandedRows={expandedRows}
                setExpandedRows={setExpandedRows}
              />
            }
            onColumnsChange={
              displayType === 'ngram'
                ? setNgramVisibleColumns
                : viewType === 'keywords'
                  ? setVisibleColumns
                  : setSearchVisibleColumns
            }
            visibleColumns={
              displayType === 'ngram'
                ? ngramVisibleColumns
                : viewType === 'keywords'
                  ? visibleColumns
                  : searchVisibleColumns
            }
            onRowsPerPageChange={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            type={viewType === 'keywords' ? 'keywords' : 'searchTerms'}
            maxLength={maxLength}
            onMaxLengthChange={setMaxLength}
            wordCount={wordCount}
            onWordCountChange={setWordCount}
            hasExpandedRows={expandedRows?.size > 0}
            onCollapseAll={collapseAll}
          />
        </div>

        {/* Add Fredo */}
        <Fredo
          pageData={{
            viewType,
            displayType,
            filters: viewType === 'keywords' ? filters : searchFilters,
            data: sortedData,
            ngramData: filteredNgramData,
            activeQuickFilter,
            advancedFilters,
            sort,
            visibleColumns: displayType === 'ngram'
              ? ngramVisibleColumns
              : viewType === 'keywords'
                ? visibleColumns
                : searchVisibleColumns,
            maxLength,
            wordCount
          }}
        />
      </div>
    </VersionGate>
  )
}