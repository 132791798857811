// src/contexts/campaign-data.tsx
import { createContext, useContext, useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { Campaign, StorageData, LoadingStatus, CampConvData, ConvActionData, AssetData, GroupData, TermData, SummaryData, NGramData, PathData, PTitleData } from '@/types/metrics'
import { db } from '@/services/db'
import { SAMPLE_ACCOUNT_ID, SHEET_TABS } from '@/lib/constants'
import { useUser } from '@clerk/clerk-react'
import { fetchAllData } from '@/lib/data-utils'
import { transformers } from '@/lib/sample-data-utils'
import { toast } from '@/components/ui/use-toast'
import { AccountContext } from './account-context'

const DEBUG = process.env.NODE_ENV === 'development'

interface CampaignDataContextType {
    data: StorageData | null
    accountData: Record<string, StorageData>
    activeDexieId: string
    isLoading: boolean
    loadingStatus: LoadingStatus
    error?: string
    lastUpdated: Date | null
    refreshData: (dexieId: string) => Promise<void>
    useSampleData: boolean
    initialCogsAmount?: number
    initialBreakEvenCpa?: number
    updateInitialCogsAmount: (value: number) => Promise<void>
    updateInitialBreakEvenCpa: (value: number) => Promise<void>
    switchToSampleData: (value: boolean) => Promise<void>
    currentGameLevel: number
    updateCurrentGameLevel: (level: number) => Promise<void>
    thirtyDayData?: Campaign[]
    queueRefresh: (dexieId: string, immediate?: boolean) => void
    isRefreshing: boolean
}

const CampaignDataContext = createContext<CampaignDataContextType | null>(null)

export function CampaignDataProvider({ children }: { children: React.ReactNode }) {
    const accountContext = useContext(AccountContext)
    if (!accountContext) {
        throw new Error('CampaignDataProvider must be used within AccountProvider')
    }

    const { activeDexieId, initialized } = accountContext
    const { user, isSignedIn } = useUser()

    const [accountData, setAccountData] = useState<Record<string, StorageData>>({})
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('initial')
    const [error, setError] = useState<string | undefined>()
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null)
    const [currentGameLevel, setCurrentGameLevel] = useState(0)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const refreshQueue = useRef<string[]>([])
    const isProcessingQueue = useRef(false)

    // Process refresh queue
    const processQueue = useCallback(async () => {
        if (isProcessingQueue.current || !refreshQueue.current.length) return

        isProcessingQueue.current = true
        setIsRefreshing(true)

        try {
            while (refreshQueue.current.length > 0) {
                const dexieId = refreshQueue.current[0]
                const account = await db.getAccount(dexieId)

                if (account && !account.isSampleAccount && account.webAppUrl) {
                    try {
                        if (DEBUG) console.log('Processing refresh for account:', dexieId)
                        const newData = await fetchAllData(dexieId, account.webAppUrl)
                        await db.saveAccountData(dexieId, {
                            ...newData,
                            dexieId,
                            accountCID: account.accountCID,
                            timestamp: new Date().toISOString()
                        })
                        setAccountData(prev => ({ ...prev, [dexieId]: newData }))
                        setLastUpdated(new Date())
                    } catch (err) {
                        console.error('Background refresh failed for account:', dexieId, err)
                    }
                }

                refreshQueue.current.shift()
            }
        } finally {
            isProcessingQueue.current = false
            setIsRefreshing(false)
        }
    }, [])

    // Queue refresh for an account
    const queueRefresh = useCallback((dexieId: string, immediate = false) => {
        if (!refreshQueue.current.includes(dexieId)) {
            if (immediate) {
                refreshQueue.current.unshift(dexieId)
            } else {
                refreshQueue.current.push(dexieId)
            }
            processQueue()
        }
    }, [processQueue])

    // Handle initial login and background refreshes
    useEffect(() => {
        if (isSignedIn && user && initialized) {
            const lastUsedId = user.unsafeMetadata.lastUsedDexieId as string

            // First, refresh the last used account (if it exists)
            if (lastUsedId) {
                db.getAccount(lastUsedId).then(account => {
                    if (account && !account.isSampleAccount) {
                        queueRefresh(lastUsedId, true) // Priority refresh
                    }
                })
            }
        }
    }, [isSignedIn, user, initialized, queueRefresh])

    // Initial data load
    useEffect(() => {
        let mounted = true

        const loadInitialData = async () => {
            if (!activeDexieId || !initialized) return

            try {
                const account = await db.getAccount(activeDexieId)
                if (!account) throw new Error(`Account not found: ${activeDexieId}`)

                if (account.isSampleAccount) {
                    const { loadSampleData } = await import('@/lib/sample-data-utils')
                    const sampleData = await loadSampleData()

                    setAccountData(prev => ({
                        ...prev,
                        [activeDexieId]: sampleData
                    }))
                    setLastUpdated(new Date(sampleData.timestamp))
                } else {
                    const storedData = await db.getAccountData(activeDexieId)
                    if (!mounted) return

                    if (storedData) {
                        setAccountData(prev => ({
                            ...prev,
                            [activeDexieId]: storedData
                        }))
                        setLastUpdated(new Date(storedData.timestamp))

                        // Queue a refresh if data is older than 5 minutes
                        const dataAge = Date.now() - new Date(storedData.timestamp).getTime()
                        if (dataAge > 5 * 60 * 1000) { // 5 minutes
                            queueRefresh(activeDexieId, true) // Priority refresh for switched account
                        }
                    } else {
                        console.warn('No stored data found for account:', activeDexieId)
                        // No stored data, queue immediate refresh
                        queueRefresh(activeDexieId, true)
                    }
                }
            } catch (err) {
                console.error('Initial data load failed:', err)
                setError(err instanceof Error ? err.message : 'Failed to load data')
            } finally {
                if (mounted) {
                    setLoadingStatus('idle')
                    console.log('Data loading complete for:', activeDexieId)
                }
            }
        }

        loadInitialData()
        return () => { mounted = false }
    }, [activeDexieId, initialized, queueRefresh])

    // Simple refresh function (for manual refreshes)
    const refreshData = async (dexieId: string) => {
        // Don't set loading status for individual account refreshes
        // setLoadingStatus('refresh')

        try {
            const account = await db.getAccount(dexieId)
            if (!account) throw new Error('Account not found')

            // Rest of refresh logic
            const newData = await fetchAllData(dexieId, account.webAppUrl)

            // Get script version from account data
            const accountInfo = newData[SHEET_TABS.ACCOUNT]?.[0]
            if (accountInfo?.scriptVersion && accountInfo.scriptVersion !== account.scriptVersion) {
                console.log('Updating account script version:', accountInfo.scriptVersion)
                await accountContext.updateAccount({
                    ...account,
                    scriptVersion: accountInfo.scriptVersion
                })
            }

            await db.saveAccountData(dexieId, {
                ...newData,
                dexieId,
                accountCID: account.accountCID,
                timestamp: new Date().toISOString()
            })

            setAccountData(prev => ({
                ...prev,
                [dexieId]: newData
            }))

            setLastUpdated(new Date())
            console.log('Data refresh complete for:', dexieId)

            if (DEBUG) {
                console.log('Refreshed data metrics:', {
                    h_dow_30d: newData[SHEET_TABS.H_DOW_30D]?.length,
                    quality: newData[SHEET_TABS.QUALITY]?.length,
                    bidding: newData[SHEET_TABS.BIDDING]?.length
                })
            }
        } catch (err) {
            console.error('Failed to refresh data:', err)
            throw err
        }
    }

    // Load game level from Clerk for signed-in users
    useEffect(() => {
        if (isSignedIn && user) {
            const clerkLevel = user.unsafeMetadata.currentGameLevel
            if (typeof clerkLevel === 'number') {
                setCurrentGameLevel(clerkLevel)
            }
        }
    }, [isSignedIn, user])

    const updateCurrentGameLevel = useCallback(async (level: number) => {
        // Optimistically update UI first
        setCurrentGameLevel(level)

        // Only persist for signed-in users
        if (isSignedIn && user) {
            try {
                await user.update({
                    unsafeMetadata: {
                        ...user.unsafeMetadata,
                        currentGameLevel: level
                    }
                })
            } catch (err) {
                // On error, revert the optimistic update
                setCurrentGameLevel(currentGameLevel)
                console.error('Failed to update game level:', err)
                throw err
            }
        }
    }, [isSignedIn, user, currentGameLevel])

    const contextValue = useMemo(() => ({
        data: activeDexieId ? accountData[activeDexieId] : null,
        accountData,
        activeDexieId,
        isLoading: loadingStatus !== 'idle',
        loadingStatus,
        error,
        lastUpdated,
        refreshData,
        useSampleData: activeDexieId === SAMPLE_ACCOUNT_ID,
        initialCogsAmount: 0,
        initialBreakEvenCpa: 0,
        updateInitialCogsAmount: async () => { },
        updateInitialBreakEvenCpa: async () => { },
        switchToSampleData: async () => { },
        currentGameLevel,
        updateCurrentGameLevel,
        thirtyDayData: accountData[activeDexieId]?.[SHEET_TABS.THIRTY_DAYS] ?? [],
        queueRefresh,
        isRefreshing
    }), [
        activeDexieId,
        accountData,
        loadingStatus,
        error,
        lastUpdated,
        currentGameLevel,
        isRefreshing
    ])

    useEffect(() => {
        if (DEBUG) {
            console.log('CampaignData context value:', {
                activeDexieId,
                isSampleData: activeDexieId === SAMPLE_ACCOUNT_ID,
                hasData: !!accountData[activeDexieId],
                placementsCount: accountData[activeDexieId]?.[SHEET_TABS.PLACEMENTS]?.length
            })
        }
    }, [activeDexieId, accountData])

    if (!initialized || loadingStatus === 'initial') {
        return (
            <div className="flex items-center justify-center h-96">
                <div className="text-lg">
                    {error ? 'Error loading data' : 'Loading data...'}
                </div>
                {error && (
                    <div className="text-red-500 text-sm">
                        {error}
                    </div>
                )}
            </div>
        )
    }

    return (
        <CampaignDataContext.Provider value={contextValue}>
            {children}
        </CampaignDataContext.Provider>
    )
}

export function useCampaignData() {
    const context = useContext(CampaignDataContext)
    if (!context) {
        throw new Error('useCampaignData must be used within CampaignDataProvider')
    }
    return context
}