// src/hooks/use-ai-usage.ts
import { useState, useCallback } from 'react'
import { useUser } from '@clerk/clerk-react'

interface AIUsageStats {
    apiCalls: number
    totalCost: number
    lastUpdated: string
}

interface ClerkMetadata {
    aiApiCalls?: number
    aiTotalCost?: number
    aiLastUpdated?: string
    [key: string]: unknown
}

const LOCAL_STORAGE_KEY = 'ai_usage_stats'

function isValidStats(stats: unknown): stats is AIUsageStats {
    if (!stats || typeof stats !== 'object') return false
    const s = stats as Record<string, unknown>
    return (
        typeof s.apiCalls === 'number' &&
        typeof s.totalCost === 'number' &&
        typeof s.lastUpdated === 'string' &&
        !isNaN(new Date(s.lastUpdated).getTime())
    )
}

export function useAIUsage() {
    const { user, isSignedIn } = useUser()

    const [stats, setStats] = useState<AIUsageStats>(() => {
        const stored = localStorage.getItem(LOCAL_STORAGE_KEY)
        if (stored) {
            try {
                const parsed = JSON.parse(stored)
                if (isValidStats(parsed)) {
                    return parsed
                }
            } catch (e) {
                console.error('Failed to parse stored stats:', e)
            }
        }
        return {
            apiCalls: 0,
            totalCost: 0,
            lastUpdated: new Date().toISOString()
        }
    })

    // Update local stats only
    const updateStats = useCallback((cost: number) => {
        setStats(prev => ({
            apiCalls: prev.apiCalls + 1,
            totalCost: prev.totalCost + cost,
            lastUpdated: new Date().toISOString()
        }))

        // Save to localStorage
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stats))
    }, [stats])

    // Sync to Clerk on demand
    const syncToClerk = useCallback(async () => {
        if (!user || !isSignedIn) return

        try {
            const metadata = user.unsafeMetadata as ClerkMetadata
            await user.update({
                unsafeMetadata: {
                    ...metadata,
                    aiApiCalls: (metadata.aiApiCalls ?? 0) + stats.apiCalls,
                    aiTotalCost: (metadata.aiTotalCost ?? 0) + stats.totalCost,
                    aiLastUpdated: new Date().toISOString()
                }
            })

            // Reset local stats after successful sync
            setStats({
                apiCalls: 0,
                totalCost: 0,
                lastUpdated: new Date().toISOString()
            })
        } catch (error) {
            console.error('Failed to sync stats to Clerk:', error)
        }
    }, [user, isSignedIn, stats])

    return { stats, updateStats, syncToClerk }
}