import React, { createContext, useContext, useState, useEffect } from 'react';

interface GameStateContextType {
    currentGameLevel: number;
    updateGameLevel: (level: number) => void;
}

const GameStateContext = createContext<GameStateContextType | undefined>(undefined);

export function GameStateProvider({ children }: { children: React.ReactNode }) {
    const [currentGameLevel, setCurrentGameLevel] = useState(() => {
        const saved = localStorage.getItem('gameLevel');
        return saved ? parseInt(saved, 10) : 0;
    });

    const updateGameLevel = (level: number) => {
        setCurrentGameLevel(level);
        localStorage.setItem('gameLevel', level.toString());
    };

    return (
        <GameStateContext.Provider value={{ currentGameLevel, updateGameLevel }}>
            {children}
        </GameStateContext.Provider>
    );
}

export function useGameState() {
    const context = useContext(GameStateContext);
    if (context === undefined) {
        throw new Error('useGameState must be used within a GameStateProvider');
    }
    return context;
} 