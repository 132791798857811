// src/components/cpd-images.tsx
import React from 'react';

const HexagonComponent = ({
  x,
  y,
  size = 200,
  label = '',
  color = '#0ea5e9',
  opacity = 1,
  textColor = 'white'
}: {
  x: number;
  y: number;
  size?: number;
  label?: string;
  color?: string;
  opacity?: number;
  textColor?: string;
}) => {
  const points = [
    [x, y - size],
    [x + size * 0.866, y - size * 0.5],
    [x + size * 0.866, y + size * 0.5],
    [x, y + size],
    [x - size * 0.866, y + size * 0.5],
    [x - size * 0.866, y - size * 0.5],
  ].map(point => point.join(',')).join(' ');

  return (
    <g>
      <polygon points={points} fill={color} fillOpacity={opacity} stroke="#d1d5db" strokeWidth="2" />
      <text
        x={x}
        y={y}
        textAnchor="middle"
        dominantBaseline="middle"
        fill={textColor}
        fontSize={size * 0.25}
        fontWeight="bold"
      >
        {label}
      </text>
    </g>
  );
};

const ExtendedHexagonComponent = ({
  x,
  y,
  width,
  height,
  text,
  color = '#0ea5e9',
  opacity = 1,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  text: string;
  color?: string;
  opacity?: number;
}) => {
  const points = [
    [x, y - height / 2],
    [x + width / 2, y - height / 2],
    [x + width / 2 + height / 3, y],
    [x + width / 2, y + height / 2],
    [x, y + height / 2],
    [x - height / 3, y],
  ].map(point => point.join(',')).join(' ');

  return (
    <g>
      <polygon
        points={points}
        fill={color}
        fillOpacity={opacity}
        stroke="#d1d5db"
        strokeWidth="2"
      />
      <text
        x={x + width / 4}
        y={y}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="white"
        fontSize={height * 0.4}
        fontWeight="bold"
      >
        {text}
      </text>
    </g>
  );
};

export const Chart = ({ step = 1, width = 800, height = 600 }: {
  step: number;
  width?: number;
  height?: number;
}) => {
  // Calculate responsive size based on viewport
  const baseSize = Math.min(width, height) / 6;
  const hexSize = Math.max(baseSize, 70); // Keep minimum size at 70 to match existing
  const centerX = width / 2;
  const centerY = height / 2;

  const stepConfigs = {
    1: ( // Introduction
      <g>
        <HexagonComponent x={centerX} y={centerY} label="CPD" size={hexSize * 1.5} />
      </g>
    ),
    2: ( // Platform-Only Past
      <g>
        <HexagonComponent
          x={centerX - hexSize * 2}
          y={centerY}
          label="Creative"
          size={hexSize * 0.7}
          opacity={0.3}
        />
        <HexagonComponent
          x={centerX}
          y={centerY}
          label="PLATFORM"
          size={hexSize * 1.2}
        />
        <HexagonComponent
          x={centerX + hexSize * 2}
          y={centerY}
          label="Data"
          size={hexSize * 0.7}
          opacity={0.3}
        />
      </g>
    ),
    3: ( // Three Pillars
      <g>
        <HexagonComponent x={centerX - hexSize * 2} y={centerY} label="CREATIVE" color="#ec4899" size={hexSize} />
        <HexagonComponent x={centerX} y={centerY} label="PLATFORM" size={hexSize} opacity={0.3} />
        <HexagonComponent x={centerX + hexSize * 2} y={centerY} label="DATA" color="#6366f1" size={hexSize} opacity={0.3} />
      </g>
    ),
    4: (
      <g>
        <HexagonComponent x={centerX - hexSize * 2} y={centerY} label="CREATIVE" color="#ec4899" size={hexSize} opacity={0.3} />
        <HexagonComponent x={centerX} y={centerY} label="PLATFORM" size={hexSize} opacity={0.3} />
        <HexagonComponent x={centerX + hexSize * 2} y={centerY} label="DATA" color="#6366f1" size={hexSize} />
      </g>
    ),
    5: ( // Career Paths
      <g>
        <HexagonComponent x={centerX - hexSize * 2} y={centerY} label="CREATIVE" color="#ec4899" size={hexSize} />
        <HexagonComponent x={centerX} y={centerY} label="PLATFORM" size={hexSize} />
        <HexagonComponent x={centerX + hexSize * 2} y={centerY} label="DATA" color="#6366f1" size={hexSize} />
      </g>
    ),
    6: (
      <g>
        <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY - hexSize * 1.6}
          width={hexSize * 8}
          height={hexSize * 0.7}
          text="MARKETING STRATEGY"
        />
        <HexagonComponent x={centerX - hexSize * 2} y={centerY} label="CREATIVE" color="#999" opacity={0.8} size={hexSize} />
        <HexagonComponent x={centerX} y={centerY} label="PLATFORM" color="#999" opacity={0.8} size={hexSize} />
        <HexagonComponent x={centerX + hexSize * 2} y={centerY} label="DATA" color="#999" opacity={0.8} size={hexSize} />
      </g>
    ),
    7: ( // Project Management
      <g>
           <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY - hexSize * 1.6}
          width={hexSize * 8}
          height={hexSize * 0.7}
          opacity={0.3}
          color="#999"
          text="MARKETING STRATEGY"
        />
        <HexagonComponent x={centerX - hexSize * 2} y={centerY} label="CREATIVE" color="#999" opacity={0.8} size={hexSize} />
        <HexagonComponent x={centerX} y={centerY} label="PLATFORM" color="#999" opacity={0.8} size={hexSize} />
        <HexagonComponent x={centerX + hexSize * 2} y={centerY} label="DATA" color="#999" opacity={0.8} size={hexSize} />
        <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY + hexSize * 1.6}
          width={hexSize * 8}
          height={hexSize * 0.7}
          text="PROJECT MANAGEMENT"
        />
      </g>
    ),
    8: ( // Data Foundation
      <g>
           <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY - hexSize * 1.6}
          width={hexSize * 8}
          height={hexSize * 0.7}
          opacity={0.3}
          color="#999"
          text="MARKETING STRATEGY"
        />
        <HexagonComponent x={centerX - hexSize * 2} y={centerY} label="CREATIVE" color="#999" opacity={0.3} size={hexSize} />
        <HexagonComponent x={centerX} y={centerY} label="PLATFORM" color="#999" opacity={0.3} size={hexSize} />
        <HexagonComponent x={centerX + hexSize * 2} y={centerY} label="DATA" opacity={1} size={hexSize} />
        <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY + hexSize * 1.6}
          width={hexSize * 8}
          height={hexSize * 0.7}
          opacity={0.3}
          color="#999"
          text="PROJECT MANAGEMENT"
        />
      </g>
    ),
    9: ( // Data-Driven Future
      <g>
           <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY - hexSize * 1.6}
          width={hexSize * 8}
          height={hexSize * 0.7}
          color="#ec4899"
          text="AUTOMATION"
        />
        <HexagonComponent x={centerX} y={centerY} label="DATA" size={hexSize} />
        <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY + hexSize * 1.6}
          width={hexSize * 8}
          height={hexSize * 0.7}
          color="#6366f1"
          text="AI & AGENTS"
        />
      </g>
    ),
    10: ( // Continuous Growth
      <g>
        <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY - hexSize * 0.5}
          width={hexSize * 8}
          height={hexSize * 0.7}
          text="CONTINUOUS LEARNING"
          color="#ec4899"
        />
        <ExtendedHexagonComponent
          x={centerX - hexSize * 2}
          y={centerY + hexSize * 0.5}
          width={hexSize * 8}
          height={hexSize * 0.7}
          text="CAREER GROWTH"
          color="#6366f1"
        />
      </g>
    )
  };

  return (
    <svg width={width} height={height}>
      <rect width={width} height={height} fill="#fff" fillOpacity={0.1} />
      {stepConfigs[step as keyof typeof stepConfigs] || stepConfigs[1]}
    </svg>
  );
};