import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { useUser } from "@clerk/clerk-react";
import { Video, Star, Brain, Calendar, BookOpen, CheckCircle2 } from 'lucide-react';

export default function MasteryWorkshop() {
  const { user } = useUser();

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-purple-600 to-blue-700 text-white py-12 overflow-hidden">
        {/* Removed SVG Pattern Overlay */}
        {/*
        <svg className="absolute top-0 left-0 w-full h-full opacity-20 pointer-events-none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="pattern-circles" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
              <circle cx="20" cy="20" r="3" fill="white" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#pattern-circles)" />
        </svg>
        */}
        {/* Updated container width to 1400px */}
        <div className="relative container mx-auto px-4 max-w-[1400px] z-10 text-center">
          <Badge variant="secondary" className="mb-3 bg-white/10 backdrop-blur-sm">
            2024 Workshop Replay
          </Badge>
          <h1 className="text-5xl font-bold mb-4 drop-shadow-lg">
            Unlock the Power of Automation: <br /> Google Ads Scripts & Sheets Mastery 2024
          </h1>
          <p className="text-2xl mb-6 text-white/90 drop-shadow">
            Transform your campaigns with step‑by‑step training that shows you exactly how to automate your success.
          </p>
          <div className="flex justify-center gap-6 flex-wrap mb-6">
            <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full">
              <Video className="h-5 w-5" />
              <span className="font-semibold">Full-Length Workshop Replays</span>
            </div>
            <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full">
              <Star className="h-5 w-5" />
              <span className="font-semibold">Proven Sample Scripts Included</span>
            </div>
          </div>
          <a
            href="https://mikerhodes.circle.so/checkout/2024-scripts-and-sheets-workshop"
            className="inline-block"
          >
            <Button size="lg" className="bg-white text-purple-600 hover:bg-white/90 transition-colors duration-200 shadow-lg">
              Claim Instant Access for Only $299 USD
            </Button>
          </a>
        </div>
      </div>

      {/* Main Content */}
      {/* Updated container width to 1400px */}
      <div className="container mx-auto px-4 py-16 max-w-[1400px]">
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <Card className="transition-transform duration-300 hover:scale-105 hover:shadow-2xl rounded-lg">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Brain className="h-6 w-6 text-purple-600" />
                The QTS System™
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <p className="text-lg text-muted-foreground">
                Discover my signature QTS System™ – a foolproof blueprint to master Google Ads scripts even if you've never written a line of code.
              </p>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">Q</span>
                  <div>
                    <h4 className="font-semibold">Query</h4>
                    <p className="text-muted-foreground">
                      Extract precisely the data you need to skyrocket your campaigns.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">T</span>
                  <div>
                    <h4 className="font-semibold">Template</h4>
                    <p className="text-muted-foreground">
                      Leverage time‑tested templates to create powerful scripts in minutes.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <span className="text-purple-600 font-bold">S</span>
                  <div>
                    <h4 className="font-semibold">Sheet</h4>
                    <p className="text-muted-foreground">
                      Harness advanced Google Sheets techniques to automate your reporting.
                    </p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="transition-transform duration-300 hover:scale-105 hover:shadow-2xl rounded-lg">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Calendar className="h-6 w-6 text-purple-600" />
                Workshop Breakdown
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {[
                  { num: 1, title: "Script Fundamentals", desc: "Get hands‑on from installation to writing your very first script." },
                  { num: 2, title: "Google Sheets Mastery", desc: "Unlock advanced formulas and charting to visualize your data like a pro." },
                  { num: 3, title: "AI-Enhanced Scripting", desc: "Discover how AI can accelerate your script creation process." },
                  { num: 4, title: "Advanced Automation", desc: "Dive into high‑level automation and seamless API integrations." },
                ].map((item) => (
                  <div key={item.num} className="flex items-start gap-3">
                    <span className="bg-purple-100 dark:bg-purple-900 w-8 h-8 flex items-center justify-center rounded-full text-purple-600 font-bold flex-shrink-0">
                      {item.num}
                    </span>
                    <div>
                      <h4 className="font-semibold">{item.title}</h4>
                      <p className="text-muted-foreground">{item.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="mb-16 transition-transform duration-300 hover:scale-105 hover:shadow-2xl rounded-lg">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <BookOpen className="h-6 w-6 text-purple-600" />
              What's Included
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid md:grid-cols-2 gap-8">
              {/* Left Column */}
              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold text-lg mb-4">In-Depth Workshop Training</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Full-Length Replays</strong>
                        <p className="text-muted-foreground">Every session recorded – complete with timestamps for quick reference.</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Downloadable Materials</strong>
                        <p className="text-muted-foreground">Access slides, resource guides, and bonus cheat sheets.</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Real-World Exercises</strong>
                        <p className="text-muted-foreground">Practice with hands‑on exercises that cement your skills.</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-semibold text-lg mb-4">Exclusive Premium Scripts</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Instant-Use Sample Scripts</strong>
                        <p className="text-muted-foreground">Deploy proven scripts that have been battle-tested in real campaigns.</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>5+ Bonus Scripts</strong>
                        <p className="text-muted-foreground">Gain access to additional scripts designed to supercharge your workflow.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Right Column */}
              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold text-lg mb-4">Essential Resources & Tools</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>10+ Ready-Made Templates</strong>
                        <p className="text-muted-foreground">Jumpstart your projects with plug‑and‑play templates.</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>AI Prompt Vault</strong>
                        <p className="text-muted-foreground">Unlock a curated collection of high‑performance prompts for effortless scriptwriting.</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Expert Debugging Toolkit</strong>
                        <p className="text-muted-foreground">Quickly identify and resolve issues like a seasoned pro.</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div>
                  <h3 className="font-semibold text-lg mb-4">Exclusive Bonuses</h3>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Private Community Access</strong>
                        <p className="text-muted-foreground">Join an exclusive network of like-minded marketers and script enthusiasts.</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-purple-500 mt-1">✓</span>
                      <div>
                        <strong>Lifetime Content Updates</strong>
                        <p className="text-muted-foreground">Stay ahead of the curve with free, perpetual updates and new bonus content.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="mb-16 transition-transform duration-300 hover:scale-105 hover:shadow-2xl rounded-lg">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <CheckCircle2 className="h-6 w-6 text-purple-600" />
              What You'll Need
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-4">
              <h3 className="font-semibold text-lg">Everything You Need to Succeed</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  A basic understanding of Google Sheets
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  Access to your Google Ads account
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-purple-500">✓</span>
                  An eagerness to learn and innovate
                </li>
              </ul>
            </div>
          </CardContent>
        </Card>

        {/* Final CTA Section */}
        <div className="text-center space-y-6 py-8">
          <h2 className="text-3xl font-bold">Ready to Revolutionize Your Campaigns?</h2>
          <p className="text-xl text-muted-foreground">
            Take control of your marketing destiny. Unlock the complete workshop recordings and start automating success today!
          </p>
          <div className="flex flex-col items-center gap-4">
            <div className="text-4xl font-bold text-purple-600">$299</div>
            <a
              href="https://mikerhodes.circle.so/checkout/2024-scripts-and-sheets-workshop"
              className="inline-block"
            >
              <Button size="lg" className="bg-purple-600 hover:bg-purple-700 transition-colors duration-200 shadow-lg">
                Claim Instant Access Now
              </Button>
            </a>
            <p className="text-sm text-muted-foreground">
              One‑time payment • Price in USD • Lifetime access with free updates
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
