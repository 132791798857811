import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import type { ProcessedMetrics, BusinessMode, CampaignWithShare } from '@/types/metrics'
import type { CurvePoint } from '@/types/profit'

interface DebugPanelProps {
  campaign: CampaignWithShare | null
  curvePoints: CurvePoint[]
  currentMetrics: ProcessedMetrics
  businessMode: BusinessMode
  cogsAmount: number
  breakEvenCpa: number
  selectedCurrency: string
}

export function DebugPanel({
  campaign,
  curvePoints,
  currentMetrics,
  businessMode,
  cogsAmount,
  breakEvenCpa,
  selectedCurrency
}: DebugPanelProps) {
  if (!campaign || !curvePoints?.length) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Debug Panel</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-muted-foreground">No data available</div>
        </CardContent>
      </Card>
    )
  }

  const formatNumber = (num: number) => Math.round(num).toLocaleString()
  const formatCurrency = (num: number) => `${selectedCurrency}${formatNumber(num)}`
  const formatPercent = (num: number) => `${(num * 100).toFixed(1)}%`

  const currentCPA = currentMetrics.conv > 0 ? currentMetrics.cost / currentMetrics.conv : 0
  const currentROAS = currentMetrics.cost > 0 ? currentMetrics.value / currentMetrics.cost : 0
  const currentAOV = currentMetrics.conv > 0 ? currentMetrics.value / currentMetrics.conv : 0

  const minCostPoint = curvePoints[0]
  const maxCostPoint = curvePoints[curvePoints.length - 1]
  const maxProfitPoint = curvePoints.reduce((max, point) => point.profit > max.profit ? point : max, curvePoints[0])

  const currentIndex = curvePoints.findIndex(p => p.cost >= currentMetrics.cost)
  const leftPoint = currentIndex > 0 ? curvePoints[currentIndex - 1] : null
  const rightPoint = currentIndex < curvePoints.length ? curvePoints[currentIndex] : null

  return (
    <Card>
      <CardHeader>
        <CardTitle>Debug Panel</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-12 gap-4 text-sm">
          {/* Base Metrics */}
          <div className="col-span-2">
            <h3 className="font-semibold mb-2">Campaign Base</h3>
            <div className="grid grid-cols-2 gap-1">
              <div>Campaign:</div>
              <div>{campaign.campaign}</div>
              <div>Base Cost:</div>
              <div>{formatCurrency(campaign.cost)}</div>
              <div>Base Conv:</div>
              <div>{formatNumber(campaign.conv)}</div>
              <div>Base Value:</div>
              <div>{formatCurrency(campaign.value)}</div>
              <div>Impr Share:</div>
              <div>{campaign.imprShare ? formatPercent(campaign.imprShare) : 'N/A'}</div>
            </div>
          </div>

          {/* Current Point */}
          <div className="col-span-2">
            <h3 className="font-semibold mb-2">Current Point</h3>
            <div className="grid grid-cols-2 gap-1">
              <div>Cost:</div>
              <div>{formatCurrency(currentMetrics.cost)}</div>
              <div>Conv:</div>
              <div>{formatNumber(currentMetrics.conv)}</div>
              <div>Value:</div>
              <div>{formatCurrency(currentMetrics.value)}</div>
              <div>Profit:</div>
              <div>{formatCurrency(currentMetrics.profit)}</div>
              <div>CPA:</div>
              <div>{formatCurrency(currentCPA)}</div>
              <div>ROAS:</div>
              <div>{currentROAS.toFixed(2)}x</div>
              <div>AOV:</div>
              <div>{formatCurrency(currentAOV)}</div>
            </div>
          </div>

          {/* Left Point */}
          <div className="col-span-2">
            <h3 className="font-semibold mb-2">Left Point</h3>
            <div className="grid grid-cols-2 gap-1">
              {leftPoint ? (
                <>
                  <div>Cost:</div>
                  <div>{formatCurrency(leftPoint.cost)}</div>
                  <div>Conv:</div>
                  <div>{formatNumber(leftPoint.conv)}</div>
                  <div>Value:</div>
                  <div>{formatCurrency(leftPoint.value)}</div>
                  <div>Profit:</div>
                  <div>{formatCurrency(leftPoint.profit)}</div>
                  <div>CPA:</div>
                  <div>{formatCurrency(leftPoint.cpa || 0)}</div>
                  <div>ROAS:</div>
                  <div>{leftPoint.roas?.toFixed(2)}x</div>
                </>
              ) : (
                <div className="col-span-2">No left point</div>
              )}
            </div>
          </div>

          {/* Right Point */}
          <div className="col-span-2">
            <h3 className="font-semibold mb-2">Right Point</h3>
            <div className="grid grid-cols-2 gap-1">
              {rightPoint ? (
                <>
                  <div>Cost:</div>
                  <div>{formatCurrency(rightPoint.cost)}</div>
                  <div>Conv:</div>
                  <div>{formatNumber(rightPoint.conv)}</div>
                  <div>Value:</div>
                  <div>{formatCurrency(rightPoint.value)}</div>
                  <div>Profit:</div>
                  <div>{formatCurrency(rightPoint.profit)}</div>
                  <div>CPA:</div>
                  <div>{formatCurrency(rightPoint.cpa || 0)}</div>
                  <div>ROAS:</div>
                  <div>{rightPoint.roas?.toFixed(2)}x</div>
                </>
              ) : (
                <div className="col-span-2">No right point</div>
              )}
            </div>
          </div>

          {/* Curve Parameters */}
          <div className="col-span-2">
            <h3 className="font-semibold mb-2">Curve Params</h3>
            <div className="grid grid-cols-2 gap-1">
              <div>Mode:</div>
              <div>{businessMode}</div>
              <div>COGS:</div>
              <div>{formatPercent(cogsAmount/100)}</div>
              <div>B/E CPA:</div>
              <div>{formatCurrency(breakEvenCpa)}</div>
              <div>Min Cost:</div>
              <div>{formatCurrency(minCostPoint.cost)}</div>
              <div>Max Cost:</div>
              <div>{formatCurrency(maxCostPoint.cost)}</div>
            </div>
          </div>

          {/* Optimal Point */}
          <div className="col-span-2">
            <h3 className="font-semibold mb-2">Optimal Point</h3>
            <div className="grid grid-cols-2 gap-1">
              <div>Cost:</div>
              <div>{formatCurrency(maxProfitPoint.cost)}</div>
              <div>Conv:</div>
              <div>{formatNumber(maxProfitPoint.conv)}</div>
              <div>Value:</div>
              <div>{formatCurrency(maxProfitPoint.value)}</div>
              <div>Profit:</div>
              <div>{formatCurrency(maxProfitPoint.profit)}</div>
              <div>CPA:</div>
              <div>{formatCurrency(maxProfitPoint.cpa || 0)}</div>
              <div>ROAS:</div>
              <div>{maxProfitPoint.roas?.toFixed(2)}x</div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}