import { Provider, Model, APIUsage } from '@/types/ai'

function calculateCost(model: Model, usage: { inputTokens: number; outputTokens: number }): number {
    const inputCost = (usage.inputTokens / 1_000_000) * model.inputPrice
    const outputCost = (usage.outputTokens / 1_000_000) * model.outputPrice
    return inputCost + outputCost
}

export async function generateAIResponse(
    provider: Provider,
    model: Model,
    prompt: string,
    maxTokens: number,
    stream?: boolean
): Promise<{ text: string; usage: APIUsage } | AsyncGenerator<string, void, unknown>> {
    if (provider === 'gemini') {
        const apiKey = import.meta.env.VITE_GEMINI_API_KEY
        if (!apiKey) throw new Error('Gemini API key not found')

        const url = `https://generativelanguage.googleapis.com/v1beta/models/${model.id}:${stream ? 'streamGenerateContent' : 'generateContent'}?key=${apiKey}`

        const body = {
            contents: [{ parts: [{ text: prompt }] }],
            generationConfig: { maxOutputTokens: maxTokens }
        }

        if (stream) {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            })

            if (!response.ok) {
                const error = await response.json()
                if (response.status === 429) {
                    throw new Error('Rate limit exceeded')
                }
                throw new Error(error.error?.message || 'Gemini API error')
            }

            if (!response.body) {
                throw new Error('No response body')
            }

            const reader = response.body.getReader()
            const decoder = new TextDecoder()

            async function* streamResponse() {
                try {
                    while (true) {
                        const { done, value } = await reader.read()
                        if (done) break

                        const chunk = decoder.decode(value)
                        const lines = chunk.split('\n').filter(Boolean)

                        for (const line of lines) {
                            if (line.startsWith('data: ')) {
                                const data = JSON.parse(line.slice(5))
                                if (data.candidates?.[0]?.content?.parts?.[0]?.text) {
                                    yield data.candidates[0].content.parts[0].text
                                }
                            }
                        }
                    }
                } finally {
                    reader.releaseLock()
                }
            }

            return streamResponse()
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        })

        const data = await response.json()
        if (!response.ok) {
            if (response.status === 429) {
                throw new Error('Rate limit exceeded')
            }
            throw new Error(data.error?.message || 'Gemini API error')
        }

        const totalTokens = data.usageMetadata?.totalTokenCount || 0
        const outputTokens = data.usageMetadata?.candidatesTokenCount || 0
        const inputTokens = data.usageMetadata?.promptTokenCount || 0

        return {
            text: data.candidates[0].content.parts[0].text,
            usage: {
                inputTokens,
                outputTokens,
                costs: {
                    total: calculateCost(model, {
                        inputTokens,
                        outputTokens
                    })
                }
            }
        }
    }

    else if (provider === 'openai') {
        const apiKey = import.meta.env.VITE_OPENAI_API_KEY
        if (!apiKey) throw new Error('OpenAI API key not found')

        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`
            },
            body: JSON.stringify({
                model: model.id,
                messages: [{ role: 'user', content: prompt }],
                max_tokens: maxTokens
            })
        })

        const data = await response.json()
        if (!response.ok) {
            if (response.status === 429) {
                throw new Error('Rate limit exceeded')
            }
            throw new Error(data.error?.message || 'OpenAI API error')
        }

        const usage: APIUsage = {
            inputTokens: data.usage?.prompt_tokens || 0,
            outputTokens: data.usage?.completion_tokens || 0,
            costs: {
                total: calculateCost(model, {
                    inputTokens: data.usage?.prompt_tokens || 0,
                    outputTokens: data.usage?.completion_tokens || 0
                })
            }
        }

        return {
            text: data.choices[0].message.content,
            usage
        }
    }

    else if (provider === 'anthropic') {
        const apiKey = import.meta.env.VITE_ANTHROPIC_API_KEY
        if (!apiKey) throw new Error('Anthropic API key not found')

        const response = await fetch('https://api.anthropic.com/v1/messages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
                'anthropic-version': '2023-06-01',
                'anthropic-dangerous-direct-browser-access': 'true'
            },
            body: JSON.stringify({
                messages: [{ role: 'user', content: prompt }],
                model: model.id,
                max_tokens: maxTokens
            })
        })

        const data = await response.json()
        if (!response.ok) {
            if (response.status === 429) {
                throw new Error('Rate limit exceeded')
            }
            throw new Error(data.error?.message || 'Anthropic API error')
        }

        const usage: APIUsage = {
            inputTokens: data.usage?.input_tokens || 0,
            outputTokens: data.usage?.output_tokens || 0,
            costs: {
                total: calculateCost(model, {
                    inputTokens: data.usage?.input_tokens || 0,
                    outputTokens: data.usage?.output_tokens || 0
                })
            }
        }

        return {
            text: data.content[0].text,
            usage
        }
    }

    throw new Error(`Provider ${provider} not supported`)
}