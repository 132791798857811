// src/components/data-display.tsx
import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Slider } from "@/components/ui/slider"
import { ColumnChooser } from '@/components/ui/column-chooser'
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"

interface DataDisplayProps {
  viewType: 'tree' | 'table' | 'ngram'
  onViewTypeChange: (type: 'tree' | 'table' | 'ngram') => void
  data: any[]
  treeComponent: (props: { showAllKeywords: boolean }) => React.ReactNode
  tableComponent: React.ReactNode
  ngramComponent?: React.ReactNode
  onColumnsChange: (columns: Set<string>) => void
  visibleColumns: Set<string>
  onRowsPerPageChange: (rows: number) => void
  rowsPerPage: number
  type: 'keywords' | 'searchTerms'
  maxLength: number
  onMaxLengthChange: (value: number) => void
  wordCount?: number
  onWordCountChange?: (count: number) => void
  onCollapseAll?: () => void
  hasExpandedRows?: boolean
}

const COLUMNS = {
  keywords: [
    { key: 'campaign', label: 'Campaign', width: 'w-[240px]' },
    { key: 'adGroup', label: 'Ad Group', width: 'w-[200px]' },
    { key: 'keywordText', label: 'Keyword', width: 'w-[200px]' },
    { key: 'keywordMatchType', label: 'Match Type', width: 'w-[80px]' },
    { key: 'score', label: 'QS', width: 'w-[60px]' },
    { key: 'impr', label: 'Impressions', width: 'w-[80px]' },
    { key: 'clicks', label: 'Clicks', width: 'w-[80px]' },
    { key: 'CTR', label: 'CTR', width: 'w-[80px]' },
    { key: 'cost', label: 'Cost', width: 'w-[80px]' },
    { key: 'CPC', label: 'CPC', width: 'w-[80px]' },
    { key: 'conv', label: 'Conv.', width: 'w-[80px]' },
    { key: 'CvR', label: 'CvR', width: 'w-[80px]' },
    { key: 'value', label: 'Value', width: 'w-[80px]' },
    { key: 'ROAS', label: 'ROAS', width: 'w-[80px]' },
    { key: 'CPA', label: 'CPA', width: 'w-[80px]' },
    { key: 'expCTR', label: 'Exp. CTR', width: 'w-[80px]' },
    { key: 'adRelevance', label: 'Ad Relevance', width: 'w-[80px]' },
    { key: 'landingExp', label: 'Landing Exp.', width: 'w-[80px]' }
  ],
  searchTerms: [
    { key: 'campaign', label: 'Campaign', width: 'w-[240px]' },
    { key: 'searchMatchType', label: 'Match Type', width: 'w-[100px]' },
    { key: 'searchTerm', label: 'Search Term', width: 'w-[240px]' },
    { key: 'impr', label: 'Impressions', width: 'w-[80px]' },
    { key: 'clicks', label: 'Clicks', width: 'w-[80px]' },
    { key: 'CTR', label: 'CTR', width: 'w-[80px]' },
    { key: 'cost', label: 'Cost', width: 'w-[80px]' },
    { key: 'CPC', label: 'CPC', width: 'w-[80px]' },
    { key: 'conv', label: 'Conv.', width: 'w-[80px]' },
    { key: 'CvR', label: 'CvR', width: 'w-[80px]' },
    { key: 'value', label: 'Value', width: 'w-[80px]' },
    { key: 'ROAS', label: 'ROAS', width: 'w-[80px]' },
    { key: 'CPA', label: 'CPA', width: 'w-[80px]' }
  ],
  ngrams: [
    { key: 'ngram', label: 'N-gram', width: 'w-[200px]' },
    { key: 'wordCount', label: 'Words', width: 'w-[80px]' },
    { key: 'impr', label: 'Impressions', width: 'w-[80px]' },
    { key: 'clicks', label: 'Clicks', width: 'w-[80px]' },
    { key: 'CTR', label: 'CTR', width: 'w-[80px]' },
    { key: 'cost', label: 'Cost', width: 'w-[80px]' },
    { key: 'CPC', label: 'CPC', width: 'w-[80px]' },
    { key: 'conv', label: 'Conv.', width: 'w-[80px]' },
    { key: 'CvR', label: 'CvR', width: 'w-[80px]' },
    { key: 'value', label: 'Value', width: 'w-[80px]' },
    { key: 'ROAS', label: 'ROAS', width: 'w-[80px]' },
    { key: 'CPA', label: 'CPA', width: 'w-[80px]' }
  ]
}

export function DataDisplay({
  viewType,
  onViewTypeChange,
  data,
  treeComponent,
  tableComponent,
  ngramComponent,
  onColumnsChange,
  visibleColumns,
  onRowsPerPageChange,
  rowsPerPage,
  type,
  maxLength = 25,
  onMaxLengthChange,
  wordCount = 1,
  onWordCountChange,
  onCollapseAll,
  hasExpandedRows = false
}: DataDisplayProps) {
  const [showAllKeywords, setShowAllKeywords] = useState(true)

  const handleShowAllKeywordsChange = (checked: boolean) => {
    setShowAllKeywords(checked)
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-2">
          <Button
            variant={viewType === 'tree' ? 'default' : 'outline'}
            onClick={() => onViewTypeChange('tree')}
            size="sm"
          >
            Tree View
          </Button>
          <Button
            variant={viewType === 'table' ? 'default' : 'outline'}
            onClick={() => onViewTypeChange('table')}
            size="sm"
          >
            Table View
          </Button>
          <Button
            variant={viewType === 'ngram' ? 'default' : 'outline'}
            onClick={() => onViewTypeChange('ngram')}
            size="sm"
          >
            nGram View
          </Button>
        </div>

        <div className="flex items-center gap-4">
          {viewType === 'tree' ? (
            <>
              <div className="flex items-center gap-2">
                <Switch
                  checked={showAllKeywords}
                  onCheckedChange={handleShowAllKeywordsChange}
                />
                <Label>Show Keywords</Label>
              </div>
              <div className="flex items-center gap-2 w-[400px]">
                <span className="text-sm whitespace-nowrap">Name Length:</span>
                <Slider
                  value={[maxLength]}
                  onValueChange={([value]) => {
                    if (onMaxLengthChange) onMaxLengthChange(value)
                  }}
                  min={10}
                  max={80}
                  step={1}
                  className="flex-1"
                />
              </div>
            </>
          ) : (
            <div className="flex items-center gap-4">
              {viewType === 'ngram' && (
                <>
                  {hasExpandedRows && onCollapseAll && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={onCollapseAll}
                    >
                      Collapse All
                    </Button>
                  )}
                  {onWordCountChange && (
                    <Select
                      value={wordCount.toString()}
                      onValueChange={(value) => onWordCountChange(Number(value))}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Word Count" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="1">1-word nGrams</SelectItem>
                        <SelectItem value="2">2-word nGrams</SelectItem>
                        <SelectItem value="3">3-word nGrams</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                </>
              )}
              <ColumnChooser
                columns={COLUMNS[viewType === 'ngram' ? 'ngrams' : type]}
                visibleColumns={visibleColumns}
                onColumnToggle={(columnKey) => {
                  const newColumns = new Set(visibleColumns)
                  if (newColumns.has(columnKey)) {
                    newColumns.delete(columnKey)
                  } else {
                    newColumns.add(columnKey)
                  }
                  onColumnsChange(newColumns)
                }}
              />
              <Select
                value={rowsPerPage.toString()}
                onValueChange={(value) => onRowsPerPageChange?.(Number(value))}
              >
                <SelectTrigger className="w-[100px]">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {[10, 20, 30, 50, 100].map(size => (
                    <SelectItem key={size} value={size.toString()}>
                      {size} rows
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>
      </div>

      {viewType === 'tree' ? (
        treeComponent({ showAllKeywords })
      ) : viewType === 'table' ? (
        tableComponent
      ) : (
        ngramComponent
      )}
    </div>
  )
}