// src/components/version-gate-popover.tsx
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

const SETUP_INSTRUCTIONS = [
  'Delete this Account from the app and Use the Setup page to :',
  'Create a new template sheet from the latest version',
  'Download the new script (you can replace the existing 8020 script - you might need to remove the existing schedule first)',
  'Deploy the new web app',
  'Paste the new web app URL into step 3 of the setup page'
] as const;

interface VersionGatePopoverProps {
  isOpen: boolean
  onClose: () => void
  label?: string
  currentVersion: string
  minVersion: string
  onDelete: () => void
}

export function VersionGatePopover({
  isOpen,
  onClose,
  label,
  currentVersion,
  minVersion,
  onDelete
}: VersionGatePopoverProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Script Update Required</DialogTitle>
          <DialogDescription>
            <Alert variant="destructive" className="mt-4">
              <AlertDescription>
                <p className="mb-4">
                  The {label || 'feature'} requires the Google Ads script to be
                  version {minVersion} or higher. You're currently using
                  version {currentVersion}.
                </p>
                <div className="space-y-2 mb-6">
                  <p className="font-medium">To update:</p>
                  <ol className="list-decimal list-inside space-y-1">
                    {SETUP_INSTRUCTIONS.map((instruction, i) => (
                      <li key={i}>{instruction}</li>
                    ))}
                  </ol>
                </div>
                <div className="flex">
                  <Button onClick={onDelete}>
                    Delete Account & Set Up New Version  
                  </Button>
                </div>
              </AlertDescription>
            </Alert>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}