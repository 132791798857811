// src/components/layouts/tutorial-layout.tsx
import { Button } from "@/components/ui/button";
import { useNavigate, Outlet } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

export default function TutorialLayout({ children }: { children?: React.ReactNode }) {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen bg-background">
            <div className="border-b">
                <div className="container flex h-14 items-center px-4">
                    <Button
                        variant="ghost"
                        onClick={() => navigate('/')}
                        className="gap-2"
                    >
                        <ArrowLeft className="h-4 w-4" />
                        Back to 8020agent
                    </Button>
                </div>
            </div>
            <main>
                <Outlet />
            </main>
        </div>
    );
} 