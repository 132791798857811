// src/pages/build.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import {
    Loader2, CalendarDays, Users, Star, Video, Bot, Calendar,
    BookOpen, CheckCircle2, Zap, BarChart, DollarSign, LineChart,
    Brain, Code, Shield, Sparkles, Rocket
} from 'lucide-react';
import { toast } from '@/components/ui/use-toast';
import { testimonials } from '@/lib/testimonials';
import { useUser } from '@clerk/clerk-react';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';

const StatsCard = ({ icon: Icon, stat, description }: { icon: any, stat: string, description: string }) => (
    <Card className="border-none shadow-lg">
        <CardContent className="flex flex-col items-center p-6">
            <Icon className="h-8 w-8 text-purple-600 mb-2" />
            <div className="text-2xl font-bold mb-1">{stat}</div>
            <p className="text-sm text-center text-muted-foreground">{description}</p>
        </CardContent>
    </Card>
);

const PricingTier = ({ title, price, features, isVIP = false }: {
    title: string,
    price: string,
    features: string[],
    isVIP?: boolean
}) => (
    <Card className={cn("relative", isVIP && "border-purple-600")}>
        {isVIP && <Badge className="absolute top-4 right-4">VIP</Badge>}
        <CardHeader>
            <CardTitle>{title}</CardTitle>
            <div className="text-3xl font-bold mt-2">${price}</div>
        </CardHeader>
        <CardContent>
            <ul className="space-y-3">
                {features.map((feature, i) => (
                    <li key={i} className="flex items-start gap-2">
                        <span className="text-purple-500 mt-1">✓</span>
                        <span>{feature}</span>
                    </li>
                ))}
            </ul>
        </CardContent>
    </Card>
);

const Testimonial = ({ quote, author, role, image }: {
    quote: string,
    author: string,
    role: string,
    image: string
}) => (
    <Card className="border-none shadow-lg">
        <CardContent className="p-6">
            <div className="flex items-start gap-4">
                <img src={image} alt={author} className="w-16 h-14 rounded-full object-cover" />
                <div>
                    <p className="text-lg mb-4" dangerouslySetInnerHTML={{
                        __html: quote.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                    }} />
                    <p className="font-semibold">{author}</p>
                    <p className="text-sm text-muted-foreground">{role}</p>
                </div>
            </div>
        </CardContent>
    </Card>
);

const BenefitCard = ({ icon: Icon, title, description }: {
    icon: any,
    title: string,
    description: string
}) => (
    <Card className="border-none shadow-lg">
        <CardContent className="p-6">
            <Icon className="h-8 w-8 text-purple-600 mb-4" />
            <h3 className="text-xl font-bold mb-2">{title}</h3>
            <p className="text-muted-foreground">{description}</p>
        </CardContent>
    </Card>
);

export default function BuildThePage() {
    const { user, isLoaded } = useUser();

    // Add debug logging
    useEffect(() => {
        console.log('Clerk user:', { user, isLoaded });
    }, [user, isLoaded]);

    return (
        <div className="min-h-screen">
            {/* Hero Section - Reduced height */}
            <div className="relative bg-gradient-to-r from-purple-800 to-indigo-500 text-white py-12">
                <div className="container mx-auto px-4 max-w-6xl">
                    <div className="text-center mb-8">
                        <Badge variant="secondary" className="mb-4 bg-white/50 text-lg">Early Bird Pricing Until April 16</Badge>
                        <h1 className="text-6xl font-bold mb-6">From Smart Scripts to Intelligent Agents: The Evolution of PPC Professionals</h1>
                        <p className="text-2xl mb-4 text-white/90">We'll start with Google Ads scripts so you get value for your day job immediately, then</p>
                        <p className="text-2xl mb-4 text-white/90">I'll build all the code for your first agent & give it to you (value $999)</p>
                        <p className="text-2xl mb-4 text-white/90">Then teach you how to build more just by talking to your computer</p>
                        <p className="text-xl mb-8 text-white/70">Gain the most in-demand skills, transform your career & jump on the biggest business trend of 2025</p>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center gap-6 mt-12">
                        <a href="https://mikerhodes.circle.so/checkout/build-the-agent">
                            <Button className="bg-orange-500 hover:bg-orange-600 text-white text-lg px-8 py-6 h-auto font-semibold shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-105" size="lg">
                                Early Bird - $799
                            </Button>
                        </a>
                        <a href="https://mikerhodes.circle.so/checkout/build-the-agent-vip">
                            <Button className="bg-emerald-600 hover:bg-emerald-700 text-white text-lg px-8 py-6 h-auto font-semibold shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-105" size="lg">
                                Early Bird VIP - $1999
                            </Button>
                        </a>
                    </div>
                </div>
            </div>

            {/* Industry Leader Quote & Video Section */}
            <div className="bg-slate-50 dark:bg-slate-900 py-8">
                <div className="container mx-auto px-4 max-w-6xl">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Industry Leaders on AI Agents</h2>
                    <p className="text-3xl font-bold text-center mb-12">The Future is Here: AI Agents are the Next Big Thing</p>
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div className="space-y-8">
                            <p className="text-xl">The most inside-AI of all AI people believe that AI Agents will transform work as we know it.</p>
                            <div className="relative">
                                <div className="absolute -left-4 top-0 text-6xl text-purple-300 opacity-50">"</div>
                                <blockquote className="relative z-10 pl-6 text-xl">
                                    <p className="mb-4">AI agents are AI systems that can do work for you independently. You give them a task and they go off and do it.</p>
                                    <p>We think this is going to be a big trend in AI and really impact the work people can do, how productive they can be, how creative they can be, what they can accomplish.</p>
                                    <footer className="mt-4 text-sm text-muted-foreground">
                                        <cite>Sam Altman, CEO of OpenAI - Jan 2025</cite>
                                    </footer>
                                </blockquote>
                            </div>
                            <div className="bg-indigo-100 dark:bg-indigo-950/40 p-6 rounded-lg shadow-lg">
                                <p className="text-lg italic mb-4">"I used to love programming, but what killed it for me was repetition of the boring bits. This is all joy! My mind is awash with the possibilities and I'm having so much fun now."</p>
                                <p className="text-sm text-muted-foreground">- Lee Duncan, Agency Owner (Cohort 1 Participant)</p>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className="aspect-video">
                                <iframe
                                    className="w-full h-full rounded-lg shadow-lg"
                                    src="https://www.youtube.com/embed/hdi5PhCsOhc"
                                    title="Patrick Gilbert on AI Agents"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            <p className="text-sm text-muted-foreground text-center">
                                Watch Patrick Gilbert, CEO of AdVenture Media and author of 'Join or Die', share his praise for Build the Agent 2025.
                                <a href="https://www.linkedin.com/in/patrickjamesgilbert/" target="_blank" rel="noopener noreferrer" className="text-purple-600 hover:text-purple-700 ml-1">
                                    Patrick's LinkedIn
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-12 text-center max-w-3xl mx-auto">
                        <p className="text-2xl font-bold">The AI revolution is accelerating, and agents are at the forefront.
                            <br />
                            But how do you actually build one? Where do you start?</p>
                    </div>
                </div>
            </div>

            {/* 4Cs Framework Section */}
            <div className="container mx-auto px-4 py-12 max-w-6xl">
                <p className="text-3xl font-bold text-center mb-12">Building AI Agents Doesn't Have to Be Complicated</p>

                <div className="prose prose-lg dark:prose-invert max-w-none mb-12">
                    <p className="text-lg text-muted-foreground">
                        For the past year and a half, I've been coding almost every day - building scripts, experimenting with AI, and pushing the boundaries of what's possible. Through countless hours of trial and error, I've learned not just what works, but more importantly, what doesn't.
                    </p>

                    <p className="text-lg text-muted-foreground">
                        I've already been offered a six-figure sum for 8020agent, but I turned it down. So why am I sharing exactly how to build these tools for less than a thousand dollars?
                    </p>

                    <p className="text-lg text-muted-foreground">
                        Because this is THE skill that agencies need to master. It's too important to keep to myself. The purpose of this course is to save you months of trial and error, days of frustration, and thousands of dollars in wasted time.
                    </p>
                </div>

                <div className="grid md:grid-cols-3 gap-8 items-start">
                    {/* Left side text - takes up 2 columns */}
                    <div className="md:col-span-2 space-y-8">
                        <div className="prose prose-lg dark:prose-invert max-w-none">
                            <p className="text-lg text-muted-foreground mb-8">
                                Scripts & Sheets Mastery was all about creating scripts that could <strong>CHECK</strong> and <strong>CHART</strong> your data,
                                Now we're going to take your skills to the next level.
                                Learn to build Google Ads scripts that can not only make <strong>CHANGES</strong> to your account
                                they can use <strong>COGNITION</strong> to help you automate your work, save time & gain rare & valuable skills!
                                <br />
                                All following my 'as is', 'adjust', 'author' framework. Simple to follow. Wonderfully powerful.
                            </p>

                            <div className="bg-indigo-100 dark:bg-indigo-950/40 p-6 rounded-lg shadow-lg mb-8">
                                <p className="text-lg italic mb-4">"I've been playing around with Cursor, it's magic! You also inspired me to go back to pen and paper. Now, I first sketch out an idea on paper: what dots do I want to connect, where should the info come from? Then, I try to translate it into Sheets with a script."</p>
                                <p className="text-sm text-muted-foreground">- Wilco Mulder, PPC Expert (Cohort 1 Participant)</p>
                            </div>

                            <div className="grid grid-cols-2 gap-8">
                                <div>
                                    <h3 className="font-bold text-xl mb-4">Basic Scripts</h3>
                                    <div className="space-y-4">
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Check (alerts)</h4>
                                            <p className="text-muted-foreground">Monitor your accounts and get notified of important changes or issues.</p>
                                        </div>
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Chart (info)</h4>
                                            <p className="text-muted-foreground">Visualize your data to uncover insights and patterns in your campaigns.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-bold text-xl mb-4">Advanced Scripts</h3>
                                    <div className="space-y-4">
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Change (action)</h4>
                                            <p className="text-muted-foreground">Automatically make optimizations and adjustments to your campaigns.</p>
                                        </div>
                                        <div>
                                            <h4 className="font-semibold text-lg mb-2">Cognition (think)</h4>
                                            <p className="text-muted-foreground">Use AI to make intelligent decisions and strategic recommendations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right side image - with added top padding on mobile */}
                    <div className="relative pt-[100px] md:pt-[20px]">
                        <img
                            src="/images/cognition.png"
                            alt="4Cs Framework: Check, Chart, Change, Cognition"
                            className="w-full rounded-lg shadow-lg"
                        />
                    </div>
                </div>
                <div className="mt-12 text-center max-w-3xl mx-auto">
                    <p className="text-2xl font-bold">Now that you understand the framework, let's look at how this will transform your business...</p>
                </div>
            </div>

            {/* Benefits/Outcomes Section */}
            <div className="container mx-auto px-4 py-12 max-w-6xl">
                <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">The Impact</h2>
                <p className="text-3xl font-bold text-center mb-12">Your Competitive Advantage in 2025 and Beyond</p>
                <div className="grid md:grid-cols-3 gap-8 mb-16">
                    <BenefitCard
                        icon={Zap}
                        title="Automate Specific Tasks"
                        description="Create AI agents that handle targeted tasks like campaign optimization, reporting, and client management"
                    />
                    <BenefitCard
                        icon={DollarSign}
                        title="Expand Your Services"
                        description="Offer high-value AI services beyond chat - from custom tools to complete automation solutions"
                    />
                    <BenefitCard
                        icon={BarChart}
                        title="Stay Ahead"
                        description="Master the skills that will define business success in 2025 and beyond"
                    />
                </div>
                <div className="bg-purple-50 dark:bg-purple-900/20 rounded-2xl p-8 mb-1">
                    <h3 className="text-2xl font-bold mb-6">Real Results from AI Agents:</h3>
                    <div className="grid md:grid-cols-2 gap-6">
                        {[
                            "Save 5-20 hours per week on campaign management and reporting",
                            "Automate 80% of routine Google Ads tasks & make your team happier",
                            "Generate thousands per month in new service revenue",
                            "Reduce campaign management time by 60%",
                            "Create custom tools worth $500 to $2,000 each (or more)",
                            "Deploy agents that work 24/7 monitoring accounts"
                        ].map((benefit, i) => (
                            <div key={i} className="flex items-start gap-3">
                                <CheckCircle2 className="h-5 w-5 text-purple-600 mt-1" />
                                <span>{benefit}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-8 bg-indigo-100 dark:bg-indigo-950/40 p-6 rounded-lg shadow-lg">
                    <p className="text-lg italic mb-4">"I'm building a negative finder script that gets 180 days of data, compares terms, and uses AI to suggest potential negatives. Next stage - email me the results and add negatives to account following a review."</p>
                    <p className="text-sm text-muted-foreground">- Andy, Clarity PPC (Cohort 1 Participant)</p>
                </div>
            </div>

            {/* Schedule Section */}
            <div className="container mx-auto px-4 py-1 max-w-6xl">
                <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Ready to master these skills? Here's exactly how we'll get you there...</h2>
                <p className="text-3xl font-bold text-center mb-12">From Basics to Building Your Own AI Agents in 8 Weeks</p>
                <div className="relative space-y-12">
                    {/* First 4 weeks */}
                    <div className="relative">
                        {/* Vertical Path line */}
                        <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-purple-200 dark:bg-purple-800 z-0" />

                        <div className="relative z-10 grid grid-cols-1 md:grid-cols-2 gap-6">
                            {[
                                {
                                    week: "Week 1",
                                    date: "April 23, 2025",
                                    title: "From Scripts to AI Agents",
                                    description: "Lay the foundation for AI-enhanced development",
                                    extras: [
                                        "Master the two core skills – describing what you want & debugging with AI",
                                        "Break down complex problems into reusable, modular \"Lego blocks\"",
                                        "Learn the 4Cs framework – Check, Chart, Change, Cognition",
                                        "Use AI as a coding partner to accelerate learning and problem-solving"
                                    ],
                                    icon: Bot,
                                    side: "left"
                                },
                                {
                                    week: "Week 2",
                                    date: "April 30, 2025",
                                    title: "Making Scripts Think",
                                    description: "Bridge automation and AI reasoning",
                                    extras: [
                                        "Integrate AI into scripts to analyze and interpret data",
                                        "Experiment with AI-driven decision-making inside Google Ads & beyond",
                                        "Learn structured prompting techniques for guiding AI effectively",
                                        "Refine your debugging workflow to handle AI-generated code"
                                    ],
                                    icon: Brain,
                                    side: "right"
                                },
                                {
                                    week: "Week 3",
                                    date: "May 7, 2025",
                                    title: "Your First AI Agent",
                                    description: "Move from scripts to autonomous applications",
                                    extras: [
                                        "Deploy an AI-powered agent that processes, learns, and acts",
                                        "Step beyond spreadsheets – introduce a UI for real-world interaction",
                                        "Use AI-assisted development to build functional, adaptable tools",
                                        "Master the transition from static scripts to dynamic applications"
                                    ],
                                    icon: Bot,
                                    side: "left"
                                },
                                {
                                    week: "Week 4",
                                    date: "May 14, 2025",
                                    title: "Design & Debugging",
                                    description: "Building better tools with practical techniques",
                                    extras: [
                                        "Design principles & rapid prototyping",
                                        "Practical debugging techniques",
                                        "Hands-on troubleshooting workshop"
                                    ],
                                    icon: Shield,
                                    side: "right"
                                }
                            ].map((week, i) => (
                                <div key={i} className={cn(
                                    "bg-white dark:bg-slate-800 rounded-lg p-6 shadow-lg border border-purple-100 dark:border-purple-900",
                                    "relative",
                                    week.side === "left" ? "md:mr-8" : "md:ml-8",
                                    week.side === "right" && "md:col-start-2"
                                )}>
                                    {/* Connector dot */}
                                    <div className="hidden md:block absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full bg-purple-600 ring-4 ring-purple-200 dark:ring-purple-800"
                                        style={{
                                            [week.side === "left" ? "right" : "left"]: "-28px"
                                        }}
                                    />
                                    {/* Connector line */}
                                    <div className="hidden md:block absolute top-1/2 h-1 bg-purple-200 dark:bg-purple-800 w-8"
                                        style={{
                                            [week.side === "left" ? "right" : "left"]: "-32px"
                                        }}
                                    />
                                    <div className="flex items-start gap-4">
                                        <week.icon className="h-6 w-6 text-purple-600 mt-1" />
                                        <div>
                                            <div className="font-semibold text-purple-600 mb-1">{week.week}</div>
                                            <div className="text-sm text-muted-foreground mb-2">{week.date}</div>
                                            <h3 className="text-xl font-bold mb-2">{week.title}</h3>
                                            <p className="text-muted-foreground mb-2">{week.description}</p>
                                            {week.extras && (
                                                <ul className="space-y-1 text-sm text-muted-foreground">
                                                    {week.extras.map((extra, i) => (
                                                        <li key={i} className="flex items-center gap-2">
                                                            <span className="text-purple-500">•</span>
                                                            {extra}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Break Week Indicator */}
                    <div className="relative py-4 text-center">
                        <div className="absolute left-0 right-0 top-1/2 h-1 bg-purple-200 dark:bg-purple-800 -z-10" />
                        <span className="bg-white dark:bg-background px-4 text-lg font-semibold text-purple-600">
                            Break Week - May 21, 2025
                        </span>
                    </div>

                    {/* Last 4 weeks */}
                    <div className="relative">
                        {/* Vertical Path line */}
                        <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-purple-200 dark:bg-purple-800 z-0" />

                        <div className="relative z-10 grid grid-cols-1 md:grid-cols-2 gap-6">
                            {[
                                {
                                    week: "Week 5",
                                    date: "May 28, 2025",
                                    title: "Advanced Data Handling",
                                    description: "Mastering data workflows",
                                    extras: [
                                        "Building specialized mega prompts",
                                        "Data validation techniques",
                                        "Working with complex data structures"
                                    ],
                                    icon: Code,
                                    side: "left"
                                },
                                {
                                    week: "Week 6",
                                    date: "June 4, 2025",
                                    title: "Integration Workshop",
                                    description: "We'll build an interactive game/tool",
                                    extras: [
                                        "Combine all techniques learned",
                                        "Building dashboards & tools",
                                        "Interactive game that reinforces concepts"
                                    ],
                                    icon: Sparkles,
                                    side: "right"
                                },
                                {
                                    week: "Week 7",
                                    date: "June 11, 2025",
                                    title: "Deployment & Custom Domains",
                                    description: "Get your agent live on the web",
                                    extras: [
                                        "Step-by-step Vercel deployment",
                                        "Setting up custom domains",
                                        "Exploring other hosting options"
                                    ],
                                    icon: LineChart,
                                    side: "left"
                                },
                                {
                                    week: "Week 8",
                                    date: "June 18, 2025",
                                    title: "Group Q&A & Showcase",
                                    description: "Ensure you're ready to succeed",
                                    extras: [
                                        "Live troubleshooting session",
                                        "Participant project showcase",
                                        "Final tips & next steps"
                                    ],
                                    icon: Rocket,
                                    side: "right"
                                }
                            ].map((week, i) => (
                                <div key={i} className={cn(
                                    "bg-white dark:bg-slate-800 rounded-lg p-6 shadow-lg border border-purple-100 dark:border-purple-900",
                                    "relative",
                                    week.side === "left" ? "md:mr-8" : "md:ml-8",
                                    week.side === "right" && "md:col-start-2"
                                )}>
                                    {/* Connector dot */}
                                    <div className="hidden md:block absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full bg-purple-600 ring-4 ring-purple-200 dark:ring-purple-800"
                                        style={{
                                            [week.side === "left" ? "right" : "left"]: "-28px"
                                        }}
                                    />
                                    {/* Connector line */}
                                    <div className="hidden md:block absolute top-1/2 h-1 bg-purple-200 dark:bg-purple-800 w-8"
                                        style={{
                                            [week.side === "left" ? "right" : "left"]: "-32px"
                                        }}
                                    />
                                    <div className="flex items-start gap-4">
                                        <week.icon className="h-6 w-6 text-purple-600 mt-1" />
                                        <div>
                                            <div className="font-semibold text-purple-600 mb-1">{week.week}</div>
                                            <div className="text-sm text-muted-foreground mb-2">{week.date}</div>
                                            <h3 className="text-xl font-bold mb-2">{week.title}</h3>
                                            <p className="text-muted-foreground mb-2">{week.description}</p>
                                            {week.extras && (
                                                <ul className="space-y-1 text-sm text-muted-foreground">
                                                    {week.extras.map((extra, i) => (
                                                        <li key={i} className="flex items-center gap-2">
                                                            <span className="text-purple-500">•</span>
                                                            {extra}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mt-12 text-center max-w-3xl mx-auto">
                    <p className="text-2xl font-bold">Choose the path that's right for you...</p>
                </div>
            </div>
            {/* Pricing Section */}
            <div className="bg-slate-50 dark:bg-slate-900 py-12">
                <div className="container mx-auto px-4 max-w-6xl">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">You have Two Investment Options</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <Card className="relative flex flex-col">
                            <CardHeader>
                                <CardTitle>Early Bird</CardTitle>
                                <div className="space-y-2">
                                    <div className="text-3xl font-bold">$799 USD</div>
                                    <div className="text-xs text-muted-foreground">Early Bird Price, Reg. $1,499 after April 16</div>
                                </div>
                            </CardHeader>
                            <CardContent className="flex-1 flex flex-col">
                                <ul className="space-y-3 mb-6">
                                    {[
                                        "Custom AI Agent (valued at $999)",
                                        "8-Week intensive live workshop",
                                        "Scripts & Sheets Mastery ($299 value)",
                                        "Core Courses ($299 value)",
                                        "Extensive prompt library",
                                        "Ready-to-use code templates",
                                        "Implementation guides",
                                        "Community support"
                                    ].map((feature, i) => (
                                        <li key={i} className="flex items-start gap-2">
                                            <span className="text-purple-500 mt-1">✓</span>
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                                <a href="https://mikerhodes.circle.so/checkout/build-the-agent" className="mt-auto">
                                    <Button className="w-full bg-purple-600 hover:bg-purple-700 text-white">
                                        Get Started Now
                                    </Button>
                                </a>
                            </CardContent>
                        </Card>

                        <Card className="relative border-purple-600 flex flex-col">
                            <Badge className="absolute top-4 right-4">VIP</Badge>
                            <CardHeader>
                                <CardTitle>VIP Track</CardTitle>
                                <div className="space-y-2">
                                    <div className="text-3xl font-bold">$1999 USD</div>
                                    <div className="text-xs text-muted-foreground">Early Bird Price, Reg. $2,999 after April 16</div>
                                    <div className="text-sm text-muted-foreground">Very limited spots</div>
                                </div>
                            </CardHeader>
                            <CardContent className="flex-1 flex flex-col">
                                <ul className="space-y-3 mb-6">
                                    {[
                                        "Everything in Standard Program",
                                        "4 private 1-on-1 consulting calls",
                                        "Dedicated VIP space in Circle"
                                    ].map((feature, i) => (
                                        <li key={i} className="flex items-start gap-2">
                                            <span className="text-purple-500 mt-1">✓</span>
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                                <a href="https://mikerhodes.circle.so/checkout/build-the-agent-vip" className="mt-auto">
                                    <Button className="w-full bg-purple-600 hover:bg-purple-700 text-white">
                                        Get VIP Access Now
                                    </Button>
                                </a>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>

            {/* Testimonials */}
            <div className="bg-slate-50 dark:bg-slate-900 py-12 overflow-hidden">
                <div className="container mx-auto px-4 max-w-6xl">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Success Stories</h2>
                    <p className="text-3xl font-bold text-center mb-4">Join Our Community of AI Pioneers</p>
                    <p className="text-lg text-center text-muted-foreground mb-12">See what participants from previous courses have said</p>
                    <div className="grid md:grid-cols-2 gap-8 -mx-32">
                        {testimonials.map((testimonial, i) => (
                            <div key={i} className={cn(
                                "transform",
                                i % 2 === 0 ? "-translate-x-16" : "translate-x-16"
                            )}>
                                <Card className="border-none shadow-lg bg-white dark:bg-slate-800">
                                    <CardContent className="p-6">
                                        <div className="flex items-start gap-4">
                                            <img src={testimonial.image} alt={testimonial.author} className="w-16 h-14 rounded-full object-cover" />
                                            <div>
                                                <p className="text-lg mb-4" dangerouslySetInnerHTML={{
                                                    __html: testimonial.quote.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                                                }} />
                                                <p className="font-semibold">{testimonial.author}</p>
                                                <p className="text-sm text-muted-foreground">{testimonial.role}</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Value Stack Section */}
            <div className="container mx-auto px-4 py-12 max-w-6xl">
                <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">The Full Package</h2>
                <p className="text-3xl font-bold text-center mb-12">Everything You Need to Succeed</p>
                <div className="max-w-2xl mx-auto">
                    <Card className="border-2 border-purple-200 dark:border-purple-800">
                        <CardContent className="p-8">
                            <ul className="space-y-4">
                                {[
                                    { name: "Custom AI Agent", value: 999 },
                                    { name: "Live Training Program", value: 2000 },
                                    { name: "Templates & Frameworks", value: 499 },
                                    { name: "Scripts & Sheets Mastery", value: 299 },
                                    { name: "Community Access", value: 199 },
                                    { name: "Early Access to Updates", value: 100 },
                                    { name: "'Own the Agent™' Gift Certificate", value: 499 }
                                ].map((item, i) => (
                                    <li key={i} className="flex justify-between items-center">
                                        <span>{item.name}</span>
                                        <span className="font-bold">${item.value}</span>
                                    </li>
                                ))}
                                <li className="pt-4 border-t">
                                    <div className="flex justify-between items-center text-lg font-bold">
                                        <span>Total Value</span>
                                        <span>$4,595</span>
                                    </div>
                                </li>
                                <li className="pt-4 border-t">
                                    <div className="flex justify-between items-center text-2xl font-bold text-purple-600">
                                        <span>Your Investment</span>
                                        <span>$799</span>
                                    </div>
                                    <div className="text-sm text-muted-foreground mt-2 text-right">
                                        Early Bird Price (Regular $1,499 after April 16)
                                    </div>
                                </li>
                            </ul>
                        </CardContent>
                    </Card>
                </div>
            </div>

            {/* About Me Section */}
            <div className="container mx-auto px-4 py-12 max-w-6xl">
                <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Your Guide</h2>
                <p className="text-3xl font-bold text-center mb-12">Learn from Someone Who's Been There</p>
                <div className="grid md:grid-cols-2 gap-12 items-center">
                    <div>
                        <img src="/images/people/mike.png" alt="Profile" className="rounded-lg shadow-lg" />
                    </div>
                    <div>
                        <div className="space-y-4">
                            <p>Hi I'm Mike, husband to Gabbi & dad to two gorgeous girls</p>
                            <p>Built & sold 4 businesses over the past 20 years</p>
                            <p>The last one was WebSavvy, named by Google as a Global Top 18 Agency</p>
                            <p>Trained 20,000+ professionals in Google Ads</p>
                            <p>Co-authored the world's best-selling book on Google Ads</p>
                            <p>Top 20 Most Influential PPC Expert since 2015</p>
                            <p>Teaching AI implementation since 2017</p>
                            <p>Spoken at 100+ events worldwide</p>
                            <p>Creator of 8020agent.com</p>
                            <p>Huge fan of AI agents and excited to help you build your first one!</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Why Now Section */}
            <div className="bg-slate-50 dark:bg-slate-900 py-12">
                <div className="container mx-auto px-4 max-w-6xl">
                    <h2 className="text-2xl font-semibold mb-4 text-center text-muted-foreground">Perfect Timing</h2>
                    <p className="text-3xl font-bold text-center mb-12">Why AI Agents Are the Next Big Opportunity</p>
                    <div className="grid md:grid-cols-2 gap-12 items-center mb-12">
                        <div>
                            <div className="space-y-6">
                                <div>
                                    <h3 className="text-xl font-bold mb-2">Market Growth</h3>
                                    <p className="text-muted-foreground">AI agent adoption is forecast to grow almost 10x in the next 5 years</p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-2">Early Advantage</h3>
                                    <p className="text-muted-foreground">Only 8% of businesses currently using AI agents - massive opportunity for early adopters</p>
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-2">Revenue Potential</h3>
                                    <p className="text-muted-foreground">AI services commanding 2-3x higher rates than traditional services</p>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <img
                                src="/images/agents.webp"
                                alt="AI Agents Visualization"
                                className="rounded-lg shadow-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Enhanced Final CTA */}
            <div className="bg-gradient-to-r from-purple-600 to-indigo-700 text-white py-12">
                <div className="container mx-auto px-4 text-center max-w-3xl">
                    <h2 className="text-4xl font-bold mb-6">The AI Revolution Is Here</h2>
                    <p className="text-xl mb-8">Join your fellow pioneers who are using AI agents to transform their agencies and client businesses. Get your custom agent and learn to build more</p>
                    <div className="flex flex-col md:flex-row justify-center gap-6 mt-8">
                        <a href="https://mikerhodes.circle.so/checkout/build-the-agent">
                            <Button className="bg-orange-500 hover:bg-orange-600 text-white text-lg px-8 py-6 h-auto font-semibold shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-105" size="lg">
                                Early Bird - $799
                            </Button>
                        </a>
                        <a href="https://mikerhodes.circle.so/checkout/build-the-agent-vip">
                            <Button className="bg-emerald-600 hover:bg-emerald-700 text-white text-lg px-8 py-6 h-auto font-semibold shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-105" size="lg">
                                Early Bird VIP - $1999
                            </Button>
                        </a>
                    </div>
                    <p className="text-sm mt-4">Early bird pricing ends April 16</p>
                </div>
            </div>
        </div>
    );
} 