// src/lib/sample-data-utils.ts
import Papa from 'papaparse';
import { db } from '@/services/db'
import {
  StorageData,
  DailyData,
  HourlyData,
  CampaignSettings,
  ProductData,
  SearchTermData,
  PMaxData,
  AccountInfo,
  BaseMetrics,
  Campaign,
  QualityData,
  PlacementData,
  CampaignMetrics,
  KeywordData,
  CampConvData,
  ConvActionData,
  AssetData,
  GroupData,
  TermData,
  SummaryData,
  NGramData,
  PathData,
  PTitleData
} from '@/types/metrics';
import { SHEET_TABS, SAMPLE_ACCOUNT_ID, SAMPLE_ACCOUNT_SCRIPT_VERSION } from './constants';

const DEBUG = process.env.NODE_ENV === 'development';

interface LocalBaseMetrics {
  impr: number;
  clicks: number;
  cost: number;
  conv: number;
  value: number;
  imprShare?: number;
  lostBudget?: number;
  lostRank?: number;
}

// Helper function to sanitize strings in an object
const sanitizeStrings = (obj: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {}
  for (const [key, value] of Object.entries(obj)) {
    result[key] = typeof value === 'string' ? value.trim() : value
  }
  return result
}

// Helper to handle both cases for metric names
export const getMetricValue = (row: Record<string, any>, camelCase: string, pascalCase: string): number => {
  return Number(row[camelCase] || row[pascalCase] || 0);
};

export const transformBaseMetrics = (row: Record<string, any>): LocalBaseMetrics & {
  CTR: number;
  CvR: number;
  ROAS: number;
  CPA: number;
  AOV: number;
  profit: number;
} => {
  const impr = Number(row.Impressions || row.impr || 0);
  const clicks = Number(row.Clicks || row.clicks || 0);
  const cost = Number(row.Cost || row.cost || 0);
  const conv = Number(row.Conversions || row.conv || 0);
  const value = Number(row.ConvValue || row.value || 0);
  const imprShare = Number(row.ImprShare || row.imprShare || 0);
  const lostBudget = Number(row.LostToBudget || row.lostBudget || 0);
  const lostRank = Number(row.LostToRank || row.lostRank || 0);

  return {
    impr,
    clicks,
    cost,
    conv,
    value,
    imprShare,
    lostBudget,
    lostRank,
    CTR: impr ? (clicks / impr) * 100 : 0,
    CvR: clicks ? (conv / clicks) * 100 : 0,
    ROAS: cost ? value / cost : 0,
    CPA: conv ? cost / conv : 0,
    AOV: conv ? value / conv : 0,
    profit: value - cost
  };
};

const transformCampaignData = (row: Record<string, any>): CampaignMetrics => {
  const baseMetrics = transformBaseMetrics(row);
  return {
    campaign: String(row.campaign || row.Campaign || ''),
    campaignId: String(row.campaignId || row.CampaignId || ''),
    impr: baseMetrics.impr,
    clicks: baseMetrics.clicks,
    cost: baseMetrics.cost,
    conv: baseMetrics.conv,
    value: baseMetrics.value,
    imprShare: baseMetrics.imprShare,
    lostBudget: baseMetrics.lostBudget,
    lostRank: baseMetrics.lostRank,
    CTR: baseMetrics.CTR,
    CvR: baseMetrics.CvR,
    ROAS: baseMetrics.ROAS,
    CPA: baseMetrics.CPA,
    AOV: baseMetrics.AOV,
    profit: baseMetrics.profit
  };
};

type DataTransformer<T> = (data: Record<string, any>[]) => T[];

export const transformers: {
  [key in keyof typeof SHEET_TABS]: DataTransformer<
    key extends 'VERSION' | 'ACCOUNT' ? AccountInfo :
    key extends 'DAILY' | 'THIRTY_DAYS' | 'P_THIRTY_DAYS' | 'SEVEN_DAYS' | 'P_SEVEN_DAYS' ? DailyData :
    key extends 'HOURLY' | 'HOURLY_YEST' | 'H_CAMP_30D' | 'H_CAMP_7D' | 'H_DOW_30D' | 'H_DOW_7D' ? HourlyData :
    key extends 'CAMP_SETTINGS' | 'BIDDING' ? CampaignSettings :
    key extends 'PMAX_PERF' ? PMaxData :
    key extends 'PRODUCTS' ? ProductData :
    key extends 'SEARCH80' ? SearchTermData :
    key extends 'PLACEMENTS' ? PlacementData :
    key extends 'QUALITY' ? QualityData :
    key extends 'KEYWORD80' ? KeywordData :
    key extends 'CAMP_CONV' ? CampConvData :
    key extends 'CONV_ACTION' ? ConvActionData :
    key extends 'ASSET' ? AssetData :
    key extends 'GROUPS' ? GroupData :
    key extends 'TERMS' | 'TOTALTERMS' ? TermData :
    key extends 'SUMMARY' | 'TOTALS' ? SummaryData :
    key extends 'TNGRAMS' | 'SNGRAMS' ? NGramData :
    key extends 'PATHS' ? PathData :
    key extends 'PTITLE' | 'PTITLECAMPAIGN' | 'PTITLEID' ? PTitleData :
    never
  >
} = {
  ACCOUNT: (data): AccountInfo[] => [{
    accountName: 'Sample Account',
    scriptVersion: SAMPLE_ACCOUNT_SCRIPT_VERSION
  }],

  VERSION: (data): AccountInfo[] => [{
    accountName: 'Sample Account',
    scriptVersion: SAMPLE_ACCOUNT_SCRIPT_VERSION
  }],

  DAILY: (data) => data.map(row => {
    const sanitized = sanitizeStrings(row)
    const metrics = transformBaseMetrics(sanitized)
    const date = String(sanitized.date || sanitized.Date || new Date().toISOString())
    const campaign = String(sanitized.campaign || sanitized.Campaign || '')
    const campaignId = String(sanitized.campaignId || sanitized.CampaignId || '')

    return {
      ...metrics,
      imprShare: metrics.imprShare || 0,
      lostBudget: metrics.lostBudget || 0,
      lostRank: metrics.lostRank || 0,
      campaign,
      campaignId,
      date,
      Date: date,
      Campaign: campaign,
      CampaignId: campaignId
    }
  }),

  THIRTY_DAYS: (data): DailyData[] => transformers.DAILY(data),
  P_THIRTY_DAYS: (data): DailyData[] => transformers.DAILY(data),
  SEVEN_DAYS: (data): DailyData[] => transformers.DAILY(data),
  P_SEVEN_DAYS: (data): DailyData[] => transformers.DAILY(data),

  HOURLY: (data): HourlyData[] => {
    return data.map(row => ({
      campaign: String(row.campaign || ''),
      Campaign: String(row.campaign || ''),
      campaignId: String(row.campaignId || ''),
      hour: Number(row.hour || 0),
      Hour: Number(row.hour || 0),
      days: Number(row.days || 0),
      impr: Number(row.impr || 0),
      clicks: Number(row.clicks || 0),
      cost: Number(row.cost || 0),
      conv: Number(row.conv || 0),
      value: Number(row.value || 0),
      imprShare: 0,
      lostBudget: 0,
      lostRank: 0,
      CTR: 0,
      CvR: 0,
      ROAS: 0,
      CPA: 0,
      AOV: 0,
      profit: 0
    }));
  },

  HOURLY_YEST: (data): HourlyData[] => transformers.HOURLY(data),

  CAMP_SETTINGS: (data): CampaignSettings[] =>
    data.map(row => ({
      campaign: String(row.Campaign || row.campaign || ''),
      campaignId: String(row.CampaignId || row.campaignId || ''),
      bidStrategy: String(row.BidStrategy || ''),
      budget: Number(row.Budget || 0),
      status: String(row.Status || ''),
      labels: String(row.Labels || ''),
      maxCpc: Number(row.MaxCPC || 0),
      rtbOptIn: Boolean(row.RTBOptIn),
      statusReasons: String(row.StatusReasons || ''),
      campaignType: String(row.CampaignType || ''),
      targetCpa: Number(row.TargetCPA || 0),
      targetRoas: Number(row.TargetROAS || 0),
      maxCpv: Number(row.MaxCPV || 0),
      excludedTypes: String(row.ExcludedTypes || ''),
      includedTypes: String(row.IncludedTypes || ''),
      targetImprShare: Number(row.TargetImprShare || 0),
      excludedPlacements: String(row.ExcludedPlacements || ''),
      notes: String(row.Notes || '')
    })),

  PRODUCTS: (data): ProductData[] =>
    data.map(row => {
      const metrics = transformBaseMetrics(row);
      return {
        impressions: metrics.impr,
        clicks: metrics.clicks,
        cost: metrics.cost,
        conversions: metrics.conv,
        convValue: metrics.value,
        imprShare: metrics.imprShare || 0,
        lostToBudget: metrics.lostBudget || 0,
        lostRank: metrics.lostRank || 0,
        ProductId: String(row.ProductId || ''),
        ProductTitle: String(row.ProductTitle || '')
      };
    }),

  PMAX_PERF: (data): PMaxData[] =>
    data.map(row => ({
      Date: String(row.Date || row.date || ''),
      Campaign: String(row.Campaign || row.campaign || ''),
      "Search Cost": Number(row["Search Cost"] || row.SearchCost || 0),
      "Search Conv": Number(row["Search Conv"] || row.SearchConv || 0),
      "Search Value": Number(row["Search Value"] || row.SearchValue || 0),
      "Display Cost": Number(row["Display Cost"] || row.DisplayCost || 0),
      "Display Conv": Number(row["Display Conv"] || row.DisplayConv || 0),
      "Display Value": Number(row["Display Value"] || row.DisplayValue || 0),
      "Video Cost": Number(row["Video Cost"] || row.VideoCost || 0),
      "Video Conv": Number(row["Video Conv"] || row.VideoConv || 0),
      "Video Value": Number(row["Video Value"] || row.VideoValue || 0),
      "Shopping Cost": Number(row["Shopping Cost"] || row.ShoppingCost || 0),
      "Shopping Conv": Number(row["Shopping Conv"] || row.ShoppingConv || 0),
      "Shopping Value": Number(row["Shopping Value"] || row.ShoppingValue || 0),
      "Total Cost": Number(row["Total Cost"] || row.TotalCost || 0),
      "Total Conv": Number(row["Total Conv"] || row.TotalConv || 0),
      "Total Value": Number(row["Total Value"] || row.TotalValue || 0)
    })),

  PLACEMENTS: (data): PlacementData[] =>
    data.map(row => ({
      displayName: String(row['Display Name'] || row.displayName || ''),
      placement: String(row.Placement || row.placement || ''),
      type: String(row.Type || row.type || ''),
      targetUrl: String(row['Target URL'] || row.targetUrl || ''),
      campaign: String(row.Campaign || row.campaign || ''),
      impressions: Number(String(row.Impressions || row.impressions || '0').replace(/,/g, ''))
    })),

  H_CAMP_30D: (data): HourlyData[] => {
    return data.map(row => ({
      campaign: row.campaign,
      Campaign: row.campaign,
      campaignId: String(row.campaignId || ''),
      hour: Number(row.hour || 0),
      Hour: Number(row.hour || 0),
      days: Number(row.days || 0),
      impr: Number(row.impr || 0),
      clicks: Number(row.clicks || 0),
      cost: Number(row.cost || 0),
      conv: Number(row.conv || 0),
      value: Number(row.value || 0),
      imprShare: 0,
      lostBudget: 0,
      lostRank: 0,
      CTR: 0,
      CvR: 0,
      ROAS: 0,
      CPA: 0,
      AOV: 0,
      profit: 0
    }));
  },

  H_CAMP_7D: (data): HourlyData[] => {
    return data.map(row => ({
      campaign: String(row.campaign || ''),
      campaignId: String(row.campaignId || ''),
      hour: Number(row.hour || 0),
      Hour: Number(row.hour || 0),
      days: Number(row.days || 0),
      impr: Number(row.impr || 0),
      clicks: Number(row.clicks || 0),
      cost: Number(row.cost || 0),
      conv: Number(row.conv || 0),
      value: Number(row.value || 0),
      imprShare: 0,
      lostBudget: 0,
      lostRank: 0,
      CTR: 0,
      CvR: 0,
      ROAS: 0,
      CPA: 0,
      AOV: 0,
      profit: 0
    }));
  },

  H_DOW_30D: (data): HourlyData[] => {

    return data.map(row => {
      const dowNum = typeof row.dow === 'string'
        ? getDayNumber(row.dow)
        : Number(row.dow || 0);

      return {
        campaign: String(row.campaign || ''),
        campaignId: String(row.campaignId || ''),
        hour: Number(row.hour || 0),
        Hour: Number(row.hour || 0),
        dow: dowNum,
        DayOfWeek: dowNum,
        days: Number(row.days || 0),
        impr: Number(row.impr || 0),
        clicks: Number(row.clicks || 0),
        cost: Number(row.cost || 0),
        conv: Number(row.conv || 0),
        value: Number(row.value || 0),
        imprShare: 0,
        lostBudget: 0,
        lostRank: 0,
        CTR: 0,
        CvR: 0,
        ROAS: 0,
        CPA: 0,
        AOV: 0,
        profit: 0
      };
    });
  },

  H_DOW_7D: (data): HourlyData[] => {
    // Use the same transformer as H_DOW_30D
    return transformers.H_DOW_30D(data);
  },

  BIDDING: (data): CampaignSettings[] =>
    data.map(row => ({
      campaign: String(row.campaign || row.Campaign || row.name || ''),
      campaignId: String(row.campaignId || row.CampaignId || row.id || ''),
      bidStrategy: String(row.bidStrategy || row.BidStrategy || row.type || ''),
      budget: Number(row.budget || row.Budget || 0),
      status: String(row.status || row.Status || ''),
      labels: String(row.labels || row.Labels || ''),
      maxCpc: Number(row.maxCpc || row.MaxCPC || 0),
      rtbOptIn: Boolean(row.rtbOptIn || row.RTBOptIn || false),
      statusReasons: String(row.statusReasons || row.StatusReasons || ''),
      campaignType: String(row.campaignType || row.CampaignType || ''),
      targetCpa: Number(row.targetCpa || row.TargetCPA || 0),
      targetRoas: Number(row.targetRoas || row.TargetROAS || row.troas_target || 0),
      maxCpv: Number(row.maxCpv || row.MaxCPV || 0),
      excludedTypes: String(row.excludedTypes || row.ExcludedTypes || ''),
      includedTypes: String(row.includedTypes || row.IncludedTypes || ''),
      targetImprShare: Number(row.targetImprShare || row.TargetImprShare || 0),
      excludedPlacements: String(row.excludedPlacements || row.ExcludedPlacements || ''),
      notes: String(row.notes || row.Notes || '')
    })),

  QUALITY: (data): QualityData[] => data.map(row => ({
    campaign: String(row.campaign || ''),
    adGroup: String(row.adGroup || ''),
    keyword: String(row.keyword || ''),
    score: Number(row.score || 0),
    expCTR: String(row.expCTR || 'AVERAGE'),
    adRelevance: String(row.adRelevance || 'AVERAGE'),
    landingExp: String(row.landingExp || 'AVERAGE')
  })),

  KEYWORD80: (data): KeywordData[] => data.map(row => {
    const metrics = transformBaseMetrics(row);
    return {
      campaign: String(row.campaign || row.Campaign || ''),
      adGroup: String(row.adGroup || row.AdGroup || ''),
      keywordText: String(row.keyword || row.keywordText || ''),
      keywordMatchType: String(row.matchType || row.keywordMatchType || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      allConv: Number(row.allConv || 0),
      allValue: Number(row.allValue || 0)
    };
  }),

  SEARCH80: (data): SearchTermData[] =>
    data.map(row => {
      const metrics = transformBaseMetrics(row);
      return {
        campaign: String(row.campaign || ''),
        searchTerm: String(row.searchTerm || row.SearchTerm || ''),
        searchMatchType: String(row.searchMatchType || row.matchtype || ''),
        date: String(row.date || new Date().toISOString().split('T')[0]),
        impr: metrics.impr,
        clicks: metrics.clicks,
        cost: metrics.cost,
        conv: metrics.conv,
        value: metrics.value,
        allConv: Number(row.allConv || 0),
        allValue: Number(row.allValue || 0),
        SearchTerm: String(row.searchTerm || row.SearchTerm || ''),
        impressions: metrics.impr,
        conversions: metrics.conv,
        convValue: metrics.value,
        CTR: metrics.CTR,
        CPC: metrics.cost / metrics.clicks || 0,
        CvR: metrics.CvR,
        ROAS: metrics.ROAS,
        CPA: metrics.CPA
      };
    }),

  CAMP_CONV: (data: any[]): CampConvData[] => data.map(row => {
    const metrics = transformBaseMetrics(row)
    return {
      campaign: String(row.campaign || ''),
      value: metrics.value,
      conv: metrics.conv,
      allValue: Number(row.allValue || 0),
      allConv: Number(row.allConv || 0),
      networkType: String(row.networkType || ''),
      convCategory: String(row.convCategory || ''),
      date: String(row.date || ''),
      convAction: String(row.convAction || ''),
      destType: String(row.destType || '')
    }
  }),

  CONV_ACTION: (data: any[]): ConvActionData[] => data.map(row => ({
    allValue: Number(row.allValue || 0),
    allConv: Number(row.allConv || 0),
    date: String(row.date || ''),
    convStatus: String(row.convStatus || ''),
    convType: String(row.convType || ''),
    countingType: String(row.countingType || ''),
    convId: String(row.convId || ''),
    convName: String(row.convName || ''),
    includeInMetric: Boolean(row.includeInMetric),
    primaryGoal: Boolean(row.primaryGoal)
  })),

  ASSET: (data: any[]): AssetData[] => data.map(row => {
    const metrics = transformBaseMetrics(row)
    return {
      assetName: String(row['Asset Name'] || ''),
      source: String(row.Source || ''),
      fileTitle: String(row['File/Title'] || ''),
      urlId: String(row['URL/ID'] || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      views: Number(row.Views || 0),
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      CTR: metrics.CTR,
      CVR: metrics.CvR,
      AOV: metrics.AOV,
      ROAS: metrics.ROAS,
      CPA: metrics.CPA,
      Bucket: String(row.Bucket || '')
    }
  }),

  GROUPS: (data: any[]): GroupData[] => data.map(row => {
    const metrics = transformBaseMetrics(row)
    return {
      campName: String(row['Camp Name'] || ''),
      assetGroupName: String(row['Asset Group Name'] || ''),
      status: String(row.Status || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      CTR: metrics.CTR,
      CVR: metrics.CvR,
      AOV: metrics.AOV,
      ROAS: metrics.ROAS,
      CPA: metrics.CPA
    }
  }),

  TERMS: (data: any[]): TermData[] => data.map(row => ({
    campaignName: String(row['Campaign Name'] || ''),
    campaignId: String(row['Campaign ID'] || ''),
    categoryLabel: String(row['Category Label'] || ''),
    clicks: Number(row.Clicks || 0),
    impr: Number(row.Impr || 0),
    conv: Number(row.Conv || 0),
    value: Number(row.Value || 0),
    bucket: String(row.Bucket || ''),
    distance: Number(row.Distance || 0)
  })),

  TOTALTERMS: (data: any[]): TermData[] => data.map(row => ({
    campaignName: String(row['Campaign Name'] || ''),
    campaignId: String(row['Campaign ID'] || ''),
    categoryLabel: String(row['Category Label'] || ''),
    clicks: Number(row.Clicks || 0),
    impr: Number(row.Impr || 0),
    conv: Number(row.Conv || 0),
    value: Number(row.Value || 0),
    bucket: String(row.Bucket || ''),
    distance: Number(row.Distance || 0)
  })),

  SUMMARY: (data: any[]): SummaryData[] => data.map(row => ({
    date: String(row.Date || ''),
    campaignName: String(row['Campaign Name'] || ''),
    campCost: Number(row['Camp Cost'] || 0),
    campConv: Number(row['Camp Conv'] || 0),
    campValue: Number(row['Camp Value'] || 0),
    shopCost: Number(row['Shop Cost'] || 0),
    shopConv: Number(row['Shop Conv'] || 0),
    shopValue: Number(row['Shop Value'] || 0),
    dispCost: Number(row['Disp Cost'] || 0),
    dispConv: Number(row['Disp Conv'] || 0),
    dispValue: Number(row['Disp Value'] || 0),
    videoCost: Number(row['Video Cost'] || 0),
    videoConv: Number(row['Video Conv'] || 0),
    videoValue: Number(row['Video Value'] || 0),
    searchCost: Number(row['Search Cost'] || 0),
    searchConv: Number(row['Search Conv'] || 0),
    searchValue: Number(row['Search Value'] || 0),
    campaignType: String(row['Campaign Type'] || '')
  })),

  TOTALS: (data: any[]): SummaryData[] => data.map(row => ({
    date: String(row.Date || ''),
    campaignName: String(row['Campaign Name'] || ''),
    campCost: Number(row['Camp Cost'] || 0),
    campConv: Number(row['Camp Conv'] || 0),
    campValue: Number(row['Camp Value'] || 0),
    shopCost: Number(row['Shop Cost'] || 0),
    shopConv: Number(row['Shop Conv'] || 0),
    shopValue: Number(row['Shop Value'] || 0),
    dispCost: Number(row['Disp Cost'] || 0),
    dispConv: Number(row['Disp Conv'] || 0),
    dispValue: Number(row['Disp Value'] || 0),
    videoCost: Number(row['Video Cost'] || 0),
    videoConv: Number(row['Video Conv'] || 0),
    videoValue: Number(row['Video Value'] || 0),
    searchCost: Number(row['Search Cost'] || 0),
    searchConv: Number(row['Search Conv'] || 0),
    searchValue: Number(row['Search Value'] || 0),
    campaignType: String(row['Campaign Type'] || '')
  })),

  TNGRAMS: (data: any[]): NGramData[] => data.map(row => {
    const metrics = transformBaseMetrics(row)
    return {
      nGram: String(row.nGram || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      CTR: metrics.CTR,
      CvR: metrics.CvR,
      AOV: metrics.AOV,
      ROAS: metrics.ROAS,
      Bucket: String(row.Bucket || '')
    }
  }),

  SNGRAMS: (data: any[]): NGramData[] => data.map(row => ({
    nGram: String(row.nGram || ''),
    impr: Number(row.Impr || 0),
    clicks: Number(row.Clicks || 0),
    cost: 0, // Search n-grams don't have cost data
    conv: Number(row.Conv || 0),
    value: Number(row.Value || 0),
    CTR: Number(row.CTR || 0),
    CvR: Number(row.CvR || 0),
    AOV: Number(row.AOV || 0),
    ROAS: 0, // Can't calculate ROAS without cost
    Bucket: String(row.Bucket || '')
  })),

  PATHS: (data: any[]): PathData[] => data.map(row => {
    const metrics = transformBaseMetrics(row)
    return {
      PathDetail: String(row.PathDetail || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      CTR: metrics.CTR,
      CVR: metrics.CvR,
      AOV: metrics.AOV,
      ROAS: metrics.ROAS,
      CPA: metrics.CPA,
      Bucket: String(row.Bucket || ''),
      Domain: String(row.Domain || ''),
      Path1: String(row.Path1 || ''),
      Path2: String(row.Path2 || ''),
      Path3: String(row.Path3 || ''),
      Flag: String(row.Flag || '')
    }
  }),

  PTITLE: (data: any[]): PTitleData[] => data.map(row => {
    const metrics = transformBaseMetrics(row)
    return {
      productTitle: String(row['Product Title'] || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      CTR: metrics.CTR,
      ROAS: metrics.ROAS,
      CvR: metrics.CvR,
      bucket: String(row.Bucket || '')
    }
  }),

  PTITLECAMPAIGN: (data: any[]): PTitleData[] => data.map(row => ({
    productTitle: String(row['Product Title'] || ''),
    campaign: String(row['Campaign Name'] || ''),
    impr: Number(row.Impr || 0),
    clicks: Number(row.Clicks || 0),
    cost: Number(row.Cost || 0),
    conv: Number(row.Conv || 0),
    value: Number(row.Value || 0),
    CTR: Number(row.CTR || 0),
    ROAS: Number(row.ROAS || 0),
    CvR: Number(row.CvR || 0),
    bucket: String(row.Bucket || ''),
    channel: String(row.Channel || ''),
    flag: String(row.Flag || '')
  })),

  PTITLEID: (data: any[]): PTitleData[] => data.map(row => ({
    productTitle: String(row['Product Title'] || ''),
    productId: String(row['Product ID'] || ''),
    campaign: String(row['Campaign Name'] || ''),
    impr: Number(row.Impr || 0),
    clicks: Number(row.Clicks || 0),
    cost: Number(row.Cost || 0),
    conv: Number(row.Conv || 0),
    value: Number(row.Value || 0),
    CTR: Number(row.CTR || 0),
    ROAS: Number(row.ROAS || 0),
    CvR: Number(row.CvR || 0),
    bucket: String(row.Bucket || ''),
    channel: String(row.Channel || ''),
    flag: String(row.Flag || '')
  }))
}

export const createEmptyStorageData = (): Omit<StorageData, 'timestamp'> => ({
  dexieId: SAMPLE_ACCOUNT_ID,
  accountCID: SAMPLE_ACCOUNT_ID,
  records: {},
  [SHEET_TABS.VERSION]: [],
  [SHEET_TABS.ACCOUNT]: [],
  [SHEET_TABS.DAILY]: [],
  [SHEET_TABS.HOURLY]: [],
  [SHEET_TABS.HOURLY_YEST]: [],
  [SHEET_TABS.THIRTY_DAYS]: [],
  [SHEET_TABS.P_THIRTY_DAYS]: [],
  [SHEET_TABS.SEVEN_DAYS]: [],
  [SHEET_TABS.P_SEVEN_DAYS]: [],
  [SHEET_TABS.CAMP_SETTINGS]: [],
  [SHEET_TABS.PRODUCTS]: [],
  [SHEET_TABS.PMAX_PERF]: [],
  [SHEET_TABS.H_CAMP_30D]: [],
  [SHEET_TABS.H_CAMP_7D]: [],
  [SHEET_TABS.H_DOW_30D]: [],
  [SHEET_TABS.H_DOW_7D]: [],
  [SHEET_TABS.BIDDING]: [],
  [SHEET_TABS.QUALITY]: [],
  [SHEET_TABS.PLACEMENTS]: [],
  [SHEET_TABS.KEYWORD80]: [],
  [SHEET_TABS.SEARCH80]: [],
  [SHEET_TABS.CAMP_CONV]: [],
  [SHEET_TABS.CONV_ACTION]: [],
  [SHEET_TABS.ASSET]: [],
  [SHEET_TABS.GROUPS]: [],
  [SHEET_TABS.TERMS]: [],
  [SHEET_TABS.TOTALTERMS]: [],
  [SHEET_TABS.SUMMARY]: [],
  [SHEET_TABS.TOTALS]: [],
  [SHEET_TABS.TNGRAMS]: [],
  [SHEET_TABS.SNGRAMS]: [],
  [SHEET_TABS.PATHS]: [],
  [SHEET_TABS.PTITLE]: [],
  [SHEET_TABS.PTITLECAMPAIGN]: [],
  [SHEET_TABS.PTITLEID]: []
});

// Then update the loadSampleData function to properly access transformers
export async function loadSampleData(): Promise<StorageData> {
  const timestamp = new Date().toISOString();
  const emptyData = createEmptyStorageData();

  try {
    // First load quality data
    const qualityData = await loadCsvFile('QUALITY');
    const transformedQuality = transformers.QUALITY(qualityData);

    // Then load everything else
    const results = await Promise.all(
      (Object.keys(SHEET_TABS) as Array<keyof typeof SHEET_TABS>).map(async (tab) => {
        try {
          if (tab === 'QUALITY') {
            return { tab, data: transformedQuality };
          }

          const rawData = await loadCsvFile(tab);

          // For KEYWORD80, join with quality data
          if (tab === 'KEYWORD80') {
            const baseTransformed = transformers.KEYWORD80(rawData);

            // Join with quality data
            const transformed = baseTransformed.map(kw => {
              const qualityInfo = transformedQuality.find(q =>
                q.campaign === kw.campaign &&
                q.adGroup === kw.adGroup &&
                q.keyword === kw.keywordText
              );

              return {
                ...kw,
                score: qualityInfo?.score || null,
                expCTR: qualityInfo?.expCTR || null,
                adRelevance: qualityInfo?.adRelevance || null,
                landingExp: qualityInfo?.landingExp || null
              };
            });

            return { tab, data: transformed };
          }

          // For other tabs, use normal transformer
          if (!(tab in transformers)) {
            console.warn(`No transformer found for tab: ${tab}`);
            return { tab, data: [] };
          }

          const transformedData = transformers[tab](rawData);
          return { tab, data: transformedData };
        } catch (err) {
          console.warn(`Failed to load ${tab} data:`, err);
          return { tab, data: [] };
        }
      })
    );

    const result: StorageData = {
      ...emptyData,
      timestamp,
      dexieId: SAMPLE_ACCOUNT_ID
    };

    // Populate data from results
    results.forEach(({ tab, data }) => {
      const key = SHEET_TABS[tab];
      (result[key] as any) = data;

      // Create records from daily data
      if (key === SHEET_TABS.DAILY) {
        const records: Record<string, Campaign> = {};
        data.forEach((row: DailyData) => {
          if (row.campaign && !records[row.campaign]) {
            records[row.campaign] = {
              campaign: row.campaign,
              campaignId: row.campaignId,
              cost: row.cost,
              conv: row.conv,
              value: row.value,
              imprShare: row.imprShare
            };
          }
        });
        result.records = records;
      }
    });

    return result;
  } catch (err) {
    console.error('Failed to load sample data:', err);
    return {
      ...emptyData,
      timestamp,
      dexieId: SAMPLE_ACCOUNT_ID
    };
  }
}

async function loadCsvFile(tab: keyof typeof SHEET_TABS): Promise<Record<string, any>[]> {
  try {
    const filename = `sample_${SHEET_TABS[tab].toLowerCase()}.csv`;
    const response = await fetch(`/sample-data/sample_${SHEET_TABS[tab].toLowerCase()}.csv`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const text = await response.text();

    return new Promise((resolve, reject) => {
      Papa.parse(text, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.errors.length > 0) {
            console.warn(`Parse warnings for ${tab}:`, results.errors);
          }
          resolve(results.data);
        },
        error: (error) => reject(error)
      });
    });
  } catch (err) {
    console.error(`Error loading ${tab}:`, err);
    return [];
  }
}

export async function loadSampleDataInBackground(): Promise<void> {
  try {
    // Wait for initial delay
    await new Promise(resolve => setTimeout(resolve, 1500))

    // Load sample data
    const sampleData = await loadSampleData()

    // Store in DB
    await db.saveAccountData(SAMPLE_ACCOUNT_ID, sampleData)

    console.log('Background sample data load complete')
  } catch (err) {
    console.error('Background sample data load failed:', err)
  }
}

const DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] as const;
type DayName = typeof DAYS[number];  // This creates a union type of the day names

export const getDayNumber = (dayName: string): number => {
  const upperDay = dayName.toUpperCase() as DayName;
  return DAYS.indexOf(upperDay);
};