// src/Router.tsx
import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useLocation,
  type RouteObject,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { toast } from "./components/ui/use-toast";
import { db } from "./services/db";

// Layouts, error boundary and context
import RootLayout from "@/components/layouts/root-layout";
import TutorialLayout from "@/components/layouts/tutorial-layout";
import RouteError from "@/route-error";
import { GameStateProvider } from "@/contexts/game-state";
import SalesLayout from "@/components/layouts/sales-layout";
import { AccountProvider } from '@/contexts/account-context';
import { CampaignDataProvider } from '@/contexts/campaign-data';

// Pages
import Index from "@/pages/index";
import Analysis from "@/pages/analysis";
import Trends from "@/pages/trends";
import Placements from "@/pages/placements";
import Curve from "@/pages/curve";
import Settings from "@/pages/settings";
import Hourly from "@/pages/hourly";
import Buy from "@/pages/sales/buy";
import SignInPage from "@/pages/auth/sign-in";
import SignUpPage from "@/pages/auth/sign-up";
import LearnGoogleAdsScripts from "@/pages/learn/scary";
import NotFound from "@/pages/not-found";
import Help from "@/pages/info/help";
import Setup from "@/pages/setup";
import Story from "@/pages/info/story";
import Feedback from "@/pages/feedback";
import WhyPage from "@/pages/info/why";
import SimPage from "@/pages/dev/sim";
import KeywordsAnalysis from "@/pages/keywords";
import MasteryWorkshop from "@/pages/sales/mastery";
import AsteroidsGame from "@/pages/asteroids";
import Test from "@/pages/dev/test";
import Privacy from "@/pages/footer/privacy";
import Terms from "@/pages/footer/terms";
import LearnNLEScripts from "@/pages/learn/nle";
import ScriptHelp from "@/pages/learn/script-help";
import Prompt from "@/pages/dev/prompt";
import Brief from "@/pages/dev/brief";
import DataPage from "@/pages/dev/data";
import GroupsPage from "@/pages/dev/groups";
import MobilePage from "@/pages/mobile";
import BuildThePage from "@/pages/sales/build";
import FourCsPage from "@/pages/info/4Cs";
import CPDPage from "@/pages/info/CPD";
import LearnPSAScripts from "@/pages/learn/psa";
import PmaxScript from "@/pages/sales/pmax";
import Plot from "@/pages/plot";
import SlidesPage from "@/pages/sales/slides";

const DEBUG = process.env.NODE_ENV === 'development';

// A simple loading spinner
const LoadingState: React.FC = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
  </div>
);

// Mobile detection wrapper
interface MobileDetectionWrapperProps {
  children: React.ReactNode;
}

const MobileDetectionWrapper: React.FC<MobileDetectionWrapperProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  // Data pages that should redirect to mobile landing
  const DATA_PAGES = [
    '/analysis',
    '/trends',
    '/placements',
    '/curve',
    '/hourly',
    '/keywords',
    '/settings',
    '/setup'
  ];

  // Mobile-optimized pages (these don't redirect)
  const MOBILE_OPTIMIZED = [
    '/mobile',
    '/build',
    '/buy'
  ];

  const isMobile = isMobileDevice();
  const isDataPage = DATA_PAGES.some(path => location.pathname === path);
  const isOnMobilePage = MOBILE_OPTIMIZED.some(path => location.pathname.startsWith(path));

  useEffect(() => {
    if (isMobile && isDataPage && !isOnMobilePage) {
      navigate('/mobile', { replace: true });
    }
  }, [location.pathname, navigate, isMobile, isDataPage, isOnMobilePage]);

  return <>{children}</>;
};

// Basic routes (no special needs)
const basicRoutes: RouteObject[] = [
  { path: "/sign-in", element: <SignInPage /> },
  { path: "/sign-up", element: <SignUpPage /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/terms", element: <Terms /> },
  { path: "/feedback", element: <Feedback /> },
  { path: "/asteroids", element: <AsteroidsGame /> },
  { path: "*", element: <NotFound /> }
];

// Info/Help routes (using tutorial layout)
const infoRoutes: RouteObject[] = [
  { path: "/help", element: <Help /> },
  { path: "/story", element: <Story /> },
  { path: "/why", element: <WhyPage /> }
];

// Tutorial routes
const tutorialRoutes: RouteObject[] = [
  ...infoRoutes, // Include info routes in tutorial layout
  { path: "/nle", element: <LearnNLEScripts /> },
  { path: "/psa", element: <LearnPSAScripts /> },
  { path: "/script-help", element: <ScriptHelp /> },
  { path: "/scary", element: <LearnGoogleAdsScripts /> }
];

// Mobile routes
const mobileRoutes: RouteObject[] = [
  { path: "/mobile", element: <MobilePage /> }
];

// Sales routes (including mobile-optimized pages)
const salesRoutes: RouteObject[] = [
  { path: "/mobile", element: <MobilePage /> },
  { path: "/build", element: <BuildThePage /> },
  { path: "/pmax", element: <PmaxScript /> },
  { path: "/mastery", element: <MasteryWorkshop /> },
  { path: "/buy", element: <Buy /> },
  { path: "/4cs", element: <FourCsPage /> },
  { path: "/cpd", element: <CPDPage /> },
  { path: "/slides", element: <SlidesPage /> }
];

// Data-dependent routes
const dataRoutes: RouteObject[] = [
  { path: "/", element: <Index /> },
  { path: "/analysis", element: <Analysis /> },
  { path: "/trends", element: <Trends /> },
  { path: "/placements", element: <Placements /> },
  { path: "/curve", element: <Curve /> },
  { path: "/hourly", element: <Hourly /> },
  { path: "/keywords", element: <KeywordsAnalysis /> },
  { path: "/settings", element: <Settings /> },
  { path: "/setup", element: <Setup /> }
];

// Dev routes
const devRoutes: RouteObject[] = [
  { path: "/data", element: <DataPage /> },
  { path: "/test", element: <Test /> },
  { path: "/plot", element: <Plot /> },
  { path: "/prompt", element: <Prompt /> },
  { path: "/brief", element: <Brief /> },
  { path: "/groups", element: <GroupsPage /> },
  { path: "/sim", element: <SimPage /> }
];

// Combine route groups into your router configuration
const routes: RouteObject[] = [
  {
    element: <MobileDetectionWrapper><Outlet /></MobileDetectionWrapper>,
    children: [
      // Basic routes (no special needs)
      ...basicRoutes,

      // Tutorial routes with GameState
      {
        element: <GameStateProvider><TutorialLayout /></GameStateProvider>,
        children: tutorialRoutes,
      },

      // Sales routes (including mobile pages)
      {
        element: <SalesLayout />,
        children: salesRoutes,
      },

      // Data-dependent routes with contexts (including dev routes in dev mode)
      {
        element: (
          <AccountProvider>
            <CampaignDataProvider>
              <RootLayout />
            </CampaignDataProvider>
          </AccountProvider>
        ),
        errorElement: <RouteError />,
        children: [
          ...dataRoutes,
          ...(DEBUG ? devRoutes : [])
        ],
      }
    ],
  },
];

// Create the browser router instance
const browserRouter = createBrowserRouter(routes);

// The main Router component that handles error state recovery
const Router: React.FC = () => {
  const { isLoaded: isAuthLoaded } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.error) {
      toast({
        variant: "destructive",
        title: "Error Loading Account",
        description: location.state.error,
      });
      // Clear error state
      navigate(location.pathname, { replace: true, state: {} });
      // Optionally clear storage if flagged
      if (location.state.clearStorage) {
        db.delete().then(() => console.log("Cleared corrupted storage"));
      }
    }
  }, [location.state, navigate, location.pathname]);

  if (!isAuthLoaded) return <LoadingState />;
  return <RouterProvider router={browserRouter} />;
};

export { browserRouter as router };
export default Router;
