// src/lib/data-utils.ts
import {
  StorageData,
  DailyData,
  HourlyData,
  CampaignSettings,
  ConvActionData,
  CampConvData,
  AssetData,
  SummaryData,
  NGramData,
  PathData,
  PTitleData,
  VersionInfo,
  PlacementData,
  QualityData,
  KeywordData,
  SearchTermData,
  GroupData,
  ProductData,
  TermData,
  Campaign,
  AccountInfo
} from '@/types/metrics'
import { SHEET_TABS, SAMPLE_ACCOUNT_ID } from '@/lib/constants'
import Papa from 'papaparse'
import { getDayNumber, loadSampleData } from '@/lib/sample-data-utils'

const DEBUG = process.env.NODE_ENV === 'development'

// String sanitization helper
const sanitizeName = (str: string) => {
  if (!str) return ''
  return str
    // Control chars
    //.replace(/[\u0000-\u001F\u007F-\u009F]/g, '')
    // Zero width spaces 
    //.replace(/[\u200B-\u200D\uFEFF]/g, '')
    // Special chars that commonly cause issues
    //.replace(/[|<>{}[\]\/\\~`!@#$%^&*()=+]/g, '-')
    .trim()
}

interface LocalBaseMetrics {
  impr: number;
  clicks: number;
  cost: number;
  conv: number;
  value: number;
  imprShare?: number;
  lostBudget?: number;
  lostRank?: number;
}

// Helper function to sanitize strings in an object
const sanitizeStrings = (obj: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {}
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string') {
      // Fields that should be sanitized
      const shouldSanitize = [
        'campaign', 'Campaign',
        'adGroup', 'AdGroup',
        'keyword', 'Keyword',
        'SearchTerm',
        'displayName', 'Display Name',
        'placement', 'Placement',
        'ProductTitle',
        'labels', 'Labels'
      ].includes(key)

      result[key] = shouldSanitize ? sanitizeName(value) : value
    } else {
      result[key] = value
    }
  }
  return result
}

// Create empty data structure with all required fields
function createEmptyStorageData(accountCID: string): StorageData {
  return {
    dexieId: accountCID,
    accountCID,
    timestamp: new Date().toISOString(),
    records: {},
    [SHEET_TABS.VERSION]: [],
    [SHEET_TABS.ACCOUNT]: [],
    [SHEET_TABS.DAILY]: [],
    [SHEET_TABS.HOURLY]: [],
    [SHEET_TABS.HOURLY_YEST]: [],
    [SHEET_TABS.THIRTY_DAYS]: [],
    [SHEET_TABS.P_THIRTY_DAYS]: [],
    [SHEET_TABS.SEVEN_DAYS]: [],
    [SHEET_TABS.P_SEVEN_DAYS]: [],
    [SHEET_TABS.PMAX_PERF]: [],
    [SHEET_TABS.CAMP_SETTINGS]: [],
    [SHEET_TABS.PRODUCTS]: [],
    [SHEET_TABS.PLACEMENTS]: [],
    [SHEET_TABS.H_CAMP_30D]: [],
    [SHEET_TABS.H_CAMP_7D]: [],
    [SHEET_TABS.H_DOW_30D]: [],
    [SHEET_TABS.H_DOW_7D]: [],
    [SHEET_TABS.BIDDING]: [],
    [SHEET_TABS.QUALITY]: [],
    [SHEET_TABS.KEYWORD80]: [],
    [SHEET_TABS.SEARCH80]: [],
    [SHEET_TABS.CAMP_CONV]: [],
    [SHEET_TABS.CONV_ACTION]: [],
    [SHEET_TABS.ASSET]: [],
    [SHEET_TABS.GROUPS]: [],
    [SHEET_TABS.TERMS]: [],
    [SHEET_TABS.TOTALTERMS]: [],
    [SHEET_TABS.SUMMARY]: [],
    [SHEET_TABS.TOTALS]: [],
    [SHEET_TABS.TNGRAMS]: [],
    [SHEET_TABS.SNGRAMS]: [],
    [SHEET_TABS.PATHS]: [],
    [SHEET_TABS.PTITLE]: [],
    [SHEET_TABS.PTITLECAMPAIGN]: [],
    [SHEET_TABS.PTITLEID]: []

  }
}

async function loadCsvFile(tab: keyof typeof SHEET_TABS): Promise<Record<string, any>[]> {
  const filename = `sample_${SHEET_TABS[tab].toLowerCase()}.csv`
  try {
    if (DEBUG) console.log(`Loading CSV file: ${filename}`)

    const response = await fetch(`/sample-data/${filename}`)
    if (!response.ok) {
      console.error(`HTTP error loading ${filename}:`, response.status, response.statusText)
      return []
    }
    const text = await response.text()
    if (!text.trim()) {
      console.error(`Empty or invalid CSV file: ${filename}`)
      return []
    }

    return new Promise((resolve, reject) => {
      Papa.parse(text, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.errors.length > 0) {
            console.warn(`Parse warnings for ${filename}:`, results.errors)
          }
          if (DEBUG) {
            console.log(`Parsed ${filename}:`, {
              rowCount: results.data.length,
              firstTwo: results.data.slice(0, 2)
            })
          }
          resolve(results.data)
        },
        error: (error) => {
          console.error(`Parse error for ${filename}:`, error)
          reject(error)
        }
      })
    })
  } catch (err) {
    console.error(`Error loading ${filename}:`, err)
    return []
  }
}

// Transform functions moved from sample-data-utils.ts
const transformBaseMetrics = (row: Record<string, any>) => {
  // Sanitize the input row first
  const sanitizedRow = sanitizeStrings(row)

  // Get all possible variations
  const impr = Number(sanitizedRow.impr || sanitizedRow.Impressions || 0)
  const clicks = Number(sanitizedRow.clicks || sanitizedRow.Clicks || 0)
  const cost = Number(sanitizedRow.cost || sanitizedRow.Cost || 0)
  const conv = Number(sanitizedRow.conv || sanitizedRow.Conversions || 0)
  const value = Number(sanitizedRow.value || sanitizedRow.ConvValue || 0)
  const imprShare = Number(sanitizedRow.imprShare || sanitizedRow.ImprShare || 0)
  const lostBudget = Number(sanitizedRow.lostBudget || sanitizedRow.LostToBudget || 0)
  const lostRank = Number(sanitizedRow.lostRank || sanitizedRow.LostToRank || 0)

  // Calculate derived metrics
  const CTR = impr ? (clicks / impr) * 100 : 0
  const CvR = clicks ? (conv / clicks) * 100 : 0
  const ROAS = cost ? value / cost : 0
  const CPA = conv ? cost / conv : 0
  const AOV = conv ? value / conv : 0
  const profit = value - cost

  return {
    impr,
    clicks,
    cost,
    conv,
    value,
    imprShare,
    lostBudget,
    lostRank,
    profit,
    CTR,
    CvR,
    ROAS,
    CPA,
    AOV
  }
}

interface BiddingData {
  campaign: string;
  campaignId: string;
  bidStrategy: string;
  budget: number;
  status: string;
  labels: string;
  maxCpc: number;
  rtbOptIn: boolean;
  statusReasons: string;
  targetRoas: number;
  campaigns: string;
}

const transformers: Record<keyof typeof SHEET_TABS, (data: Record<string, any>[]) => any[]> = {
  VERSION: (data): VersionInfo[] => data.map(row => {
    const sanitizedRow = sanitizeStrings(row)
    return {
      accountName: String(sanitizedRow.accountName || sanitizedRow.account_name || ''),
      accountCID: String(sanitizedRow.accountCID || sanitizedRow.account_cid || ''),
      scriptVersion: String(sanitizedRow.scriptVersion || sanitizedRow.script_version || ''),
      lastUpdated: String(sanitizedRow.lastUpdated || sanitizedRow.last_updated || '')
    }
  }),

  ACCOUNT: (data): AccountInfo[] => data.map(row => {
    const sanitizedRow = sanitizeStrings(row)
    return {
      accountName: String(sanitizedRow.accountName || sanitizedRow.account_name || ''),
      scriptVersion: String(sanitizedRow.scriptVersion || sanitizedRow.script_version || '')
    }
  }),

  DAILY: (data): DailyData[] => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      ...transformBaseMetrics(sanitized),
      campaign: String(sanitized.campaign || sanitized.Campaign || ''),
      campaignId: String(sanitized.campaignId || sanitized.CampaignId || ''),
      Date: String(sanitized.date || sanitized.Date || new Date().toISOString())
    }
  }),

  THIRTY_DAYS: (data) => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      ...transformBaseMetrics(sanitized),
      campaign: String(sanitized.campaign || sanitized.Campaign || ''),
      campaignId: String(sanitized.campaignId || sanitized.CampaignId || ''),
      date: String(sanitized.date || sanitized.Date || new Date().toISOString())
    }
  }),

  P_THIRTY_DAYS: (data) => transformers.DAILY(data),
  SEVEN_DAYS: (data) => transformers.DAILY(data),
  P_SEVEN_DAYS: (data) => transformers.DAILY(data),

  HOURLY: (data) => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      ...transformBaseMetrics(sanitized),
      campaign: String(sanitized.campaign || sanitized.Campaign || ''),
      campaignId: String(sanitized.campaignId || sanitized.CampaignId || ''),
      hour: Number(sanitized.hour || sanitized.Hour || 0)
    }
  }),

  HOURLY_YEST: (data) => transformers.HOURLY(data),

  CAMP_SETTINGS: (data) => data.map(row => {
    const sanitizedRow = sanitizeStrings(row)
    return {
      campaign: String(sanitizedRow.campaign || ''),
      campaignId: String(sanitizedRow.campaignId || ''),
      campaignType: String(sanitizedRow.campaignType || ''),
      status: String(sanitizedRow.status || ''),
      budget: Number(sanitizedRow.budget || 0),
      bidStrategy: String(sanitizedRow.bidStrategy || ''),
      targetCpa: Number(sanitizedRow.targetCpa || 0),
      targetRoas: Number(sanitizedRow.targetRoas || 0),
      maxCpc: Number(sanitizedRow.maxCpc || 0),
      maxCpv: Number(sanitizedRow.maxCpv || 0),
      excludedTypes: String(sanitizedRow.excludedTypes || ''),
      excludedPlacements: String(sanitizedRow.excludedPlacements || ''),
      labels: String(sanitizedRow.labels || ''),
      notes: String(sanitizedRow.notes || '')
    }
  }),

  PRODUCTS: (data): ProductData[] => data.map(row => {
    const sanitized = sanitizeStrings(row)
    const metrics = transformBaseMetrics(sanitized)
    return {
      ProductId: String(sanitized.ProductId || ''),
      ProductTitle: String(sanitized.ProductTitle || ''),
      impressions: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conversions: metrics.conv,
      convValue: metrics.value
    }
  }),

  PMAX_PERF: (data) => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      Date: String(sanitized.Date || sanitized.date || ''),
      Campaign: String(sanitized.Campaign || sanitized.campaign || ''),
      "Search Cost": Number(sanitized["Search Cost"] || sanitized.SearchCost || 0),
      "Search Conv": Number(sanitized["Search Conv"] || sanitized.SearchConv || 0),
      "Search Value": Number(sanitized["Search Value"] || sanitized.SearchValue || 0),
      "Display Cost": Number(sanitized["Display Cost"] || sanitized.DisplayCost || 0),
      "Display Conv": Number(sanitized["Display Conv"] || sanitized.DisplayConv || 0),
      "Display Value": Number(sanitized["Display Value"] || sanitized.DisplayValue || 0),
      "Video Cost": Number(sanitized["Video Cost"] || sanitized.VideoCost || 0),
      "Video Conv": Number(sanitized["Video Conv"] || sanitized.VideoConv || 0),
      "Video Value": Number(sanitized["Video Value"] || sanitized.VideoValue || 0),
      "Shopping Cost": Number(sanitized["Shopping Cost"] || sanitized.ShoppingCost || 0),
      "Shopping Conv": Number(sanitized["Shopping Conv"] || sanitized.ShoppingConv || 0),
      "Shopping Value": Number(sanitized["Shopping Value"] || sanitized.ShoppingValue || 0),
      "Total Cost": Number(sanitized["Total Cost"] || sanitized.TotalCost || 0),
      "Total Conv": Number(sanitized["Total Conv"] || sanitized.TotalConv || 0),
      "Total Value": Number(sanitized["Total Value"] || sanitized.TotalValue || 0)
    }
  }),

  PLACEMENTS: (data): PlacementData[] => {
    if (DEBUG) {
      console.log('PLACEMENTS transformer - Input data:', {
        count: data.length,
        firstRow: data[0],
        hasDisplayName: data[0]?.['Display Name'] || data[0]?.displayName,
        hasPlacement: data[0]?.Placement || data[0]?.placement
      })
    }

    const transformed = data.map(row => {
      const sanitized = sanitizeStrings(row)
      return {
        displayName: String(sanitized['Display Name'] || sanitized.displayName || sanitized.Placement || sanitized.placement || ''),
        placement: String(sanitized.Placement || sanitized.placement || sanitized['Display Name'] || sanitized.displayName || ''),
        type: String(sanitized.Type || sanitized.type || ''),
        targetUrl: String(sanitized['Target URL'] || sanitized.targetUrl || sanitized.Placement || sanitized.placement || ''),
        campaign: String(sanitized.Campaign || sanitized.campaign || 'All Campaigns'),
        impressions: Number(String(sanitized.Impressions || sanitized.impressions || '0').replace(/,/g, ''))
      }
    })

    if (DEBUG) {
      console.log('PLACEMENTS transformer - Output data:', {
        count: transformed.length,
        firstRow: transformed[0]
      })
    }

    return transformed
  },

  H_CAMP_30D: (data) => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      ...transformBaseMetrics(sanitized),
      campaign: String(sanitized.campaign || sanitized.Campaign || ''),
      campaignId: String(sanitized.campaignId || sanitized.CampaignId || ''),
      date: String(sanitized.date || sanitized.Date || new Date().toISOString())
    }
  }),

  H_DOW_30D: (data) => data.map(row => {
    const sanitized = sanitizeStrings(row)
    const dowNum = typeof sanitized.dow === 'string'
      ? getDayNumber(sanitized.dow)
      : Number(sanitized.dow || 0);

    return {
      ...transformBaseMetrics(sanitized),
      campaign: String(sanitized.campaign || ''),
      campaignId: String(sanitized.campaignId || ''),
      hour: Number(sanitized.hour || 0),
      Hour: Number(sanitized.hour || 0),
      dow: dowNum,
      DayOfWeek: dowNum,
      days: Number(sanitized.days || 0)
    };
  }),

  H_DOW_7D: (data) => transformers.H_DOW_30D(data),

  H_CAMP_7D: (data) => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      ...transformBaseMetrics(sanitized),
      campaign: String(sanitized.campaign || ''),
      campaignId: String(sanitized.campaignId || ''),
      hour: Number(sanitized.hour || 0),
      Hour: Number(sanitized.hour || 0),
      days: Number(sanitized.days || 0)
    }
  }),

  BIDDING: (data): BiddingData[] => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      campaign: String(sanitized.campaign || sanitized.Campaign || sanitized.name || ''),
      campaignId: String(sanitized.campaignId || sanitized.CampaignId || sanitized.id || ''),
      bidStrategy: String(sanitized.bidStrategy || sanitized.BidStrategy || sanitized.type || ''),
      budget: Number(sanitized.budget || sanitized.Budget || 0),
      status: String(sanitized.status || sanitized.Status || ''),
      labels: String(sanitized.labels || sanitized.Labels || ''),
      maxCpc: Number(sanitized.maxCpc || sanitized.MaxCPC || 0),
      rtbOptIn: Boolean(sanitized.rtbOptIn || sanitized.RTBOptIn || false),
      statusReasons: String(sanitized.statusReasons || sanitized.StatusReasons || ''),
      targetRoas: Number(sanitized.targetRoas || sanitized.TargetROAS || sanitized.troas_target || 0),
      campaigns: String(sanitized.campaigns || '')
    }
  }),

  QUALITY: (data): QualityData[] => {
    if (DEBUG) {
      console.log('Quality data transformer input:', {
        count: data.length,
        firstRow: data[0],
        fields: data[0] ? Object.keys(data[0]) : []
      })
    }

    const transformed = data.map(row => {
      const sanitized = sanitizeStrings(row)
      const score = Number(sanitized.score || sanitized.qualityScore || sanitized.QualityScore || 0)

      return {
        campaign: String(sanitized.campaign || sanitized.Campaign || ''),
        adGroup: String(sanitized.adGroup || sanitized.AdGroup || ''),
        keyword: String(sanitized.keyword || sanitized.Keyword || ''),
        score,
        expCTR: String(sanitized.expCTR || sanitized.ExpectedCTR || ''),
        adRelevance: String(sanitized.adRelevance || sanitized.AdRelevance || ''),
        landingExp: String(sanitized.landingExp || sanitized.LandingPageExperience || '')
      }
    })

    return transformed
  },

  KEYWORD80: (data): KeywordData[] => data.map(row => {
    const sanitizedRow = sanitizeStrings(row)
    const metrics = transformBaseMetrics(sanitizedRow)
    return {
      campaign: String(sanitizedRow.campaign || sanitizedRow.campName || ''),
      adGroup: String(sanitizedRow.adGroup || sanitizedRow.adGroupName || ''),
      keywordText: String(sanitizedRow.keyword || sanitizedRow.keywordText || ''),
      keywordMatchType: String(sanitizedRow.matchType || sanitizedRow.keywordMatchType || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      allConv: Number(sanitizedRow.allConv || 0),
      allValue: Number(sanitizedRow.allValue || 0)
    }
  }),

  SEARCH80: (data): SearchTermData[] => data.map(row => {
    const sanitizedRow = sanitizeStrings(row)
    const metrics = transformBaseMetrics(sanitizedRow)
    return {
      campaign: String(sanitizedRow.campaign || ''),
      searchTerm: String(sanitizedRow.searchTerm || sanitizedRow.query || ''),
      searchMatchType: String(sanitizedRow.searchMatchType || sanitizedRow.match_type || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      allConv: Number(sanitizedRow.allConv || sanitizedRow.all_conv || 0),
      allValue: Number(sanitizedRow.allValue || sanitizedRow.all_value || 0),
      date: String(sanitizedRow.date || ''),
      CTR: metrics.CTR,
      CPC: metrics.cost / metrics.clicks,
      CvR: metrics.CvR,
      ROAS: metrics.ROAS,
      CPA: metrics.CPA
    }
  }),

  CAMP_CONV: (data): CampConvData[] => data.map(row => ({
    campaign: String(row.campaign || ''),
    value: Number(row.value || 0),
    conv: Number(row.conv || 0),
    allValue: Number(row.allValue || 0),
    allConv: Number(row.allConv || 0),
    networkType: String(row.networkType || ''),
    convCategory: String(row.convCategory || ''),
    date: String(row.date || ''),
    convAction: String(row.convAction || ''),
    destType: String(row.destType || '')
  })),

  CONV_ACTION: (data): ConvActionData[] => data.map(row => ({
    allValue: Number(row.allValue || 0),
    allConv: Number(row.allConv || 0),
    date: String(row.date || ''),
    convStatus: String(row.convStatus || ''),
    convType: String(row.convType || ''),
    countingType: String(row.countingType || ''),
    convId: String(row.convId || ''),
    convName: String(row.convName || ''),
    includeInMetric: Boolean(row.includeInMetric),
    primaryGoal: Boolean(row.primaryGoal)
  })),

  ASSET: (data): AssetData[] => data.map(row => {
    const metrics = transformBaseMetrics(row)
    return {
      assetName: String(row['Asset Name'] || ''),
      source: String(row.Source || ''),
      fileTitle: String(row['File/Title'] || ''),
      urlId: String(row['URL/ID'] || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      views: Number(row.Views || 0),
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      CTR: metrics.CTR,
      CVR: metrics.CvR,
      AOV: metrics.AOV,
      ROAS: metrics.ROAS,
      CPA: metrics.CPA,
      Bucket: String(row.Bucket || '')
    }
  }),

  GROUPS: (data): GroupData[] => data.map(row => {
    const sanitized = sanitizeStrings(row)
    const metrics = transformBaseMetrics(sanitized)
    return {
      campName: String(sanitized['Camp Name'] || ''),
      assetGroupName: String(sanitized['Asset Group Name'] || ''),
      status: String(sanitized.Status || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      CTR: metrics.CTR,
      CVR: metrics.CvR,
      AOV: metrics.value / metrics.conv,
      ROAS: metrics.ROAS,
      CPA: metrics.CPA
    }
  }),

  TERMS: (data): TermData[] => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      campaignName: String(sanitized['Campaign Name'] || ''),
      campaignId: String(sanitized['Campaign ID'] || ''),
      categoryLabel: String(sanitized['Category Label'] || ''),
      clicks: Number(sanitized.Clicks || 0),
      impr: Number(sanitized.Impr || 0),
      conv: Number(sanitized.Conv || 0),
      value: Number(sanitized.Value || 0),
      bucket: String(sanitized.Bucket || ''),
      distance: Number(sanitized.Distance || 0)
    }
  }),

  TOTALTERMS: (data) => data.map(row => {
    const sanitized = sanitizeStrings(row)
    return {
      categoryLabel: String(sanitized['Category Label'] || ''),
      clicks: Number(sanitized.Clicks || 0),
      impr: Number(sanitized.Impr || 0),
      conv: Number(sanitized.Conv || 0),
      value: Number(sanitized.Value || 0),
      bucket: String(sanitized.Bucket || ''),
      distance: Number(sanitized.Distance || 0)
    }
  }),

  SUMMARY: (data): SummaryData[] => data.map(row => ({
    date: String(row.Date || ''),
    campaignName: String(row['Campaign Name'] || ''),
    campCost: Number(row['Camp Cost'] || 0),
    campConv: Number(row['Camp Conv'] || 0),
    campValue: Number(row['Camp Value'] || 0),
    shopCost: Number(row['Shop Cost'] || 0),
    shopConv: Number(row['Shop Conv'] || 0),
    shopValue: Number(row['Shop Value'] || 0),
    dispCost: Number(row['Disp Cost'] || 0),
    dispConv: Number(row['Disp Conv'] || 0),
    dispValue: Number(row['Disp Value'] || 0),
    videoCost: Number(row['Video Cost'] || 0),
    videoConv: Number(row['Video Conv'] || 0),
    videoValue: Number(row['Video Value'] || 0),
    searchCost: Number(row['Search Cost'] || 0),
    searchConv: Number(row['Search Conv'] || 0),
    searchValue: Number(row['Search Value'] || 0),
    campaignType: String(row['Campaign Type'] || '')
  })),

  TOTALS: (data): SummaryData[] => transformers.SUMMARY(data),

  TNGRAMS: (data): NGramData[] => {
    return data.map(row => ({
      nGram: String(row.nGram),
      impr: Number(row.Impr),
      clicks: Number(row.Clicks),
      cost: Number(row.Cost),
      conv: Number(row.Conv),
      value: Number(row.Value),
      CTR: Number(row.CTR),
      CvR: Number(row.CvR),
      AOV: Number(row.AOV),
      ROAS: Number(row.ROAS),
      Bucket: String(row.Bucket)
    }))
  },

  SNGRAMS: (data): NGramData[] => {
    return data.map(row => ({
      nGram: String(row.nGram),
      impr: Number(row.Impr),
      clicks: Number(row.Clicks),
      cost: Number(row.Cost),
      conv: Number(row.Conv),
      value: Number(row.Value),
      CTR: Number(row.CTR),
      CvR: Number(row.CvR),
      AOV: Number(row.AOV),
      ROAS: Number(row.ROAS),
      Bucket: String(row.Bucket)
    }))
  },

  PATHS: (data): PathData[] => data.map(row => {
    const metrics = transformBaseMetrics(row)
    return {
      PathDetail: String(row.PathDetail || ''),
      impr: metrics.impr,
      clicks: metrics.clicks,
      cost: metrics.cost,
      conv: metrics.conv,
      value: metrics.value,
      CTR: metrics.CTR,
      CVR: metrics.CvR,
      AOV: metrics.AOV,
      ROAS: metrics.ROAS,
      CPA: metrics.CPA,
      Bucket: String(row.Bucket || ''),
      Domain: String(row.Domain || ''),
      Path1: String(row.Path1 || ''),
      Path2: String(row.Path2 || ''),
      Path3: String(row.Path3 || ''),
      Flag: String(row.Flag || '')
    }
  }),

  PTITLE: (data): PTitleData[] => {
    return data.map(row => ({
      productTitle: String(row['Product Title']),
      impr: Number(row.Impr),
      clicks: Number(row.Clicks),
      cost: Number(row.Cost),
      conv: Number(row.Conv),
      value: Number(row.Value),
      CTR: Number(row.CTR),
      ROAS: Number(row.ROAS),
      CvR: Number(row.CvR),
      bucket: String(row.Bucket)
    }))
  },

  PTITLECAMPAIGN: (data): PTitleData[] => {
    return data.map(row => ({
      productTitle: String(row['Product Title']),
      campaign: String(row['Campaign Name'] || ''),
      impr: Number(row.Impr),
      clicks: Number(row.Clicks),
      cost: Number(row.Cost),
      conv: Number(row.Conv),
      value: Number(row.Value),
      CTR: Number(row.CTR),
      ROAS: Number(row.ROAS),
      CvR: Number(row.CvR),
      bucket: String(row.Bucket)
    }))
  },

  PTITLEID: (data): PTitleData[] => {
    return data.map(row => ({
      productTitle: String(row['Product Title']),
      productId: String(row['Product ID'] || ''),
      impr: Number(row.Impr),
      clicks: Number(row.Clicks),
      cost: Number(row.Cost),
      conv: Number(row.Conv),
      value: Number(row.Value),
      CTR: Number(row.CTR),
      ROAS: Number(row.ROAS),
      CvR: Number(row.CvR),
      bucket: String(row.Bucket)
    }))
  }
}

export function isValidAccountCID(cid: string): boolean {
  return Boolean(cid && typeof cid === 'string' &&
    (/^\d+$/.test(cid) || /^\d{3}-\d{3}-\d{4}$/.test(cid))
  )
}

interface AccountValidationResult {
  isValid: boolean;
  error?: string;
}

interface StorageMetadata {
  totalRecords: number;
  loadedTabs: number;
  errors: Record<string, string>;
  timestamp: string;
}

interface ValidateAccountInfo {
  accountCID?: string;
  account_cid?: string;
  cid?: string;
  CID?: string;
  accountName?: string;
  account_name?: string;
  name?: string;
  NAME?: string;
}

export function validateAccountData(accountInfo: ValidateAccountInfo): AccountValidationResult {
  if (!accountInfo) {
    return { isValid: false, error: 'No account data received' }
  }

  // Support all legacy field names for backward compatibility
  const cid = String(accountInfo.accountCID || accountInfo.account_cid || accountInfo.cid || accountInfo.CID || '')
  const name = String(accountInfo.accountName || accountInfo.account_name || accountInfo.name || accountInfo.NAME || '')

  if (!cid) {
    return { isValid: false, error: 'Missing account CID' }
  }

  if (!isValidAccountCID(cid)) {
    return { isValid: false, error: 'Invalid account CID format' }
  }

  if (!name) {
    return { isValid: false, error: 'Missing account name' }
  }

  return { isValid: true }
}

// Helper to safely get data for new tabs
function getSafeTabData(data: any[], tab: string): any[] {
  try {
    // Special case: if data has an 'error' field and mentions 'not found'
    if (data.length === 1 && data[0]?.error?.includes('not found')) {
      console.debug(`Tab ${tab} not found:`, data[0].error)
      return []
    }

    // If no data or empty array, return empty array
    if (!data || !Array.isArray(data) || data.length === 0) {
      return []
    }

    // If transformer exists, try to use it
    if (transformers[tab as keyof typeof SHEET_TABS]) {
      try {
        return transformers[tab as keyof typeof SHEET_TABS](data)
      } catch (err) {
        console.error(`Transform failed for ${tab}:`, err)
        return []
      }
    }

    // If no transformer, return raw data
    return data
  } catch (err) {
    console.debug(`Failed to transform tab ${tab}:`, err)
    return []
  }
}

interface TabError {
  tabName: string;
  message: string;
}

interface FetchResult<T = unknown> {
  tabName: string;
  key: string;
  data: T[];
  success: boolean;
  error?: string;
}

export async function fetchAllData(accountCID: string, webAppUrl: string): Promise<StorageData> {
  console.log('fetchAllData: Starting for', { accountCID, webAppUrl, isSample: accountCID === SAMPLE_ACCOUNT_ID })

  if (!accountCID) {
    throw new Error('Account CID is required')
  }

  // Handle sample data
  if (accountCID === SAMPLE_ACCOUNT_ID) {
    const sampleData = await loadSampleData()
    if (DEBUG) {
      console.log('Sample data loaded:', {
        placementsCount: sampleData[SHEET_TABS.PLACEMENTS]?.length,
        sample: sampleData[SHEET_TABS.PLACEMENTS]?.slice(0, 2)
      })
    }
    return sampleData
  }

  // Start with empty storage data
  const storageData = createEmptyStorageData(accountCID)
  const errors: Record<string, string> = {}

  // Fetch all tabs in parallel
  const fetchPromises = Object.entries(SHEET_TABS).map(async ([key, tabName]): Promise<FetchResult<unknown>> => {
    try {
      // Add retry logic
      let attempts = 0;
      const maxAttempts = 3;

      while (attempts < maxAttempts) {
        try {
          const response = await fetch(`${webAppUrl}?tab=${tabName}`);

          if (!response.ok) {
            throw new Error(`HTTP ${response.status}: ${response.statusText}`);
          }

          const data = await response.json();

          if (!Array.isArray(data)) {
            throw new Error('Invalid data format - expected array');
          }

          // Special handling for empty or error responses
          if (data.length === 1 && data[0]?.error) {
            throw new Error(data[0].error);
          }

          // Transform the data
          const transformedData = transformers[key as keyof typeof SHEET_TABS](data);

          if (DEBUG) {
            console.log(`Loaded ${tabName}:`, {
              raw: data.length,
              transformed: transformedData.length,
              sample: transformedData.slice(0, 1)
            });
          }

          return {
            tabName,
            key,
            data: transformedData,
            success: true
          };

        } catch (err) {
          attempts++;
          if (attempts === maxAttempts) {
            throw err;
          }
          // Wait before retry (exponential backoff)
          await new Promise(resolve => setTimeout(resolve, attempts * 1000));
        }
      }

    } catch (err) {
      const errorMsg = err instanceof Error ? err.message : 'Unknown error'
      errors[tabName] = errorMsg

      return {
        tabName,
        key,
        data: [],
        success: false,
        error: errorMsg
      }
    }
  });

  try {
    const results = await Promise.allSettled(fetchPromises)
    let totalRecords = 0
    let loadedTabs = 0

    results.forEach((result) => {
      if (result.status === 'fulfilled' && result.value) {
        const { key, data } = result.value
        const tabName = SHEET_TABS[key as keyof typeof SHEET_TABS]
        if (tabName) {
          storageData[tabName] = data as any // Necessary evil due to dynamic nature
          totalRecords += data.length
          loadedTabs++
        }
      }
    })

    const metadata: StorageMetadata = {
      totalRecords,
      loadedTabs,
      errors,
      timestamp: new Date().toISOString()
    }

    storageData.records = {} as Record<string, Campaign>

    console.log('fetchAllData: Complete', {
      accountCID,
      totalRecords,
      loadedTabs,
      errorCount: Object.keys(errors).length,
      timestamp: storageData.timestamp
    });

    return storageData;

  } catch (err) {
    console.error('Failed to fetch data:', err);
    throw err;
  }
}