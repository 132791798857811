// src/pages/setup.tsx
import { useState, useCallback, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAccounts } from "@/contexts/account-context"
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"
import { ExternalLink, Copy, Loader2 } from "lucide-react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { getGoogleAdsScript } from "@/lib/google-ads-script-template"
import { cn } from "@/lib/utils"
import { db } from '@/services/db'
import { fetchAllData } from '@/lib/data-utils'
import { useUser } from "@clerk/clerk-react"
import { Account, Campaign, StorageData } from "@/types/metrics"
import { storeTempWebAppUrlWithTimestamp, clearTempWebAppUrl } from '@/lib/storage-utils'
import { useCampaignData } from "@/contexts/campaign-data"
import { SHEET_TABS } from "@/lib/constants"
import { validateAccountData } from '@/lib/data-utils'

const DEBUG = process.env.NODE_ENV === 'development'
const TEMPLATE_URL = "https://docs.google.com/spreadsheets/d/1XmQYHWYbQbtn6mnrGYB71dsixbp66Eo3n5o7fka_s10/copy"

export default function Setup() {
    const navigate = useNavigate()
    const { toast } = useToast()
    const { user, isSignedIn } = useUser()
    const [showAuthDialog, setShowAuthDialog] = useState(false)
    const { addAccount, accounts, switchAccount } = useAccounts()
    const { refreshData } = useCampaignData()

    // Simple state, always starts at step 1
    const [setupState, setSetupState] = useState({
        sheetUrl: '',
        webAppUrl: '',
        currentStep: DEBUG ? 3 : 1,
        scriptCopied: DEBUG // Auto-mark as copied in dev mode
    })

    const [isValidating, setIsValidating] = useState(false)
    const [loadingDialog, setLoadingDialog] = useState<{
        title: string
        description: string
        open: boolean
    } | null>(null)

    // Log every state change
    useEffect(() => {
        console.log('🔄 Setup State Changed:', setupState)
    }, [setupState])

    // Clear any stale URLs on mount
    useEffect(() => {
        clearTempWebAppUrl()
    }, [])

    // Copy script helper
    const copyScript = async () => {
        if (DEBUG) console.log('Copying script for sheet URL:', setupState.sheetUrl)
        try {
            const script = getGoogleAdsScript(setupState.sheetUrl)
            await navigator.clipboard.writeText(script)
            setSetupState(prev => ({ ...prev, scriptCopied: true }))
            toast({
                title: "Script Copied",
                description: "The script has been copied to your clipboard"
            })
        } catch (err) {
            console.error('Failed to copy script:', err)
            toast({
                variant: "destructive",
                title: "Error",
                description: "Failed to copy script"
            })
        }
    }

    // Add new helper for account info validation
    const validateAccountInfo = (data: any): { isValid: boolean; error?: string } => {
        if (!Array.isArray(data) || !data[0]) {
            return { isValid: false, error: 'Invalid account data format' }
        }

        const info = data[0]
        // Check for all possible ID field names
        const id = info.accountCID || info.accountId || info.cid || info.CID
        const name = info.accountName || info.name

        if (!id || !name) {
            return { isValid: false, error: 'Missing required account fields' }
        }

        return { isValid: true }
    }

    // Update handleWebAppUrlSubmit
    const handleWebAppUrlSubmit = async (url: string) => {
        console.group('🚀 Setup Flow Started')

        try {
            // Validate URL format first
            if (!url.includes('script.google.com/macros/s/')) {
                throw new Error('Invalid URL format. Must be a Google Apps Script web app URL.')
            }

            if (url.includes('googleusercontent')) {
                throw new Error('Invalid URL: Contains googleusercontent. Please use the proper Web App URL.')
            }

            if (!isSignedIn) {
                storeTempWebAppUrlWithTimestamp(url)
                navigate('/sign-up')
                return
            }

            // Use the exact URL provided by the user
            const response = await fetch(url + '?tab=account')
            const accountData = await response.json()

            if (!accountData?.[0]?.accountCID) {
                throw new Error('No account data found. Please ensure the Google Ads script has run at least once.')
            }

            const { accountCID, accountName } = accountData[0]
            console.log('Got Account:', { accountCID, accountName })

            // Add placeholder account first
            await addAccount({
                id: accountCID,
                dexieId: accountCID,
                accountCID,
                name: accountName || 'Newly added account',
                webAppUrl: url,
                businessMode: 'ecomm',
                currency: '$',
                cogsAmount: 50,
                breakEvenCpa: 25,
                isSampleAccount: false,
                isLoading: true // Add this flag
            })

            // Navigate to settings
            navigate('/settings')

            // Then fetch and save data in background
            const initialData = await fetchAllData(accountCID, url)

            // Save to Dexie
            await db.saveAccountData(accountCID, {
                ...initialData,
                dexieId: accountCID,
                accountCID,
                timestamp: new Date().toISOString()
            })

            // Update account with final data
            await addAccount({
                id: accountCID,
                dexieId: accountCID,
                accountCID,
                name: accountName,
                webAppUrl: url,
                businessMode: 'ecomm',
                currency: '$',
                cogsAmount: 50,
                breakEvenCpa: 25,
                isSampleAccount: false,
                isLoading: false
            })

            // Refresh data and switch account
            await refreshData(accountCID)
            await switchAccount(accountCID)

            // Show success toast
            toast({
                title: 'Success',
                description: `${accountName} has been connected successfully`
            })

        } catch (err) {
            console.error('Setup failed:', err)
            toast({
                variant: 'destructive',
                title: 'Setup Failed',
                description: err instanceof Error ? err.message : 'Failed to connect account'
            })
            return
        } finally {
            setLoadingDialog(null)
            console.groupEnd()
        }
    }

    // Update analysis click handler
    const handleAnalysisClick = () => {
        navigate('/analysis')  // Just navigate, state is already cleared
    }

    // Then define steps array using the handler
    const steps = [
        {
            number: 1,
            title: "Create Sheet",
            description: "Copy template",
            instructions: (
                <div className="space-y-4">
                    <p>First, create a copy of the template sheet that will store your campaign data</p>
                    <p>Set it so 'Anyone with link' can edit it. (This is important for the script to work)</p>
                    <p>Then paste the URL of your sheet in the space below & click Continue.</p>
                    <Button
                        onClick={() => window.open(TEMPLATE_URL, '_blank')}
                        className="flex items-center space-x-2"
                    >
                        <ExternalLink className="h-4 w-4" />
                        <span>Create Copy of Template</span>
                    </Button>
                    <Input
                        value={setupState.sheetUrl}
                        onChange={(e) => setSetupState(prev => ({
                            ...prev,
                            sheetUrl: e.target.value
                        }))}
                        placeholder="Paste your sheet URL here"
                    />
                    <Button
                        onClick={() => setSetupState(prev => ({ ...prev, currentStep: 2 }))}
                        disabled={!setupState.sheetUrl.includes('sheet')}
                    >
                        Continue
                    </Button>
                </div>
            )
        },
        {
            number: 2,
            title: "Install Script",
            description: "Set up Google Ads script",
            instructions: (
                <div className="space-y-4">
                    <p>The script has been created with your sheet url, all you have to do is copy & paste.</p>
                    <p>(If you need help DM Mike or for a 14min script course, click 'Scary!' in the nav bar)</p>
                    <p>Paste this script in your Google Ads account, Authorise it & run it once to verify it works.</p>
                    <p>You'll see the sheet fill with data. Then schedule it to run hourly, and click Continue.</p>
                    <Button
                        onClick={copyScript}
                        variant={setupState.scriptCopied ? "ghost" : "default"}
                        className="flex items-center space-x-2"
                    >
                        <Copy className="h-4 w-4" />
                        <span>{setupState.scriptCopied ? 'Copied!' : 'Copy Script'}</span>
                    </Button>
                    <Button
                        onClick={() => setSetupState(prev => ({ ...prev, currentStep: 3 }))}
                        disabled={!setupState.scriptCopied}
                    >
                        Continue
                    </Button>
                </div>
            )
        },
        {
            number: 3,
            title: "Connect Account",
            description: "Link your Google Ads account",
            instructions: (
                <div className="space-y-4">
                    <p>Enter the Web App URL from your deployed sheet</p>
                    <div className="space-y-2 text-sm text-muted-foreground">
                        <p>To get your Web App URL:</p>
                        <ol className="list-decimal list-inside space-y-1 ml-2">
                            <li>Open your Google Ads sheet</li>
                            <li>Click 'Extensions' then 'Apps Script'</li>
                            <li>Click "Deploy" in the top right</li>
                            <li>Select "New deployment"</li>
                            <li>Choose "New deployment" again</li>
                            <li>Set "Execute as" to your account</li>
                            <li>Under "Who has access" select "Anyone"</li>
                            <li>Click "Deploy"</li>
                            <li>Copy the Web App URL provided, not the ID. The URL is the one that starts script.google.com/...</li>
                            <li>(Note: If you close the window by mistake, click Deploy, then Manage Deployments)</li>
                        </ol>
                    </div>
                    <Input
                        value={setupState.webAppUrl}
                        onChange={(e) => setSetupState(prev => ({
                            ...prev,
                            webAppUrl: e.target.value
                        }))}
                        placeholder="Paste your Web App URL here"
                    />
                    {!isSignedIn && (
                        <p className="text-sm text-muted-foreground">
                            You'll need to sign in or create an account to connect your Google Ads account.
                        </p>
                    )}
                    <Button
                        onClick={() => {
                            if (!setupState.webAppUrl) {
                                toast({
                                    variant: "destructive",
                                    title: "Missing URL",
                                    description: "Please enter a Web App URL"
                                })
                                return
                            }
                            handleWebAppUrlSubmit(setupState.webAppUrl)
                        }}
                        disabled={!setupState.webAppUrl || !setupState.webAppUrl.includes('macros')}
                    >
                        Complete Setup
                    </Button>
                </div>
            )
        },
        {
            number: 4,
            title: "Success",
            description: "Account connected successfully",
            instructions: (
                <div className="space-y-4">
                    <p className="text-green-600">Your Google Ads account has been connected successfully!</p>
                    <Button
                        onClick={handleAnalysisClick}
                        className="w-full"
                    >
                        Analyze My Data
                    </Button>
                </div>
            )
        }
    ]

    // Add this helper function
    const verifyDataSaved = async (dexieId: string): Promise<{ success: boolean, error?: string }> => {
        console.group('🔍 Verifying Saved Data')
        try {
            const savedData = await db.getAccountData(dexieId)
            const account = await db.getAccount(dexieId)

            // Check for minimum required data
            const result = {
                hasData: !!savedData,
                hasAccount: !!account,
                // Only check for daily data as it's the most critical
                hasDailyData: savedData ? (savedData[SHEET_TABS.DAILY]?.length || 0) > 0 : false
            }

            console.log('Verification result:', result)

            if (!result.hasData || !result.hasAccount || !result.hasDailyData) {
                return {
                    success: false,
                    error: `Missing: ${!result.hasData ? 'data ' : ''}${!result.hasAccount ? 'account ' : ''}${!result.hasDailyData ? 'daily' : ''}`
                }
            }

            return { success: true }
        } catch (err) {
            console.error('Verification failed:', err)
            return { success: false, error: err instanceof Error ? err.message : 'Unknown error' }
        } finally {
            console.groupEnd()
        }
    }

    return (
        <div className="container mx-auto py-6 space-y-6">
            <Card>
                <CardHeader>
                    <CardTitle>Setup Wizard</CardTitle>
                    <CardDescription>Let's get your campaign data connected</CardDescription>
                </CardHeader>
                <CardContent className="space-y-8">
                    {steps.map((step) => (
                        (!DEBUG || step.number === 3) && (
                            <div
                                key={step.number}
                                className={cn(
                                    'space-y-4',
                                    step.number !== setupState.currentStep && 'opacity-50'
                                )}
                            >
                                <div className="flex items-start gap-4">
                                    <div className={cn(
                                        "flex h-8 w-8 shrink-0 items-center justify-center rounded-full border text-center",
                                        step.number === setupState.currentStep && "bg-primary text-primary-foreground border-primary",
                                        step.number !== setupState.currentStep && "border-muted-foreground"
                                    )}>
                                        {step.number}
                                    </div>
                                    <div>
                                        <h3 className="font-semibold">{step.title}</h3>
                                        <p className="text-sm text-muted-foreground">{step.description}</p>
                                    </div>
                                </div>

                                {step.number === setupState.currentStep && (
                                    <div className="ml-12">
                                        {DEBUG && step.number === 3 ? (
                                            // Simplified dev mode UI for step 3
                                            <div className="space-y-4">
                                                <Input
                                                    type="url"
                                                    placeholder="Enter Web App URL"
                                                    value={setupState.webAppUrl}
                                                    onChange={(e) => setSetupState(prev => ({ ...prev, webAppUrl: e.target.value }))}
                                                />
                                                <Button
                                                    onClick={() => handleWebAppUrlSubmit(setupState.webAppUrl)}
                                                    disabled={!setupState.webAppUrl}
                                                >
                                                    Complete Setup
                                                </Button>
                                            </div>
                                        ) : (
                                            // Regular instructions for production
                                            step.instructions
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    ))}
                </CardContent>
            </Card>

            {/* Auth Dialog */}
            <Dialog open={showAuthDialog} onOpenChange={setShowAuthDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Sign In Required</DialogTitle>
                        <p className="text-sm text-muted-foreground pt-2">
                            To connect your Google Ads account, you'll need to be signed in.
                            Choose an option below to continue:
                        </p>
                    </DialogHeader>
                    <div className="flex flex-col sm:flex-row gap-2 justify-end">
                        <Button
                            variant="outline"
                            onClick={() => {
                                navigate('/sign-up', {
                                    state: { from: location.pathname }
                                })
                            }}
                        >
                            Create Account
                        </Button>
                        <Button
                            onClick={() => {
                                navigate('/sign-in', {
                                    state: { from: location.pathname }
                                })
                            }}
                        >
                            Sign In
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Loading Dialog */}
            {loadingDialog && (
                <Dialog open={loadingDialog.open}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>{loadingDialog.title}</DialogTitle>
                        </DialogHeader>
                        <div className="py-6">
                            <div className="flex items-center space-x-4">
                                <Loader2 className="h-6 w-6 animate-spin" />
                                <p>{loadingDialog.description}</p>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    )
} 