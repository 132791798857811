import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { TablePagination } from "@/components/ui/table-pagination"
import { ArrowUp, ArrowDown, ChevronRight, ChevronDown } from "lucide-react"
import { cn } from '@/lib/utils'
import { ProcessedKeywordData } from '@/pages/keywords'
import { SearchTermData } from '@/types/metrics'

interface NgramData {
    ngram: string
    wordCount: number
    count: number
    impr: number
    clicks: number
    cost: number
    conv: number
    value: number
    CTR: number
    CPC: number
    CvR: number
    ROAS: number
    CPA: number
    sourceData?: Array<ProcessedKeywordData | SearchTermData>  // Original data containing this ngram
}

interface ColumnDef {
    key: string
    label: string
    width?: string
    isNumeric?: boolean
}

const DEFAULT_COLUMNS: ColumnDef[] = [
    { key: 'ngram', label: 'nGram', width: 'w-[160px]' },
    { key: 'wordCount', label: 'Words', width: 'w-[80px]', isNumeric: true },
    { key: 'count', label: 'Count', width: 'w-[80px]', isNumeric: true },
    { key: 'impr', label: 'Impr.', width: 'w-[80px]', isNumeric: true },
    { key: 'clicks', label: 'Clicks', width: 'w-[80px]', isNumeric: true },
    { key: 'CTR', label: 'CTR', width: 'w-[80px]', isNumeric: true },
    { key: 'cost', label: 'Cost', width: 'w-[80px]', isNumeric: true },
    { key: 'CPC', label: 'CPC', width: 'w-[80px]', isNumeric: true },
    { key: 'conv', label: 'Conv.', width: 'w-[80px]', isNumeric: true },
    { key: 'CvR', label: 'CvR', width: 'w-[80px]', isNumeric: true },
    { key: 'value', label: 'Value', width: 'w-[80px]', isNumeric: true },
    { key: 'ROAS', label: 'ROAS', width: 'w-[80px]', isNumeric: true },
    { key: 'CPA', label: 'CPA', width: 'w-[80px]', isNumeric: true }
]

interface NgramTableProps {
    data: NgramData[]
    visibleColumns: Set<string>
    sort: {
        field: string
        direction: 'asc' | 'desc'
    }
    onSortChange: (field: string) => void
    currentPage: number
    rowsPerPage: number
    onPageChange: (page: number) => void
    accountCurrency: string
    type?: 'keywords' | 'searchTerms'
    expandedRows: Set<string>
    setExpandedRows: React.Dispatch<React.SetStateAction<Set<string>>>
}

export function NgramTable({
    data,
    visibleColumns,
    sort,
    onSortChange,
    currentPage,
    rowsPerPage,
    onPageChange,
    accountCurrency,
    type = 'keywords',
    expandedRows,
    setExpandedRows
}: NgramTableProps) {
    const toggleRow = (ngram: string) => {
        const newExpanded = new Set(expandedRows)
        if (newExpanded.has(ngram)) {
            newExpanded.delete(ngram)
        } else {
            newExpanded.add(ngram)
        }
        setExpandedRows(newExpanded)
    }

    // Format metric value based on type
    const formatValue = (key: string, value: any): string => {
        if (value === null || value === undefined) return 'N/A'

        switch (key) {
            case 'CTR':
            case 'CvR':
                return `${value.toFixed(1)}%`
            case 'cost':
            case 'CPC':
            case 'value':
            case 'CPA':
                return `${accountCurrency}${value.toFixed(2)}`
            case 'ROAS':
                return value.toFixed(2)
            case 'conv':
                return value.toFixed(1)
            default:
                return typeof value === 'number' ? value.toLocaleString() : value
        }
    }

    const startIndex = (currentPage - 1) * rowsPerPage
    const endIndex = startIndex + rowsPerPage
    const paginatedData = data.slice(startIndex, endIndex)

    const getSourceText = (source: ProcessedKeywordData | SearchTermData): string => {
        if ('keywordText' in source) {
            return source.keywordText
        }
        return source.searchTerm
    }

    const getSourceColumns = () => {
        const sourceColumns: ColumnDef[] = [
            { key: 'indent', label: '', width: 'w-[24px]' },
            {
                key: type === 'keywords' ? 'keywordText' : 'searchTerm',
                label: type === 'keywords' ? 'Keyword' : 'Search Term',
                width: 'w-[136px]'
            },
            { key: 'wordCount', label: 'Words', width: 'w-[80px]', isNumeric: true },
            { key: 'count', label: 'Count', width: 'w-[80px]', isNumeric: true },
            ...DEFAULT_COLUMNS.filter(col =>
                visibleColumns.has(col.key) &&
                !['ngram', 'wordCount', 'count'].includes(col.key)
            ).map(col => ({
                ...col,
                width: col.width || 'w-[80px]',
                isNumeric: col.isNumeric || false
            }))
        ]
        return sourceColumns
    }

    const sortSourceData = (sourceData: Array<ProcessedKeywordData | SearchTermData>) => {
        return [...sourceData].sort((a, b) => {
            const aValue = a[sort.field as keyof typeof a]
            const bValue = b[sort.field as keyof typeof b]
            const multiplier = sort.direction === 'asc' ? 1 : -1

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return multiplier * aValue.localeCompare(bValue)
            }
            return multiplier * ((Number(aValue) || 0) - (Number(bValue) || 0))
        })
    }

    return (
        <div className="space-y-4">
            <div className="rounded-md border dark:border-gray-800">
                <Table>
                    <TableHeader>
                        <TableRow className="hover:bg-transparent border-b-2 dark:border-gray-700">
                            <TableHead className="w-8" />
                            {DEFAULT_COLUMNS.filter(col => visibleColumns.has(col.key)).map(column => (
                                <TableHead
                                    key={column.key}
                                    className={cn(
                                        column.width,
                                        column.isNumeric ? 'text-right' : '',
                                        'text-foreground font-semibold bg-gray-100 dark:bg-gray-800/80'
                                    )}
                                    onClick={() => onSortChange(column.key)}
                                >
                                    <div className={cn(
                                        "flex items-center gap-1 cursor-pointer hover:bg-gray-200 rounded px-1",
                                        column.isNumeric ? "justify-end" : "justify-start",
                                        "dark:hover:bg-gray-700/80"
                                    )}>
                                        <span>{column.label}</span>
                                        {sort.field === column.key && (
                                            sort.direction === 'asc' ?
                                                <ArrowUp className="h-3 w-3" /> :
                                                <ArrowDown className="h-3 w-3" />
                                        )}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {paginatedData.map((row, rowIndex) => (
                            <React.Fragment key={`${row.ngram}-${rowIndex}`}>
                                <TableRow
                                    className={cn(
                                        "h-[2.5rem] max-h-[2.5rem] cursor-pointer",
                                        "hover:bg-accent/80 dark:hover:bg-gray-800/80",
                                        expandedRows.has(row.ngram) && "bg-accent/70 dark:bg-gray-800/70"
                                    )}
                                    onClick={() => toggleRow(row.ngram)}
                                >
                                    <TableCell className="w-8 p-2">
                                        {row.sourceData?.length ? (
                                            expandedRows.has(row.ngram) ?
                                                <ChevronDown className="h-4 w-4" /> :
                                                <ChevronRight className="h-4 w-4" />
                                        ) : null}
                                    </TableCell>
                                    {DEFAULT_COLUMNS.filter(col => visibleColumns.has(col.key)).map(column => (
                                        <TableCell
                                            key={column.key}
                                            className={cn(
                                                "py-2 overflow-hidden",
                                                column.isNumeric ? "text-right" : "",
                                                "text-foreground"
                                            )}
                                        >
                                            {formatValue(column.key, row[column.key as keyof NgramData])}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                {expandedRows.has(row.ngram) && row.sourceData && (
                                    <TableRow className="bg-accent/50 dark:bg-gray-800/70">
                                        <TableCell colSpan={DEFAULT_COLUMNS.filter(col => visibleColumns.has(col.key)).length + 1}>
                                            <div className="py-1">
                                                <Table>
                                                    <TableBody>
                                                        {sortSourceData(row.sourceData).map((source, idx) => (
                                                            <TableRow
                                                                key={idx}
                                                                className="hover:bg-accent/40 dark:hover:bg-gray-800/40"
                                                            >
                                                                {getSourceColumns().map(column => {
                                                                    if (column.key === 'indent') {
                                                                        return <TableCell key={column.key} className="p-0 w-[40px]" />
                                                                    }
                                                                    if (column.key === 'wordCount' || column.key === 'count') {
                                                                        return <TableCell key={column.key} className={cn(
                                                                            "py-1 text-sm text-right",
                                                                            column.width
                                                                        )} />
                                                                    }
                                                                    const value = column.key === (type === 'keywords' ? 'keywordText' : 'searchTerm')
                                                                        ? getSourceText(source)
                                                                        : source[column.key as keyof typeof source]
                                                                    return (
                                                                        <TableCell
                                                                            key={column.key}
                                                                            className={cn(
                                                                                "py-1 text-sm",
                                                                                column.width,
                                                                                column.isNumeric ? "text-right" : "",
                                                                                "text-foreground"
                                                                            )}
                                                                        >
                                                                            {formatValue(column.key, value)}
                                                                        </TableCell>
                                                                    )
                                                                })}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <TablePagination
                totalItems={data.length}
                pageSize={rowsPerPage}
                currentPage={currentPage}
                onPageChange={onPageChange}
            />
        </div>
    )
} 