// src/pages/scary.tsx
import React, { useState, useEffect, useMemo } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { levels } from "@/lib/script-levels";
import Confetti from "react-confetti";
import { ArrowRight, CheckCircle, Copy } from "lucide-react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import CourseIntro from "@/components/course-intro";
import { useGameState } from "@/contexts/game-state";
import { toast } from "@/components/ui/use-toast";
import { Fredo } from "@/components/fredo-chat"

type GameState = {
  isCorrect: boolean;
  showCelebration: boolean;
  windowSize: { width: number; height: number };
  activePart: number;
  taskCompletion: boolean[];
  codeCopied: boolean;
  showIntro: boolean;
};

const formatLogMessage = (messages: string | string[]): string => {
  if (typeof messages === "string") messages = [messages];
  const baseTime = new Date();
  return messages
    .map((message, index) => {
      const timestamp = new Date(baseTime.getTime() + index * 1000).toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
      return `${timestamp}   ${message}`;
    })
    .join("\n");
};

export default function LearnGoogleAdsScripts() {
  const navigate = useNavigate();
  const { isSignedIn, user } = useUser();
  const { currentGameLevel, updateGameLevel } = useGameState();

  const [gameState, setGameState] = useState<GameState>({
    isCorrect: false,
    showCelebration: false,
    windowSize: { width: 0, height: 0 },
    activePart: 1,
    taskCompletion: [],
    codeCopied: false,
    showIntro: false,
  });

  const activeLevel = useMemo(() => levels[currentGameLevel], [currentGameLevel]);
  const isLastLevel = currentGameLevel === levels.length - 1;

  useEffect(() => {
    const handleResize = () => {
      setGameState(prev => ({
        ...prev,
        windowSize: { width: window.innerWidth, height: window.innerHeight }
      }));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log('Setting initial intro state:', { currentGameLevel });
    if (currentGameLevel === 0) {
      setGameState(prev => ({ ...prev, showIntro: true }));
    }
  }, [currentGameLevel]);

  useEffect(() => {
    console.log('Level changed effect:', { currentGameLevel });
    setGameState(prev => ({
      ...prev,
      taskCompletion: new Array(
        (activeLevel?.preCodeTasks?.length || 0) +
        (activeLevel?.postCodeTasks?.length || 0)
      ).fill(false),
      activePart: 1,
      codeCopied: false,
      showIntro: false,
    }));
  }, [currentGameLevel, activeLevel]);

  useEffect(() => {
    console.log('currentGameLevel changed:', {
      currentGameLevel,
      activeLevel: levels[currentGameLevel]?.title
    });
  }, [currentGameLevel]);

  const handleTaskToggle = (index: number) => {
    setGameState(prev => {
      const newCompletion = [...prev.taskCompletion];
      newCompletion[index] = !newCompletion[index];
      return { ...prev, taskCompletion: newCompletion };
    });
  };

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(activeLevel.code);
      setGameState(prev => ({
        ...prev,
        codeCopied: true,
        activePart: 2
      }));
    } catch (err) {
      console.error("Failed to copy code:", err);
    }
  };

  const handleRunCode = () => {
    setGameState(prev => ({ ...prev, activePart: 3 }));
  };

  const handleCorrectAnswer = () => {
    // Show confetti immediately
    setGameState(prev => ({
      ...prev,
      showCelebration: true
    }));

    // Slight delay before showing completion dialog
    setTimeout(() => {
      setGameState(prev => ({
        ...prev,
        isCorrect: true
      }));
    }, 400);
  };

  const handleNextLevel = async () => {
    const nextLevel = currentGameLevel + 1;
    console.log('handleNextLevel - Before update:', {
      currentLevel: currentGameLevel,
      nextLevel,
      activeLevel: levels[currentGameLevel]?.title,
      nextActiveLevel: levels[nextLevel]?.title
    });

    setGameState(prev => ({
      ...prev,
      isCorrect: false,
      showCelebration: false
    }));

    if (isLastLevel) {
      navigate(isSignedIn ? '/settings' : '/sign-up');
      return;
    }

    try {
      // Update game state
      updateGameLevel(nextLevel);

      // Only sync with Clerk after completing a level
      if (isSignedIn && user) {
        await user.update({
          unsafeMetadata: {
            ...user.unsafeMetadata,
            currentGameLevel: nextLevel
          }
        });
      }
    } catch (err) {
      console.error('Failed to update game level:', err);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update game level"
      });
    }
  };

  useEffect(() => {
    console.log('Game state initialized:', {
      currentGameLevel,
      showIntro: currentGameLevel === 0,
      userMeta: user?.unsafeMetadata
    });
  }, []);

  if (gameState.showIntro) {
    return <CourseIntro onStart={() => setGameState(prev => ({ ...prev, showIntro: false }))} />;
  }


  const FinalContent = ({ onComplete, isSignedIn, navigate }: {
    onComplete: () => void,
    isSignedIn: boolean,
    navigate: (path: string) => void
  }) => (
    <div className="space-y-8">
      <div className="prose prose-lg text-muted-foreground">
        <p className="text-xl font-medium whitespace-pre-line">
          {`Congratulations! You've proven that Google Ads Scripts aren't scary at all.
          In fact, they're incredibly powerful tools when used correctly.
          You've just learnt to :
          - preview, run & schedule scripts
          - create & use Google Sheets with your scripts
          - send yourself email alerts

          Now you can put these building blocks together to create your own automations.


          `}
        </p>
        <p className="text-2xl font-bold whitespace-pre-line">
          Ready to take your Google Ads automation to the next level? I've got two options for you:
        </p>
      </div>

      <div className="grid gap-6 md:grid-cols-2 mt-8">
        <Card className="bg-gradient-to-br from-orange-50 to-orange-100 dark:from-orange-900/20 dark:to-orange-800/20 border-orange-200">
          <CardHeader>
            <CardTitle className="text-orange-700 dark:text-orange-400">Scripts & Sheets Workshop</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="mb-4">Master script creation with our comprehensive workshop:</p>
            <ul className="list-none space-y-2 mb-6">
              <li className="flex items-start">
                <span className="text-orange-500 mr-2">✓</span>
                Create custom scripts from scratch
              </li>
              <li className="flex items-start">
                <span className="text-orange-500 mr-2">✓</span>
                Pro automation techniques
              </li>
              <li className="flex items-start">
                <span className="text-orange-500 mr-2">✓</span>
                Script templates included
              </li>
            </ul>
            <Button
              className="w-full bg-orange-600 hover:bg-orange-700 text-white"
              onClick={() => window.open('https://mikerhodes.circle.so/checkout/2024-scripts-and-sheets-workshop', '_blank')}
            >
              Join Workshop ($299) <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </CardContent>
        </Card>

        <Card className="bg-gradient-to-br from-blue-50 to-blue-100 dark:from-blue-900/20 dark:to-blue-800/20 border-blue-200">
          <CardHeader>
            <CardTitle className="text-blue-700 dark:text-blue-400">Setup 8020agent Now</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="mb-4">Start using professional scripts immediately:</p>
            <ul className="list-none space-y-2 mb-6">
              <li className="flex items-start">
                <span className="text-blue-500 mr-2">✓</span>
                Ready-to-use profit optimization
              </li>
              <li className="flex items-start">
                <span className="text-blue-500 mr-2">✓</span>
                Free to get started
              </li>
              <li className="flex items-start">
                <span className="text-blue-500 mr-2">✓</span>
                Support included
              </li>
            </ul>
            <Button
              variant="outline"
              className="w-full border-blue-500 text-blue-700 hover:bg-blue-50 dark:hover:bg-blue-950"
              onClick={() => navigate(isSignedIn ? '/settings' : '/sign-up')}
            >
              {isSignedIn ? 'Complete Setup' : 'Start Free'} <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </CardContent>
        </Card>
      </div>

      <div className="flex justify-center mt-8">
        <Button
          onClick={onComplete}
          variant="default"
          className="min-w-[200px] max-w-[300px] hover:bg-orange-600 hover:text-white"
        >
          Complete Challenge
        </Button>
      </div>
    </div>
  );

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6">
      {gameState.showCelebration && (
        <>
          {/* Semi-transparent light overlay to make confetti more visible */}
          <div className="fixed inset-0 bg-white/30 dark:bg-white/10 pointer-events-none" />
          <Confetti
            width={gameState.windowSize.width}
            height={gameState.windowSize.height}
            recycle={false}
            numberOfPieces={500}
            gravity={0.3}
            initialVelocityY={5}
            colors={['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff', '#ffffff']}
            opacity={0.9}
          />
        </>
      )}

      {/* Progress Bar */}
      <Progress value={(currentGameLevel / levels.length) * 100} className="mb-4" />

      <Card>
        <CardHeader className="relative">
          {/* Title */}
          <CardTitle className="text-4xl font-bold">{activeLevel.title}</CardTitle>

          {/* Description - Split into paragraphs - Only show for non-last levels */}
          {!isLastLevel && (
            <div className="text-lg font-medium text-muted-foreground space-y-4">
              {activeLevel.description.split('\n').filter(Boolean).map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          )}

          {/* Level Number and Dev Mode Selector */}
          <div className="absolute top-4 right-6 flex items-center gap-4">
            {process.env.NODE_ENV === 'development' && (
              <input
                type="number"
                min={0}
                max={levels.length - 1}
                value={currentGameLevel}
                onChange={(e) => updateGameLevel(Math.min(Math.max(0, Number(e.target.value)), levels.length - 1))}
                className="w-16 h-8 px-2 border rounded bg-gray-50"
                title="Dev mode: Jump to level"
              />
            )}
            <p className="text-lg font-medium text-muted-foreground">
              Level {currentGameLevel + 1} of {levels.length}
            </p>
          </div>
        </CardHeader>

        <CardContent className="min-h-[600px] relative">
          {isLastLevel ? (
            <FinalContent
              onComplete={handleCorrectAnswer}
              isSignedIn={isSignedIn}
              navigate={navigate}
            />
          ) : (
            <>

              {/* Part 1: Pre-Code Tasks */}
              {gameState.activePart === 1 && (
                <div className="space-y-4 flex-1">
                  <h3 className="text-2xl font-bold">Step 1: Copy the Code</h3>
                  <div>
                    {activeLevel.preCodeTasks.map((task, index) => (
                      <div key={index} className="flex items-center mb-4">
                        <Checkbox
                          checked={gameState.taskCompletion[index]}
                          onCheckedChange={() => handleTaskToggle(index)}
                          className="mr-4 transform scale-150"
                        />
                        <span className="text-lg">{task}</span>
                      </div>
                    ))}
                  </div>
                  {activeLevel.id >= 6 && (
                    <div className="flex justify-center mb-4">
                      <Button
                        variant={gameState.codeCopied ? "secondary" : "default"}
                        onClick={handleCopyCode}
                        className={`min-w-[200px] max-w-[300px] ${gameState.codeCopied ? "opacity-70 cursor-not-allowed" : ""}`}
                        disabled={gameState.codeCopied}
                      >
                        {gameState.codeCopied ? (
                          <>
                            <CheckCircle className="h-4 w-4 mr-2" />
                            <span>Copied!</span>
                          </>
                        ) : (
                          <>
                            <Copy className="h-4 w-4 mr-2" />
                            <span>Copy Code to Clipboard</span>
                          </>
                        )}
                      </Button>
                    </div>
                  )}
                  <pre className="bg-muted p-4 rounded-md relative overflow-x-auto">
                    <code className="grid w-full min-w-0">
                      {activeLevel.code.split('\n').map((line, i, arr) => {
                        const displayLine = (i === 0 || i === arr.length - 1)
                          ? line
                          : '  ' + line;

                        return (
                          <div key={i} className="grid grid-cols-[2rem_1fr] gap-2 min-w-0">
                            <span className="text-muted-foreground select-none text-right">{i + 1}</span>
                            <span className="font-mono whitespace-pre-wrap break-all overflow-x-auto">{displayLine}</span>
                          </div>
                        );
                      })}
                    </code>
                  </pre>
                  {activeLevel.id < 6 && (
                    <div className="flex justify-center w-full absolute bottom-6 left-0 px-6">
                      <Button
                        variant={gameState.codeCopied ? "secondary" : "default"}
                        onClick={handleCopyCode}
                        className={`min-w-[200px] max-w-[300px] ${gameState.codeCopied ? "opacity-70 cursor-not-allowed" : ""}`}
                        disabled={gameState.codeCopied}
                      >
                        {gameState.codeCopied ? (
                          <>
                            <CheckCircle className="h-4 w-4 mr-2" />
                            <span>Copied!</span>
                          </>
                        ) : (
                          <>
                            <Copy className="h-4 w-4 mr-2" />
                            <span>Copy Code to Clipboard</span>
                          </>
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              )}

              {/* Part 2: Post-Code Tasks */}
              {gameState.activePart === 2 && (
                <div className="space-y-4 flex-1 flex flex-col">
                  <h3 className="text-2xl font-bold">Step 2: Preview the Code</h3>
                  <div>
                    {activeLevel.postCodeTasks.map((task, index) => (
                      <div key={index + activeLevel.preCodeTasks.length} className="flex items-center mb-4">
                        <Checkbox
                          checked={gameState.taskCompletion[index + activeLevel.preCodeTasks.length]}
                          onCheckedChange={() => handleTaskToggle(index + activeLevel.preCodeTasks.length)}
                          className="mr-4 transform scale-150"
                        />
                        <span className="text-lg">{task}</span>
                      </div>
                    ))}
                  </div>

                  {/* Only show image if it exists in the level data */}
                  {activeLevel.images && (
                    <div className="flex justify-center mb-8">
                      <img
                        src={`/${activeLevel.images.replace('public/', '')}`}
                        alt={`Guide for ${activeLevel.title}`}
                        className="rounded-lg shadow-md max-h-[400px] w-auto"
                      />
                    </div>
                  )}

                  <div className="flex justify-center w-full absolute bottom-6 left-0 px-6">
                    <Button
                      onClick={handleRunCode}
                      variant="default"
                      className="min-w-[200px] max-w-[300px]"
                    >
                      I've run the code
                    </Button>
                  </div>
                </div>
              )}

              {/* Part 3: Confirmation */}
              {gameState.activePart === 3 && (
                <div className="space-y-4 flex-1 flex flex-col">
                  <h3 className="text-2xl font-bold">Step 3: Confirm Output</h3>
                  <p>Did the output look like this? (Note: if metrics are shown, numbers will be different)</p>
                  <p>If not, don't worry, go back to the start & redo the steps one by one.</p>
                  <div className="border p-4 rounded-md bg-muted">
                    <div className="flex text-sm text-muted-foreground mb-2 space-x-4">
                      <span>Changes</span>
                      <span className="border-b-2 border-primary">Logs</span>
                      <span>Done (0:07)</span>
                    </div>
                    <pre className="bg-card p-2 rounded-md border font-sans">
                      <code className="text-foreground">{formatLogMessage(activeLevel.expectedOutput)}</code>
                      <br /><br />
                    </pre>
                  </div>
                  <div className="flex justify-center w-full absolute bottom-6 left-0 px-6">
                    <Button
                      onClick={handleCorrectAnswer}
                      variant="default"
                      className="min-w-[200px] max-w-[300px] bg-orange-600 hover:bg-orange-700"
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Completion Dialog */}
      <AlertDialog open={gameState.isCorrect} onOpenChange={(open) => setGameState(prev => ({ ...prev, isCorrect: open }))}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {isLastLevel ? "🎓 Ready for More?" : "🎉 Great Job!"}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {isLastLevel ? (
                "You've mastered the basics! Ready to take your Google Ads automation to the next level?"
              ) : (
                `You've completed ${activeLevel.title}!`
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button
              onClick={handleNextLevel}
              variant="default"
              className="min-w-[200px] hover:bg-primary hover:text-primary-foreground"
            >
              {isLastLevel ? (
                isSignedIn ? 'Setup 8020agent' : 'Get Started Free'
              ) : (
                'Next Level'
              )}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Add Fredo */}
      <Fredo
        pageData={{
          currentGameLevel,
          activeLevel,
          isLastLevel,
          gameState,
          taskCompletion: gameState.taskCompletion,
          isSignedIn
        }}
      />
    </div>
  );
}