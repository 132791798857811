import { SignIn } from "@clerk/clerk-react"
import { dark } from "@clerk/themes"
import { useEffect } from 'react'

export default function SignInPage() {
  useEffect(() => {
    console.log('SignIn: Current location', window.location.href)
  }, [])

  return (
    <div className="flex min-h-screen items-center justify-center p-4">
      <div className="w-full max-w-md">
        <SignIn
          fallbackRedirectUrl="/"
          signUpUrl="/sign-up"
          appearance={{
            baseTheme: dark,
            variables: {
              colorPrimary: '#0ea5e9',
              colorTextOnPrimaryBackground: '#ffffff',
            },
            elements: {
              formButtonPrimary: {
                backgroundColor: '#0ea5e9',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#0284c7',
                },
                '&:active': {
                  backgroundColor: '#0369a1',
                },
                '&:focus': {
                  outline: '2px solid #0ea5e9',
                  outlineOffset: '2px',
                }
              },
              card: 'shadow-lg',
              rootBox: 'w-full'
            }
          }}
        />
      </div>
    </div>
  )
} 