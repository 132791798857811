// src/lib/filter-utils.ts

export interface AdvancedFilter {
    field: string
    operator: string
    value: string
}

export const OPERATORS = {
    dimension: [
        { value: 'includes', label: 'Includes' },
        { value: 'excludes', label: 'Excludes' },
        { value: 'equals', label: 'Equals' },
        { value: 'not_equals', label: 'Does not equal' }
    ],
    metric: [
        { value: '>', label: 'Greater than' },
        { value: '<', label: 'Less than' },
        { value: '=', label: 'Equal to' }
    ],
    percent: [
        { value: '>', label: 'Greater than' },
        { value: '<', label: 'Less than' }
    ],
    enum: [
        { value: 'in', label: 'Is any of' }
    ]
} as const

export const ENUM_VALUES = {
    keywordMatchType: [
        { value: 'EXACT', label: 'Exact' },
        { value: 'PHRASE', label: 'Phrase' },
        { value: 'BROAD', label: 'Broad' }
    ],
    searchMatchType: [
        { value: 'EXACT', label: 'Exact' },
        { value: 'NEAR_EXACT', label: 'Near Exact' },
        { value: 'PHRASE', label: 'Phrase' },
        { value: 'NEAR_PHRASE', label: 'Near Phrase' },
        { value: 'BROAD', label: 'Broad' }
    ],
    expCTR: [
        { value: 'Above Avg', label: 'Above Average' },
        { value: 'Average', label: 'Average' },
        { value: 'Below Avg', label: 'Below Average' }
    ],
    adRelevance: [
        { value: 'Above Avg', label: 'Above Average' },
        { value: 'Average', label: 'Average' },
        { value: 'Below Avg', label: 'Below Average' }
    ],
    landingExp: [
        { value: 'Above Avg', label: 'Above Average' },
        { value: 'Average', label: 'Average' },
        { value: 'Below Avg', label: 'Below Average' }
    ]
} as const

export const FIELD_TYPES = {
    // Common fields
    campaign: 'dimension',
    impr: 'metric',
    clicks: 'metric',
    cost: 'metric',
    conv: 'metric',
    value: 'metric',
    CTR: 'percent',
    CvR: 'percent',
    CPC: 'metric',
    CPA: 'metric',
    ROAS: 'metric',

    // Keyword specific
    keywordText: 'dimension',
    keywordMatchType: 'enum',
    adGroup: 'dimension',
    score: 'metric',
    expCTR: 'enum',
    adRelevance: 'enum',
    landingExp: 'enum',

    // Search term specific
    searchTerm: 'dimension',
    searchMatchType: 'enum'
} as const

// Helper to create logarithmic scale values for sliders
export const createExpScale = (maxValue: number): number[] => {
    const scale = []
    let value = 0

    if (maxValue <= 10) {
        for (let i = 0; i <= maxValue; i++) {
            scale.push(i)
        }
        return scale
    }

    if (maxValue <= 100) {
        while (value <= maxValue) {
            scale.push(value)
            value += 5
        }
        return scale
    }

    const steps = 20
    const factor = Math.exp(Math.log(maxValue + 1) / steps)

    while (value <= maxValue) {
        scale.push(Math.round(value))
        value = Math.max(value + 1, value * factor)
    }

    return scale
}

// Apply a single advanced filter to a data item
export const applyAdvancedFilter = <T extends Record<string, any>>(
    item: T,
    filter: AdvancedFilter,
    type: 'keywords' | 'searchTerms',
    logExample = false
): boolean => {
    const itemValue = item[filter.field]
    const fieldType = FIELD_TYPES[filter.field as keyof typeof FIELD_TYPES]

    if (itemValue === undefined || itemValue === null) return false

    // Handle enum type (checkbox selections)
    if (fieldType === 'enum') {
        const selectedValues = filter.value.split(',').filter(Boolean)
        console.log('Processing enum filter:', {
            field: filter.field,
            originalValue: filter.value,
            selectedValues,
            itemValue: String(itemValue),
            itemValueUpper: String(itemValue).toUpperCase()
        });

        if (selectedValues.length === 0) return true

        // For 'in' operator, check if itemValue is in the list of selected values
        if (filter.operator === 'in') {
            const matches = selectedValues.includes(String(itemValue))
            console.log('Match result:', {
                itemValue: String(itemValue),
                selectedValues,
                matches
            });
            return matches
        }

        // For other operators (if any), use default enum handling
        const matches = selectedValues.includes(String(itemValue))
        if (logExample) {
            console.log(`Filter example (${matches ? 'match' : 'no match'}):`, {
                type,
                field: filter.field,
                operator: filter.operator,
                selectedValues,
                itemValue: String(itemValue),
                matches
            })
        }
        return matches
    }

    // Handle numeric comparisons
    if (fieldType === 'metric' || fieldType === 'percent') {
        const numericValue = Number(itemValue)
        const numericFilterValue = Number(filter.value)
        const matches = (() => {
            switch (filter.operator) {
                case '>': return numericValue > numericFilterValue
                case '<': return numericValue < numericFilterValue
                case '=': return numericValue === numericFilterValue
                default: return true
            }
        })()

        if (logExample) {
            console.log(`Filter example (${matches ? 'match' : 'no match'}):`, {
                type,
                field: filter.field,
                operator: filter.operator,
                itemValue: numericValue,
                filterValue: numericFilterValue,
                matches
            })
        }
        return matches
    }

    // Handle string/dimension comparisons
    const stringValue = String(itemValue).toLowerCase()
    const stringFilterValue = String(filter.value).toLowerCase()
    const matches = (() => {
        switch (filter.operator) {
            case 'includes': return stringValue.includes(stringFilterValue)
            case 'excludes': return !stringValue.includes(stringFilterValue)
            case 'equals': return stringValue === stringFilterValue
            case 'not_equals': return stringValue !== stringFilterValue
            default: return true
        }
    })()

    if (logExample) {
        console.log(`Filter example (${matches ? 'match' : 'no match'}):`, {
            type,
            field: filter.field,
            operator: filter.operator,
            itemValue: stringValue,
            filterValue: stringFilterValue,
            matches
        })
    }
    return matches
}


// Format value based on field type
export const formatFilterValue = (
    value: number,
    field: string,
    currency?: string
): string => {
    if (field === 'cost' || field === 'CPC' || field === 'CPA') {
        return `${currency || '$'}${Math.round(value)}`
    }
    if (field === 'CTR' || field === 'CvR') {
        return `${value.toFixed(2)}%`
    }
    return Math.round(value).toString()
}

// Convert slider value (0-100) to actual value using scale
export const getThresholdValue = (
    value: number,
    scale: number[]
): number => {
    const index = Math.floor((value / 100) * (scale.length - 1))
    return scale[index] || 0
}

// Convert actual value to slider value (0-100)
export const getSliderValue = (
    value: number,
    scale: number[]
): number => {
    const index = scale.findIndex(v => v >= value)
    return index >= 0 ? (index / (scale.length - 1)) * 100 : 0
}