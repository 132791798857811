// src/lib/analytics.ts

// Type declaration for Fathom
declare global {
  interface Window {
    fathom?: {
      trackEvent: (eventName: string) => void;
      trackGoal: (eventName: string, value: number) => void;
    };
  }
}

interface AnalyticsEvent {
  category: string;
  action: string;
  label?: string;
}

type EventInput = string | AnalyticsEvent;

/**
 * Safely track a Fathom event
 * @param event The event to track (either a string name or an event object)
 */
export const trackEvent = (event: EventInput) => {
  if (typeof window === 'undefined' || !('fathom' in window)) return;

  if (typeof event === 'string') {
    window.fathom?.trackEvent(event);
  } else {
    // For object events, create a consistent event name
    const eventName = [
      event.category,
      event.action,
      event.label
    ].filter(Boolean).join('_').toLowerCase();

    window.fathom?.trackEvent(eventName);
  }
}

// Predefined event names for consistency
export const ANALYTICS_EVENTS = {
  // Trends page events
  TRENDS: {
    TIME_RANGE: (days: string) => `trends_time_range_${days}`,
    CHART_TYPE: (type: string) => `trends_chart_type_${type}`,
    METRIC_TOGGLE: (metric: string) => `trends_metric_toggle_${metric}`,
    VIEW_MODE: (mode: string) => `trends_view_mode_${mode}`,
  },
  // Hourly page events
  HOURLY: {
    TIME_RANGE: (range: string) => `hourly_timerange_${range}`,
    VIEW_TYPE: (type: string) => `hourly_view_${type}`,
    CHART_TYPE: (type: string) => `hourly_chart_${type}`,
    METRIC_TOGGLE: (metric: string) => `hourly_metric_toggle_${metric}`,
    ANALYSIS_VIEW: (view: string) => `hourly_analysis_${view}`,
    DOW_TIMERANGE: (range: string) => `hourly_dow_timerange_${range}`,
    HEATMAP_THEME: (theme: string) => `hourly_heatmap_theme_${theme}`,
    ROWS_PER_PAGE: (rows: number) => `hourly_rows_per_page_${rows}`,
  },
  // Analysis page events
  ANALYSIS: {
    TIME_RANGE: (range: string) => `analysis_timerange_${range}`,
    OPTIMIZATION_MODE: (mode: string) => `analysis_optimization_${mode}`,
    TAB_CHANGE: (tab: string) => `analysis_tab_${tab}`,
    ROWS_PER_PAGE: (rows: number) => `analysis_rows_per_page_${rows}`,
    CAMPAIGN_FILTER: (type: 'include' | 'exclude') => `analysis_filter_${type}`,
  },
  // Settings page events
  SETTINGS: {
    ACCOUNT_EDIT: (action: string) => `settings_account_${action}`,
    BUSINESS_MODE: (mode: string) => `settings_business_mode_${mode}`,
    CURRENCY_CHANGE: (currency: string) => `settings_currency_${currency}`,
    REFRESH_DATA: () => 'settings_refresh_data',
    DELETE_ACCOUNT: () => 'settings_delete_account',
  },
  // Curve page events
  CURVE: {
    CHART_TYPE: (type: string) => `curve_chart_type_${type}`,
    RESPONSE_MODEL: (model: string) => `curve_response_model_${model}`,
    CAMPAIGN_SELECT: (campaign: string) => `curve_campaign_select`,
    COST_CHANGE: () => `curve_cost_change`,
    VALUE_CHANGE: () => `curve_value_change`,
    CONVERSIONS_CHANGE: () => `curve_conversions_change`,
  },
  // Placements page events
  PLACEMENTS: {
    CAMPAIGN_SELECT: (campaign: string) => ({
      category: 'Placements',
      action: 'Campaign Select',
      label: campaign
    }),
    TYPE_FILTER: (type: string) => ({
      category: 'Placements',
      action: 'Type Filter',
      label: type
    }),
    PLACEMENT_FILTER: () => ({
      category: 'Placements',
      action: 'Placement Filter'
    }),
    ROWS_PER_PAGE: (rows: number) => ({
      category: 'Placements',
      action: 'Rows Per Page',
      label: String(rows)
    }),
    EXCLUDE_TOGGLE: () => ({
      category: 'Placements',
      action: 'Exclude Toggle'
    }),
    COPY_EXCLUSIONS: () => ({
      category: 'Placements',
      action: 'Copy Exclusions'
    }),
    BULK_EXCLUDE: (type: 'all' | 'visible') => ({
      category: 'Placements',
      action: 'Bulk Exclude',
      label: type
    }),
    COPY_APP_CATEGORIES: () => ({
      category: 'Placements',
      action: 'Copy App Categories'
    })
  },
  // Help page events
  HELP: {
    STORY_VIEW: () => 'help_story_view',
    TUTORIAL_VIEW: () => 'help_tutorial_view',
    FEEDBACK_CLICK: () => 'help_feedback_click',
    CHALLENGE_START: () => 'help_challenge_start',
    CURVE_STORY: () => 'help_curve_story',
  },
} as const; 