// src/components/tree-visualization.tsx
import React, { useEffect, useRef, useState, useMemo } from 'react'
import * as d3 from 'd3'
import { ProcessedKeywordData } from '@/pages/keywords'
import { HierarchyNode } from 'd3'
import { SearchTermData } from '@/types/metrics'
import { Slider } from "@/components/ui/slider"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"

interface BaseData {
  campaign: string
  impr: number
  clicks: number
  cost: number
}

interface TreeNode {
  name: string
  originalName: string
  children?: TreeNode[]
  value?: number
  score?: number | null
  matchType?: string
}

interface ExtendedHierarchyNode extends HierarchyNode<TreeNode> {
  x0?: number
  y0?: number
  _children?: ExtendedHierarchyNode[] | null
  id: string
}

interface TreeVisualizationProps {
  data: any[]
  type: 'keywords' | 'searchTerms'
  maxLength: number
  onMaxLengthChange: (value: number) => void
  showAllKeywords?: boolean
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text
  return text.slice(0, maxLength - 3) + '...'
}

const processKeywordData = (
  data: ProcessedKeywordData[],
  maxLength: number,
  adGroupScores: { [key: string]: number },
  showAllKeywords: boolean,
  selectedAdGroup: string | null
): TreeNode => {
  const root: TreeNode = { name: 'Search', originalName: 'Search' }
  const campaigns: { [key: string]: TreeNode } = {}
  const adGroups: { [key: string]: TreeNode } = {}
  const adGroupHasVisibleKeywords: { [key: string]: boolean } = {}

  // First pass: Create campaign and ad group nodes with their scores
  for (const kw of data) {
    const adGroupKey = `${kw.campaign}|${kw.adGroup}`

    // Create campaign node if it doesn't exist
    if (!campaigns[kw.campaign]) {
      campaigns[kw.campaign] = {
        name: truncateText(kw.campaign, maxLength),
        originalName: kw.campaign,
        children: []
      }
      root.children = root.children || []
      root.children.push(campaigns[kw.campaign])
    }

    // Create ad group node if it doesn't exist
    if (!adGroups[adGroupKey]) {
      adGroups[adGroupKey] = {
        name: truncateText(kw.adGroup, maxLength),
        originalName: kw.adGroup,
        children: [],
        // Always use the pre-calculated score for ad groups
        score: adGroupScores[adGroupKey] || null
      }
      campaigns[kw.campaign].children = campaigns[kw.campaign].children || []
      campaigns[kw.campaign].children.push(adGroups[adGroupKey])
      adGroupHasVisibleKeywords[adGroupKey] = false
    }

    // Check if this keyword should be shown based on selection
    const shouldShowKeyword = showAllKeywords ||
      (selectedAdGroup ? adGroupKey === selectedAdGroup : true)

    if (shouldShowKeyword) {
      // Add keyword node
      adGroups[adGroupKey].children = adGroups[adGroupKey].children || []
      adGroups[adGroupKey].children.push({
        name: truncateText(kw.keywordText, maxLength),
        originalName: kw.keywordText,
        value: kw.cost,
        score: kw.score
      })
      // If any keyword in the ad group is visible, mark the group as visible
      adGroupHasVisibleKeywords[adGroupKey] = true
    }
  }

  // Second pass: Keep ad groups that have any visible keywords
  for (const campaign of Object.values(campaigns)) {
    campaign.children = campaign.children?.filter(adGroup => {
      const adGroupKey = `${campaign.originalName}|${adGroup.originalName}`
      return adGroupHasVisibleKeywords[adGroupKey]
    })
  }

  // Final pass: Keep campaigns that have any ad groups
  root.children = root.children?.filter(campaign => campaign.children?.length > 0)

  return root
}

const processSearchData = (data: SearchTermData[], maxLength: number): TreeNode => {
  const root: TreeNode = { name: 'Search', originalName: 'Search' }
  const campaigns: { [key: string]: TreeNode } = {}
  const matchTypes: { [key: string]: TreeNode } = {}

  data.forEach(term => {
    // Create campaign node if it doesn't exist
    if (!campaigns[term.campaign]) {
      campaigns[term.campaign] = {
        name: truncateText(term.campaign, maxLength),
        originalName: term.campaign,
        children: []
      }
      root.children = root.children || []
      root.children.push(campaigns[term.campaign])
    }

    // Create match type node if it doesn't exist
    const matchTypeKey = `${term.campaign}-${term.searchMatchType}`
    if (!matchTypes[matchTypeKey]) {
      matchTypes[matchTypeKey] = {
        name: term.searchMatchType.replace('_', ' '),
        originalName: term.searchMatchType,
        children: [],
        matchType: term.searchMatchType
      }
      campaigns[term.campaign].children = campaigns[term.campaign].children || []
      campaigns[term.campaign].children.push(matchTypes[matchTypeKey])
    }

    // Add search term node
    matchTypes[matchTypeKey].children = matchTypes[matchTypeKey].children || []
    matchTypes[matchTypeKey].children.push({
      name: truncateText(term.searchTerm, maxLength),
      originalName: term.searchTerm,
      value: term.cost,
      matchType: term.searchMatchType
    })
  })

  return root
}

export function TreeVisualization({ data, type, maxLength, showAllKeywords = true }: TreeVisualizationProps) {
  const svgRef = useRef<SVGSVGElement>(null)
  const [selectedAdGroup, setSelectedAdGroup] = useState<string | null>(null)
  const [originalData] = useState(data) // Store original data on first render

  // Calculate scores once from original data
  const adGroupScores = useMemo(() => {
    if (type !== 'keywords') return {}

    const scores: { [key: string]: number } = {}
    const stats: { [key: string]: { totalImpr: number; weightedScore: number } } = {}

    // Calculate scores from ORIGINAL data
    for (const kw of originalData as ProcessedKeywordData[]) {
      const adGroupKey = `${kw.campaign}|${kw.adGroup}`

      if (!stats[adGroupKey]) {
        stats[adGroupKey] = { totalImpr: 0, weightedScore: 0 }
      }

      // Only include keywords with quality scores in the weighted average
      if (kw.score !== null) {
        stats[adGroupKey].totalImpr += kw.impr
        stats[adGroupKey].weightedScore += (kw.score * kw.impr)
      }
    }

    // Calculate final scores
    for (const [key, stat] of Object.entries(stats)) {
      if (stat.totalImpr > 0) {
        scores[key] = stat.weightedScore / stat.totalImpr
      }
    }

    return scores
  }, [originalData, type]) // Only recompute if original data changes

  useEffect(() => {
    if (!svgRef.current || !data.length) return

    const processedData = type === 'keywords'
      ? processKeywordData(data as ProcessedKeywordData[], maxLength, adGroupScores, showAllKeywords, selectedAdGroup)
      : processSearchData(data as SearchTermData[], maxLength)

    // Clear previous SVG content
    d3.select(svgRef.current).selectAll('*').remove()

    // Set up dimensions
    const width = 1200
    const marginTop = 10
    const marginRight = 120
    const marginBottom = 10
    const marginLeft = 120

    // Create hierarchy and layout
    const root = d3.hierarchy(processedData) as ExtendedHierarchyNode

    // Filter nodes based on visibility settings
    if (!showAllKeywords) {
      root.eachBefore(node => {
        if (node.depth === 2) { // Ad group level
          if (selectedAdGroup === null || node.data.originalName !== selectedAdGroup) {
            node.children = [] // Hide keywords for non-selected ad groups
          }
        }
      })
    }

    const dx = 10
    const dy = (width - marginRight - marginLeft) / (1 + root.height)

    // Set up tree layout
    const tree = d3.tree<TreeNode>().nodeSize([dx, dy])
    const diagonal = d3.linkHorizontal<any, any>()
      .x(d => d.y)
      .y(d => d.x)

    // Create SVG with transparent background
    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', dx)
      .attr('viewBox', [-marginLeft, -marginTop, width, dx])
      .attr('style', 'max-width: 100%; height: auto; font: 10px sans-serif; user-select: none; background: transparent;')

    const gLink = svg.append('g')
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .attr('stroke-opacity', d3.select('html').classed('dark') ? 0.3 : 0.18)
      .attr('stroke-width', d3.select('html').classed('dark') ? 1 : 0.8)

    const gNode = svg.append('g')
      .attr('cursor', 'pointer')
      .attr('pointer-events', 'all')

    // Initialize the display
    root.x0 = dy / 2
    root.y0 = 0
    root.descendants().forEach((d, i) => {
      d.id = String(i)
      d._children = d.children as ExtendedHierarchyNode[] | null
      if (!showAllKeywords && d.depth === 2 && d.data.originalName !== selectedAdGroup) {
        d.children = null
      } else {
        d.children = d._children
      }
    })

    // Compute the new tree layout
    tree(root)

    let left = root
    let right = root
    root.eachBefore(node => {
      if (node.x < left.x) left = node
      if (node.x > right.x) right = node
    })

    const height = right.x - left.x + marginTop + marginBottom

    svg.attr('height', height)
      .attr('viewBox', [-marginLeft, left.x - marginTop, width, height] as any)

    // Update the nodes with click handling
    const node = gNode.selectAll<SVGGElement, ExtendedHierarchyNode>('g')
      .data(root.descendants(), d => d.id)
      .join('g')
      .attr('transform', d => `translate(${d.y},${d.x})`)
      .attr('cursor', d => d.depth === 1 ? 'pointer' : 'default') // Only make ad groups clickable
      .on('click', (event, d) => {
        if (d.depth === 1) { // Ad group level
          if (selectedAdGroup === d.data.originalName) {
            setSelectedAdGroup(null)
          } else {
            setSelectedAdGroup(d.data.originalName)
          }
        }
      })

    // Add circles to nodes
    node.append('circle')
      .attr('r', d => d.depth === 1 ? 4 : 3) // Make ad group nodes slightly larger
      .attr('fill', d => {
        // Always use default color for root and campaign nodes
        if (d.depth <= 1) return 'currentColor'

        // For ad groups, use their pre-calculated score
        if (d.depth === 2) {
          const score = d.data.score
          if (score === null) return 'currentColor'
          if (score >= 8) return '#22c55e' // green-500
          if (score >= 5) return '#eab308' // yellow-500
          return '#ef4444' // red-500
        }

        // For keywords, use their individual scores
        if (d.data.score !== null) {
          const score = d.data.score
          if (score >= 8) return '#22c55e' // green-500
          if (score >= 5) return '#eab308' // yellow-500
          return '#ef4444' // red-500
        }

        return 'currentColor'
      })

    // Add text labels
    node.append('text')
      .attr('dy', '0.31em')
      .attr('x', d => d._children ? -8 : 8)
      .attr('text-anchor', d => d._children ? 'end' : 'start')
      .attr('fill', 'currentColor')
      .text(d => d.data.name)
      .append('title')
      .text(d => d.data.originalName)

    // Update links
    const link = gLink.selectAll('path')
      .data(root.links(), (d: any) => d.target.id)
      .join('path')
      .attr('d', diagonal)

  }, [data, maxLength, type, showAllKeywords, selectedAdGroup])

  return (
    <div className="w-full h-full bg-background">
      {selectedAdGroup && !showAllKeywords && (
        <div className="text-sm text-muted-foreground mb-4">
          Selected Ad Group: {selectedAdGroup}
        </div>
      )}
      <svg
        ref={svgRef}
        className="w-full text-foreground"
        style={{ minHeight: '500px', background: 'transparent' }}
      />
    </div>
  )
}