'use client'

import { useState, useEffect, useMemo } from "react"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { DEBUG } from "@/lib/constants"
import { Switch } from "@/components/ui/switch"
import { TablePagination } from "@/components/ui/table-pagination"

interface PlacementData {
  displayName: string;
  placement: string;
  type: string;
  targetUrl: string;
  campaign: string;
  impressions: number;
  exclude?: boolean;
  canExclude: boolean;
}

// Helper function to create YouTube link
const createYouTubeLink = (videoId: string) => {
  return `https://www.youtube.com/watch?v=${videoId}`
}

// Helper to determine if we should show the target URL
const shouldShowTargetUrl = (type: string, displayName: string, targetUrl: string) => {
  if (type === 'YOUTUBE_VIDEO') {
    return displayName !== 'Video no longer available' && targetUrl
  }
  return type === 'MOBILE_APPLICATION' && targetUrl
}

// Helper to render the display name with appropriate linking
const renderDisplayName = (item: PlacementData) => {
  if (item.type === 'YOUTUBE_VIDEO' && item.displayName !== 'Video no longer available') {
    return (
      <a 
        href={createYouTubeLink(item.placement)}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline"
      >
        {item.displayName || item.placement}
      </a>
    )
  }
  
  if (item.type === 'MOBILE_APPLICATION' && item.targetUrl) {
    return (
      <a 
        href={item.targetUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline"
      >
        {item.displayName || item.placement}
      </a>
    )
  }

  return item.displayName || item.placement
}

interface DataTableProps {
  data: PlacementData[];
  pageSize: number;
  onExclusionToggle: (targetUrl: string, checked: boolean) => void;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export function DataTable({ 
  data, 
  pageSize, 
  onExclusionToggle,
  currentPage,
  onPageChange
}: DataTableProps) {
  // Calculate pagination
  const totalPages = Math.ceil(data.length / pageSize)
  const startIndex = (currentPage - 1) * pageSize
  const paginatedData = data.slice(startIndex, startIndex + pageSize)

  return (
    <div className="space-y-4">
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[50%]">Display Name</TableHead>
              <TableHead className="w-[20%]">Type</TableHead>
              <TableHead className="text-right w-[20%]">Impressions</TableHead>
              <TableHead className="text-center w-[10%]">Exclude?</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.map((row) => (
              <TableRow key={row.targetUrl}>
                <TableCell className="w-[50%]">
                  <div>
                    {row.type === 'YOUTUBE_VIDEO' && row.displayName !== 'Video no longer available' ? (
                      <a 
                        href={`https://www.youtube.com/watch?v=${row.placement}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {row.displayName}
                      </a>
                    ) : row.type === 'MOBILE_APPLICATION' && row.targetUrl ? (
                      <a 
                        href={row.targetUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {row.displayName}
                      </a>
                    ) : (
                      row.displayName
                    )}
                    {(row.type === 'MOBILE_APPLICATION' || (row.type === 'YOUTUBE_VIDEO' && row.displayName !== 'Video no longer available')) && row.targetUrl && (
                      <div className="text-sm text-muted-foreground mt-0.5">
                        {row.targetUrl}
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell className="w-[20%]">{row.type}</TableCell>
                <TableCell className="text-right w-[20%]">{row.impressions.toLocaleString()}</TableCell>
                <TableCell className="text-center w-[10%]">
                  {row.canExclude ? (
                    <Switch
                      checked={row.exclude}
                      onCheckedChange={(checked) => onExclusionToggle(row.targetUrl, checked)}
                    />
                  ) : (
                    <span className="text-xs text-muted-foreground">N/A</span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {totalPages > 1 && (
        <div className="flex items-center justify-end space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <div className="text-sm text-muted-foreground">
            Page {currentPage} of {totalPages}
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
} 