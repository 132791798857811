import { User } from "@clerk/clerk-react";

interface FeatureVote {
  feature: string;
  vote: 'would-not-use' | 'unsure' | 'please-create';
  timestamp: string;
}

export async function recordFeatureVote(user: User | null, vote: FeatureVote) {
  if (!user) return;

  try {
    // Get existing votes
    const existingVotes = (user.unsafeMetadata.featureVotes as FeatureVote[]) || [];
    
    // Add new vote, replacing if exists
    const newVotes = [
      ...existingVotes.filter(v => v.feature !== vote.feature),
      vote
    ];

    // Update metadata
    await user.update({
      unsafeMetadata: {
        ...user.unsafeMetadata,
        featureVotes: newVotes
      }
    });

    return true;
  } catch (error) {
    console.error('Failed to record feature vote:', error);
    return false;
  }
}

export function hasVotedOnFeature(user: User | null | undefined, featureId: string): boolean {
  if (!user?.unsafeMetadata?.featureVotes) {
    console.log('Vote check - no votes found:', {
      featureId,
      metadata: user?.unsafeMetadata
    });
    return false;
  }

  const votes = user.unsafeMetadata.featureVotes as VoteRecord[];
  const hasVoted = votes.some(vote => vote.feature === featureId);

  console.log('Vote check:', {
    featureId,
    votes,
    hasVoted,
    metadata: user?.unsafeMetadata
  });

  return hasVoted;
}

export function getFeatureVote(user: User | null, feature: string): FeatureVote | null {
  if (!user) return null;
  
  const votes = user.unsafeMetadata.featureVotes as FeatureVote[] || [];
  return votes.find(vote => vote.feature === feature) || null;
} 