// src/pages/asteroids.tsx
import React, { useEffect, useRef, useState } from 'react';
import { X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

interface GameObject {
  x: number;
  y: number;
  radius: number;
  speed: number;
  angle: number;
  dx: number;
  dy: number;
  size?: 'large' | 'medium' | 'small';
  vertices?: number[];
}

interface Ship extends GameObject {
  rotationSpeed: number;
  isThrusting: boolean;
}

interface Bullet extends GameObject {
  active: boolean;
  lifetime: number;
}



export default function AsteroidsGame() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const scoreRef = useRef(0);
  const highScoreRef = useRef(0);
  const gameStateRef = useRef({
    isOver: false,
    started: false,
    waitingForRestart: false,
    hasEverStarted: false  // New flag to track if game has ever been started
  });
  const lastFrameTimeRef = useRef(0);
  const FPS = 120; // Double the frame rate
  const frameDelay = 100 / FPS; // ms between frames
  const { isSignedIn, user } = useUser();
  const setScore = (newScore: number) => {
    scoreRef.current = newScore;
  };
  const [animationFrame, setAnimationFrame] = useState<number | null>(null);
  const navigate = useNavigate();
  const [isNewHighScore, setIsNewHighScore] = useState(false);

  // Load high score from Clerk metadata on mount
  useEffect(() => {
    if (isSignedIn && user?.unsafeMetadata?.highScore) {
      highScoreRef.current = Number(user.unsafeMetadata.highScore) || 0;
    }
  }, [isSignedIn, user]);

  // Update high score in Clerk metadata
  const updateHighScore = async (newScore: number) => {
    if (!isSignedIn || !user) return;

    try {
      if (newScore > highScoreRef.current) {
        highScoreRef.current = newScore;
        setIsNewHighScore(true);
        await user.update({
          unsafeMetadata: {
            ...user.unsafeMetadata,
            highScore: newScore
          }
        });
      }
    } catch (err) {
      console.error('Failed to update high score:', err);
    }
  };

  // Game state
  const shipRef = useRef<Ship>({
    x: 0,
    y: 0,
    radius: 15,
    speed: 0,
    angle: 0,
    dx: 0,
    dy: 0,
    rotationSpeed: 0,
    isThrusting: false
  });

  const asteroidsRef = useRef<GameObject[]>([]);
  const bulletsRef = useRef<Bullet[]>([]);
  const keysRef = useRef<{ [key: string]: boolean }>({});

  // Add bullet lifetime tracking
  const BULLET_LIFETIME = 60;  // frames

  // Game loop function
  const updateGame = (timestamp: number) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Calculate time since last frame
    const deltaTime = timestamp - lastFrameTimeRef.current;

    // Only update if enough time has passed
    if (deltaTime >= frameDelay) {
      // Clear canvas
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Show welcome screen only if never started
      if (!gameStateRef.current.hasEverStarted) {
        drawWelcomeScreen(ctx);
        lastFrameTimeRef.current = timestamp;
        requestAnimationFrame(updateGame);
        return;
      }

      // Show game over screen if waiting for restart
      if (gameStateRef.current.waitingForRestart) {
        drawGameOverScreen(ctx);
        lastFrameTimeRef.current = timestamp;
        requestAnimationFrame(updateGame);
        return;
      }

      // Only update game objects if game is active
      if (!gameStateRef.current.isOver) {
        updateShip();
        updateAsteroids();
        updateBullets();
        checkCollisions();
        draw(ctx);
      }

      lastFrameTimeRef.current = timestamp;
    }

    requestAnimationFrame(updateGame);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set optimal game dimensions
    const OPTIMAL_GAME_WIDTH = 1200;  // Optimal width
    const OPTIMAL_GAME_HEIGHT = 800;  // Optimal height

    // Set canvas size (capped at optimal dimensions)
    canvas.width = Math.min(window.innerWidth - 130, OPTIMAL_GAME_WIDTH);
    canvas.height = Math.min(window.innerHeight - 130, OPTIMAL_GAME_HEIGHT);

    // Initialize ship position
    shipRef.current.x = canvas.width / 2;
    shipRef.current.y = canvas.height / 2;

    // Create initial asteroids
    createInitialAsteroids();

    // Start game loop
    const frame = requestAnimationFrame(updateGame);
    setAnimationFrame(frame);

    // Event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    const handleResize = () => {
      canvas.width = Math.min(window.innerWidth - 130, OPTIMAL_GAME_WIDTH);
      canvas.height = Math.min(window.innerHeight - 130, OPTIMAL_GAME_HEIGHT);
      // Reset ship position on resize
      shipRef.current.x = canvas.width / 2;
      shipRef.current.y = canvas.height / 2;
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('resize', handleResize);
      if (animationFrame) cancelAnimationFrame(animationFrame);
    };
  }, []);

  // Update text rendering functions to use optimal dimensions for scaling
  const getOptimalDimension = (canvas: HTMLCanvasElement) => {
    const OPTIMAL_GAME_WIDTH = 1200;
    const OPTIMAL_GAME_HEIGHT = 800;
    const currentDimension = Math.min(canvas.width, canvas.height * 2);
    const optimalDimension = Math.min(OPTIMAL_GAME_WIDTH, OPTIMAL_GAME_HEIGHT * 2);
    return Math.min(currentDimension, optimalDimension);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    // If never started, this is first time start
    if (!gameStateRef.current.hasEverStarted) {
      if (e.key === ' ') {
        gameStateRef.current = {
          isOver: false,
          started: true,
          waitingForRestart: false,
          hasEverStarted: true
        };
        resetGame();
      }
      return;
    }

    // If waiting for restart, handle restart
    if (gameStateRef.current.waitingForRestart) {
      if (e.key === ' ') {
        gameStateRef.current = {
          isOver: false,
          started: true,
          waitingForRestart: false,
          hasEverStarted: true
        };
        resetGame();
      }
      return;
    }

    // Regular game controls
    keysRef.current[e.key] = true;
    if (e.key === ' ') {
      shoot();
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    keysRef.current[e.key] = false;
  };

  const handleResize = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  };

  const createInitialAsteroids = () => {
    asteroidsRef.current = []; // Clear existing asteroids
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Helper function to get random position away from center
    const getRandomPosition = () => {
      let x, y;
      do {
        x = Math.random() * canvas.width;
        y = Math.random() * canvas.height;
      } while (
        Math.abs(x - canvas.width / 2) < 200 &&
        Math.abs(y - canvas.height / 2) < 200
      );
      return { x, y };
    };

    // Create exactly 2 small asteroids
    for (let i = 0; i < 2; i++) {
      const { x, y } = getRandomPosition();
      asteroidsRef.current.push(createAsteroid(x, y, 'small'));
    }

    // Create exactly 4 medium asteroids
    for (let i = 0; i < 4; i++) {
      const { x, y } = getRandomPosition();
      asteroidsRef.current.push(createAsteroid(x, y, 'medium'));
    }

    // Create exactly 6 large asteroids
    for (let i = 0; i < 6; i++) {
      const { x, y } = getRandomPosition();
      asteroidsRef.current.push(createAsteroid(x, y, 'large'));
    }
  };

  const createAsteroid = (x: number, y: number, size: 'large' | 'medium' | 'small'): GameObject => {
    const SIZES = {
      large: { radius: 40, speed: 0.5, points: 200 },
      medium: { radius: 25, speed: 0.75, points: 300 },
      small: { radius: 15, speed: 1.0, points: 500 }
    };

    const angle = Math.random() * Math.PI * 2;
    const specs = SIZES[size];

    // More jagged vertices (12-16 points with more variation)
    const vertexCount = 12 + Math.floor(Math.random() * 5);
    const vertices: number[] = [];
    for (let i = 0; i < vertexCount; i++) {
      const vertexAngle = (i / vertexCount) * Math.PI * 2;
      // Much more variation in radius (±50%)
      const radius = specs.radius * (0.5 + Math.random());
      vertices.push(radius);
    }

    return {
      x,
      y,
      radius: specs.radius,
      speed: specs.speed + Math.random() * 0.2,
      angle,
      dx: Math.cos(angle) * specs.speed,
      dy: Math.sin(angle) * specs.speed,
      size,
      vertices
    };
  };

  const splitAsteroid = (asteroid: GameObject): GameObject[] => {
    if (asteroid.size === 'small') return [];

    const newSize = asteroid.size === 'large' ? 'medium' : 'small';
    const newAsteroids: GameObject[] = [];

    // Create two new asteroids with similar momentum
    for (let i = 0; i < 2; i++) {
      const angleOffset = (Math.PI / 4) * (i === 0 ? 1 : -1); // Split at 45-degree angles
      const newAngle = Math.atan2(asteroid.dy, asteroid.dx) + angleOffset;

      // Calculate new speed (slightly faster than parent)
      const parentSpeed = Math.sqrt(asteroid.dx * asteroid.dx + asteroid.dy * asteroid.dy);
      const newSpeed = parentSpeed * 1.2;

      // Create new asteroid at exact same position
      const newAsteroid = createAsteroid(asteroid.x, asteroid.y, newSize);

      // Override the random velocity with calculated split velocity
      newAsteroid.dx = Math.cos(newAngle) * newSpeed;
      newAsteroid.dy = Math.sin(newAngle) * newSpeed;

      newAsteroids.push(newAsteroid);
    }

    return newAsteroids;
  };

  const shoot = () => {
    const ship = shipRef.current;
    const bullet: Bullet = {
      x: ship.x + Math.cos(ship.angle) * ship.radius,
      y: ship.y + Math.sin(ship.angle) * ship.radius,
      radius: 2,
      speed: 10,
      angle: ship.angle,
      dx: Math.cos(ship.angle) * 10,
      dy: Math.sin(ship.angle) * 10,
      active: true,
      lifetime: BULLET_LIFETIME
    };
    bulletsRef.current.push(bullet);
  };

  const updateShip = () => {
    const ship = shipRef.current;
    const keys = keysRef.current;

    // Rotation
    if (keys['ArrowLeft']) ship.angle -= 0.1;
    if (keys['ArrowRight']) ship.angle += 0.1;

    // Thrust
    ship.isThrusting = keys['ArrowUp'];
    if (ship.isThrusting) {
      ship.dx += Math.cos(ship.angle) * 0.1;
      ship.dy += Math.sin(ship.angle) * 0.1;
    }

    // Apply friction
    ship.dx *= 0.99;
    ship.dy *= 0.99;

    // Update position
    ship.x += ship.dx;
    ship.y += ship.dy;

    // Wrap around screen
    wrapPosition(ship);
  };

  const updateAsteroids = () => {
    asteroidsRef.current.forEach(asteroid => {
      asteroid.x += asteroid.dx;
      asteroid.y += asteroid.dy;
      wrapPosition(asteroid);
    });
  };

  const updateBullets = () => {
    bulletsRef.current = bulletsRef.current.filter(bullet => {
      bullet.x += bullet.dx;
      bullet.y += bullet.dy;
      bullet.lifetime--;
      wrapPosition(bullet);
      return bullet.active && bullet.lifetime > 0;
    });
  };

  const wrapPosition = (obj: GameObject) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (obj.x < 0) obj.x = canvas.width;
    if (obj.x > canvas.width) obj.x = 0;
    if (obj.y < 0) obj.y = canvas.height;
    if (obj.y > canvas.height) obj.y = 0;
  };

  const checkCollisions = () => {
    if (gameStateRef.current.isOver) return;

    const ship = shipRef.current;

    // Check ship collision with asteroids
    for (const asteroid of asteroidsRef.current) {
      const dx = ship.x - asteroid.x;
      const dy = ship.y - asteroid.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      const shipCollisionRadius = 18;
      const asteroidCollisionRadius = asteroid.radius;

      if (distance < shipCollisionRadius + asteroidCollisionRadius) {
        console.log('Player died!');

        // ONLY update game state and high score state
        const finalScore = scoreRef.current;

        if (finalScore > highScoreRef.current) {
          setIsNewHighScore(true);
          highScoreRef.current = finalScore;
        } else {
          setIsNewHighScore(false);
        }

        gameStateRef.current = {
          isOver: true,
          started: true,  // Keep this true
          waitingForRestart: true,
          hasEverStarted: true
        };
        return;
      }
    }

    // Check bullet collisions with asteroids
    const asteroidsToRemove: number[] = [];
    const newAsteroids: GameObject[] = [];

    // First pass: check all bullet collisions
    for (const bullet of bulletsRef.current) {
      if (!bullet.active) continue;

      asteroidsRef.current.forEach((asteroid, index) => {
        if (asteroidsToRemove.includes(index)) return; // Skip already marked asteroids

        const dx = bullet.x - asteroid.x;
        const dy = bullet.y - asteroid.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        // Use slightly larger collision radius for bullets to make hitting easier
        const hitRadius = bullet.radius * 2 + asteroid.radius * 0.8;

        if (distance < hitRadius) {
          // Mark asteroid for removal
          asteroidsToRemove.push(index);
          bullet.active = false;

          // Update score
          const points = asteroid.size === 'large' ? 200 :
            asteroid.size === 'medium' ? 300 : 500;
          setScore(scoreRef.current + points);

          // Split asteroid if not small
          if (asteroid.size !== 'small') {
            const splitAsteroids = splitAsteroid(asteroid);
            newAsteroids.push(...splitAsteroids);
          }
        }
      });
    }

    // Remove hit asteroids (in reverse order to maintain correct indices)
    for (let i = asteroidsToRemove.length - 1; i >= 0; i--) {
      asteroidsRef.current.splice(asteroidsToRemove[i], 1);
    }

    // Add all new split asteroids
    if (newAsteroids.length > 0) {
      asteroidsRef.current.push(...newAsteroids);
    }

    // Remove inactive bullets
    bulletsRef.current = bulletsRef.current.filter(bullet => bullet.active);

    // Create new asteroids if all are destroyed
    if (asteroidsRef.current.length === 0) {
      createInitialAsteroids();
    }
  };

  const resetGame = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // If we had a new high score, NOW we update Clerk
    if (isNewHighScore && isSignedIn && user) {
      user.update({
        unsafeMetadata: {
          ...user.unsafeMetadata,
          highScore: highScoreRef.current
        }
      }).catch(err => {
        console.error('Failed to update high score:', err);
      });
    }

    // Reset game objects
    shipRef.current = {
      x: canvas.width / 2,
      y: canvas.height / 2,
      radius: 15,
      speed: 0,
      angle: 0,
      dx: 0,
      dy: 0,
      rotationSpeed: 0,
      isThrusting: false
    };

    // Cancel any existing animation frame
    if (animationFrame) {
      cancelAnimationFrame(animationFrame);
      setAnimationFrame(null);
    }

    asteroidsRef.current = [];
    bulletsRef.current = [];
    keysRef.current = {};
    setScore(0);
    setIsNewHighScore(false);
    createInitialAsteroids();

    // Restart animation
    const frame = requestAnimationFrame(updateGame);
    setAnimationFrame(frame);
  };

  const drawGameOverScreen = (ctx: CanvasRenderingContext2D) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Clear screen with black
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Calculate font sizes based on optimal or current dimension, whichever is smaller
    const dimension = getOptimalDimension(canvas);
    const titleSize = Math.min(dimension / 15, 72);
    const scoreSize = Math.min(dimension / 25, 48);
    const messageSize = Math.min(dimension / 45, 24);

    // Draw white text
    ctx.fillStyle = '#ffffff';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Set letter spacing based on font size
    ctx.letterSpacing = `${titleSize / 15}px`;

    // GAME OVER
    ctx.font = `${titleSize}px 'Press Start 2P', monospace`;
    ctx.fillText('GAME OVER', canvas.width / 2, canvas.height * 0.3);

    // Score
    ctx.letterSpacing = `${scoreSize / 15}px`;
    ctx.font = `${scoreSize}px 'Press Start 2P', monospace`;
    ctx.fillText(`SCORE: ${scoreRef.current}`, canvas.width / 2, canvas.height * 0.45);

    // High Score
    const highScoreText = isNewHighScore
      ? `NEW HIGH SCORE: ${highScoreRef.current}!`
      : `HIGH SCORE: ${highScoreRef.current}`;
    ctx.fillText(highScoreText, canvas.width / 2, canvas.height * 0.55);

    // Share instructions
    ctx.letterSpacing = `${messageSize / 20}px`;
    ctx.font = `${messageSize}px 'Press Start 2P', monospace`;
    ctx.fillText('Share your score on LinkedIn!', canvas.width / 2, canvas.height * 0.65);
    ctx.fillText('Tag @MikeRhodesIdeas', canvas.width / 2, canvas.height * 0.7);

    // Play again option
    ctx.fillText('PRESS SPACE TO PLAY AGAIN', canvas.width / 2, canvas.height * 0.8);
  };

  const drawWelcomeScreen = (ctx: CanvasRenderingContext2D) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Clear screen with black
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Calculate font sizes based on optimal or current dimension, whichever is smaller
    const dimension = getOptimalDimension(canvas);
    const titleSize = Math.min(dimension / 15, 72);
    const messageSize = Math.min(dimension / 45, 24);

    // Set letter spacing based on font size
    ctx.letterSpacing = `${titleSize / 15}px`;

    // Draw white text
    ctx.fillStyle = '#ffffff';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Welcome text
    ctx.font = `${titleSize}px 'Press Start 2P', monospace`;
    ctx.fillText('WELCOME', canvas.width / 2, canvas.height * 0.3);

    // Message
    ctx.letterSpacing = `${messageSize / 20}px`;
    ctx.font = `${messageSize}px 'Press Start 2P', monospace`;
    const messages = [
      "You've found the hidden Asteroids game",
      "Tag me on LinkedIn to let me know your high score",
      "@MikeRhodesIdeas ;)"
    ];
    messages.forEach((msg, i) => {
      ctx.fillText(msg, canvas.width / 2, canvas.height * (0.5 + i * 0.08));
    });

    // Start option
    ctx.fillText('PRESS SPACE TO START', canvas.width / 2, canvas.height * 0.8);
  };

  const draw = (ctx: CanvasRenderingContext2D) => {
    drawShip(ctx);
    drawAsteroids(ctx);
    drawBullets(ctx);
    drawScore(ctx);
    drawControls(ctx);
  };

  const drawShip = (ctx: CanvasRenderingContext2D) => {
    const ship = shipRef.current;
    ctx.save();
    ctx.translate(ship.x, ship.y);
    ctx.rotate(ship.angle);
    ctx.strokeStyle = '#ffffff';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(-15, -15);
    ctx.lineTo(30, 0);
    ctx.lineTo(-15, 15);
    ctx.lineTo(-5, 0);
    ctx.closePath();
    ctx.stroke();

    // Draw thrust if active
    if (ship.isThrusting) {
      ctx.beginPath();
      ctx.moveTo(-5, 0);
      ctx.lineTo(-25, -8);
      ctx.lineTo(-20, 0);
      ctx.lineTo(-25, 8);
      ctx.closePath();
      ctx.stroke();
    }
    ctx.restore();
  };

  const drawAsteroids = (ctx: CanvasRenderingContext2D) => {
    ctx.strokeStyle = '#ffffff';
    ctx.lineWidth = 2;
    asteroidsRef.current.forEach(asteroid => {
      ctx.beginPath();
      if (asteroid.vertices) {
        // Draw jagged asteroid shape
        const vertexCount = asteroid.vertices.length;
        for (let i = 0; i <= vertexCount; i++) {
          const angle = ((i % vertexCount) / vertexCount) * Math.PI * 2;
          const radius = asteroid.vertices[i % vertexCount];
          const x = asteroid.x + Math.cos(angle + asteroid.angle) * radius;
          const y = asteroid.y + Math.sin(angle + asteroid.angle) * radius;
          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }
      }
      ctx.closePath();
      ctx.stroke();
    });
  };

  const drawBullets = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = '#ffffff';
    bulletsRef.current.forEach(bullet => {
      ctx.beginPath();
      ctx.arc(bullet.x, bullet.y, bullet.radius, 0, Math.PI * 2);
      ctx.fill();
    });
  };

  const drawScore = (ctx: CanvasRenderingContext2D) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const dimension = getOptimalDimension(canvas);
    const scoreSize = Math.min(dimension / 30, 36);
    ctx.letterSpacing = `${scoreSize / 20}px`;
    ctx.font = `${scoreSize}px 'Press Start 2P', monospace`;
    ctx.fillStyle = '#ffffff';
    ctx.textAlign = 'right';
    ctx.fillText(`${scoreRef.current.toString().padStart(6, '0')}`, canvas.width - 20, 50);
  };

  const drawControls = (ctx: CanvasRenderingContext2D) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const dimension = getOptimalDimension(canvas);
    const controlsSize = Math.min(dimension / 60, 16);
    ctx.letterSpacing = `${controlsSize / 20}px`;
    ctx.font = `${controlsSize}px 'Press Start 2P', monospace`;
    ctx.fillStyle = '#ffffff';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    ctx.fillText('← → to rotate   ↑ to thrust   SPACE to shoot', canvas.width / 2, canvas.height - 20);
  };

  return (
    <div className="relative w-full h-[calc(100vh-130px)] bg-black overflow-hidden flex items-center justify-center">
      <div className="relative w-[calc(100vw-130px)] h-[calc(100vh-130px)] bg-black">
        <Link to="/" className="absolute top-4 left-4 z-10">
          <Button variant="ghost" size="icon" className="text-white hover:text-gray-300">
            <X className="h-6 w-6" />
          </Button>
        </Link>
        <Link to="/" className="absolute bottom-4 left-4 z-10">
          <Button variant="ghost" className="text-white hover:text-gray-300">
            Return to Home Page
          </Button>
        </Link>
        <canvas
          ref={canvasRef}
          className="w-full h-full"
          onKeyDown={(e) => e.preventDefault()}
          tabIndex={0}
        />
      </div>
    </div>
  );
}

