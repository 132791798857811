import React, { useState, useMemo } from 'react';
import { useUser, useClerk } from '@clerk/clerk-react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Slider } from "@/components/ui/slider";
import { useToast } from "@/components/ui/use-toast";
import { recordFeatureVote, hasVotedOnFeature } from '@/lib/user-votes';
import { AlertDialog, AlertDialogContent, AlertDialogDescription } from "@/components/ui/alert-dialog"

// Feature voting options
type VoteOption = 'would-not-use' | 'unsure' | 'please-create';

interface Feature {
    id: string;
    name: string;
    description: string;
    vote: VoteOption;
}

const voteValues: VoteOption[] = ['would-not-use', 'unsure', 'please-create'];

export default function FeedbackPage() {
    const { user } = useUser();
    const { openSignIn, openSignUp } = useClerk();
    const { toast } = useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const sourceFeature = searchParams.get('feature');
    const returnTo = searchParams.get('returnTo') || '/';

    // Initial features list
    const initialFeatures: Feature[] = [
        {
            id: 'ai-assistant',
            name: 'AI Assistant',
            description: 'Built-in AI to help you navigate the app and chat with your data. Get instant insights and recommendations from your campaign performance.',
            vote: sourceFeature === 'ai-assistant' ? 'please-create' : 'unsure'
        },
        {
            id: 'user-lists',
            name: 'User Lists Editor',
            description: 'Create and manage user lists for placement exclusions',
            vote: sourceFeature === 'user-lists' ? 'please-create' : 'unsure'
        },
        {
            id: 'high-cpc-warnings',
            name: 'High CPC Warnings',
            description: 'Get alerts when your cost per click exceeds historical averages or predefined thresholds',
            vote: sourceFeature === 'high-cpc-warnings' ? 'please-create' : 'unsure'
        },
        {
            id: 'campaign-level-metrics',
            name: 'Individual Campaign Metrics',
            description: 'Set unique COGS percentages and break-even CPAs for each campaign instead of account-wide settings',
            vote: sourceFeature === 'campaign-level-metrics' ? 'please-create' : 'unsure'
        },
        {
            id: 'budget-pacing',
            name: 'Budget Pacing',
            description: 'Track and visualize daily budget utilization and get alerts for under/overspending',
            vote: sourceFeature === 'budget-pacing' ? 'please-create' : 'unsure'
        },
        {
            id: 'training-videos',
            name: 'More Training Videos',
            description: 'Additional video tutorials covering advanced features and optimization strategies',
            vote: sourceFeature === 'training-videos' ? 'please-create' : 'unsure'
        },
        {
            id: 'product-trends',
            name: 'Product Performance Trends',
            description: 'Track and analyze performance trends at the product level for pmax and shopping campaigns',
            vote: sourceFeature === 'product-trends' ? 'please-create' : 'unsure'
        },
        {
            id: 'quality-score',
            name: 'Quality Score Analysis',
            description: 'Detailed quality score breakdowns and improvement recommendations',
            vote: sourceFeature === 'quality-score' ? 'please-create' : 'unsure'
        },
        {
            id: 'white-label',
            name: 'White Label Option',
            description: 'Ability to rebrand the application with your own logo and colors',
            vote: sourceFeature === 'white-label' ? 'please-create' : 'unsure'
        }
    ];

    const [features, setFeatures] = useState<Feature[]>(initialFeatures);
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSubmitting, setShowSubmitting] = useState(false);
    const [voteTimestamp, setVoteTimestamp] = useState<string>('');

    // Memoize voted states to prevent unnecessary checks
    const votedStates = useMemo(() => {
        return features.reduce((acc, feature) => ({
            ...acc,
            [feature.id]: hasVotedOnFeature(user, feature.id)
        }), {} as Record<string, boolean>);
    }, [user, features, voteTimestamp]);

    // Replace individual checks with memoized values
    const hasVotedFeature = (featureId: string) => votedStates[featureId];

    const handleVoteChange = (featureId: string, value: number[]) => {
        const voteIndex = value[0];
        const vote = voteValues[voteIndex];
        setFeatures(features.map(feature =>
            feature.id === featureId ? { ...feature, vote } : feature
        ));
    };

    const getVoteValue = (vote: VoteOption): number => {
        return voteValues.indexOf(vote);
    };

    const handleSubmit = async () => {
        if (!user?.primaryEmailAddress?.emailAddress) {
            toast({
                variant: "destructive",
                title: "Error",
                description: "Please sign in to submit feedback"
            });
            return;
        }

        setIsSubmitting(true);
        setShowSubmitting(true);

        try {
            // Queue up all the promises we need to execute
            const featureVotes = features
                .filter(feature => feature.vote !== 'unsure') // Only send non-neutral votes
                .map(feature => ({
                    feature: feature.id,
                    vote: feature.vote,
                    timestamp: new Date().toISOString()
                }));

            // Send each vote as a separate request
            const votePromises = featureVotes.map(vote => {
                const params = new URLSearchParams({
                    timestamp: new Date().toISOString(),
                    email: user.primaryEmailAddress.emailAddress,
                    feature: vote.feature,
                    vote: vote.vote,
                    feedback: feedback || 'No additional feedback'
                });

                return fetch(
                    `https://script.google.com/macros/s/AKfycbzNbaeZzSr9J-IxwJLWdR8hvv3H1-ZjPxffHK5oIjpqoNcxPsDP1P3r0BJzEhd4_r-VUQ/exec?${params.toString()}`,
                    { method: 'GET', mode: 'no-cors' }
                );
            });

            // Execute all promises in parallel
            await Promise.all([
                // Record votes in Clerk metadata
                ...featureVotes.map(vote => recordFeatureVote(user, vote)),
                // Submit votes to Google Sheets
                ...votePromises
            ]);

            // Force recalculation of vote states
            setVoteTimestamp(new Date().toISOString());

            toast({
                title: "Thank you!",
                description: "Your feedback has been submitted successfully."
            });

        } catch (error) {
            console.error('Feedback submission error:', error);
            toast({
                variant: "destructive",
                title: "Error",
                description: "Failed to submit feedback. Please try again."
            });
        } finally {
            // Hide the submitting dialog and navigate back
            setShowSubmitting(false);
            setIsSubmitting(false);
            navigate('/asteroids');
        }
    };

    // If user is not logged in, show the overlay with sign in/up options
    if (!user) {
        return (
            <div className="relative">
                {/* Background content (greyed out) */}
                <div className="opacity-50 pointer-events-none">
                    <div className="container mx-auto px-4 py-8 max-w-4xl">
                        <h1 className="text-3xl font-bold mb-6">Upcoming Features & Feedback</h1>
                        <section>
                            <p className="text-lg mb-6">
                                Help us prioritize new features by letting us know which ones you'd find most useful.
                            </p>

                            <section className="mb-6 bg-orange-100/80 dark:bg-orange-900/20 text-foreground p-6 rounded-lg border border-orange-200 dark:border-orange-800">
                                <h2 className="text-2xl font-bold mb-4">How to Vote</h2>
                                <div className="flex items-center">
                                    <span className="mr-2">💡</span>
                                    If you don't have a strong opinion about a feature, just leave the slider in the middle position
                                </div>
                            </section>

                            <div className="rounded-md border">
                                <div className="px-6 py-3 border-b bg-muted/50">
                                    <div className="grid grid-cols-5 gap-4">
                                        <div className="col-span-3">Feature</div>
                                        <div className="col-span-2 text-right">Interest Level</div>
                                    </div>
                                </div>
                                <div className="divide-y">
                                    {features.map(feature => (
                                        <div key={feature.id} className="px-6 py-4">
                                            <div className="grid grid-cols-5 gap-4 items-center">
                                                <div className="col-span-3">
                                                    <h3 className="font-medium">{feature.name}</h3>
                                                    <p className="text-sm text-muted-foreground">{feature.description}</p>
                                                </div>
                                                <div className="col-span-2">
                                                    <Slider
                                                        value={[1]}
                                                        max={2}
                                                        step={1}
                                                        disabled={true}
                                                        className="w-full cursor-not-allowed"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                {/* Login overlay */}
                <div className="fixed inset-0 bg-background/80 backdrop-blur-sm">
                    <div className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <div className="mx-auto flex flex-col items-center gap-4 bg-card p-8 rounded-lg border shadow-lg max-w-md w-full">
                            <h2 className="text-xl font-semibold">Sign in to Vote on Features</h2>
                            <p className="text-center text-muted-foreground mb-4">
                                Voting for features is only available to logged in users. It's free to create an account!
                            </p>
                            <div className="flex gap-4">
                                <Button onClick={() => openSignUp()} className="w-32">
                                    Sign Up
                                </Button>
                                <Button onClick={() => openSignIn()} variant="outline" className="w-32">
                                    Sign In
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-4xl">
            <h1 className="text-3xl font-bold mb-6">Upcoming Features & Feedback</h1>

            {/* Feature voting section */}
            <section>

                <p className="text-lg mb-6">
                    Help us prioritize new features by letting us know which ones you'd find most useful.
                </p>

                <section className="mb-6 bg-orange-100/80 dark:bg-orange-900/20 text-foreground p-6 rounded-lg border border-orange-200 dark:border-orange-800">
                    <h2 className="text-2xl font-bold mb-4">How to Vote</h2>
                    <div className="flex items-center">
                        <span className="mr-2">💡</span>
                        If you don't have a strong opinion about a feature, just leave the slider in the middle position
                    </div>
                </section>

                <div className="rounded-md border">
                    <div className="px-6 py-3 border-b bg-muted/50">
                        <div className="grid grid-cols-5 gap-4">
                            <div className="col-span-3">Feature</div>
                            <div className="col-span-2 text-right">Interest Level</div>
                        </div>
                    </div>

                    <div className="divide-y">
                        {features.map(feature => {
                            const alreadyVoted = votedStates[feature.id];
                            return (
                                <div
                                    key={feature.id}
                                    className={`px-6 py-4 ${alreadyVoted ? 'opacity-50 bg-muted/20' : ''}`}
                                >
                                    <div className="grid grid-cols-5 gap-4 items-center">
                                        <div className="col-span-3">
                                            <div className="flex items-center gap-2">
                                                <h3 className="font-medium">{feature.name}</h3>
                                                {alreadyVoted && (
                                                    <span className="text-xs text-muted-foreground">(Already voted)</span>
                                                )}
                                            </div>
                                            <p className="text-sm text-muted-foreground">{feature.description}</p>
                                        </div>
                                        <div className="col-span-2">
                                            <div className="space-y-1">
                                                <div className="grid grid-cols-3 text-xs text-muted-foreground px-1">
                                                    <div className="leading-tight text-left pl-1">
                                                        <span>Would</span><br />
                                                        <span>not use</span>
                                                    </div>
                                                    <div className="leading-tight text-center">
                                                        <span>No firm</span><br />
                                                        <span>opinion</span>
                                                    </div>
                                                    <div className="leading-tight text-right pr-1">
                                                        <span>Please</span><br />
                                                        <span>create!</span>
                                                    </div>
                                                </div>
                                                <Slider
                                                    value={[getVoteValue(feature.vote)]}
                                                    onValueChange={(value) => !alreadyVoted && handleVoteChange(feature.id, value)}
                                                    max={2}
                                                    step={1}
                                                    disabled={alreadyVoted}
                                                    className={`w-full ${alreadyVoted ? 'cursor-not-allowed' : ''}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            {/* Additional feedback section */}
            <section className="mt-12">
                <h2 className="text-2xl font-semibold mb-4">Additional Feedback</h2>
                <p className="text-lg mb-4">
                    Have more suggestions or feedback? Please let me know here.
                </p>
                <Textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Your feedback here..."
                    className="min-h-[100px] mb-4"
                />
                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    size="lg"
                    className="text-2xl py-6 px-8"
                >
                    {isSubmitting ? 'Submitting...' : 'Send My Feedback'}
                </Button>
            </section>

            <AlertDialog open={showSubmitting} onOpenChange={setShowSubmitting}>
                <AlertDialogContent className="text-center">
                    <div className="flex flex-col items-center gap-4 py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                        <AlertDialogDescription>
                            Recording your feedback...
                        </AlertDialogDescription>
                    </div>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
} 