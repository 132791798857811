// src/lib/storage-utils.ts
import { STORAGE_KEYS, CACHE_DURATION } from './constants';
import type { StorageData } from '@/types/metrics';

// Local storage keys for temporary data
export const LOCAL_STORAGE_KEYS = {
  COGS_AMOUNT: 'temp_cogs_amount',
  BREAK_EVEN_CPA: 'temp_break_even_cpa',
  LAST_CLERK_UPDATE: 'last_clerk_update',
  TEMP_WEBAPP_URL: 'temp_webapp_url'
} as const;

// Temporary data handling
export function setLocalValue(key: string, value: number) {
  localStorage.setItem(key, value.toString());
  localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_CLERK_UPDATE, Date.now().toString());
}

export function getLocalValue(key: string): number | null {
  const value = localStorage.getItem(key);
  return value ? Number(value) : null;
}

// Web App URL handling for auth flow
export function storeTempWebAppUrlWithTimestamp(url: string) {
  // Validate URL format
  if (!url.includes('script.google.com/macros/s/')) {
    throw new Error('Invalid Web App URL format. Must be a Google Apps Script web app URL.');
  }

  // Prevent googleusercontent URLs
  if (url.includes('googleusercontent')) {
    throw new Error('Invalid URL: Contains googleusercontent. Please use the proper Web App URL.');
  }

  const data = {
    url,
    timestamp: new Date().toISOString()
  };
  localStorage.setItem(LOCAL_STORAGE_KEYS.TEMP_WEBAPP_URL, JSON.stringify(data));
}

export function getTempWebAppUrlWithTimestamp(): { url: string; timestamp: string } | null {
  const data = localStorage.getItem(LOCAL_STORAGE_KEYS.TEMP_WEBAPP_URL);
  if (!data) return null;
  try {
    return JSON.parse(data);
  } catch {
    return null;
  }
}

export function clearTempWebAppUrl() {
  // Clear both temporary and permanent storage
  localStorage.removeItem(LOCAL_STORAGE_KEYS.TEMP_WEBAPP_URL);
  localStorage.removeItem(STORAGE_KEYS.WEB_APP_URL);
}

// Storage expiry check
export function isStorageExpired(timestamp: string, expiryMinutes: number = 5): boolean {
  const storedTime = new Date(timestamp).getTime();
  const expiryTime = storedTime + (expiryMinutes * 60 * 1000);
  return Date.now() > expiryTime;
}

// Campaign data cache validation
export function needsRefresh(data: StorageData | null): boolean {
  if (!data?.timestamp) return true;
  const cacheAge = Date.now() - new Date(data.timestamp).getTime();
  return cacheAge > CACHE_DURATION;
}

// Clear all temporary storage
export function clearAllTempStorage() {
  Object.values(LOCAL_STORAGE_KEYS).forEach(key => {
    localStorage.removeItem(key);
  });
}

const SAVED_PROMPTS_KEY = 'savedPrompts'

interface SavedPrompt {
  id: string
  prompt: string
  lastModified: number
}

export const getSavedPrompt = (promptId: string): string | null => {
  try {
    const saved = localStorage.getItem(SAVED_PROMPTS_KEY)
    if (!saved) return null

    const prompts: SavedPrompt[] = JSON.parse(saved)
    const found = prompts.find(p => p.id === promptId)
    return found?.prompt || null
  } catch (err) {
    console.error('Error getting saved prompt:', err)
    return null
  }
}

export const savePrompt = (promptId: string, prompt: string) => {
  try {
    const saved = localStorage.getItem(SAVED_PROMPTS_KEY)
    const prompts: SavedPrompt[] = saved ? JSON.parse(saved) : []

    const existingIndex = prompts.findIndex(p => p.id === promptId)
    if (existingIndex >= 0) {
      prompts[existingIndex] = { id: promptId, prompt, lastModified: Date.now() }
    } else {
      prompts.push({ id: promptId, prompt, lastModified: Date.now() })
    }

    localStorage.setItem(SAVED_PROMPTS_KEY, JSON.stringify(prompts))
  } catch (err) {
    console.error('Error saving prompt:', err)
  }
}

export const resetPrompt = (promptId: string) => {
  try {
    const saved = localStorage.getItem(SAVED_PROMPTS_KEY)
    if (!saved) return

    const prompts: SavedPrompt[] = JSON.parse(saved)
    const filtered = prompts.filter(p => p.id !== promptId)
    localStorage.setItem(SAVED_PROMPTS_KEY, JSON.stringify(filtered))
  } catch (err) {
    console.error('Error resetting prompt:', err)
  }
}