// src/pages/curve.tsx
'use client'
import { useState, useEffect, useMemo } from 'react'
import { useCampaignData } from '@/contexts/campaign-data'
import { useAccounts } from '@/contexts/account-context'
import { ProfitAnalysisControls } from '@/components/profit-analysis/controls'
import { ProfitAnalysisChart } from '@/components/profit-analysis/chart'
import { ProfitAnalysisPanel } from '@/components/profit-analysis/panel'
import { DebugPanel } from '@/components/profit-analysis/debug-panel'
import { Fredo } from '@/components/fredo-chat'
import {
  ResponseModel,
  ChartType,
  ProfitInputs,
  RawMetrics,
  BusinessMode,
  OptimalZone,
  ProfitDataPoint,
  IncrementalDataPoint
} from '@/types/metrics'
import { useProfit } from '@/hooks/useProfit'
import { calculateRanges, transformCampaignData, processCampaignData } from '@/lib/campaign-utils'
import { Card, CardTitle, CardContent, CardHeader } from '@/components/ui/card'
import { LeadGenControls } from '@/components/profit-analysis/lead-gen-controls'
import { transformBaseMetrics } from '@/lib/sample-data-utils'
import { trackEvent, ANALYTICS_EVENTS } from '@/lib/analytics'

// Default empty metrics when no campaign is selected
const emptyMetrics: RawMetrics = {
  date: new Date().toISOString(),
  impr: 0,
  clicks: 0,
  cost: 0,
  conv: 0,
  value: 0
}

// Convert CurvePoint to ProfitDataPoint for chart component
const convertToProfitDataPoint = (point: CurvePoint): ProfitDataPoint => ({
  cost: point.cost,
  conv: point.conv,
  value: point.value,
  profit: point.profit,
  cpa: point.cpa,
  roas: point.roas,
  marginalCpa: point.marginalCpa,
  marginalRoas: point.marginalRoas
})

// Update ProfitInputs to match what we're actually using
interface PageProfitInputs {
  cost: number
  value: number
  cogs: number
  breakEvenCpa: number
}

// Keep our local CurvePoint definition since it matches what useProfit returns
interface CurvePoint {
  cost: number
  conv: number  // Note: this is conv not conversions
  value: number
  profit: number
  cpa?: number
  roas?: number
  marginalCpa?: number
  marginalRoas?: number
}

export default function ProfitCurvePage() {
  const { data, loadingStatus, error } = useCampaignData()
  const { activeAccount } = useAccounts()
  const businessMode = activeAccount?.businessMode || 'ecomm'
  const accountCurrency = activeAccount?.currency || '$'

  // Campaign selection state
  const [selectedCampaign, setSelectedCampaign] = useState('')
  const [responseModel, setResponseModel] = useState<ResponseModel>('diminishing')
  const [activeChart, setActiveChart] = useState<ChartType>('profit-curve')
  const [currentConversions, setCurrentConversions] = useState(0)

  // What-if analysis inputs
  const [inputs, setInputs] = useState<PageProfitInputs>({
    cost: 0,
    value: 0,
    cogs: activeAccount?.cogsAmount || 0,
    breakEvenCpa: activeAccount?.breakEvenCpa || 0
  })

  // Early returns for loading/error states
  if (loadingStatus === 'initial' || loadingStatus === 'refresh') {
    console.log('Loading state:', loadingStatus)
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">Loading campaign data...</div>
      </div>
    )
  }

  if (error || !data) {
    console.log('Error or no data:', { error, data })
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg text-red-500">
          {error || "No campaign data available"}
        </div>
      </div>
    )
  }

  // Only process campaigns if we have data
  const campaigns = useMemo(() => {
    if (!data?.thirty_days?.length) return []

    // Transform raw data first - use the THIRTY_DAYS transformer from sample-data-utils
    const transformedData = data.thirty_days.map(row => {
      const metrics = transformBaseMetrics(row);
      const date = row.date || row.Date || new Date().toISOString().split('T')[0];
      return {
        impr: metrics.impr,
        clicks: metrics.clicks,
        cost: metrics.cost,
        conv: metrics.conv,
        value: metrics.value,
        imprShare: metrics.imprShare || 0,
        lostBudget: metrics.lostBudget || 0,
        lostRank: metrics.lostRank || 0,
        CTR: metrics.CTR,
        CvR: metrics.CvR,
        ROAS: metrics.ROAS,
        CPA: metrics.CPA,
        AOV: metrics.AOV,
        profit: metrics.profit,
        campaign: String(row.campaign || row.Campaign || ''),
        campaignId: String(row.campaignId || row.CampaignId || ''),
        Date: date,
        Campaign: String(row.campaign || row.Campaign || ''),
        CampaignId: String(row.campaignId || row.CampaignId || '')
      };
    });

    // Then process the transformed data
    return processCampaignData(transformedData)
  }, [data?.thirty_days])

  // Early return if no campaigns
  if (!campaigns.length) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-lg">No campaign data available</div>
      </div>
    )
  }

  // Initialize or update state when campaigns or account changes
  useEffect(() => {
    if (campaigns.length > 0) {
      // Always select the campaign with highest spend when account changes
      const highestSpendCampaign = campaigns[0] // campaigns are already sorted by cost
      setSelectedCampaign(highestSpendCampaign.campaign)
      setInputs(prev => ({
        ...prev,
        cost: highestSpendCampaign.cost,
        value: businessMode === 'leadgen'
          ? highestSpendCampaign.conv * (activeAccount?.breakEvenCpa || 0)
          : highestSpendCampaign.value,
        cogs: activeAccount?.cogsAmount || 0,
        breakEvenCpa: activeAccount?.breakEvenCpa || 0
      }))
      setCurrentConversions(highestSpendCampaign.conv)
    }
  }, [campaigns, activeAccount?.dexieId, businessMode, activeAccount?.breakEvenCpa, activeAccount?.cogsAmount])

  const renderControls = () => {
    const commonProps = {
      campaigns,
      selectedCampaign,
      responseModel,
      currentCost: inputs.cost,
      costRange: ranges.costRange,
      selectedCurrency: accountCurrency,
      onCampaignSelect: handleCampaignSelect,
      onResponseModelChange: setResponseModel,
      onCostChange: handleCostChange,
    }

    if (businessMode === 'leadgen') {
      console.log('LeadGen Mode - Current State:', {
        cost: inputs.cost,
        conversions: currentConversions,
        breakEvenCpa: inputs.breakEvenCpa,
        value: currentConversions * inputs.breakEvenCpa
      });

      return (
        <LeadGenControls
          {...commonProps}
          currentConversions={currentConversions}
          onConversionsChange={(conv: number) => {
            trackEvent(ANALYTICS_EVENTS.CURVE.CONVERSIONS_CHANGE());
            setCurrentConversions(conv);
            setInputs(prev => ({
              ...prev,
              conv: conv,
              value: conv * inputs.breakEvenCpa
            }));
          }}
        />
      )
    }

    return (
      <ProfitAnalysisControls
        {...commonProps}
        currentValue={inputs.value}
        valueRange={ranges.valueRange}
        businessMode={businessMode}
        onValueChange={handleValueChange}
      />
    )
  }

  // Get selected campaign data with proper typing
  const campaignData = useMemo(() => {
    if (!campaigns.length || !selectedCampaign) return null
    return campaigns.find(c => c.campaign === selectedCampaign)
  }, [campaigns, selectedCampaign])

  // Calculate ranges for sliders
  const ranges = useMemo(() => {
    if (!campaignData) return {
      costRange: { min: 0, max: 1000 },
      valueRange: { min: 0, max: 1000 }
    }
    return calculateRanges(campaignData)
  }, [campaignData])

  // Handle input changes
  const handleCostChange = (value: number) => {
    trackEvent(ANALYTICS_EVENTS.CURVE.COST_CHANGE());
    setInputs(prev => ({ ...prev, cost: value }))
  }

  const handleValueChange = (value: number) => {
    trackEvent(ANALYTICS_EVENTS.CURVE.VALUE_CHANGE());
    setInputs(prev => ({ ...prev, value: value }))
  }

  // Reset inputs when campaign changes
  const handleCampaignSelect = (campaign: string) => {
    trackEvent(ANALYTICS_EVENTS.CURVE.CAMPAIGN_SELECT(campaign));
    setSelectedCampaign(campaign)
    const newCampaign = campaigns.find(c => c.campaign === campaign)
    if (newCampaign) {
      setInputs(prev => ({
        ...prev,
        cost: newCampaign.cost,
        value: businessMode === 'leadgen' ? newCampaign.conv * inputs.breakEvenCpa : newCampaign.value
      }))
      // Reset conversions for lead gen mode
      if (businessMode === 'leadgen') {
        setCurrentConversions(newCampaign.conv)
      }
    }
  }

  // Calculate profit analysis
  const profitAnalysis = useProfit(
    campaignData ? {
      campaign: campaignData.campaign,
      cost: inputs.cost,
      conv: businessMode === 'leadgen' ? currentConversions : campaignData.conv,
      value: businessMode === 'leadgen' ? currentConversions * inputs.breakEvenCpa : inputs.value
    } : null,
    {
      businessMode,
      cogsAmount: inputs.cogs,
      breakEvenCpa: inputs.breakEvenCpa
    }
  )

  // Transform curve points to chart data
  const chartData = useMemo(() => {
    if (!profitAnalysis?.curvePoints?.length) return []
    return profitAnalysis.curvePoints.map(convertToProfitDataPoint)
  }, [profitAnalysis?.curvePoints])

  // Calculate incremental data points
  const incrementalData = useMemo(() => {
    if (!chartData?.length) return []
    return chartData.map((point, i): IncrementalDataPoint => {
      const prev = i > 0 ? chartData[i - 1] : point
      const incrementalCost = point.cost - prev.cost
      const incrementalConv = point.conv - prev.conv
      const incrementalSales = point.value - prev.value

      return {
        cost: point.cost,
        incrementalProfit: point.profit - prev.profit,
        incrementalSales,
        incrementalROAS: incrementalCost > 0 ? incrementalSales / incrementalCost : 0,
        incrementalCPA: incrementalConv > 0 ? incrementalCost / incrementalConv : 0
      }
    })
  }, [chartData])

  return (
    <div className="container mx-auto p-4 space-y-8">
      <div className="space-y-4">
        {/* Top section: Controls (30%) and Chart (70%) */}
        <div className="flex gap-4">
          <div className="w-[30%]">
            {renderControls()}
          </div>

          <div className="flex-1">
            <Card>
              <CardHeader>
                <CardTitle>Profit Curve</CardTitle>
              </CardHeader>
              <CardContent>
                <ProfitAnalysisChart
                  data={chartData}
                  incrementalData={incrementalData}
                  activeChart={activeChart}
                  onChartTypeChange={(type) => {
                    trackEvent(ANALYTICS_EVENTS.CURVE.CHART_TYPE(type));
                    setActiveChart(type);
                  }}
                  optimalZone={profitAnalysis?.optimalZone}
                  currentCost={profitAnalysis?.currentMetrics.cost}
                  currentProfit={profitAnalysis?.currentMetrics.profit}
                  selectedCurrency={accountCurrency}
                  businessMode={businessMode}
                />
              </CardContent>
            </Card>
          </div>
        </div>

        {/* Bottom section: Analysis Panel (full width) */}
        <Card>
          <CardHeader>
            <CardTitle>Analysis Panel</CardTitle>
          </CardHeader>
          <CardContent>
            <ProfitAnalysisPanel
              optimalZone={profitAnalysis?.optimalZone}
              currentMetrics={profitAnalysis?.currentMetrics}
              profitData={chartData}
              selectedCurrency={accountCurrency}
              currentCost={profitAnalysis?.currentMetrics.cost}
              businessMode={businessMode}
              breakEvenCpa={inputs.breakEvenCpa}
              cogsPercentage={inputs.cogs}
              campaign={campaignData}
            />
          </CardContent>
        </Card>

        {/* Debug Panel */}
        <DebugPanel
          campaign={campaignData}
          curvePoints={profitAnalysis?.curvePoints || []}
          currentMetrics={profitAnalysis?.currentMetrics || {
            cost: 0,
            conv: 0,
            value: 0,
            profit: 0
          }}
          businessMode={businessMode}
          cogsAmount={inputs.cogs}
          breakEvenCpa={inputs.breakEvenCpa}
          selectedCurrency={accountCurrency}
        />

        {/* Add Fredo */}
        <Fredo
          pageData={{
            selectedCampaign,
            responseModel,
            businessMode,
            currentMetrics: profitAnalysis?.currentMetrics || {
              cost: 0,
              conv: 0,
              value: 0,
              profit: 0
            },
            optimalZone: profitAnalysis?.optimalZone,
            chartData,
            incrementalData,
            inputs: {
              cost: inputs.cost,
              value: inputs.value,
              cogs: inputs.cogs,
              breakEvenCpa: inputs.breakEvenCpa
            },
            campaign: campaignData
          }}
        />
      </div>
    </div>
  )
}