// src/hooks/use-rate-limit.ts

import { useState, useCallback } from 'react'

interface RateLimitState {
    lastCallTime: number
    callCount: number
}

const RATE_LIMIT = {
    MAX_CALLS: 10,  // Maximum calls per minute
    WINDOW_MS: 60 * 1000,  // 1 minute window
    MIN_DELAY_MS: 1000  // Minimum delay between calls
}

export function useRateLimit() {
    const [state, setState] = useState<RateLimitState>({
        lastCallTime: 0,
        callCount: 0
    })

    const checkRateLimit = useCallback(async () => {
        const now = Date.now()
        const timeSinceLastCall = now - state.lastCallTime

        // Reset counter if window has passed
        if (timeSinceLastCall >= RATE_LIMIT.WINDOW_MS) {
            setState({
                lastCallTime: now,
                callCount: 1
            })
            return
        }

        // Check if we've hit the rate limit
        if (state.callCount >= RATE_LIMIT.MAX_CALLS) {
            const timeToWait = RATE_LIMIT.WINDOW_MS - timeSinceLastCall
            await new Promise(resolve => setTimeout(resolve, timeToWait))
            setState({
                lastCallTime: Date.now(),
                callCount: 1
            })
            return
        }

        // Enforce minimum delay between calls
        if (timeSinceLastCall < RATE_LIMIT.MIN_DELAY_MS) {
            await new Promise(resolve => setTimeout(resolve, RATE_LIMIT.MIN_DELAY_MS - timeSinceLastCall))
        }

        // Increment counter
        setState(prev => ({
            lastCallTime: Date.now(),
            callCount: prev.callCount + 1
        }))
    }, [state])

    return { checkRateLimit }
} 