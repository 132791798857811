import { SignUp } from "@clerk/clerk-react"

export default function SignUpPage() {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <SignUp 
        appearance={{
          elements: {
            rootBox: "mx-auto w-full max-w-[440px]",
            card: "shadow-none"
          }
        }}
        signInUrl="/sign-in"
        fallbackRedirectUrl="/"
      />
    </div>
  )
} 