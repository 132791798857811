import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Fredo } from "@/components/fredo-chat";
import { Link } from 'react-router-dom';
import { FileText, BookOpen, Code, Presentation, Download, Lightbulb } from 'lucide-react';

interface Resource {
    name: string;
    description: string;
    link: string;
    icon?: React.ReactNode;
    isExternal?: boolean;
}

interface Script {
    name: string;
    description: string;
    price: number;
    link: string;
    extras?: string[];
}

interface Masterclass {
    name: string;
    description: string;
    link: string;
    icon?: React.ReactNode;
}

const TALK_RESOURCES: Resource[] = [
    {
        name: "Presentation Slides",
        description: "Download the complete slide deck from the talk in PDF format",
        link: "/slides.pdf",
        icon: <Presentation className="w-5 h-5" />,
        isExternal: true
    },
    {
        name: "Learn Scripts",
        description: "Interactive 14-minute challenge to master Google Ads Scripts basics",
        link: "/scary",
        icon: <Lightbulb className="w-5 h-5" />
    },
    {
        name: "4Cs Framework",
        description: "Learn the 4Cs framework for using & creating Google Ads scripts",
        link: "/4Cs",
        icon: <BookOpen className="w-5 h-5" />
    }
];

const MASTERCLASS_SCRIPTS: Masterclass[] = [
    {
        name: "Search Term Script",
        description: "Script to analyze and process search terms data from your Google Ads account",
        link: "/scripts/mc-search-terms.js",
        icon: <Download className="w-5 h-5" />
    },
    {
        name: "Classification Script",
        description: "Script that reads search terms, sends them to OpenAI and returns intent classifications",
        link: "/scripts/mc-classify.js",
        icon: <Download className="w-5 h-5" />
    }
];

const FEATURED_SCRIPTS: Script[] = [
    {
        name: "Scripts & Sheets Mastery 2024",
        price: 299,
        link: "/mastery",
        description: "Learn how to create your own scripts",
        extras: [
            "Includes my QTS system for scripts",
            "Comprehensive training",
            "Learn in demand skills",
            "Bonus scripts & templates included",
            "Lifetime access to updates"
        ]
    },
    {
        name: "MCC PMax Script",
        price: 399,
        link: "https://mikerhodes.circle.so/checkout/latest-mcc-script",
        description: "Use on unlimited accounts",
        extras: [
            "Create PMax sheet for all accounts",
            "Save hours of work",
            "Setup walkthrough video",
            "All documentation included",
            "Personal support from Mike included"
        ]
    },
    {
        name: "Single Account PMax Script",
        price: 199,
        link: "/pmax",
        description: "Used by 2000+ agencies",
        extras: [
            "Create PMax Insights in seconds",
            "Multiple reports included",
            "40 min video walkthrough",
            "Detailed documentation",
            "Free support & updates"
        ]
    }
];

const ResourceCard: React.FC<{ resource: Resource }> = ({ resource }) => (
    <Card className={cn(
        "w-full transition-all duration-300",
        "bg-gradient-to-br from-blue-50 to-blue-100/50 dark:from-blue-950/50 dark:to-blue-900/30",
        "hover:shadow-lg hover:shadow-blue-100 dark:hover:shadow-blue-900/30",
        "hover:-translate-y-1 hover:scale-[1.02]"
    )}>
        <CardContent className="pt-6">
            <div className="flex items-start gap-4">
                <div className="text-blue-500">
                    {resource.icon}
                </div>
                <div className="flex-1">
                    <h3 className="text-xl font-semibold mb-2">{resource.name}</h3>
                    <p className="text-muted-foreground mb-4">{resource.description}</p>
                    {resource.isExternal ? (
                        <a
                            href={resource.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block px-4 py-2 rounded transition-all duration-300 bg-blue-600 hover:bg-blue-700 text-white hover:shadow-md"
                        >
                            Download PDF
                        </a>
                    ) : (
                        <Link
                            to={resource.link}
                            className="inline-block px-4 py-2 rounded transition-all duration-300 bg-blue-600 hover:bg-blue-700 text-white hover:shadow-md"
                        >
                            View Resource
                        </Link>
                    )}
                </div>
            </div>
        </CardContent>
    </Card>
);

const MasterclassCard: React.FC<{ masterclass: Masterclass }> = ({ masterclass }) => (
    <Card className={cn(
        "w-full transition-all duration-300",
        "bg-gradient-to-br from-purple-50 to-purple-100/50 dark:from-purple-950/50 dark:to-purple-900/30",
        "hover:shadow-lg hover:shadow-purple-100 dark:hover:shadow-purple-900/30",
        "hover:-translate-y-1 hover:scale-[1.02]"
    )}>
        <CardContent className="pt-6">
            <div className="flex items-start gap-4">
                <div className="text-purple-500">
                    {masterclass.icon}
                </div>
                <div className="flex-1">
                    <h3 className="text-xl font-semibold mb-2">{masterclass.name}</h3>
                    <p className="text-muted-foreground mb-4">{masterclass.description}</p>
                    <a
                        href={masterclass.link}
                        download
                        className="inline-block px-4 py-2 rounded transition-all duration-300 bg-purple-600 hover:bg-purple-700 text-white hover:shadow-md"
                    >
                        Download Script
                    </a>
                </div>
            </div>
        </CardContent>
    </Card>
);

const ScriptCard: React.FC<{ script: Script }> = ({ script }) => {
    const isInternalRoute = script.link.startsWith('/');

    return (
        <Card className={cn(
            "w-full transition-all duration-300",
            "bg-gradient-to-br from-emerald-50 to-emerald-100/50 dark:from-emerald-950/50 dark:to-emerald-900/30",
            "hover:shadow-lg hover:shadow-emerald-100 dark:hover:shadow-emerald-900/30",
            "hover:-translate-y-1 hover:scale-[1.02]"
        )}>
            <CardHeader>
                <CardTitle className="flex justify-between items-center">
                    <span className="text-xl">{script.name}</span>
                    <span className="text-2xl font-bold text-emerald-700 dark:text-emerald-400">${script.price}</span>
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
                <p className="text-muted-foreground">{script.description}</p>
                {script.extras && (
                    <ul className="space-y-2">
                        {script.extras.map((extra, i) => (
                            <li key={i} className="flex items-center gap-2">
                                <span className="text-emerald-500">✓</span>
                                {extra}
                            </li>
                        ))}
                    </ul>
                )}
                <a
                    href={script.link}
                    {...(!isInternalRoute ? { target: "_blank", rel: "noopener noreferrer" } : {})}
                    className="inline-block w-full text-center px-4 py-3 rounded transition-all duration-300 bg-emerald-600 hover:bg-emerald-700 text-white hover:shadow-md"
                >
                    Get Access
                </a>
            </CardContent>
        </Card>
    );
};

export default function SlidesPage() {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-2">Talk Resources & Slides</h1>
            <p className="text-xl text-muted-foreground mb-8">
                Access the slides and resources mentioned in my recent talk about Google Ads automation
            </p>

            {/* Talk Resources Section */}
            <h2 className="text-2xl font-bold mb-6">Resources from the Talk</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                {TALK_RESOURCES.map((resource, index) => (
                    <ResourceCard key={index} resource={resource} />
                ))}
            </div>

            {/* Masterclass Scripts Section */}
            <h2 className="text-2xl font-bold mb-6">Masterclass Scripts</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                {MASTERCLASS_SCRIPTS.map((masterclass, index) => (
                    <MasterclassCard key={index} masterclass={masterclass} />
                ))}
                <div className="hidden md:block"></div> {/* Empty third column */}
            </div>

            {/* Featured Scripts Section */}
            <h2 className="text-2xl font-bold mb-6">Featured Scripts & Courses</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                {FEATURED_SCRIPTS.map((script, index) => (
                    <ScriptCard key={index} script={script} />
                ))}
            </div>

            {/* Build the Agent Section Title */}
            <h2 className="text-2xl font-bold mb-6">Build the Agent™ - Next Cohort Starting Soon</h2>

            {/* Build the Agent Waitlist Card */}
            <Card className={cn(
                "mb-12 transition-all duration-300",
                "bg-gradient-to-br from-orange-50 to-orange-100/50 dark:from-orange-950/50 dark:to-orange-900/30",
                "hover:shadow-lg hover:shadow-orange-100 dark:hover:shadow-orange-900/30",
                "hover:-translate-y-1 hover:scale-[1.01]",
                "border-2 border-orange-500/50"
            )}>
                <CardHeader>
                    <CardTitle className="flex justify-between items-center text-2xl">
                        <span>Build the Agent™</span>
                        <div>
                            <div className="text-2xl font-bold text-orange-600 dark:text-orange-400">$799 USD</div>
                            <div className="text-xs text-muted-foreground text-right">Early Bird, Reg. $1499</div>
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                    <p className="text-muted-foreground">Next Cohort Mid 2025 - Transform Your Career with AI</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="flex items-center gap-2">
                            <span className="text-orange-500">✓</span>
                            Small Group Live Training
                        </div>
                        <div className="flex items-center gap-2">
                            <span className="text-orange-500">✓</span>
                            Custom AI Agent included (value $999)
                        </div>
                        <div className="flex items-center gap-2">
                            <span className="text-orange-500">✓</span>
                            Scripts & Sheets Mastery included (value $299)
                        </div>
                        <div className="flex items-center gap-2">
                            <span className="text-orange-500">✓</span>
                            Private community access
                        </div>
                        <div className="flex items-center gap-2">
                            <span className="text-orange-500">✓</span>
                            Advanced AI prompt techniques
                        </div>
                        <div className="flex items-center gap-2">
                            <span className="text-orange-500">✓</span>
                            Personal support from Mike (VIP option available)
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <Link
                            to="/build"
                            className={cn(
                                "inline-block w-1/3 text-center px-4 py-3 rounded transition-all duration-300",
                                "bg-orange-600 hover:bg-orange-700 text-white hover:shadow-md"
                            )}
                        >
                            Learn More
                        </Link>
                    </div>
                </CardContent>
            </Card>

            {/* Add Fredo */}
            <Fredo
                pageData={{
                    resources: TALK_RESOURCES,
                    scripts: FEATURED_SCRIPTS
                }}
            />
        </div>
    );
} 