// src/lib/4cs-content.ts
import { Brain, BarChart, CheckCircle, Wrench, Blocks } from 'lucide-react'

export interface Stage {
  id: 'check' | 'chart' | 'change' | 'cognition' | 'build'
  title: string
  description: string
  icon: typeof CheckCircle
  color: string
  sections: {
    id: string
    title: string
    content?: string
    bullets?: string[]
  }[]
}

export const stages: Stage[] = [
  {
    id: 'check',
    title: 'Check Your Data',
    description: 'Start with simple checks and alerts to build confidence',
    icon: CheckCircle,
    color: 'text-green-500',
    sections: [
      {
        id: 'intro',
        title: 'Getting Started with Checks',
        content: 'The best place to start with Google Ads Scripts is simple checks and alerts. This can be a simple script that logs campaign performance data, or fully automated audit run on every account every hour!',
        bullets: [
          'Logging campaign performance data',
          'Creating email alerts for spend thresholds',
          'Monitoring key metrics like CTR or CPA',
          'Checking for missing components (e.g., missing sitelinks)'
        ]
      },
      {
        id: 'alerts',
        title: 'Setting Up Alerts',
        content: 'Scripts can send alerts through multiple channels:',
        bullets: [
          'Logs (built into Scripts and very simple)',
          'Email (very simple - see example below)',
          'Google Sheets (great for teams & clients)',
          'Slack (with a more advanced integration)',
          'Asana or other project management tools (requires API key)'
        ]
      }
    ]
  },
  {
    id: 'chart',
    title: 'Chart Your Insights',
    description: 'Visualize data patterns to uncover opportunities',
    icon: BarChart,
    color: 'text-blue-500',
    sections: [
      {
        id: 'why-visualize',
        title: 'Why Visualize Data?',
        content: 'Visual representations of data can unlock insights that might be missed when looking at raw numbers. The right chart type can instantly reveal patterns, trends, and relationships that would take hours to discover in spreadsheets. See https://support.google.com/docs/answer/190718?hl=en for more information.',
        bullets: [
          'Unlock insights that might be missed when looking at raw numbers',
          'Instantly reveal patterns, trends, and relationships that might take hours to discover in spreadsheets',
          'Great for visually spotting anomalies, trends, and opportunities'
        ]
      },
      {
        id: 'chart-types',
        title: 'Choosing the Right Chart',
        content: 'Different charts serve different purposes. Consider what story you want to tell with your data.',
        bullets: [
          'Comparison Charts (Bar/Line): Best for comparing metrics across campaigns, ad groups, or time periods',
          'Distribution Charts (Histogram/Box Plot): Show how metrics like CPA or CTR are spread across your account',
          'Composition Charts (Waterfall/Stacked): Visualize how different campaigns contribute to total spend or conv',
          'Relationship Charts (Scatter): Reveal correlations between metrics like cost vs conv or CTR vs CvR (nGrams!)'
        ]
      }
    ]
  },
  {
    id: 'change',
    title: 'Change Your Account',
    description: 'Automate optimizations & changes based on your rules',
    icon: Wrench,
    color: 'text-purple-500',
    sections: [
      {
        id: 'simple-changes',
        title: 'Simple Automated Changes',
        content: 'Scripts are more powerful than Auto Rules. They can run hourly, and are more flexible. Common automation use cases:',
        bullets: [
          'Pausing low-performing ads or keywords',
          'Adjusting bids based on performance',
          'Applying labels for performance tracking',
          'Scheduling bid adjustments',
          'Managing audience targeting'
        ]
      },
      {
        id: 'bulk-changes',
        title: 'Bulk Account Changes',
        content: 'Scripts excel at bulk operations:',
        bullets: [
          'Creating campaigns from a template Google Sheet',
          'Updating ads across multiple campaigns',
          'Implementing consistent settings',
          'Rolling out seasonal promotions'
        ]
      }
    ]
  },
  {
    id: 'cognition',
    title: 'Add Cognition',
    description: 'Leverage AI for smarter automation',
    icon: Brain,
    color: 'text-orange-500',
    sections: [
      {
        id: 'ai-intro',
        title: 'Introducing AI to Scripts',
        content: `Cognition represents the frontier of Google Ads automation - where scripts don't just execute predefined rules, 
        but can 'think' and make intelligent decisions (mostly). By leveraging the power of an LLM like chatGPT or Claude, your scripts 
        can analyze complex patterns, predict outcomes, and take sophisticated actions that adapt to changing conditions. Use with caution, 
        they do get things wrong still.

        LLMs enable scripts to:`,
        bullets: [
          'Generate performance insights',
          'Suggest optimization opportunities',
          'Explain unusual patterns',
          'Draft performance summaries or emails'
        ]
      },
      {
        id: 'whisperer',
        title: 'The Whisperer Script',
        content: `I created what I believe was the world's first LLM-powered Google Ads script in 2023, the Whisperer script - which I gave away for free to the community - demonstrates how to:`,
        bullets: [
          'Combine performance data with AI prompts',
          'Generate actionable insights',
          'Solve the "blank page" problem',
          'Accelerate analysis workflows'
        ]
      }
    ]
  },
  {
    id: 'build',
    title: 'Build the Agent',
    description: 'Learn how to build AI agents that can help automate your work',
    icon: Blocks,
    color: 'text-red-500',
    sections: [
      {
        id: 'build-intro',
        title: 'Learning to build your own is easier than you think',
        content: 'Take your automation to the next level by building AI agents that can:',
        bullets: [
          'Monitor account performance 24/7',
          'Make autonomous optimization decisions',
          'Generate natural language reports',
          'Adapt to changing market conditions'
        ]
      },
      {
        id: 'build-cta',
        title: 'Ready to Master Advanced Automation?',
        content: 'Go further than writing scripts & learn how to leverage AI to build your own tools. Join the Build the Agent workshop to master the Change & Cognition stages - you will soon create AI-powered scripts that will transform your account management (and career!)'
      }
    ]
  }
]