// src/pages/mobile.tsx
import React from 'react'
import { Card } from '@/components/ui/card'
import { Fredo } from "@/components/fredo-chat"
import {
    BarChart, Bar, LineChart, Line, XAxis, YAxis,
    CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import ReminderOptions from "@/components/reminder"
import { Link } from 'react-router-dom'


// Sample data for 10 days of channel splits
const channelData = [
    { date: 'Day 1', Shopping: 65, Display: 20, Video: 0, Search: 15, total: 100 },
    { date: 'Day 2', Shopping: 75, Display: 15, Video: 2, Search: 8, total: 100 },
    { date: 'Day 3', Shopping: 70, Display: 18, Video: 0, Search: 12, total: 100 },
    { date: 'Day 4', Shopping: 85, Display: 10, Video: 0, Search: 5, total: 100 },
    { date: 'Day 5', Shopping: 72, Display: 18, Video: 0, Search: 10, total: 100 },
    { date: 'Day 6', Shopping: 68, Display: 22, Video: 0, Search: 10, total: 100 },
    { date: 'Day 7', Shopping: 60, Display: 12, Video: 20, Search: 8, total: 100 },
    { date: 'Day 8', Shopping: 82, Display: 8, Video: 0, Search: 10, total: 100 },
    { date: 'Day 9', Shopping: 75, Display: 15, Video: 0, Search: 10, total: 100 },
    { date: 'Day 10', Shopping: 70, Display: 20, Video: 0, Search: 10, total: 100 }
]

// Sample data for trend chart
const trendData = Array.from({ length: 10 }).map((_, i) => ({
    date: `Day ${i + 1}`,
    cost: 300 + Math.random() * 100,
    value: 450 + Math.random() * 150
}))

// Sample data for heat map
const sampleHeatMapData = [
    { day: 'Mon', '09': 8500, '10': 12000, '11': 18000, '12': 22000, '13': 25000, '14': 20000, '15': 15000 },
    { day: 'Tue', '09': 5000, '10': 15000, '11': 20000, '12': 24000, '13': 23000, '14': 18000, '15': 12000 },
    { day: 'Wed', '09': 7000, '10': 14000, '11': 19000, '12': 25000, '13': 22000, '14': 16000, '15': 10000 },
    { day: 'Thu', '09': 6000, '10': 13000, '11': 21000, '12': 23000, '13': 24000, '14': 17000, '15': 11000 }
]

export default function MobilePage() {
    return (
        <div className="min-h-screen bg-gradient-to-b from-background to-secondary/10">
            <div className="container px-4 py-16 mx-auto">
                <div className="text-center mb-16">
                    <h1 className="text-4xl font-bold tracking-tight mb-4">
                        Performance Max Analyzer
                    </h1>
                    <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
                        The most comprehensive Performance Max analysis tool,
                        with channel breakdowns, trend analysis, and profit optimization
                    </p>
                </div>

                <div className="grid gap-8 mb-16">
                    {/* Channel Analysis */}
                    <Card className="p-6">
                        <h3 className="text-xl font-semibold mb-2">Performance Max Channel Analysis</h3>
                        <p className="text-muted-foreground mb-6">
                            Where it all started in 2022! Initially the free script showed where your budget was going,
                            this app takes that to a whole new level with detailed channel breakdowns and performance insights.
                        </p>
                        <div className="h-[300px]">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={channelData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="Shopping" stackId="a" fill="#047857" />
                                    <Bar dataKey="Display" stackId="a" fill="#60a5fa" />
                                    <Bar dataKey="Video" stackId="a" fill="#f97316" />
                                    <Bar dataKey="Search" stackId="a" fill="#94a3b8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>

                    {/* Trend Chart */}
                    <Card className="p-6">
                        <h3 className="text-xl font-semibold mb-2">Cost vs Value Trends</h3>
                        <p className="text-muted-foreground mb-6">
                            See trends for all your main metrics going back 6 months. Track cost, value, ROAS,
                            and more with interactive visualizations.
                        </p>
                        <div className="h-[300px]">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={trendData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line
                                        type="monotone"
                                        dataKey="cost"
                                        stroke="#2563eb"
                                        strokeWidth={2}
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="value"
                                        stroke="#16a34a"
                                        strokeWidth={2}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>

                    {/* Profit Curve */}
                    <Card className="p-6 relative">
                        <h3 className="text-xl font-semibold mb-2">Profit Curve Optimization</h3>
                        <p className="text-muted-foreground mb-6">
                            Visually see how spending more might affect your revenue (or number of conversions for lead gen accounts).
                            The tool uses your data & shows you the 'Profit Curve' for each one of your campaigns.
                        </p>
                        <div className="h-[300px] relative">
                            <img
                                src="/images/curve.png"
                                alt="Profit Curve"
                                className="w-full h-full object-contain"
                            />
                            {/* Current spend marker */}
                            <div className="absolute right-[33%] top-0 bottom-0 w-px bg-primary/50 bg-[length:10px_10px] bg-[repeating-linear-gradient(to_bottom,currentColor_0px,currentColor_5px,transparent_5px,transparent_10px)]" />
                            {/* Recommendation text */}
                            <div className="absolute top-2 right-2 max-w-[200px] text-sm bg-destructive/10 text-destructive p-3 rounded-md">
                                Recommendation: Decrease daily spend to €8k-€12k range to maximize profit
                            </div>
                        </div>
                    </Card>

                    {/* Keyword Tree Example */}
                    <Card className="p-6">
                        <h3 className="text-xl font-semibold mb-2">Keyword Analysis</h3>
                        <p className="text-muted-foreground mb-6">
                            See keywords & search terms in an intuitive tree view. Colors indicate Quality Score,
                            size shows relative spend, and you can drill down into specific ad groups.
                        </p>
                        <div className="h-[300px] relative">
                            <img
                                src="/images/tree.png"
                                alt="Keyword Tree"
                                className="w-full h-full object-contain"
                            />
                        </div>
                    </Card>

                    {/* Heat Map Example */}
                    <Card className="p-6">
                        <h3 className="text-xl font-semibold mb-2">Hourly Performance</h3>
                        <p className="text-muted-foreground mb-6">
                            View heat maps of your hourly performance data to identify peak performance times
                            and optimize your ad scheduling.
                        </p>
                        <div className="overflow-x-auto">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="w-[100px]">Day</TableHead>
                                        {['09', '10', '11', '12', '13', '14', '15'].map(hour => (
                                            <TableHead key={hour} className="text-center w-[80px]">
                                                {hour}:00
                                            </TableHead>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {sampleHeatMapData.map(row => (
                                        <TableRow key={row.day}>
                                            <TableCell className="font-medium">{row.day}</TableCell>
                                            {['09', '10', '11', '12', '13', '14', '15'].map(hour => {
                                                const value = row[hour]
                                                const intensity = (value - 5000) / (25000 - 5000)
                                                const color = `rgb(251, 146, 60, ${intensity})`
                                                return (
                                                    <TableCell
                                                        key={hour}
                                                        className="text-center"
                                                        style={{
                                                            backgroundColor: color,
                                                            transition: 'background-color 0.2s'
                                                        }}
                                                    >
                                                        €{(value).toLocaleString()}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Card>
                </div>

                <div className="text-center">
                    <p className="text-lg text-muted-foreground mb-8">
                        <span className="font-semibold text-xl block mb-4">Plus Much More!</span>
                        • See keywords & search terms in an intuitive tree view with nGram pattern analysis<br />
                        • Analyze PMax Placement performance in detail<br />
                        • View heat maps of your hourly performance data<br />
                        • Get AI-powered recommendations for optimization
                    </p>

                    <div className="max-w-md mx-auto">
                        <ReminderOptions />
                    </div>
                </div>

                {/* Quick Access Links */}
                <div className="mt-12 space-y-4">
                    <Card className="p-6 bg-muted">
                        <h3 className="text-lg font-semibold mb-4">Quick Access</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <Link to="/buy">
                                <Card className="p-4 hover:bg-accent transition-colors text-center">
                                    <h4 className="font-semibold">Get the Scripts & Courses</h4>
                                </Card>
                            </Link>
                          
                        </div>
                    </Card>
                </div>
            </div>

            {/* Add Fredo */}
            <Fredo
                pageData={{
                    isMobilePage: true,
                    message: "This is a sample page showing Performance Max analysis capabilities. For the best experience, please use this app on a desktop device."
                }}
            />
        </div>
    )
}