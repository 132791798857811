// src/lib/cpd-content.ts
interface Screen {
    id: string;
    title: string;
    content: string;
    step: number;
    image?: string;
    keyPoints?: string[];
    skills?: {
        creative?: string[];
        platform?: string[];
        data?: string[];
    };
}

export const screens: Screen[] = [
    {
        id: 'introduction',
        title: "The Modern Marketing Career Path",
        content: "CPD is a practical framework to help you think through career and agency development in the ever-changing agency landscape.",
        step: 1,
        image: '/images/welcome-slide.jpg',
        keyPoints: [
            "Understanding the shift in agency skills",
            "Planning your career progression",
            "Building foundational capabilities"
        ]
    },
    {
        id: 'platform-evolution',
        title: "It used to be enough, to just Master a Platform",
        content: "Maybe you know Google  or Meta Ads really well, and a while ago that was enough. Creative and Data skills were often a bit of an after thought. That's changed.",
        step: 2,
        image: '/images/evolution.svg',
        keyPoints: [
            "Platform expertise",
            "Basic creative skills",
            "Basic analytics"
        ]
    },
    {
        id: 'creative-importance',
        title: "Elevating Creative Skills",
        content: "Over time, Creative & Data skills have become far more important. Creative is responsible for 50% of campaign results. And covers much more than just writing text ads.",
        step: 3,
        image: '/images/creative-skills.svg',
        keyPoints: [
            "Creative skills include:",
            "Writing ads",
            "Image ads & Video scripts",
            "Landing page copy",
            "Email marketing",
            "Offer development"
        ]
    },
    {
        id: 'data-mastery',
        title: "Going beyond just Data",
        content: "Data is more than analytics or conversion tracking. It's about understanding the Data that both the Machines & Humans need to optimze campaigns properly.",
        step: 4,
        image: '/images/data-mastery.svg',
        keyPoints: [
            "Data skills include:",
            "Google Ads scripts",
            "Analytics implementation",
            "Attribution modeling",
            "Conversion tracking",
            "Lead scoring"
        ]
    },
    {
        id: 'dual-specialization',
        title: "Pick at least 2",
        content: "To be an in-demand PPC professional in 2025 & beyond, you'll want to master at least two of these three core pillars.",
        step: 5,
        image: '/images/specialization.svg',
        keyPoints: [
            "Creative excellence",
            "Platform mastery",
            "Data expertise"
        ]
    },
    {
        id: 'strategic-thinking',
        title: "There are two other paths: 1. Strategist",
        content: "Being a true Marketing Strategist requires decent knowledge of all three pillars. Maybe you only master one, but go deep into the areas you enjoy & understand how they fit together.",
        step: 6,
        image: '/images/strategy.svg',
        keyPoints: [
            "Strategic creative direction",
            "Cross-platform integration",
            "Data-driven decision making"
        ]
    },
    {
        id: 'management-skills',
        title: "2. Manager",
        content: "Management skills are about to be in very high demand. Develop the skills to manage teams, projects, and automated systems effectively.",
        step: 7,
        image: '/images/management.svg',
        keyPoints: [
            "Soft skills matter",
            "Team leadership",
            "Project management",
            "AI/Automation oversight"
        ]
    },
    {
        id: 'data-literacy',
        title: "If Data appeals to you, I can help.",
        content: "Creative was never my thing. So I've gone deep into scripts, automation and AI. These are (I believe) the most important skills to master in 2025 and what I love to teach.",
        step: 8,
        image: '/images/data-literacy.svg',
        skills: {
            data: [
                "Google Ads scripts",
                "Analytics implementation",
                "Attribution modeling",
                "Conversion tracking",
                "Lead scoring"
            ]
        }
    },
    {
        id: 'automation-ai',
        title: "Two main areas to focus on",
        content: "Automation is great & most business still need to do a lot of it. But AI is the future. Learn what's possible, when to use each & how they can help you & your clients/boss" ,
        step: 9,
        image: '/images/automation.svg',
        keyPoints: [
            "Automate Data into useful Information",
            "Analysis, sythesis & insight are needed",
            "AI & Machine learning basics",
            "AI Agents are the likely future"
        ]
    },
    {
        id: 'continuous-growth',
        title: "Your future is in your hands",
        content: "Establish a continuous learning mindset to stay ahead in the evolving digital landscape. Experiment & play with new tools. You must make time. The best way to learn is to do.",
        step: 10,
        image: '/images/growth.svg',
        keyPoints: [
            "Continuous skill development",
            "Experiment & play with new tools",
            "Make time for learning",
            "Take a workshop, share results & learnings",
            "Join my community"
        ]
    }
];